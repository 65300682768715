import { render, staticRenderFns } from "./MedicalRecordNew.vue?vue&type=template&id=5dcff055&scoped=true&"
import script from "./MedicalRecordNew.vue?vue&type=script&lang=js&"
export * from "./MedicalRecordNew.vue?vue&type=script&lang=js&"
import style0 from "./MedicalRecordNew.vue?vue&type=style&index=0&id=5dcff055&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dcff055",
  null
  
)

export default component.exports