<template>
  <div class="search-area-image">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="search-area">
        <div class="text-box-area">
          <span data-test="text-box-label">検索単語</span>
          <validation-provider
            class="validate"
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-text-box
              class="text-box"
              @input="inputText"
              :value="textValue"
              :placeholder="'画像タイトル・画像内テキスト'"
              @keyup="!invalid && $emit('click')"
            />
            <div class="error">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="period-area">
          <span class="period-label" data-test="period-label">期間</span>
          <validation-provider
            class="validate"
            :rules="`startDateRule:${endDate}`"
            v-slot="{ errors }"
          >
            <v-date-picker
              v-model="startDate"
              :input-props="{
                class: 'v-date-picker start',
                readonly: true
              }"
              @input="inputStartDate"
              data-test="v-date-picker-start"
              is-required
            />
            <div class="error">
              {{ errors[0] }}
            </div>
          </validation-provider>
          <div class="wavy-line">～</div>
          <v-date-picker
            v-model="endDate"
            :input-props="{
              class: 'v-date-picker end',
              readonly: true
            }"
            @input="inputEndDate"
            data-test="v-date-picker-end"
            is-required
          />
          <base-button-small-white class="clear" @click="clearDate"
            >クリア</base-button-small-white
          >
          <div class="search-button">
            <base-button-small-orange
              v-if="searchButtonFlg"
              ref="searchButton"
              @click="$emit('click')"
              :disabled="searchWaitFlg || invalid"
              :styles="buttonWidth"
              >検索</base-button-small-orange
            >
          </div>
        </div>
      </div>
      <tags-area
        :tagEditAreaShowFlg="tagEditAreaShowFlg"
        :value="selectedTagIds"
        :tags="tags"
        @select-tag="selectTag"
      ></tags-area>
      <div class="tag-edit-area">
        <div class="label">タグ管理</div>
        <div class="allow" data-test="allow" @click="toggleTagEditArea">
          <img
            class="bottom-allow"
            src="@/assets/images/allow3.png"
            v-show="tagEditAreaShowFlg"
          />
          <img
            class="right-allow"
            src="@/assets/images/allow4.png"
            v-show="!tagEditAreaShowFlg"
          />
        </div>
        <div v-if="tagEditAreaShowFlg" class="image-tag-edit-area">
          <div v-for="(tag, i) in editImageTags" :key="tag.id">
            <validation-observer
              class="validation-observer"
              v-slot="{ invalid }"
            >
              <validation-provider
                :rules="{ notSurrogatePair: true, requiredRule: tag.id !== 0 }"
                v-slot="{ errors }"
              >
                <base-text-box
                  :maxlength="15"
                  :placeholder="tag.id === 0 ? '新しいタグ' : ''"
                  :styles="{
                    height: '30px',
                    width: '200px',
                    'border-color': diffCheckBorderColor(tag.name, i)
                  }"
                  v-model="tag.name"
                  data-test="tag-text-box"
                />
                <span class="error" data-test="error-message">{{
                  errors[0]
                }}</span>
                <span
                  v-if="checkDuplicate(tag.name)"
                  class="error"
                  data-test="exist-error-message"
                  >既に使用されているタグ名です</span
                >
              </validation-provider>
              <button
                class="check-button"
                :disabled="
                  waitFlg ||
                    invalid ||
                    checkDuplicate(tag.name) ||
                    tag.name === ''
                "
                @click="tag.id === 0 ? createTag(tag) : updateTag(tag)"
                data-test="check-button"
              >
                <font-awesome-icon :icon="checkIcon" />
              </button>
              <button
                v-if="tag.id !== 0"
                :disabled="waitFlg"
                @click="openDeletePopup(tag)"
              >
                <img
                  src="@/assets/images/trash_orange.png"
                  width="15px"
                  height="18px"
                  alt="trash-orange"
                  data-test="trash-button"
                />
              </button>
            </validation-observer>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="overlay" v-if="flashMsgFlg">
          <div class="flash">
            <span class="text" data-test="flash-message">{{ flashMsg }}</span>
          </div>
        </div>
      </transition>
      <announce-popup
        v-if="popup.alertFlg"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        :disabled="waitFlg"
        @cancel="popup.alertFlg = false"
        @decision="popup.decision"
        @close="popup.alertFlg = false"
        >{{ popup.message }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import TagsArea from '@/components/parts/atoms/TagsArea'
import moment from 'moment'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/utils/validation_rules'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'

export default {
  name: 'SearchAreaImage',

  components: {
    BaseTextBox,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    TagsArea,
    FontAwesomeIcon,
    AnnouncePopup,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [DetectWindowSize, CheckInputDifference],

  props: {
    textValue: { type: String, default: '' },
    selectedTagIds: { type: Array },
    searchButtonFlg: { type: Boolean, default: true },
    defaultStartDate: { type: Date, default: null },
    defaultEndDate: { type: Date, default: null },
    searchWaitFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      tagEditAreaShowFlg: false,
      checkIcon: faCheck,
      editImageTags: [],
      flashMsgFlg: false,
      flashMsg: '',
      timeoutId: null,
      popup: {
        alertFlg: false,
        type: '',
        title: '',
        buttons: [],
        message: '',
        decision: () => {}
      },
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      imageTags: 'imageTags/getData'
    }),
    tags() {
      return this.imageTags.map((v, i) => {
        return { ...v, name: '# ' + v.name, key: i + 1 }
      })
    },
    buttonWidth() {
      return this.mixinWindowWidth < 1024 ? { width: '70px' } : {}
    },
    imageTagNames() {
      return this.imageTags.map(v => v.name)
    },
    editImageTagNames() {
      return this.editImageTags.map(v => v.name) || []
    },
    checkDuplicate: function() {
      return function(tagName) {
        if (tagName === '') return false
        return this.editImageTagNames.filter(
          v => v.toLowerCase() === tagName.toLowerCase()
        ).length > 1
          ? true
          : false
      }
    },
    diffCheckBorderColor: function() {
      return function(tagName, index) {
        return tagName !== this.mixinInitialData[index].name
          ? '#ef6c00'
          : '#cecece'
      }
    }
  },

  watch: {
    tagEditAreaShowFlg() {
      if (this.tagEditAreaShowFlg) {
        this.setEditImageTags()
      }
    }
  },

  created() {
    if (this.defaultStartDate) this.startDate = this.defaultStartDate
    if (this.defaultEndDate) this.endDate = this.defaultEndDate
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    inputText(text) {
      this.$emit('input', text)
    },
    clearDate() {
      this.startDate = null
      this.endDate = null
      this.$emit('clear')
    },
    inputStartDate(val) {
      if (this.startDate !== null) {
        this.$emit('input-start-date', moment(val).format('YYYYMMDD'))
      }
    },
    inputEndDate(val) {
      if (this.endDate !== null) {
        this.$emit('input-end-date', moment(val).format('YYYYMMDD'))
      }
    },
    selectTag(tagId) {
      if (!this.tagEditAreaShowFlg) this.$emit('select-tag', tagId)
    },
    toggleTagEditArea() {
      if (this.tagEditAreaShowFlg) {
        this.mixinDiffFlg
          ? this.openDiffAlertPopup()
          : (this.tagEditAreaShowFlg = false)
      } else {
        this.$emit('clear-selected-tags')
        this.tagEditAreaShowFlg = true
      }
    },
    openDiffAlertPopup() {
      this.popup = {
        alertFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['閉じない', '閉じる'],
        message: '入力内容を保存せずにタグ管理を閉じますか？',
        decision: () => {
          this.tagEditAreaShowFlg = false
          this.popup.alertFlg = false
        }
      }
    },
    setEditImageTags() {
      this.editImageTags = _.cloneDeep(
        [{ id: 0, name: '' }].concat(this.imageTags)
      )
      this.mixinInitialData = _.cloneDeep(this.editImageTags)
      this.mixinInputData = this.editImageTags
    },
    resetEditImageTags(type = '', tag) {
      if (type === 'create') {
        this.editImageTags.shift()
        const newData = [{ id: 0, name: '' }, tag].concat(this.editImageTags)
        this.editImageTags = _.cloneDeep(newData)
        this.mixinInputData = this.editImageTags
        this.mixinInitialData.shift()
        this.mixinInitialData = _.cloneDeep(
          [{ id: 0, name: '' }, tag].concat(this.mixinInitialData)
        )
      } else if (type === 'update') {
        const index = this.mixinInitialData.findIndex(v => v.id === tag.id)
        this.mixinInitialData[index].name = tag.name
      } else {
        this.mixinInputData = this.mixinInputData.filter(v => v.id !== tag.id)
        this.mixinInitialData = this.mixinInitialData.filter(
          v => v.id !== tag.id
        )
      }
    },
    async createTag(tag) {
      this.waitFlg = true
      const res = await this.$store.dispatch('imageTags/create', tag)
      if (res.result === true) {
        this.showFlashMsg('create')
        this.resetEditImageTags('create', res.tag)
      } else {
        this.openFailurePopup('create')
      }
      this.waitFlg = false
    },
    async updateTag(tag) {
      this.waitFlg = true
      const res = await this.$store.dispatch('imageTags/update', tag)
      if (res.result === true) {
        this.showFlashMsg('update')
        this.resetEditImageTags('update', res.tag)
      } else {
        this.openFailurePopup('update')
      }
      this.waitFlg = false
    },
    openDeletePopup(tag) {
      this.popup = {
        alertFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        message: '削除してもよろしいですか？',
        decision: () => this.deleteTag(tag)
      }
    },
    async deleteTag(tag) {
      this.waitFlg = true
      const res = await this.$store.dispatch('imageTags/delete', tag)
      if (res.result === true) {
        this.popup.alertFlg = false
        this.editImageTags = this.editImageTags.filter(v => v.id !== tag.id)
        this.showFlashMsg('delete')
        this.resetEditImageTags('delete', res.tag)
      } else {
        this.openFailurePopup('delete')
      }
      this.waitFlg = false
    },
    openFailurePopup(type) {
      this.popup = {
        alertFlg: true,
        type: 'failure',
        title: '失敗',
        buttons: ['閉じる'],
        message:
          type === 'create'
            ? '登録に失敗しました。'
            : type === 'update'
            ? '編集に失敗しました。'
            : '削除に失敗しました。',
        decision: () => {}
      }
    },
    showFlashMsg(type) {
      this.flashMsgFlg = true
      this.flashMsg =
        type === 'create'
          ? 'タグを作成しました。'
          : type === 'update'
          ? 'タグを編集しました。'
          : 'タグを削除しました。'
      this.timeoutId = setTimeout(() => {
        this.flashMsgFlg = false
        this.flashMsg = ''
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-area-image {
  width: 100%;
  padding: 20px 0;
  border: solid 1px #{$light-grey};
  background-color: #{$white_f7};
  align-items: center;
  box-sizing: border-box;
  font-size: 13px;
  .validation-observer {
    > .search-area {
      display: flex;
      > .text-box-area {
        width: 31%;
        display: flex;
        align-items: center;
        @include validate-message();
        > span {
          margin-left: 20px;
        }
        > .text-box {
          width: 300px;
          margin-left: 10px;
        }
      }
      > .date-box-area,
      .period-area {
        display: flex;
        align-items: center;
        width: 69%;
        @include validate-message();
        > .date-label,
        .period-label {
          margin-right: 10px;
        }
        > .search-button {
          margin: 0 20px 0 auto;
        }
        ::v-deep {
          > span {
            .v-date-picker {
              text-align: right;
              padding-right: 12px;
              width: 140px;
              height: 33px;
              border-radius: 5px;
              border: solid 2px #{$light-grey};
              text-indent: 5px;
              cursor: pointer;
              background-image: url('../../../assets/images/calendar.png');
              background-size: 18px 18px;
              background-repeat: no-repeat;
              background-position: 5px center;
            }
          }
        }
        > .wavy-line {
          margin: 0 10px;
        }
        ::v-deep .base-button-small-white button {
          margin-left: 5px;
          width: 70px;
        }
      }
      > .search-button {
        margin: 0 20px 0 auto;
      }
    }
    > .tag-edit-area {
      width: 100%;
      display: flex;
      margin-top: 20px;
      > .label {
        margin: 0 35px 0 20px;
      }
      > .allow {
        margin: 2px 20px 0 -20px;
        @include hover();
      }
      > .image-tag-edit-area {
        max-height: 300px;
        overflow-y: scroll;
        > div {
          margin-bottom: 20px;
          > .validation-observer {
            display: flex;
            @include validate-message();
            > button {
              color: #{$pumpkin};
              height: 30px;
              width: 30px;
              font-size: 13px;
              background-color: white;
              box-sizing: border-box;
              padding: auto;
              border: solid 2px #{$light-grey};
              border-radius: 6px;
              margin-right: 5px;
              cursor: pointer;
              &:disabled {
                pointer-events: none;
                background-color: #{$light-grey};
                color: #{$brown-gray};
                > img {
                  filter: grayscale(100%);
                }
              }
              &:hover {
                opacity: 0.8;
                background-color: #{$plus_ee_gray};
              }
              &:active {
                position: relative;
                top: 2px;
                height: 28px;
              }
            }
            > .check-button {
              margin-left: 5px;
            }
          }
        }
      }
    }

    > .overlay {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      > .flash {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-size: 18px;
        background: black;
        margin-top: 130px;
        padding: 0 20px;
        border-radius: 5px;
        > .text {
          color: white;
        }
      }
    }
  }
}
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-to,
.fade-enter {
  opacity: 0;
}
@media (max-width: $tablet-width) {
  .search-area-image {
    > .validation-observer {
      > .search-area {
        display: block;
        > .text-box-area {
          width: 100%;
        }
        > .period-area {
          width: 100%;
          margin-top: 20px;
          > .period-label {
            margin-left: 20px;
            margin-right: 36px;
          }
        }
      }
    }
  }
}
</style>
