<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="vaccine-reminders-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="$emit('close')"
        @click-close-mark="$emit('close')"
      >
        <template v-slot:content>
          <div class="content-wrap">
            <div class="header">
              <div class="title">予防接種連絡履歴</div>
              <div v-if="patientVaccines.length > 0" class="button">
                <base-button-register
                  :text="'新規登録'"
                  @click="openNewPopup"
                />
              </div>
            </div>
            <div v-if="patientName" class="patient-name">
              <div class="label">患者名</div>
              <div class="value">{{ patientName }}</div>
            </div>
            <div class="list">
              <div class="list-header">
                <div class="cell date">
                  <div class="text">日付</div>
                </div>
                <div class="cell media">
                  <div class="text">方法</div>
                </div>
                <div class="cell vaccines">
                  <div class="text">ワクチン</div>
                </div>
              </div>
              <div class="list-body">
                <div
                  class="row"
                  v-for="item in listVaccineReminders"
                  :key="item.id"
                  @click="openEditPopup(item.id)"
                >
                  <div class="cell date">
                    <div class="text">{{ toJpDate(item.date) }}</div>
                  </div>
                  <div class="cell media">
                    <div class="text">{{ toJpMedia(item.media) }}</div>
                  </div>
                  <div
                    class="cell vaccines"
                    :title="toVaccineNames(item.vaccineIds)"
                  >
                    <div class="text">
                      {{ toVaccineNames(item.vaccineIds) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <vaccine-reminder-new-popup
        v-if="newPopupFlg"
        :patientId="patientId"
        :patientName="patientName"
        :patientVaccines="patientVaccines"
        @close="newPopupFlg = false"
        @updated="updated"
      />
      <vaccine-reminder-edit-popup
        v-if="editPopupFlg"
        :patientName="patientName"
        :patientVaccines="patientVaccines"
        :editVaccineReminder="editVaccineReminder"
        @close="editPopupFlg = false"
        @updated="updated"
      />
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import VaccineReminderNewPopup from '@/components/popups/VaccineReminderNewPopup'
import VaccineReminderEditPopup from '@/components/popups/VaccineReminderEditPopup'

import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import moment from 'moment'

export default {
  name: 'VaccineRemindersPopup',

  components: {
    FocusTrap,
    Popup,
    BaseButtonRegister,
    VaccineReminderNewPopup,
    VaccineReminderEditPopup
  },

  props: {
    patientId: { type: Number },
    patientName: { type: String, default: '' },
    patientVaccines: { type: Array },
    vaccineReminders: { type: Array }
  },

  data() {
    return {
      newPopupFlg: false,
      editPopupFlg: false,
      editVaccineReminder: undefined
    }
  },

  computed: {
    ...mapGetters({
      masterDatumById: 'master/getDataById'
    }),
    listVaccineReminders() {
      const vaccineReminders = this.vaccineReminders
        .slice()
        .sort((a, b) => (a.date < b.date ? 1 : -1))
      return vaccineReminders
    }
  },

  methods: {
    toJpDate(date) {
      return date ? moment(date, 'YYYYMMDD').format('YYYY年MM月DD日') : ''
    },
    toJpMedia(media) {
      const conversion = {
        mail: 'メール',
        tel: '電話',
        letter: '郵便',
        other: 'その他'
      }
      return conversion[media]
    },
    toVaccineNames(vaccineIds) {
      const vaccineNames = vaccineIds
        .map(v => {
          const vaccine = this.masterDatumById('vaccines', v)
          return vaccine.name
        })
        .join('、')
      return vaccineNames
    },
    openNewPopup() {
      this.newPopupFlg = true
    },
    openEditPopup(id) {
      this.editVaccineReminder = this.vaccineReminders.find(
        vaccineReminder => vaccineReminder.id === id
      )
      this.editPopupFlg = true
    },
    updated() {
      this.$emit('updated')
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$very-light-grey};
.vaccine-reminders-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content-wrap {
    box-sizing: border-box;
    width: 700px;
    padding: 0 5px 0 5px;
    > .header {
      display: flex;
      justify-content: space-between;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
        text-align: left;
      }
    }
    > .patient-name {
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      > .label {
        margin-right: 15px;
      }
      > .value {
        flex: 1;
      }
    }
    > .list {
      margin-top: 15px;
      width: 100%;
      text-align: left;
      > .list-header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 25px;
        border: 1px solid transparent;
        > .cell {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 0 5px;
          & + .cell {
            border-left: 1px solid transparent;
          }
          &.date {
            width: 130px;
          }
          &.media {
            width: 70px;
          }
          &.vaccines {
            flex: 1;
          }
          > .text {
            flex: 1;
            width: 0;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      > .list-body {
        box-sizing: border-box;
        max-height: 401px;
        border: 1px solid $border-color;
        overflow-y: auto;
        > .row {
          box-sizing: border-box;
          display: flex;
          height: 40px;
          background-color: #{$gray_fc};
          @include hover();
          &:first-child {
            margin-top: -1px;
            border-top: 1px solid transparent;
          }
          &:nth-child(even) {
            background-color: #{$white};
          }
          & ~ .row {
            border-top: 1px solid $border-color;
          }
          > .cell {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0 5px;
            & + .cell:not(.button) {
              border-left: 1px solid $border-color;
            }
            &.date {
              width: 130px;
            }
            &.media {
              width: 70px;
            }
            &.vaccines {
              flex: 1;
              > .text {
                text-align: left;
              }
            }
            > .text {
              flex: 1;
              width: 0;
              font-size: 13px;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
