<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="vaccine-reminder-new-popup"
      tabindex="-2"
      @keyup.esc.stop="confirmClose"
    >
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          :layerNumber="2"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          :buttons="['閉じる', '登録']"
          @click-close-mark="confirmClose"
          @cancel="confirmClose"
          @decision="createData"
        >
          <template v-slot:content>
            <div class="content-wrap">
              <div class="title">予防接種連絡新規登録</div>
              <div class="input-form">
                <div v-if="patientName" class="row name">
                  <div class="label">患者名</div>
                  <div class="value">
                    <span>{{ patientName }}</span>
                  </div>
                </div>
                <div class="row date">
                  <div class="label">連絡日</div>
                  <base-date-picker
                    :maxDate="today"
                    :clearFlg="false"
                    v-model="vaccineReminder.date"
                  />
                </div>
                <div class="row media">
                  <div class="label">
                    連絡方法
                    <div class="required">必須</div>
                  </div>
                  <validation-provider
                    class="provider"
                    :rules="{ requiredRule: true, oneOfRule: mediaSelectData }"
                    v-slot="{ errors }"
                  >
                    <base-select-box
                      class="select-box"
                      :value="mediaId"
                      :selectData="mediaSelectData"
                      :styles="{ width: '120px' }"
                      @input="selectMedia"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="row top vaccine">
                  <div class="label top">ワクチン</div>
                  <div class="list">
                    <base-check-box
                      class="check-box"
                      v-for="item in checkList"
                      :key="item.id"
                      :isChecked="item.value"
                      :labelText="item.name"
                      @input="selectVaccine(item.id, $event)"
                    />
                  </div>
                </div>
                <div class="row top content">
                  <div class="label top">内容</div>
                  <validation-provider
                    class="provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      class="textarea"
                      :styles="textAreaStyle"
                      v-model="vaccineReminder.content"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        class="register-popup"
        v-if="registerPopup.flg"
        v-bind="registerPopup"
        @close="closeRegisterPopup"
        >{{ registerPopup.message }}</announce-popup
      >
      <announce-popup
        class="unsaved-popup"
        v-if="unsavedPopup.flg"
        v-bind="unsavedPopup"
        @cancel="closeUnsavedPopup"
        @decision="close"
        >{{ unsavedPopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'VaccineReminderNewPopup',

  components: {
    Popup,
    BaseDatePicker,
    BaseSelectBox,
    BaseCheckBox,
    BaseMultipleLinesTextBox,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientId: { type: Number },
    patientName: { type: String, default: '' },
    patientVaccines: { type: Array }
  },

  data() {
    return {
      initialData: {},
      inputData: {},
      vaccineReminder: {
        date: '',
        media: '',
        vaccineIds: [],
        content: ''
      },
      today: moment().format('YYYYMMDD'),
      mediaId: '',
      mediaSelectData: [
        { id: 1, name: '電話', media: 'tel' },
        { id: 2, name: '郵便', media: 'letter' },
        { id: 3, name: 'その他', media: 'other' }
      ],
      checkList: [],
      textAreaStyle: {
        width: '100%',
        height: '200px',
        fontSize: '13px'
      },
      registerPopup: {
        flg: false,
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる'],
        layerNumber: 3
      },
      unsavedPopup: {
        flg: false,
        type: 'alert',
        title: '確認',
        message: '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
        buttons: ['キャンセル', '閉じる'],
        layerNumber: 3
      },
      updatedFlg: true,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      masterDatumById: 'master/getDataById'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    }
  },

  created() {
    this.$nextTick(() => {
      this.$refs.observer.validate()
      this.$refs.observer.reset()
    })
    this.checkList = this.patientVaccines.map(v => {
      const vaccine = this.masterDatumById('vaccines', v.vaccineId)
      return { id: vaccine.id, name: vaccine.name, value: true }
    })
    const vaccineIds = this.makeVaccineIds()
    this.vaccineReminder.date = this.today
    this.vaccineReminder.vaccineIds = vaccineIds
    this.inputData = this.vaccineReminder
    this.initialData = { ...this.inputData }
  },

  methods: {
    makeVaccineIds() {
      const vaccineIds = this.checkList.flatMap(v => {
        if (!v.value) return []
        return v.id
      })
      return vaccineIds
    },
    close() {
      this.$emit('close')
    },
    confirmClose() {
      this.diffFlg ? (this.unsavedPopup.flg = true) : this.close()
    },
    closeUnsavedPopup() {
      this.unsavedPopup.flg = false
    },
    selectMedia(id) {
      this.mediaId = id
      const media = this.mediaSelectData.find(v => v.id === this.mediaId).media
      this.vaccineReminder.media = media
    },
    selectVaccine(id, value) {
      const item = this.checkList.find(v => v.id === id)
      item.value = value
      const vaccineIds = this.makeVaccineIds()
      this.vaccineReminder.vaccineIds = vaccineIds
    },
    async createData() {
      this.waitFlg = true
      const vaccineIds = this.vaccineReminder.vaccineIds.join(',')
      const vaccineReminder = {
        ...this.vaccineReminder,
        patientId: this.patientId,
        vaccineIds
      }
      const result = await this.$store.dispatch(
        'vaccineReminders/create',
        vaccineReminder
      )
      if (result === true) {
        this.initialData = { ...this.inputData }
        this.registerPopup.type = 'success'
        this.registerPopup.title = '完了'
        this.registerPopup.message = '新規登録しました'
        this.updatedFlg = true
      } else {
        this.registerPopup.type = 'failure'
        this.registerPopup.title = '失敗'
        if (result === 'no patient') {
          this.registerPopup.message =
            '登録に失敗しました。\n対象の患者は既に削除されています。'
          this.updatedFlg = true
        } else {
          this.registerPopup.message = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.registerPopup.flg = true
    },
    closeRegisterPopup() {
      this.registerPopup.flg = false
      if (this.updatedFlg) {
        this.close()
        this.$emit('updated')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccine-reminder-new-popup {
  .content-wrap {
    box-sizing: border-box;
    width: 640px;
    margin-bottom: 20px;
    padding: 0 5px 0;
    > .title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      color: #{$greyish-brown};
    }
    > .input-form {
      margin-top: 25px;
      > .row {
        display: flex;
        align-items: center;
        @include validate-message();
        &.top {
          align-items: normal;
        }
        > .provider .error {
          // date-pickerと被らないようにするため
          z-index: 0;
        }
        ~ .row {
          margin-top: 20px;
        }
        > .label {
          display: flex;
          align-items: center;
          width: 140px;
          font-size: 15px;
          &.top {
            line-height: 33px;
            align-items: normal;
          }
          > .required {
            margin-left: 10px;
            font-size: 13px;
            color: #{$tomato};
          }
        }
        > .value {
          flex: 1;
          width: 0;
          font-size: 15px;
        }
        > .provider {
          flex: 1;
        }
        > .list {
          margin-top: 3px;
          flex: 1;
          width: 0;
          display: flex;
          flex-wrap: wrap;
          max-height: 150px;
          overflow-y: auto;
          > .check-box {
            display: flex;
            align-items: center;
            height: 30px;
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
