<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="estimate-template-new-popup"
      @keyup.esc.stop="checkClose"
      tabindex="-2"
    >
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :layerNumber="2"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          :buttons="['閉じる', '登録']"
          @click-close-mark="checkClose"
          @cancel="checkClose"
          @decision="createData"
        >
          <template v-slot:content>
            <div class="content-wrap">
              <div class="title">見積書備考テンプレート新規登録</div>
              <div class="input-form">
                <div class="row name">
                  <text-input-form
                    :maxlength="15"
                    :requiredFlg="true"
                    :styles="{ width: '350px' }"
                    v-model="estimateTemplate.name"
                    >テンプレート名</text-input-form
                  >
                </div>
                <div class="row content">
                  <validation-provider
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      :styles="textAreaStyle"
                      v-model="estimateTemplate.content"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        class="register-popup"
        v-if="registerPopup.flg"
        v-bind="registerPopup"
        @close="closeRegisterPopup"
        >{{ registerPopup.message }}</announce-popup
      >
      <announce-popup
        class="unsaved-popup"
        v-if="unsavedPopup.flg"
        v-bind="unsavedPopup"
        @cancel="closeUnsavedPopup"
        @decision="close"
        >{{ unsavedPopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'EstimateTemplateNewPopup',

  components: {
    Popup,
    TextInputForm,
    BaseMultipleLinesTextBox,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  data() {
    return {
      initialData: {},
      inputData: {},
      estimateTemplate: {
        name: '',
        content: ''
      },
      textAreaStyle: {
        width: '100%',
        height: 'auto',
        minHeight: '200px',
        maxHeight: '400px',
        fontSize: '13px',
        resize: 'vertical'
      },
      registerPopup: {
        flg: false,
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる'],
        layerNumber: 3
      },
      unsavedPopup: {
        flg: false,
        type: 'alert',
        title: '確認',
        message: '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
        buttons: ['キャンセル', '閉じる'],
        layerNumber: 3
      },
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    }
  },

  created() {
    this.inputData = this.estimateTemplate
    this.initialData = { ...this.inputData }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    checkClose() {
      this.diffFlg ? (this.unsavedPopup.flg = true) : this.close()
    },
    closeUnsavedPopup() {
      this.unsavedPopup.flg = false
    },
    async createData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'estimateTemplates/create',
        this.estimateTemplate
      )
      if (result === true) {
        this.initialData = { ...this.inputData }
        this.registerPopup.type = 'success'
        this.registerPopup.title = '完了'
        this.registerPopup.message = '新規登録しました'
      } else {
        this.registerPopup.type = 'failure'
        this.registerPopup.title = '失敗'
        this.registerPopup.message = '登録に失敗しました'
      }
      this.waitFlg = false
      this.registerPopup.flg = true
    },
    closeRegisterPopup() {
      this.registerPopup.flg = false
      if (this.registerPopup.type === 'success') {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-template-new-popup {
  .content-wrap {
    box-sizing: border-box;
    width: 650px;
    margin-bottom: 20px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      color: #{$greyish-brown};
    }
    > .input-form {
      margin-top: 25px;
      > .row {
        @include validate-message();
        & ~ .row {
          margin-top: 25px;
        }
      }
    }
  }
}
</style>
