<template>
  <div class="treatment-item-input-form">
    <treatment-item-input-table
      class="input-table"
      ref="inputTable"
      :treatmentItem="treatmentItem"
      :insuranceType="insuranceType"
      @click-plus="openDetail"
      @click-trash="$emit('click-trash')"
      @click-cross="$emit('click-cross')"
    />
    <treatment-item-detail-input-popup
      v-if="popupFlg"
      :treatmentItem="treatmentItem"
      :originalPopupPreventLeaveFlg="originalPopupPreventLeaveFlg"
      :insuranceType="insuranceType"
      @cancel="popupFlg = false"
      @decision="registerDetail"
    />
  </div>
</template>

<script>
import TreatmentItemInputTable from '@/components/parts/molecules/TreatmentItemInputTable'
import TreatmentItemDetailInputPopup from '@/components/popups/TreatmentItemDetailInputPopup'

export default {
  name: 'TreatmentItemInputForm',

  components: {
    TreatmentItemInputTable,
    TreatmentItemDetailInputPopup
  },

  props: {
    treatmentItem: { type: Object },
    originalPopupPreventLeaveFlg: { type: Boolean },
    insuranceType: { type: String, default: '' }
  },

  data() {
    return {
      popupFlg: false
    }
  },

  methods: {
    openDetail() {
      this.popupFlg = true
    },
    registerDetail(treatmentItem) {
      this.$emit('decision', treatmentItem)
      this.$refs.inputTable.prescriptionFlg =
        treatmentItem.prescription.length > 0
      this.popupFlg = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
