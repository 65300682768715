<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="problem-new-popup" @keyup.esc.stop="checkDiff" tabindex="-1">
        <popup
          :invalid="invalid || waitFlg"
          :layerNumber="2"
          @cancel="checkDiff"
          @decision="create"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">プロブレム新規登録</div>
              </div>
              <div class="input-form-wrapper">
                <problem-input-form
                  :problem="newProblem"
                  @input-injury-date="inputDate($event, 'injury')"
                  @input-healing-date="inputDate($event, 'healing')"
                  @clear-healing-date="clearHealingDate"
                />
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popup.popupFlg"
          v-bind="popup"
          @cancel="resetPopup"
          @decision="closePopup"
          @close="closePopup"
          >{{ popup.content }}</announce-popup
        >
      </div>
    </validation-observer>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import ProblemInputForm from '@/components/parts/organisms/ProblemInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { ValidationObserver } from 'vee-validate'
import { FocusTrap } from 'focus-trap-vue'
import _ from 'lodash'

export default {
  name: 'ProblemNewPopup',

  components: {
    FocusTrap,
    Popup,
    ProblemInputForm,
    ValidationObserver,
    AnnouncePopup
  },

  props: {
    patientId: { type: Number }
  },

  mixins: [CheckPopupInputDifference],

  data() {
    return {
      initialData: {
        patientId: this.patientId,
        name: '',
        injuryDate: '',
        healingDate: ''
      },
      newProblem: {
        patientId: this.patientId,
        name: '',
        injuryDate: '',
        healingDate: ''
      },
      waitFlg: false,
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: ''
      }
    }
  },

  computed: {
    diffFlg() {
      return !_.isEqual(this.initialData, this.newProblem)
    }
  },

  methods: {
    inputDate(date, dateType) {
      dateType === 'injury'
        ? this.$set(this.newProblem, 'injuryDate', date)
        : this.$set(this.newProblem, 'healingDate', date)
    },
    clearHealingDate() {
      this.$set(this.newProblem, 'healingDate', '')
    },
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 3,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.closePopup()
      }
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: ''
      }
    },
    closePopup() {
      this.popup.type !== 'failure' ? this.$emit('close') : this.resetPopup()
    },
    async create() {
      this.waitFlg = true
      const res = await this.$store.dispatch('problems/create', this.newProblem)
      if (res === true) {
        this.initialData = _.cloneDeep(this.newProblem)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '新規登録しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '登録に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.problem-new-popup {
  .content {
    min-width: 680px;
    > .header {
      > .title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    > .input-form-wrapper {
      margin-top: 25px;
    }
  }
}
</style>
