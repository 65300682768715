<template>
  <div class="auto-complete-input-form">
    <div class="heading" :style="headingStyle">
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body" :style="bodyStyle">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        v-slot="{ errors }"
      >
        <base-data-list
          :maxlength="maxlength"
          :max="max"
          :min="min"
          :placeholder="placeholder"
          :type="type"
          :disabled="disabled"
          :disabledColorWhite="disabledColorWhite"
          :styles="styles"
          :selectData="selectData"
          v-model="input"
          @blur="$emit('blur')"
        />
        <div v-if="errorShowFlg" class="error">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseDataList from '@/components/parts/atoms/BaseDataList'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'AutoCompleteInputForm',

  components: {
    BaseDataList,
    ValidationProvider
  },

  props: {
    headingStyle: { type: Object },
    bodyStyle: { type: Object },
    requiredFlg: { type: Boolean, default: false },
    validationRules: { type: Object },
    maxlength: { type: Number, default: 255 },
    max: { type: Number },
    min: { type: Number },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    styles: { type: Object },
    errorShowFlg: { type: Boolean, default: true },
    value: { type: [String, Number], default: '' },
    selectData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auto-complete-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    @include validate-message();
  }
}
</style>
