<template>
  <div class="medical-content-information">
    <div
      ref="target"
      class="border-area"
      :style="borderAreaStyles"
      @click="$emit('click-medical-content', medicalContent)"
    >
      <div v-if="patientMedicalContentsFlg" class="heading">
        <div class="date">
          <span class="emphasis">診療日：</span>{{ medicalContent.formatDate }}
        </div>
        <base-button-trash
          class="button"
          v-if="lookOnlyFlg === 0 && deleteButtonShowFlg"
          :styles="{ width: '30px', height: '30px' }"
          @click.stop="$emit('delete-medical-content', medicalContent)"
        />
      </div>
      <div v-if="!patientMedicalContentsFlg" class="patient-information-table">
        <data-table v-bind="tableData" :items="[medicalContent]" />
        <base-button-trash
          class="button"
          v-if="lookOnlyFlg === 0 && deleteButtonShowFlg"
          @click.stop="$emit('delete-medical-content', medicalContent)"
        />
      </div>
      <div class="image-content-area">
        <div v-if="imageObj" class="image-frame">
          <template v-if="imageObj.medicalContentImageTexts">
            <span
              v-for="textObj in imageObj.medicalContentImageTexts"
              class="image-text"
              :key="textObj.id"
              :style="textObj.textStyle"
              v-html="$sanitize(escapeTextExceptHighLight(textObj.text))"
            ></span>
          </template>
          <div class="image-area">
            <img
              v-if="imageObj.thumbnailImage"
              class="under-image"
              :src="imageObj.thumbnailImage"
            />
            <img
              v-if="imageObj.overImage"
              class="over-image"
              :src="imageObj.overImage"
            />
          </div>
        </div>
        <div v-if="imageGettingStatus" class="image-getting-status">
          {{ imageGettingStatus }}
        </div>
        <div class="text-area">
          <div
            class="content"
            v-html="$sanitize(contentEscapedExceptHighLight)"
            :style="contentStyles"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/parts/organisms/DataTable'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import { mapGetters } from 'vuex'
import { COMPONENT_TAG_IN_CONTENT_REGEX } from '@/utils/define'
import { escapeHtml } from '@/utils/escape'
import { unescapeHighLightHtml } from '@/utils/unescape'
import { highLightText } from '@/utils/high_light'

export default {
  name: 'MedicalContentInformation',

  components: {
    DataTable,
    BaseButtonTrash
  },

  props: {
    medicalContent: { type: Object },
    searchRegExp: { type: [RegExp, null], default: null },
    imagesByMedicalContentOriginalId: { type: Object },
    patientMedicalContentsFlg: { type: Boolean, default: false },
    deleteButtonShowFlg: { type: Boolean, default: false },
    lastIndex: { type: Number, default: 0 },
    initialDisplayFlg: { type: Boolean },
    numberSetFailedToGetImages: { type: Set, default: () => new Set() }
  },

  data() {
    return {
      tableData: {
        keys: [
          'ownerShowId',
          'ownerFullName',
          'patientShowId',
          'patientName',
          'formatDate'
        ],
        headers: ['飼主ID', '飼主名', '患者ID', '患者名', '診療日'],
        bodyCellStyles: [
          { textAlign: 'center' },
          { textAlign: 'center' },
          { textAlign: 'center' },
          { textAlign: 'center' },
          { textAlign: 'center' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    borderAreaStyles() {
      return this.patientMedicalContentsFlg ? {} : { alignItems: 'center' }
    },
    imageObj() {
      const imageObj = this.imagesByMedicalContentOriginalId[
        this.medicalContent.originalId
      ]
      return imageObj
    },
    isFailedToGetImages() {
      if (this.patientMedicalContentsFlg) return false
      return this.numberSetFailedToGetImages.has(this.medicalContent.number)
    },
    imageGettingStatus() {
      const hasImages = COMPONENT_TAG_IN_CONTENT_REGEX.test(
        this.medicalContent.content
      )
      return hasImages
        ? this.imageObj
          ? ''
          : this.isFailedToGetImages
          ? '画像取得失敗'
          : '画像取得中...'
        : ''
    },
    contentStyles() {
      return !this.searchRegExp
        ? {
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 6,
            overflow: 'hidden'
          }
        : {
            maxHeight: '234px',
            overflow: 'hidden'
          }
    },
    contentEscapedExceptHighLight() {
      if (this.searchRegExp && this.medicalContent.displayContent.length > 0) {
        const highLightedText = highLightText(
          this.medicalContent.displayContent,
          this.searchRegExp
        )
        const escapedContent = escapeHtml(highLightedText)
        return unescapeHighLightHtml(escapedContent)
      } else {
        return escapeHtml(this.medicalContent.displayContent)
      }
    }
  },

  mounted() {
    if (!this.patientMedicalContentsFlg) {
      const target = this.$refs.target
      let observer = new IntersectionObserver(() => {
        this.intersect()
        this.emitScrollBottom()
      })
      observer.observe(target)
    }
  },

  methods: {
    intersect() {
      //診療内容の画像取得で使用
      this.$emit('intersect', this.medicalContent.number)
    },
    emitScrollBottom() {
      //診療内容データの取得で使用
      if (
        !this.initialDisplayFlg &&
        this.medicalContent.number === this.lastIndex
      ) {
        this.$emit('scroll-bottom')
      }
    },
    escapeTextExceptHighLight(text) {
      if (this.searchRegExp && text.length > 0) {
        const highLightedText = highLightText(text, this.searchRegExp)
        const escapedContent = escapeHtml(highLightedText)
        return unescapeHighLightHtml(escapedContent)
      } else {
        return escapeHtml(text)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-information {
  padding-bottom: 20px;
  padding-right: 15px;
  > .border-area {
    border: 1px solid #{$light-grey};
    padding: 15px;
    word-wrap: break-word;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    > .heading {
      display: flex;
      align-items: flex-end;
      height: 30px;
      padding-bottom: 5px;
      border-bottom: 1px solid #{$light-grey};
      margin-bottom: 10px;
      > .date {
        margin-left: 10px;
        font-size: 15px;
        > .emphasis {
          font-weight: bold;
        }
      }
      > .button {
        margin-left: auto;
      }
    }
    > .patient-information-table {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      > .button {
        margin-left: 15px;
      }
    }
    > .image-content-area {
      display: flex;
      > .image-frame {
        width: 150px;
        height: 150px;
        margin-right: 15px;
        position: relative;
        > .image-area {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          width: 150px;
          height: 150px;
          > img {
            width: auto;
            height: auto;
            vertical-align: top;
          }
          > .under-image {
            max-width: 150px;
            max-height: 150px;
          }
          > .over-image {
            max-width: 150px;
            max-height: 150px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      > .image-getting-status {
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-right: 15px;
      }
      > .text-area {
        flex: 1;
        overflow: hidden;
        > .content {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
