<template>
  <div class="medical-records-all">
    <base-loading :waitFlg="waitFlg" />
    <div class="area search">
      <search-area-detail
        v-if="detailSearchFlg"
        :selectBoxData="detailSelectBoxData"
        :searchConditions="searchConditions"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @select="setSelectId"
        @input-text="inputText(1, $event)"
        @input-text2="inputText(2, $event)"
        @add-search-condition="addSearchCondition"
        @trash="deleteSearchCondition"
        @hide-detail="toggleDetailSearchFlg"
        @search="resetAndGetData"
      />
      <search-area
        v-else
        class="search-area"
        :textBoxLabel="'検索単語'"
        :placeholder="'飼主ID、飼主名、患者ID、患者名、診断名、担当者名'"
        :textBoxStyles="{ width: '380px' }"
        :textValue="searchText"
        :periodFlg="true"
        :periodLabel="'診療日'"
        :toggleDetailSearchFlg="true"
        :defaultStartDate="
          moment()
            .subtract(7, 'd')
            .toDate()
        "
        :defaultEndDate="moment().toDate()"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        @input="inputSearchText"
        @show-detail="toggleDetailSearchFlg"
        @search="resetAndGetData"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">検索結果：{{ count }}件</div>
      <div class="order-setting">
        <div class="label">並び替え：診療日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <recycle-scroller
      class="area scroller"
      v-if="dispMedicalRecords.length > 0"
      :items="dispMedicalRecords"
      :item-size="221"
      key-field="key"
      v-slot="{ item }"
    >
      <medical-record-row
        class="medical-record-row"
        v-bind="item"
        :lastIndex="dispMedicalRecords.length - 1"
        :patientImage="imagesByPatientId[item.patientId]"
        @intersect="intersect(item.key)"
        @scroll-bottom="getNextData"
        @click="pushToKartePage"
      />
    </recycle-scroller>
    <announce-popup
      v-if="popup.show"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      @close="popup.show = false"
      >{{ popup.message }}</announce-popup
    >
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import SearchArea from '@/components/parts/molecules/SearchArea'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import { RecycleScroller } from 'vue-virtual-scroller'
import MedicalRecordRow from '@/components/parts/organisms/MedicalRecordRow'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { makePriceDetail, makePaymentDetail } from '@/utils/price_calculation'
import { makeBirthAge } from '@/utils/patient_info'
import { getAxiosObject } from '@/utils/library'
import { decodeBase64fromBuffer } from '@/utils/base64'
import _ from 'lodash'
import { makeHIdByMPOId, compareKarte } from '@/utils/compare'
import { toHanKakuId } from '@/utils/convert_string'

export default {
  name: 'MedicalRecordsAll',

  components: {
    BaseLoading,
    SearchArea,
    SearchAreaDetail,
    BaseSelectBox,
    RecycleScroller,
    MedicalRecordRow,
    AnnouncePopup
  },

  data() {
    return {
      dispMedicalRecords: [],
      detailSearchFlg: false,
      searchText: '',
      startDate: '',
      endDate: '',
      detailSelectBoxData: [
        { id: 1, name: '飼主名', type: 'textBox' },
        { id: 2, name: '患者名', type: 'textBox' },
        { id: 3, name: '飼主ID', type: 'textBox' },
        { id: 4, name: '患者ID', type: 'textBox' },
        { id: 5, name: '電話番号', type: 'textBox' },
        { id: 6, name: '住所', type: 'textBox' },
        { id: 7, name: '患者の年齢', type: 'textBoxes' },
        { id: 13, name: '患者の性別', type: 'selectBox' },
        { id: 14, name: '患者の種別', type: 'selectBox' },
        { id: 8, name: '患者の品種', type: 'textBox' },
        { id: 9, name: '診療日', type: 'datePicker' },
        { id: 10, name: '診断名', type: 'textBox' },
        { id: 11, name: '診療費', type: 'textBoxes' },
        { id: 12, name: '入金日', type: 'datePicker' },
        { id: 15, name: '未収金', type: 'textBoxes' },
        { id: 16, name: '担当者', type: 'selectBox' }
      ],
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      searchConditions: [{ id: 1, selectId: 0, text: '', text2: '' }],
      imagesByPatientId: {},
      updatedImagesByPatientId: {},
      intersectionTimeoutId: null,
      popup: {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      },
      moment: moment,
      page: 0,
      isFull: true,
      waitFlg: false,
      count: 0,
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters({
      medicalRecords: 'medicalRecords/getData',
      medicalPayments: 'medicalPayments/getData',
      medicalTreatmentItems: 'medicalTreatmentItems/getData',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      patientSexes: 'master/selectPatientSexesZero',
      getPrefecture: 'master/getPrefectureIndexedById',
      getSpecies: 'species/getDataById',
      species: 'species/getData',
      getMedicalPaymentsByRecordOriginalId:
        'medicalPayments/getDataByMedicalRecordOriginalId',
      getDisease: 'diseases/getDataById',
      getStaff: 'staffs/getDataById',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      hospitalizations: 'hospitalizations/getData'
    })
  },

  watch: {
    orderType() {
      this.resetAndGetData()
    }
  },

  created() {
    const initStartDate = moment()
      .subtract(7, 'd')
      .format('YYYYMMDD')
    const initEndDate = moment().format('YYYYMMDD')
    const hIdByMPOId = makeHIdByMPOId(this.hospitalizations)
    const filteredMedicalRecords = this.medicalRecords
      .filter(mr => {
        return initStartDate <= mr.date && mr.date <= initEndDate
      })
      .sort((a, b) => {
        a.medicalRecordOriginalId = a.originalId
        b.medicalRecordOriginalId = b.originalId
        const aMP = this.getMedicalPayment(a.originalId)
        const bMP = this.getMedicalPayment(b.originalId)
        return compareKarte(a, b, aMP, bMP, this.orderType, hIdByMPOId)
      })
    this.count = filteredMedicalRecords.length
    this.makeMedicalRecords(
      filteredMedicalRecords,
      this.medicalPayments,
      this.medicalTreatmentItems
    )
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'patients/updateAfter') {
        mutation.payload.forEach(patient => {
          const existsSamePatient = this.imagesByPatientId.hasOwnProperty(
            patient.id
          )
          if (existsSamePatient) {
            const acquiredImage = this.imagesByPatientId[patient.id]
            if (patient.image !== acquiredImage) {
              this.$set(
                this.updatedImagesByPatientId,
                patient.id,
                patient.image
              )
            }
          }
        })
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
    clearTimeout(this.intersectionTimeoutId)
  },

  methods: {
    makeMedicalRecords(medicalRecords, medicalPayments, medicalTreatmentItems) {
      const lastKey = this.dispMedicalRecords.length
      const medicalPaymentMapByMedicalRecordOriginalId = new Map(
        medicalPayments.map(mp => [mp.medicalRecordOriginalId, mp])
      )
      const getMedicalTreatmentItemsByMedicalPaymentId = _.groupBy(
        medicalTreatmentItems,
        'medicalPaymentId'
      )
      const addData = medicalRecords.map((medicalRecord, index) => {
        const {
          ownerId,
          ownerShowId,
          ownerFullName,
          ownerFullNameKana,
          tel,
          address
        } = this.makeOwnerInfo(medicalRecord.ownerId)
        const {
          patientId,
          patientShowId,
          patientName,
          patientNameKana,
          patientBirthAge,
          patientSex,
          patientSexNum,
          patientSpecies,
          patientSpeciesId
        } = this.makePatientInfo(medicalRecord.patientId)
        const {
          medicalTreatmentDate,
          staffId,
          staffFullName,
          diseaseNames,
          medicalExpenses,
          payDateTime,
          unpaid,
          unpaidStyle
        } = this.makeKarteInfo(
          medicalRecord,
          medicalPaymentMapByMedicalRecordOriginalId,
          getMedicalTreatmentItemsByMedicalPaymentId
        )
        return {
          medicalRecordOriginalId: medicalRecord.originalId,
          ownerId,
          ownerShowId,
          ownerFullName,
          ownerFullNameKana,
          tel,
          address,
          patientId,
          patientShowId,
          patientName,
          patientNameKana,
          patientBirthAge,
          patientSex,
          patientSexNum,
          patientSpecies,
          patientSpeciesId,
          medicalTreatmentDate,
          staffId,
          staffFullName,
          diseaseNames,
          medicalExpenses,
          payDateTime,
          unpaid,
          unpaidStyle,
          key: lastKey + index,
          index: lastKey + index
        }
      })
      this.dispMedicalRecords = this.dispMedicalRecords.concat(addData)
    },
    makeOwnerInfo(ownerId) {
      const owner = this.getOwner(ownerId)
      const prefecture =
        owner.prefectureId !== 0
          ? this.getPrefecture(owner.prefectureId).name
          : ''
      return {
        ownerId: owner.id,
        ownerShowId: owner.showId,
        ownerFullName: owner.lastName + ' ' + owner.firstName,
        ownerFullNameKana: owner.lastNameKana + owner.firstNameKana,
        tel: owner.tel,
        address: prefecture + owner.address + owner.building
      }
    },
    makePatientInfo(patientId) {
      const patient = this.getPatient(patientId)
      const breed = patient.breed !== '' ? ' / ' + patient.breed : ''
      return {
        patientId: patient.id,
        patientShowId: patient.showId,
        patientName: patient.name,
        patientNameKana: patient.nameKana,
        patientBirthAge: makeBirthAge(patient),
        patientSex: this.patientSexes.find(v => v.id === patient.sex).name,
        patientSexNum: patient.sex,
        patientSpecies: this.getSpecies(patient.speciesId).name + breed,
        patientSpeciesId: patient.speciesId
      }
    },
    makeKarteInfo(
      medicalRecord,
      medicalPaymentMapByMedicalRecordOriginalId,
      getMedicalTreatmentItemsByMedicalPaymentId
    ) {
      const medicalTreatmentDate = moment(
        medicalRecord.date,
        'YYYYMMDD'
      ).format('YYYY年MM月DD日（dd）')
      const medicalRecordOriginalId = medicalRecord.originalId
      const medicalPayment =
        medicalPaymentMapByMedicalRecordOriginalId.get(
          medicalRecordOriginalId
        ) || null

      let staffId = 0
      let staffFullName = ''
      let disease1Name = ''
      let disease2Name = ''
      let medicalExpenses = ''
      let unpaid = ''
      let payDateTime = ''
      let unpaidStyle = {}
      if (medicalPayment) {
        const staff = this.getStaff(medicalPayment.staffId)
        staffId = staff ? staff.id : 0
        staffFullName = staff ? staff.lastName + ' ' + staff.firstName : ''
        disease1Name =
          medicalPayment.disease1Id !== 0
            ? this.getDisease(medicalPayment.disease1Id).name
            : ''
        disease2Name =
          medicalPayment.disease2Id !== 0
            ? ' , ' + this.getDisease(medicalPayment.disease2Id).name
            : ''
        const medicalTreatmentItems =
          getMedicalTreatmentItemsByMedicalPaymentId[medicalPayment.id]
        medicalExpenses = medicalTreatmentItems
          ? '￥' +
            makePriceDetail(
              medicalPayment,
              medicalPayment.surgeryFlg, //← 0 or 1
              1,
              medicalTreatmentItems
            ).taxIncludedPrice
          : '￥0'
        const { unpaidAmount, latestPayDateTime } = makePaymentDetail(
          medicalPayment,
          this.getMedicalPaymentHistory,
          this.getMedicalRecordByOriginalId,
          this.getPaymentsByMedicalPaymentId
        )
        payDateTime =
          latestPayDateTime !== ''
            ? moment(latestPayDateTime, 'YYYYMMDDHHmmss').format(
                'YYYY年MM月DD日（dd）'
              )
            : ''
        unpaid =
          unpaidAmount < 0
            ? '（超過）￥' + Math.abs(unpaidAmount).toLocaleString()
            : '￥' + unpaidAmount.toLocaleString()
        unpaidStyle = unpaid !== '￥0' ? { color: '#de3d3d' } : {}
      }
      return {
        medicalTreatmentDate,
        staffId,
        staffFullName,
        diseaseNames: disease1Name + disease2Name,
        medicalExpenses,
        payDateTime,
        unpaid,
        unpaidStyle
      }
    },
    intersect(key) {
      clearTimeout(this.intersectionTimeoutId)
      const delay = 300
      this.intersectionTimeoutId = setTimeout(async () => {
        await this.getImages(key)
      }, delay)
    },
    async getImages(key) {
      try {
        const beforeAfter = 10
        const beforeAfterPatientIds = this.dispMedicalRecords
          .filter((_, i) => key - beforeAfter <= i && i < key + beforeAfter)
          .map(v => v.patientId)
          .filter((v, i, self) => self.indexOf(v) === i)
        const obtainedPatientIds = Object.keys(this.imagesByPatientId).map(id =>
          Number(id)
        )
        const unObtainedPatientIds = []
        const obtainedPatientIdsSet = new Set(obtainedPatientIds)
        beforeAfterPatientIds.forEach(v => {
          if (!obtainedPatientIdsSet.has(v)) unObtainedPatientIds.push(v)
        })
        if (unObtainedPatientIds.length === 0) return
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/patients/image/medical-records', {
          params: { patientIds: unObtainedPatientIds }
        })
        if (res.data.patients.length === 0) return
        this.setImages(res.data.patients)
      } catch (err) {
        this.popup = {
          show: true,
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message: '画像取得時に通信エラーが発生しました。'
        }
      }
    },
    setImages(patients) {
      const decodedPatients = decodeBase64fromBuffer(patients)
      decodedPatients.forEach(datum => {
        this.$set(this.imagesByPatientId, datum.id, datum.image)
      })
    },
    inputSearchText(text) {
      this.searchText = text
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    async search() {
      this.waitFlg = true
      const searchConditions = this.searchConditions.map(condition =>
        condition.selectId === 3 || condition.selectId === 4
          ? { ...condition, text: toHanKakuId(condition.text) }
          : condition
      )
      const res = this.detailSearchFlg
        ? await this.$store.dispatch('medicalRecords/detailSearch', {
            searchConditions: searchConditions,
            page: this.page,
            order: this.orderType === 0 ? 'DESC' : 'ASC'
          })
        : await this.$store.dispatch('medicalRecords/search', {
            searchText: this.searchText,
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.page,
            order: this.orderType === 0 ? 'DESC' : 'ASC'
          })
      if (res.result === 'success') {
        this.page++
        this.makeMedicalRecords(
          res.medicalRecords,
          res.medicalPayments,
          res.medicalTreatmentItems
        )
        this.count = res.count
        if (
          this.dispMedicalRecords.length < 100 ||
          res.medicalRecords.length === 0
        ) {
          this.isFull = true
        }
        if (this.page >= 10 && this.dispMedicalRecords.length >= 1000) {
          this.isFull = true
          this.popup = {
            show: true,
            type: 'alert',
            title: '警告',
            buttons: ['閉じる'],
            message: 'カルテの表示件数が上限の1000件に達しました。'
          }
        }
      } else {
        this.popup = {
          show: true,
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message: '通信エラーが発生しました。'
        }
      }
      this.waitFlg = false
    },
    async resetAndGetData() {
      this.dispMedicalRecords = []
      this.page = 0
      this.isFull = false
      this.updateImage()
      await this.search()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.search()
    },
    toggleDetailSearchFlg() {
      this.searchText = ''
      this.searchConditions = [{ id: 1, selectId: 0, text: '', text2: '' }]
      this.detailSearchFlg = !this.detailSearchFlg
    },
    setSelectId(idIndex) {
      const targetSearchCondition = this.searchConditions[idIndex.index]
      targetSearchCondition.selectId = idIndex.id
      if (idIndex.id === 13 || idIndex.id === 16) {
        targetSearchCondition.text = 0
      } else if (idIndex.id === 14) {
        const speciesId = this.species[0].id
        targetSearchCondition.text = speciesId
      } else {
        targetSearchCondition.text = ''
      }
      targetSearchCondition.text2 = ''
      this.$set(this.searchConditions, idIndex.index, targetSearchCondition)
    },
    inputText(num, textObj) {
      const text = num === 1 ? 'text' : `text${num}`
      const targetSearchCondition = this.searchConditions[textObj.index]
      targetSearchCondition[text] = textObj.text
      this.$set(this.searchConditions, textObj.index, targetSearchCondition)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({ id, selectId: 0, text: '', text2: '' })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    pushToKartePage({ ownerId, patientId, medicalRecordOriginalId }) {
      const medicalRecord = this.getMedicalRecordByOriginalId(
        medicalRecordOriginalId
      )
      if (medicalRecord.delFlg === 1) {
        this.popup = {
          show: true,
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message:
            'クリックしたカルテは既に削除されているため、\nカルテ編集画面に移動できません。'
        }
      } else {
        this.$router.push({
          path: `/main/karte/owners/${ownerId}/patients/${patientId}/medical-records/${medicalRecordOriginalId}/edit`
        })
      }
    },
    updateImage() {
      Object.keys(this.updatedImagesByPatientId).map(patientId => {
        const existsSamePatient = this.imagesByPatientId.hasOwnProperty(
          patientId
        )
        if (existsSamePatient) {
          const newImage = this.updatedImagesByPatientId[patientId]
          this.$set(this.imagesByPatientId, patientId, newImage)
        }
      })
      this.updatedImagesByPatientId = {}
    },
    getMedicalPayment(medicalRecordOriginalId) {
      let medicalPayment
      const medicalPayments = this.getMedicalPaymentsByRecordOriginalId(
        medicalRecordOriginalId
      )
      if (medicalPayments) {
        medicalPayment = medicalPayments.find(v => v.delFlg === 0)
      }
      return medicalPayment
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-records-all {
  display: inline-block;
  min-width: 100%;
  > .search {
    min-width: 1175px;
  }
  > .search-order {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .scroller {
    height: 670px;
    .medical-record-row {
      margin-bottom: 20px;
    }
  }
}
</style>
