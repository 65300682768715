<template>
  <div class="medical-contents">
    <base-loading :waitFlg="waitFlg" />
    <div class="area search">
      <search-area-detail
        v-if="detailSearchFlg"
        :selectBoxData="detailSelectBoxData"
        :searchConditions="searchConditions"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @hide-detail="toggleDetailSearchFlg"
        @select="setSelectId"
        @input-text="inputText($event)"
        @add-search-condition="addSearchCondition"
        @trash="deleteSearchCondition"
        @search="resetAndGetData"
      />
      <search-area
        v-else
        :textBoxLabel="'検索単語'"
        :textValue="searchText"
        :placeholder="'診療内容'"
        :periodFlg="true"
        :periodLabel="'期間'"
        :defaultStartDate="defaultStartDate"
        :defaultEndDate="defaultEndDate"
        :toggleDetailSearchFlg="true"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        @show-detail="toggleDetailSearchFlg"
        @input="inputSearchText"
        @search="resetAndGetData"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">検索結果：{{ hitAllDataCounts }}件</div>
      <div class="order-setting">
        <base-button-small-white
          class="toggle-show-flg-button"
          :styles="{ width: '150px' }"
          @click="toggleFlg('deleteButtonShowFlg')"
          >{{ toggleButtonText }}</base-button-small-white
        >
        <div class="label">並び替え：診療日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <dynamic-scroller
      class="list-area"
      :items="searchedMedicalContents"
      :min-item-size="273"
      ref="scroller"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
        >
          <medical-content-information
            :medicalContent="item"
            :searchRegExp="searchRegExp"
            :imagesByMedicalContentOriginalId="imagesByMedicalContentOriginalId"
            :patientMedicalContentsFlg="false"
            :deleteButtonShowFlg="deleteButtonShowFlg"
            :lastIndex="searchedMedicalContents.length - 1"
            :initialDisplayFlg="initialDisplayFlg"
            :numberSetFailedToGetImages="numberSetFailedToGetImages"
            @click-medical-content="gotoMedicalRecordEditPage"
            @delete-medical-content="openDeletePopup"
            @intersect="intersect"
            @scroll-bottom="getNextData"
          ></medical-content-information>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import SearchArea from '@/components/parts/molecules/SearchArea'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import MedicalContentInformation from '@/components/parts/organisms/MedicalContentInformation'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { mapGetters } from 'vuex'
import { getAxiosObject } from '@/utils/library'
import moment from 'moment'
import _ from 'lodash'
import { decodeBase64fromBuffer } from '@/utils/base64'
import {
  COMPONENT_TAG_IN_CONTENT_REGEX,
  UPLOAD_IMAGE_TAG_REGEX,
  MEDICAL_CONTENT_IMAGE_TAG_REGEX,
  CONTENT_IMAGE_ID_ATTRIBUTE_REGEX,
  escapeRegExp,
  FULL_WIDTH_COLON
} from '@/utils/define'
import { makeHIdByMPOId, compareKarte } from '@/utils/compare'
import { toHanKakuId } from '@/utils/convert_string'

export default {
  name: 'MedicalContents',

  components: {
    BaseLoading,
    SearchArea,
    SearchAreaDetail,
    BaseButtonSmallWhite,
    BaseSelectBox,
    MedicalContentInformation,
    AnnouncePopup,
    DynamicScroller,
    DynamicScrollerItem
  },

  data() {
    return {
      imagesByMedicalContentOriginalId: {},
      obtainedUploadImageIds: [],
      obtainedMedicalContentImageIds: [],
      numberSetFailedToGetImages: new Set(),
      detailSearchFlg: false,
      detailSelectBoxData: [
        { id: 1, name: '飼主名', type: 'textBox' },
        { id: 2, name: '患者名', type: 'textBox' },
        { id: 3, name: '飼主ID', type: 'textBox' },
        { id: 4, name: '患者ID', type: 'textBox' },
        { id: 5, name: '診療内容', type: 'textBox' },
        { id: 6, name: '診療日', type: 'datePicker' }
      ],
      searchConditions: [{ id: 1, selectId: 0, text: '' }],
      trimSearchConditions: [{ id: 1, selectId: 0, text: '' }],
      searchText: '',
      trimSearchText: '',
      startDate: '',
      endDate: '',
      deleteButtonShowFlg: false,
      deleteMedicalContentId: 0,
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      decide: () => {},
      waitFlg: false,
      intersectionTimeoutId: null,
      intersectionDelay: 300,
      searchedMedicalContents: [],
      searchRegExp: null,
      page: 0,
      isFull: true,
      hitAllDataCounts: 0,
      deleteCounts: 0,
      initialDisplayFlg: true,
      scrollTop: 0,
      deleteOperationFlg: false,
      timeoutId: null
    }
  },

  computed: {
    ...mapGetters({
      medicalContents: 'medicalContents/getData',
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getImageTextsByMedicalContentImageId:
        'medicalContentImageTexts/getDataByMedicalContentImageId',
      hospitalizations: 'hospitalizations/getData',
      medicalPaymentsByMROId: 'medicalPayments/getDataByMedicalRecordOriginalId'
    }),
    toggleButtonText() {
      return this.deleteButtonShowFlg ? '削除ボタン非表示' : '削除ボタン表示'
    }
  },

  watch: {
    orderType() {
      this.resetAndGetData()
    }
  },

  created() {
    const startMoment = moment().subtract(7, 'd')
    this.startDate = startMoment.format('YYYYMMDD')
    this.defaultStartDate = startMoment.toDate()
    const endMoment = moment()
    this.endDate = endMoment.format('YYYYMMDD')
    this.defaultEndDate = endMoment.toDate()
    this.createSearchedMedicalContents(this.medicalContents)
    this.hitAllDataCounts = this.searchedMedicalContents.length
  },

  beforeDestroy() {
    clearTimeout(this.intersectionTimeoutId)
    clearTimeout(this.timeoutId)
  },

  methods: {
    intersect(medicalContentNumber) {
      clearTimeout(this.intersectionTimeoutId)
      this.intersectionTimeoutId = setTimeout(async () => {
        //スクロール終了時だけでなく、画面表示時と検索時にもこの処理が動く
        await this.getImages(medicalContentNumber)
      }, this.intersectionDelay)
    },
    async getImages(medicalContentNumber) {
      const numberSetInGettingImages = new Set()
      try {
        const axiosObject = getAxiosObject()
        const medicalContentImageIds = []
        const uploadImageIds = []
        this.searchedMedicalContents
          .filter(
            (_, i) =>
              medicalContentNumber - 10 <= i && i < medicalContentNumber + 10
          )
          .forEach(v => {
            const componentTag = v.content.match(COMPONENT_TAG_IN_CONTENT_REGEX)
            if (componentTag === null) return
            const componentTagText = componentTag[0]
            if (UPLOAD_IMAGE_TAG_REGEX.test(componentTagText)) {
              const uploadImageId = Number(
                componentTagText.replace(/[^0-9]/g, '')
              )
              if (!this.obtainedUploadImageIds.includes(uploadImageId)) {
                uploadImageIds.push(uploadImageId)
                numberSetInGettingImages.add(v.number)
              }
            } else if (MEDICAL_CONTENT_IMAGE_TAG_REGEX.test(componentTagText)) {
              const attributeText = componentTagText.match(
                CONTENT_IMAGE_ID_ATTRIBUTE_REGEX
              )[0]
              const medicalContentImageId = Number(
                attributeText.replace(/[^0-9]/g, '')
              )
              if (
                !this.obtainedMedicalContentImageIds.includes(
                  medicalContentImageId
                )
              ) {
                medicalContentImageIds.push(medicalContentImageId)
                numberSetInGettingImages.add(v.number)
              }
            }
          })
        if (
          uploadImageIds.length === 0 &&
          medicalContentImageIds.length === 0
        ) {
          return
        }
        //get apiのqueryに入れて送る方法だと、画像のidの文字数が多い時にサイズオーバーで
        //431エラーになるのでpostで送っております。
        //https://qiita.com/daisuzz/items/60ab29a5d7f5ca73789c
        const res = await axiosObject.post(
          '/medical-content-images/in-medical-content-lists',
          { uploadImageIds, medicalContentImageIds }
        )
        if (
          res.data.uploadImages.length === 0 &&
          res.data.medicalContentImages.length === 0
        ) {
          return
        }
        if (res.data.medicalContentImageTexts.length > 0) {
          this.$store.dispatch(
            'medicalContentImageTexts/updateAfter',
            res.data.medicalContentImageTexts
          )
        }
        this.setImages(res.data.uploadImages, res.data.medicalContentImages)
        this.updateNumberSetFailedToGetImages({
          succeedNumberSet: numberSetInGettingImages
        })
      } catch (err) {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          '診療内容の画像の取得に失敗しました。\n暫く待ってから再度お試し下さい。'
        this.popupFlg = true
        this.updateNumberSetFailedToGetImages({
          failedNumberSet: numberSetInGettingImages
        })
      }
    },
    setImages(resUploadImages, resMedicalContentImages) {
      const decodedUploadImages = decodeBase64fromBuffer(resUploadImages)
      const decodedMedicalContentImages = this.calculateMedicalContentImages(
        resMedicalContentImages
      )
      const images = decodedUploadImages.concat(decodedMedicalContentImages)
      if (Object.keys(this.imagesByMedicalContentOriginalId).length === 0) {
        this.imagesByMedicalContentOriginalId = Object.assign(
          {},
          ...images.map(datum => ({
            [datum.medicalContentOriginalId]: datum
          }))
        )
      } else {
        images.forEach(datum => {
          this.$set(
            this.imagesByMedicalContentOriginalId,
            datum.medicalContentOriginalId,
            datum
          )
        })
      }
      this.obtainedUploadImageIds = this.obtainedUploadImageIds.concat(
        resUploadImages.map(v => v.id)
      )
      this.obtainedMedicalContentImageIds = this.obtainedMedicalContentImageIds.concat(
        resMedicalContentImages.map(v => v.id)
      )
    },
    updateNumberSetFailedToGetImages({
      succeedNumberSet = new Set(),
      failedNumberSet = new Set()
    }) {
      const newSet = new Set(this.numberSetFailedToGetImages)
      succeedNumberSet.forEach(number => {
        if (newSet.has(number)) newSet.delete(number)
      })
      failedNumberSet.forEach(number => {
        if (!newSet.has(number)) newSet.add(number)
      })
      this.numberSetFailedToGetImages = newSet
    },
    calculateMedicalContentImages(resMedicalContentImages) {
      const defaultImageSize = 400
      const listImageSize = 150
      const size = listImageSize / defaultImageSize
      let addData = []
      addData = decodeBase64fromBuffer(resMedicalContentImages)
      addData = addData.map(datum => {
        const texts = this.getImageTextsByMedicalContentImageId(datum.id)
        const tmp = texts ? texts : []
        const medicalContentImageTexts = tmp.map(mcit => {
          return {
            id: mcit.id,
            text: mcit.text,
            textStyle: {
              position: 'absolute',
              top: `${mcit.top * size}px`,
              left: `${mcit.left * size}px`,
              width: `${mcit.width * size}px`,
              height: `${mcit.height * size}px`,
              color: mcit.color,
              overflow: 'hidden',
              'font-size': `${mcit.size * size}px`,
              'white-space': 'pre-wrap',
              'z-index': 1,
              'word-break': 'break-all'
            }
          }
        })
        return {
          ...datum,
          thumbnailImage:
            datum.thumbnailImage === null && datum.schemaImageId !== 0
              ? require(`@/assets/images/schemas/${datum.schemaImageId}.jpg`)
              : datum.thumbnailImage,
          medicalContentImageTexts
        }
      })
      return addData
    },
    createSearchedMedicalContents(medicalContents) {
      const lastKey = this.searchedMedicalContents.length
      const addData = medicalContents.flatMap((v, index) => {
        const medicalRecord = this.getMedicalRecord(v.medicalRecordOriginalId)
        const owner = this.getOwner(medicalRecord.ownerId)
        const patient = this.getPatient(medicalRecord.patientId)
        const replaceSearchContent = v.searchContent.replace(
          new RegExp(FULL_WIDTH_COLON, 'g'),
          '：'
        )
        const displayContent = this.makeDisplayContent(replaceSearchContent)
        const displayDatum = {
          ...v,
          ownerId: owner.id,
          ownerFullName: owner.lastName + owner.firstName,
          ownerFullNameKana: owner.lastNameKana + owner.firstNameKana,
          ownerShowId: owner.showId,
          patientId: patient.id,
          patientName: patient.name,
          patientNameKana: patient.nameKana,
          patientShowId: patient.showId,
          date: medicalRecord.date,
          formatDate: moment(medicalRecord.date, 'YYYYMMDD').format(
            'YYYY年MM月DD日（dd）'
          ),
          startTime: medicalRecord.startTime,
          searchContent: replaceSearchContent,
          displayContent,
          number: this.initialDisplayFlg ? null : lastKey + index,
          latestMedicalRecordId: medicalRecord.id
        }
        if (this.initialDisplayFlg) {
          return this.filterByDate(
            medicalRecord.date,
            this.startDate,
            this.endDate
          )
            ? displayDatum
            : []
        } else {
          return displayDatum
        }
      })
      if (this.initialDisplayFlg) {
        const hIdByMPOId = makeHIdByMPOId(this.hospitalizations)
        this.searchedMedicalContents = addData
          .sort((a, b) => {
            const aMP = this.getMedicalPayment(a.medicalRecordOriginalId)
            const bMP = this.getMedicalPayment(b.medicalRecordOriginalId)
            return compareKarte(a, b, aMP, bMP, this.orderType, hIdByMPOId)
          })
          .map((v, i) => {
            return { ...v, number: i + 1 }
          })
      } else {
        this.searchedMedicalContents = this.searchedMedicalContents.concat(
          addData
        )
      }
    },
    makeDisplayContent(replaceSearchContent) {
      if (!this.searchRegExp) {
        return replaceSearchContent
      } else {
        const content = replaceSearchContent
        const searchTextIndexes = []
        let result
        while ((result = this.searchRegExp.exec(content))) {
          searchTextIndexes.push(result.index)
        }
        if (searchTextIndexes.length === 0) {
          //画像内のテキストだけヒットした場合でも、冒頭の300文字を表示するようにする
          return content.length > 300 ? content.slice(0, 300) + '...' : content
        }
        let searchContent = ''
        let endIndex = 0
        searchTextIndexes.forEach((searchTextIndex, i) => {
          if (searchTextIndex >= endIndex) {
            const startIndex =
              searchTextIndex - 10 > 0 ? searchTextIndex - 10 : 0
            endIndex = startIndex + 200
            let sliceContent = content.slice(startIndex, endIndex)
            if (
              sliceContent.length === 200 &&
              content.slice(endIndex + 1).length > 0
            ) {
              sliceContent = sliceContent + '...'
            }
            searchContent += i === 0 ? sliceContent : '　　' + sliceContent
          }
        })
        return searchContent
      }
    },
    filterByDate(medicalRecordDate, startDate, endDate) {
      return startDate !== '' && endDate !== ''
        ? startDate <= medicalRecordDate && medicalRecordDate <= endDate
        : startDate !== ''
        ? startDate <= medicalRecordDate
        : endDate !== ''
        ? medicalRecordDate <= endDate
        : true
    },
    toggleDetailSearchFlg() {
      this.searchText = ''
      this.searchConditions = [{ id: 1, selectId: 0, text: '' }]
      this.detailSearchFlg = !this.detailSearchFlg
    },
    inputSearchText(text) {
      this.searchText = text
    },
    setSelectId(idIndex) {
      const targetSearchCondition = this.searchConditions[idIndex.index]
      targetSearchCondition.selectId = idIndex.id
      targetSearchCondition.text = ''
      this.$set(this.searchConditions, idIndex.index, targetSearchCondition)
    },
    inputText(textObj) {
      const targetSearchCondition = this.searchConditions[textObj.index]
      targetSearchCondition.text = textObj.text
      this.$set(this.searchConditions, textObj.index, targetSearchCondition)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({ id, selectId: 0, text: '' })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    toggleFlg(flgName) {
      this[flgName] = !this[flgName]
    },
    async resetAndGetData() {
      this.searchedMedicalContents = []
      this.page = 0
      this.isFull = false
      this.deleteCounts = 0
      await this.searchMedicalContents()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.searchMedicalContents()
    },
    async searchMedicalContents() {
      if (this.page === 0) {
        this.imagesByMedicalContentOriginalId = {}
        this.obtainedUploadImageIds = []
        this.obtainedMedicalContentImageIds = []
        this.numberSetFailedToGetImages = new Set()
      }
      if (this.initialDisplayFlg) this.initialDisplayFlg = false
      this.waitFlg = true
      this.trimSearchText = this.searchText.toLowerCase().replace(/\s+/g, '')
      this.trimSearchConditions = this.searchConditions.map(v => {
        return v.selectId === 3 || v.selectId === 4
          ? {
              ...v,
              text: toHanKakuId(v.text.toLowerCase().replace(/\s+/g, ''))
            }
          : { ...v, text: v.text.toLowerCase().replace(/\s+/g, '') }
      })
      this.searchRegExp = this.makeSearchRegExp()
      const res = await this.$store.dispatch('medicalContents/search', {
        detailSearchFlg: this.detailSearchFlg,
        trimSearchText: this.trimSearchText,
        period: { startDate: this.startDate, endDate: this.endDate },
        trimSearchConditions: this.trimSearchConditions,
        page: this.page,
        order: this.orderType === 0 ? 'DESC' : 'ASC',
        deleteCounts: this.deleteCounts
      })
      if (res.result === true) {
        this.page++
        if (this.page === 1) this.$refs.scroller.scrollToItem(0)
        this.hitAllDataCounts = res.hitAllDataCounts
        this.createSearchedMedicalContents(res.medicalContents)
        if (
          this.searchedMedicalContents.length < 100 ||
          res.medicalContents.length === 0
        ) {
          this.isFull = true
        }
        if (this.page >= 10 && this.searchedMedicalContents.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage = '診療内容の表示件数が上限の1000件に達しました。'
        }
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    },
    makeSearchRegExp() {
      if (this.detailSearchFlg) {
        let regExp = this.trimSearchConditions.reduce(
          (value, searchCondition) => {
            if (searchCondition.text === '' || searchCondition.selectId !== 5) {
              return value
            }
            const escapedText = escapeRegExp(searchCondition.text)
            return value === '' ? escapedText : value + '|' + escapedText
          },
          ''
        )
        return regExp === '' ? null : new RegExp(regExp, 'ig')
      } else {
        const escapedText = escapeRegExp(this.trimSearchText)
        return escapedText === '' ? null : new RegExp(escapedText, 'ig')
      }
    },
    gotoMedicalRecordEditPage(medicalContent) {
      const ownerId = medicalContent.ownerId
      const patientId = medicalContent.patientId
      const medicalRecordOriginalId = medicalContent.medicalRecordOriginalId
      const medicalRecord = this.getMedicalRecord(medicalRecordOriginalId)
      if (medicalRecord.delFlg === 1) {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          'クリックしたカルテは既に削除されているため、\nカルテ編集画面に移動できません。'
      } else {
        this.$router.push(
          `/main/karte/owners/${ownerId}/patients/${patientId}/medical-records/${medicalRecordOriginalId}/edit`
        )
      }
    },
    openDeletePopup(medicalContent) {
      this.deleteMedicalContentId = medicalContent.id
      const params = {
        patientId: 0,
        medicalRecordOriginalId: medicalContent.medicalRecordOriginalId,
        hospitalizationId: 0
      }
      const isLocked = this.$store.getters['auth/isAnotherClientLocking'](
        params
      )
      if (isLocked) {
        this.$store.dispatch('petorelu/saveNextFunction', this.deleteConfirm)
        this.$store.dispatch('petorelu/showKarteLockPopup', {
          ...params,
          isTryingDelete: true
        })
      } else {
        this.deleteConfirm()
      }
    },
    deleteConfirm() {
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.decide = () => this.decideDelete()
      this.scrollTop = this.$refs.scroller.$el.scrollTop
    },
    async decideDelete() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContents/delete',
        this.deleteMedicalContentId
      )
      if (res.result === true) {
        const deleteIndex = this.searchedMedicalContents.findIndex(
          v => v.id === res.deleteMedicalContentId
        )
        this.searchedMedicalContents.splice(deleteIndex, 1)
        this.deleteCounts += 1
        this.hitAllDataCounts -= 1
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
        this.deleteOperationFlg = true
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
      if (this.deleteOperationFlg) {
        //データ削除後にスクロール位置が自動的にずれてしまう。
        //原因ははっきりとわかっていないが、virtual-scrollerとfocus-trapの影響で削除ポップアップを閉じた後に
        //focusが別の診療内容の要素に移ったことでスクロールが自動的にされた可能性がある。
        //自動的にずれる処理自体を止める方法が見つからなかったため、その直後に元のスクロール位置に戻すようにした。
        this.$nextTick(() => {
          this.timeoutId = setTimeout(() => {
            this.$refs.scroller.$el.scrollTo(0, this.scrollTop)
          }, 1)
          this.deleteOperationFlg = false
        })
      }
    },
    getMedicalPayment(medicalRecordOriginalId) {
      let medicalPayment
      const medicalPayments = this.medicalPaymentsByMROId(
        medicalRecordOriginalId
      )
      if (medicalPayments) {
        medicalPayment = medicalPayments.find(v => v.delFlg === 0)
      }
      return medicalPayment
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-contents {
  display: inline-block;
  min-width: 100%;
  > .search {
    min-width: 1065px;
  }
  > .search-order {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .toggle-show-flg-button {
        margin-right: 20px;
      }
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .list-area {
    height: 670px;
  }
}
</style>
