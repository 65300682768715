<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="treatment-items-select-popup"
      @keyup.esc.stop="$emit('cancel')"
      tabindex="-1"
    >
      <popup
        :title="'診療内容 選択'"
        :buttons="['閉じる']"
        @close="$emit('cancel')"
        @click-close-mark="$emit('cancel')"
      >
        <template v-slot:content>
          <div class="content">
            <div class="search">
              <search-area
                :textBoxLabel="'検索単語'"
                :placeholder="''"
                :textValue="searchWord"
                :textBoxStyles="{ width: '490px' }"
                :searchAreaStyles="{ minWidth: '590px' }"
                v-model="searchWord"
              />
            </div>
            <div class="tab">
              <tab-list :tabs="tabs" :activeTab="tab" @click="switchTab" />
            </div>
            <div class="list">
              <accordion-list
                v-show="searchWord.length === 0"
                ref="accordionList"
                :data="tabList"
                :styles="{ width: '600px', height: '400px' }"
                @click-row="clickRow"
              />
              <accordion-list
                v-show="searchWord.length !== 0"
                :data="filteredList"
                :keyIndexFlg="true"
                :styles="{ width: '600px', height: '400px' }"
                @click-row="clickRow"
              />
            </div>
          </div>
        </template>
      </popup>
      <treatment-items-input-popup
        v-if="popupFlg"
        :treatmentItems="treatmentItems"
        :insuranceType="insuranceType"
        @cancel="popupFlg = false"
        @decision="addTreatmentItems"
      />
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import SearchArea from '@/components/parts/molecules/SearchArea'
import TabList from '@/components/parts/molecules/TabList.vue'
import AccordionList from '@/components/parts/organisms/AccordionList'
import TreatmentItemsInputPopup from '@/components/popups/TreatmentItemsInputPopup'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TreatmentItemsSelectPopup',

  components: {
    Popup,
    SearchArea,
    TabList,
    AccordionList,
    TreatmentItemsInputPopup,
    FocusTrap
  },

  props: {
    patient: { type: Object },
    insuranceType: { type: String, default: '' }
  },

  data() {
    return {
      searchWord: '',
      tabs: ['診療項目', '薬剤物品', '診療項目セット'],
      tab: '診療項目',
      treatmentItems: [],
      popupFlg: false
    }
  },

  computed: {
    ...mapGetters({
      treatmentLargeClasses: 'treatmentLargeClasses/getData',
      treatmentClasses: 'treatmentClasses/getData',
      treatments: 'treatments/getData',
      treatmentClassesByLargeOriginalId:
        'treatmentClasses/getDataByTreatmentLargeClassOriginalId',
      treatmentsByClassOriginalId:
        'treatments/getDataByTreatmentClassOriginalId',
      medicineLargeClasses: 'medicineLargeClasses/getData',
      medicineMediumClasses: 'medicineMediumClasses/getData',
      medicineSmallClasses: 'medicineSmallClasses/getData',
      medicines: 'medicines/getData',
      medicineMediumClassesByLargeOriginalId:
        'medicineMediumClasses/getDataByMedicineLargeClassOriginalId',
      medicineSmallClassesByMediumOriginalId:
        'medicineSmallClasses/getDataByMedicineMediumClassOriginalId',
      medicinesBySmallOriginalId:
        'medicines/getDataByMedicineSmallClassOriginalId',
      treatmentSets: 'treatmentSets/getData',
      treatmentSetItemsByTreatmentSetId:
        'treatmentSetItems/getDataByTreatmentSetId',
      treatmentByOriginalId: 'treatments/getDataByOriginalId',
      medicineByOriginalId: 'medicines/getDataByOriginalId'
    }),
    tabList() {
      return this.tab === '診療項目'
        ? this.treatmentList
        : this.tab === '薬剤物品'
        ? this.medicineList
        : this.tab === '診療項目セット'
        ? this.treatmentSetList
        : []
    },
    treatmentList() {
      return this.treatmentLargeClasses.flatMap(largeClass => {
        if (!largeClass.showFlg) return []
        const treatmentClasses = (
          this.treatmentClassesByLargeOriginalId(largeClass.originalId) || []
        ).flatMap(mediumClass => {
          if (!mediumClass.showFlg) return []
          const treatments = (
            this.treatmentsByClassOriginalId(mediumClass.originalId) || []
          ).flatMap(treatment => {
            if (!treatment.showFlg) return []
            return { ...treatment, layerNumber: 3 }
          })
          return { ...mediumClass, layerNumber: 2, children: treatments }
        })
        return { ...largeClass, layerNumber: 1, children: treatmentClasses }
      })
    },
    medicineList() {
      return this.medicineLargeClasses.flatMap(largeClass => {
        if (!largeClass.showFlg) return []
        const mediumClasses = (
          this.medicineMediumClassesByLargeOriginalId(largeClass.originalId) ||
          []
        ).flatMap(mediumClass => {
          if (!mediumClass.showFlg) return []
          const smallClasses = (
            this.medicineSmallClassesByMediumOriginalId(
              mediumClass.originalId
            ) || []
          ).flatMap(smallClass => {
            if (!smallClass.showFlg) return []
            const medicines = (
              this.medicinesBySmallOriginalId(smallClass.originalId) || []
            ).flatMap(medicine => {
              if (!medicine.showFlg) return []
              return { ...medicine, layerNumber: 4 }
            })
            return { ...smallClass, layerNumber: 3, children: medicines }
          })
          return { ...mediumClass, layerNumber: 2, children: smallClasses }
        })
        return { ...largeClass, layerNumber: 1, children: mediumClasses }
      })
    },
    treatmentSetList() {
      return this.treatmentSets.map(v => ({ ...v, layerNumber: 1 }))
    },
    filteredList() {
      return this.filteredTreatmentLargeClasses.concat(
        this.filteredTreatmentClasses,
        this.filteredTreatments,
        this.filteredMedicineLargeClasses,
        this.filteredMedicineMediumClasses,
        this.filteredMedicineSmallClasses,
        this.filteredMedicines,
        this.filteredTreatmentSets
      )
    },
    filteredTreatmentLargeClasses() {
      if (this.searchWord.length === 0) return []
      return this.treatmentLargeClasses.flatMap(largeClass => {
        if (!largeClass.showFlg) return []
        if (!this.isMatched(largeClass.name)) return []
        const name = '診・大分類 / ' + largeClass.name
        const treatmentClasses = (
          this.treatmentClassesByLargeOriginalId(largeClass.originalId) || []
        ).flatMap(mediumClass => {
          if (!mediumClass.showFlg) return []
          const treatments = (
            this.treatmentsByClassOriginalId(mediumClass.originalId) || []
          ).flatMap(treatment => {
            if (!treatment.showFlg) return []
            return { ...treatment, layerNumber: 3 }
          })
          return { ...mediumClass, layerNumber: 2, children: treatments }
        })
        return {
          ...largeClass,
          layerNumber: 1,
          children: treatmentClasses,
          name
        }
      })
    },
    filteredTreatmentClasses() {
      if (this.searchWord.length === 0) return []
      return this.treatmentClasses.flatMap(mediumClass => {
        if (!mediumClass.showFlg) return []
        if (!this.isMatched(mediumClass.name)) return []
        const name = '診・分類 / ' + mediumClass.name
        const treatments = (
          this.treatmentsByClassOriginalId(mediumClass.originalId) || []
        ).flatMap(treatment => {
          if (!treatment.showFlg) return []
          return { ...treatment, layerNumber: 2 }
        })
        return { ...mediumClass, layerNumber: 1, children: treatments, name }
      })
    },
    filteredTreatments() {
      if (this.searchWord.length === 0) return []
      return this.treatments.flatMap(treatment => {
        if (!treatment.showFlg) return []
        if (!this.isMatched(treatment.name)) return []
        const name = '診・名称 / ' + treatment.name
        return { ...treatment, layerNumber: 1, name }
      })
    },
    filteredMedicineLargeClasses() {
      if (this.searchWord.length === 0) return []
      return this.medicineLargeClasses.flatMap(largeClass => {
        if (!largeClass.showFlg) return []
        if (!this.isMatched(largeClass.name)) return []
        const name = '薬・大分類 / ' + largeClass.name
        const mediumClasses = (
          this.medicineMediumClassesByLargeOriginalId(largeClass.originalId) ||
          []
        ).flatMap(mediumClass => {
          if (!mediumClass.showFlg) return []
          const smallClasses = (
            this.medicineSmallClassesByMediumOriginalId(
              mediumClass.originalId
            ) || []
          ).flatMap(smallClass => {
            if (!smallClass.showFlg) return []
            const medicines = (
              this.medicinesBySmallOriginalId(smallClass.originalId) || []
            ).flatMap(medicine => {
              if (!medicine.showFlg) return []
              return { ...medicine, layerNumber: 4 }
            })
            return { ...smallClass, layerNumber: 3, children: medicines }
          })
          return { ...mediumClass, layerNumber: 2, children: smallClasses }
        })
        return { ...largeClass, layerNumber: 1, children: mediumClasses, name }
      })
    },
    filteredMedicineMediumClasses() {
      if (this.searchWord.length === 0) return []
      return this.medicineMediumClasses.flatMap(mediumClass => {
        if (!mediumClass.showFlg) return []
        if (!this.isMatched(mediumClass.name)) return []
        const name = '薬・中分類 / ' + mediumClass.name
        const smallClasses = (
          this.medicineSmallClassesByMediumOriginalId(mediumClass.originalId) ||
          []
        ).flatMap(smallClass => {
          if (!smallClass.showFlg) return []
          const medicines = (
            this.medicinesBySmallOriginalId(smallClass.originalId) || []
          ).flatMap(medicine => {
            if (!medicine.showFlg) return []
            return { ...medicine, layerNumber: 3 }
          })
          return { ...smallClass, layerNumber: 2, children: medicines }
        })
        return { ...mediumClass, layerNumber: 1, children: smallClasses, name }
      })
    },
    filteredMedicineSmallClasses() {
      if (this.searchWord.length === 0) return []
      return this.medicineSmallClasses.flatMap(smallClass => {
        if (!smallClass.showFlg) return []
        if (!this.isMatched(smallClass.name)) return []
        const name = '薬・小分類 / ' + smallClass.name
        const medicines = (
          this.medicinesBySmallOriginalId(smallClass.originalId) || []
        ).flatMap(medicine => {
          if (!medicine.showFlg) return []
          return { ...medicine, layerNumber: 2 }
        })
        return { ...smallClass, layerNumber: 1, children: medicines, name }
      })
    },
    filteredMedicines() {
      if (this.searchWord.length === 0) return []
      return this.medicines.flatMap(medicine => {
        if (!medicine.showFlg) return []
        if (!this.isMatched(medicine.name)) return []
        const name = '薬・名称 / ' + medicine.name
        return { ...medicine, layerNumber: 1, name }
      })
    },
    filteredTreatmentSets() {
      if (this.searchWord.length === 0) return []
      return this.treatmentSets.flatMap(treatmentSet => {
        if (!this.isMatched(treatmentSet.name)) return []
        const name = 'セット・名称 / ' + treatmentSet.name
        return { ...treatmentSet, layerNumber: 1, name }
      })
    }
  },

  watch: {
    searchWord: function() {
      this.tab = this.searchWord.length > 0 ? '' : '診療項目'
    }
  },

  methods: {
    isMatched(itemName) {
      return itemName
        .toLowerCase()
        .includes(this.searchWord.toLowerCase().trim())
    },
    switchTab(tab) {
      this.tab = tab
      this.searchWord = ''
      this.$refs.accordionList.closePanel()
    },
    clickRow(rowItem) {
      // rowItem: treatment or medicine or treatmentSet
      const isTreatmentSet = !rowItem.hasOwnProperty('originalId')
      if (isTreatmentSet) {
        this.treatmentItems = this.treatmentSetItemsByTreatmentSetId(
          rowItem.id
        ).map(treatmentSetItem => {
          const { treatmentOriginalId, medicineOriginalId } = treatmentSetItem
          const baseItem =
            this.treatmentByOriginalId(treatmentOriginalId) ||
            this.medicineByOriginalId(medicineOriginalId)
          return this.makeTreatmentItem({ baseItem, treatmentSetItem })
        })
      } else {
        this.treatmentItems = [this.makeTreatmentItem({ baseItem: rowItem })]
      }
      this.popupFlg = true
    },
    makeTreatmentItem({ baseItem, treatmentSetItem = null }) {
      // baseItem: treatment or medicine
      const { insurance } = this.patient
      const insuranceFlg =
        insurance === 'ipet' || insurance === 'docomo'
          ? treatmentSetItem
            ? treatmentSetItem.ipetFlg
            : baseItem.ipetFlg
          : treatmentSetItem
          ? treatmentSetItem.anicomFlg
          : baseItem.anicomFlg
      const isTreatment = baseItem.hasOwnProperty('defaultTreatmentId')
      const {
        taxExemptFlg,
        unitPrice,
        amount,
        discountRate = 0,
        discountPrice = 0,
        prescription = ''
      } = treatmentSetItem ?? {
        taxExemptFlg: baseItem.taxExemptFlg,
        unitPrice: isTreatment ? baseItem.price : baseItem.priceRate,
        amount: isTreatment ? 1 : baseItem.defaultVolume
      }
      return {
        treatmentId: isTreatment ? baseItem.id : 0,
        medicineId: isTreatment ? 0 : baseItem.id,
        treatmentOriginalId: isTreatment ? baseItem.originalId : 0,
        medicineOriginalId: isTreatment ? 0 : baseItem.originalId,
        vaccineId: isTreatment ? 0 : baseItem.vaccineId,
        insuranceFlg,
        taxExemptFlg,
        unitPrice,
        amount,
        discountRate,
        discountPrice,
        prescription
      }
    },
    addTreatmentItems(treatmentItems) {
      this.$emit('decision', treatmentItems)
      this.popupFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-items-select-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    min-width: 650px;
    padding: 25px 25px 0 25px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .search {
      margin-top: 25px;
    }
    > .tab {
      margin-top: 25px;
    }
    > .list {
      margin-top: 25px;
    }
  }
}
</style>
