<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="estimate-template-edit-popup"
      @keyup.esc.stop="checkClose"
      tabindex="-2"
    >
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :layerNumber="2"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          :buttons="['閉じる', '登録']"
          @click-close-mark="checkClose"
          @cancel="checkClose"
          @decision="updateData"
        >
          <template v-slot:content>
            <div class="content-wrap">
              <div class="header">
                <div class="title">見積書備考テンプレート編集</div>
                <base-button-small-red
                  v-if="lookOnlyFlg === 0"
                  @click="openDeletePopup"
                  >削除</base-button-small-red
                >
              </div>
              <div class="input-form">
                <div class="row name">
                  <text-input-form
                    :maxlength="15"
                    :requiredFlg="true"
                    :styles="{ width: '350px' }"
                    v-model="estimateTemplate.name"
                    >テンプレート名</text-input-form
                  >
                </div>
                <div class="row content">
                  <validation-provider
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      :styles="textAreaStyle"
                      v-model="estimateTemplate.content"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        class="delete-popup"
        v-if="deletePopup.flg"
        v-bind="deletePopup"
        :disabled="waitFlg"
        @close="closeDeletePopup"
        @cancel="cancelDelete"
        @decision="decideDelete"
        >{{ deletePopup.message }}</announce-popup
      >
      <announce-popup
        class="register-popup"
        v-if="registerPopup.flg"
        v-bind="registerPopup"
        @close="closeRegisterPopup"
        >{{ registerPopup.message }}</announce-popup
      >
      <announce-popup
        class="unsaved-popup"
        v-if="unsavedPopup.flg"
        v-bind="unsavedPopup"
        @cancel="closeUnsavedPopup"
        @decision="close"
        >{{ unsavedPopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import _ from 'lodash'

const basePopup = {
  flg: false,
  type: '',
  title: '',
  message: '',
  buttons: ['閉じる'],
  layerNumber: 3
}
export default {
  name: 'EstimateTemplateEditPopup',

  components: {
    Popup,
    BaseButtonSmallRed,
    TextInputForm,
    BaseMultipleLinesTextBox,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      initialData: {},
      inputData: {},
      estimateTemplate: {},
      textAreaStyle: {
        width: '100%',
        height: 'auto',
        minHeight: '200px',
        maxHeight: '400px',
        fontSize: '13px',
        resize: 'vertical'
      },
      deletePopup: { ...basePopup },
      registerPopup: { ...basePopup },
      unsavedPopup: {
        flg: false,
        type: 'alert',
        title: '確認',
        message: '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
        buttons: ['キャンセル', '閉じる'],
        layerNumber: 3
      },
      waitFlg: false,
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      estimateTemplateById: 'estimateTemplates/getDataById'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    }
  },

  created() {
    this.estimateTemplate = { ...this.estimateTemplateById(this.id) }
    this.inputData = this.estimateTemplate
    this.initialData = { ...this.inputData }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    checkClose() {
      this.diffFlg ? (this.unsavedPopup.flg = true) : this.close()
    },
    closeUnsavedPopup() {
      this.unsavedPopup.flg = false
    },
    openDeletePopup() {
      this.deletePopup.type = 'alert'
      this.deletePopup.title = '注意'
      this.deletePopup.message = '削除してもよろしいですか？'
      this.deletePopup.buttons = ['削除しない', '削除する']
      this.deletePopup.flg = true
    },
    cancelDelete() {
      this.deletePopup.flg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'estimateTemplates/delete',
        this.id
      )
      if (result === true) {
        this.initialData = { ...this.inputData }
        this.deletePopup.type = 'success'
        this.deletePopup.title = '完了'
        this.deletePopup.buttons = ['閉じる']
        this.deletePopup.message = '削除しました'
      } else {
        this.deletePopup.type = 'failure'
        this.deletePopup.title = '失敗'
        this.deletePopup.buttons = ['閉じる']
        this.deletePopup.message = '削除に失敗しました'
      }
      this.waitFlg = false
      this.deletePopup.flg = true
    },
    closeDeletePopup() {
      this.deletePopup.flg = false
      this.close()
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'estimateTemplates/update',
        this.estimateTemplate
      )
      if (result === true) {
        this.initialData = { ...this.inputData }
        this.registerPopup.type = 'success'
        this.registerPopup.title = '完了'
        this.registerPopup.message = '編集しました'
      } else {
        this.registerPopup.type = 'failure'
        this.registerPopup.title = '失敗'
        if (result === 'no data' || result === 'no data in clinic') {
          this.registerPopup.message =
            '編集に失敗しました。\n編集中の見積書備考テンプレートは既に削除されています。'
          this.noDataFlg = true
        } else {
          this.registerPopup.message = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.registerPopup.flg = true
    },
    closeRegisterPopup() {
      this.registerPopup.flg = false
      if (this.registerPopup.type === 'success' || this.noDataFlg) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-template-edit-popup {
  .content-wrap {
    box-sizing: border-box;
    width: 650px;
    margin-bottom: 20px;
    > .header {
      display: flex;
      justify-content: space-between;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
        text-align: left;
      }
    }
    > .input-form {
      margin-top: 15px;
      > .row {
        @include validate-message();
        & ~ .row {
          margin-top: 25px;
        }
      }
    }
  }
}
</style>
