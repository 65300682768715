<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="no-apply-reason-popup"
      @keyup.esc.stop="checkDiff"
      tabindex="-1"
    >
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          :invalid="invalid"
          @cancel="checkDiff"
          @click-close-mark="checkDiff"
          @decision="registerReason"
        >
          <template v-slot:content>
            <div class="content">
              <div class="title">保険対象外事由 登録</div>
              <div class="check-list">
                <div class="list 1">
                  <base-check-box
                    v-for="item in checkList.filter(v => v.id <= 4)"
                    :key="item.id"
                    :isChecked="item.value"
                    :labelText="item.text"
                    :styles="{
                      width: '140px',
                      marginRight: item.id !== 4 ? '30px' : 0
                    }"
                    :numFlg="true"
                    v-model="item.value"
                    :data-test="`checkbox-${item.id}`"
                  />
                </div>
                <div class="list 2">
                  <base-check-box
                    v-for="item in checkList.filter(
                      v => v.id >= 5 && v.id <= 7
                    )"
                    :key="item.id"
                    :isChecked="item.value"
                    :labelText="item.text"
                    :styles="{ width: '140px', marginRight: '30px' }"
                    :numFlg="true"
                    v-model="item.value"
                    :data-test="`checkbox-${item.id}`"
                  />
                </div>
                <div class="list 3">
                  <base-check-box
                    v-for="item in checkList.filter(v => v.id === 8)"
                    :key="item.id"
                    :isChecked="item.value"
                    :labelText="item.text"
                    :styles="{ width: '140px', marginRight: '0' }"
                    :numFlg="true"
                    v-model="item.value"
                    :data-test="`checkbox-${item.id}`"
                  />
                </div>
              </div>
              <div class="text">
                <validation-provider
                  class="validation-provider"
                  :rules="{ notSurrogatePair: true }"
                  v-slot="{ errors }"
                >
                  <base-multiple-lines-text-box
                    :maxlength="256"
                    :placeholder="'256文字以内'"
                    :styles="{ width: '650px', height: '150px' }"
                    v-model="text"
                  />
                  <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
                </validation-provider>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        v-if="popup.show"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        :layerNumber="2"
        @cancel="closePopup"
        @decision="$emit('cancel')"
        >{{ popup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import _ from 'lodash'

export default {
  name: 'NoApplyReasonPopup',

  components: {
    Popup,
    BaseCheckBox,
    BaseMultipleLinesTextBox,
    FocusTrap,
    ValidationObserver,
    ValidationProvider,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    medicalPayment: { type: Object }
  },

  data() {
    return {
      checkList: [
        { id: 1, key: 'reason01', text: '予防', value: 0 },
        { id: 2, key: 'reason02', text: '去勢・避妊', value: 0 },
        { id: 3, key: 'reason03', text: '日常ケア・フード', value: 0 },
        { id: 4, key: 'reason04', text: '妊娠・出産', value: 0 },
        { id: 5, key: 'reason05', text: '臍・鼠径ヘルニア', value: 0 },
        { id: 6, key: 'reason06', text: '美容', value: 0 },
        { id: 7, key: 'reason07', text: '医薬品以外', value: 0 },
        { id: 8, key: 'reason08', text: 'その他', value: 0 }
      ],
      text: '',
      initialData: {},
      popup: {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      }
    }
  },

  computed: {
    diffFlg() {
      return !_.isEqual(this.initialData, {
        checkList: this.checkList,
        text: this.text
      })
    }
  },

  created() {
    this.checkList = this.checkList.map(v => {
      const value = this.medicalPayment[v.key]
      return { ...v, value }
    })
    this.text = this.medicalPayment.reason09Txt
    this.initialData = _.cloneDeep({
      checkList: this.checkList,
      text: this.text
    })
  },

  watch: {
    text: function(text) {
      this.checkList.find(v => v.id === 8).value = text !== '' ? 1 : 0
    }
  },

  methods: {
    registerReason() {
      const checkList = this.checkList
      const reason09Txt = this.text
      this.$emit('decision', checkList, reason09Txt)
    },
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          show: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          message:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.$emit('cancel')
      }
    },
    closePopup() {
      this.popup = {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-apply-reason-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    min-width: 650px;
    margin-bottom: 20px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .check-list {
      margin-top: 25px;
      > .list {
        display: flex;
        margin-bottom: 10px;
      }
    }
  }
}
.validation-provider {
  position: relative;
  > .error {
    position: absolute;
    height: 18px;
    line-height: 18px;
    padding: 0 3px;
    background-color: #{$white};
    color: #{$tomato};
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
  }
}
</style>
