<template>
  <div class="problem-input-form">
    <div class="row">
      <auto-complete-input-form
        v-model="problem.name"
        :requiredFlg="true"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :selectData="idOrderProblems"
        :styles="{ width: '420px' }"
        :headingStyle="{ width: '30%' }"
        :bodyStyle="{ width: '70%' }"
        >プロブレム名</auto-complete-input-form
      >
    </div>
    <div class="row injury-date">
      <date-input-form
        :requiredFlg="true"
        :date="problem.injuryDate"
        :maxDate="problem.healingDate ? problem.healingDate : todaysDate"
        :headingStyle="{ width: '30%' }"
        :bodyStyle="{ width: '70%' }"
        @input-date="date => $emit('input-injury-date', date)"
        >受傷日
      </date-input-form>
    </div>
    <div class="row healing-date">
      <date-input-form
        :date="problem.healingDate"
        :minDate="problem.injuryDate"
        :maxDate="todaysDate"
        :headingStyle="{ width: '186px' }"
        :bodyStyle="{ width: '140px' }"
        @input-date="date => $emit('input-healing-date', date)"
        >治癒日
      </date-input-form>
      <base-button-small-white
        class="clear"
        @click="$emit('clear-healing-date')"
        >クリア</base-button-small-white
      >
    </div>
  </div>
</template>

<script>
import AutoCompleteInputForm from '@/components/parts/molecules/AutoCompleteInputForm'
import DateInputForm from '@/components/parts/molecules/DateInputForm'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import '@/utils/validation_rules'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ProblemInputForm',

  components: {
    AutoCompleteInputForm,
    DateInputForm,
    BaseButtonSmallWhite
  },

  props: {
    problem: { type: Object }
  },

  computed: {
    ...mapGetters({
      problems: 'problems/getData'
    }),
    idOrderProblems() {
      return this.problems
        .filter(
          (problem, index, self) =>
            self.findIndex(p => p.name === problem.name) === index
        )
        .sort((a, b) => (a.id > b.id ? -1 : 1))
    },
    todaysDate() {
      return moment().format('YYYYMMDD')
    }
  }
}
</script>

<style lang="scss" scoped>
.problem-input-form {
  > .row {
    &.injury-date {
      margin-top: 25px;
    }
    &.healing-date {
      margin-top: 25px;
      display: flex;
      > .clear {
        margin-left: 20px;
      }
    }
  }
}
</style>
