<template>
  <div class="treatment-items-set-table">
    <div class="table">
      <div class="table-header" v-if="headerFlg" :class="{ scroll: scrollFlg }">
        <div class="headers">
          <div
            class="cell"
            v-for="(item, i) in headers"
            :key="item"
            :style="headerCellStyles[i]"
          >
            <div class="text">{{ item }}</div>
          </div>
        </div>
        <div class="trash"></div>
      </div>
      <div
        class="table-body"
        :class="{ scroll: scrollFlg }"
        :style="{ maxHeight: bodyMaxHeight }"
      >
        <draggable
          class="draggable"
          v-bind="options"
          v-model="draggableItems"
          @start="dragStart"
          @end="dragEnd"
        >
          <div
            class="row draggable"
            v-for="item in items"
            :class="{ disabled: disabled }"
            :key="item.key"
            @click="clickItem(item)"
          >
            <div class="item">
              <div class="item-inside">
                <div
                  class="cell"
                  v-for="(key, i) in keys"
                  :key="i"
                  :style="bodyCellStyles[i]"
                >
                  <div class="text" :title="item[key]">{{ item[key] }}</div>
                  <div
                    v-if="key === 'name' && item.taxExemptFlg"
                    class="tax-exempt"
                    :data-test="`tax-exempt-${item.id}`"
                  >
                    （非課税）
                  </div>
                </div>
              </div>
              <div
                class="item-inside prescription"
                v-if="existsPrescription(item)"
              >
                <div class="cell prescription">
                  <div class="icon">
                    <div class="L-shaped-icon">
                      <div class="line vertical"></div>
                      <div class="line horizontal"></div>
                    </div>
                  </div>
                  <div class="label">処方：</div>
                  <div class="value">
                    <div class="text" :title="item.prescription">
                      {{ item.prescription }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="trash">
              <div class="cell trash">
                <div
                  class="image-wrap trash"
                  v-show="!disabled"
                  @click.stop="remove(item)"
                >
                  <img class="image" src="@/assets/images/trash_red.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="row plus" v-show="!disabled">
            <div class="cell plus">
              <div class="image-wrap plus" @click="add">
                <img
                  class="image plus"
                  src="@/assets/images/circle_plus_orange.png"
                />
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div class="table-footer" v-if="items.length === 0">
        新規登録の際は空白部分をクリック（タップ）してください。<br />
        診療項目内容をドラッグすることで並び順が変更できます。
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'TreatmentItemsSetTable',

  components: {
    Draggable
  },

  props: {
    keys: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    sortItems: { type: Array, default: () => [] },
    headerCellStyles: { type: Array, default: () => [] },
    bodyCellStyles: { type: Array, default: () => [] },
    bodyMaxHeight: { type: String, default: '' },
    scrollFlg: { type: Boolean, default: true },
    headerFlg: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      options: {
        animation: 200,
        draggable: '.draggable',
        ghostClass: 'ghost',
        dragClass: 'drag',
        forceFallback: true
      },
      dragFlg: false,
      timeoutId: null
    }
  },

  computed: {
    draggableItems: {
      get() {
        return this.sortItems
      },
      set(items) {
        this.$emit('order', items)
      }
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    existsPrescription(item) {
      return item.prescription?.length > 0
    },
    clickItem(item) {
      if (!this.dragFlg) {
        this.$emit('click-item', item)
      }
    },
    dragStart() {
      this.dragFlg = true
    },
    dragEnd() {
      this.timeoutId = setTimeout(() => {
        this.dragFlg = false
      }, 100)
    },
    remove(item) {
      this.$emit('click-trash', item)
    },
    add() {
      this.$emit('click-plus')
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$very-light-grey};
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin text {
  flex: 1;
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin cell {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  line-height: 29px;
  padding: 0 8px;
  font-size: 11px;
  text-align: center;
  > .text {
    @include text;
  }
}
.treatment-items-set-table {
  width: 100%;
  > .table {
    width: 100%;
    > .table-header {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      height: 30px;
      border: solid transparent;
      border-width: 1px 1px 0 1px;
      &.scroll {
        padding-right: 17px;
      }
      > .headers {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 36px);
        > .cell {
          @include cell;
          padding: 0;
          font-weight: bold;
        }
        > .cell + .cell {
          border-color: transparent;
        }
      }
      > .trash {
        width: 36px;
        border-left: 1px solid transparent;
      }
    }
    > .table-body {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid $border-color;
      &.scroll {
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .row {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        margin-top: -1px;
        background-color: #{$white};
        border: solid $border-color;
        border-width: 1px 0 1px 0;
        cursor: move;
        &:hover {
          opacity: 0.8;
        }
        &:nth-child(odd) {
          background-color: #{$gray_fc};
        }
        &.plus {
          @include center;
          width: 100%;
          border-bottom: none;
          cursor: pointer;
          > .cell {
            width: 100%;
            > .image-wrap {
              @include center;
              width: 100%;
              height: 28px;
              &:hover .image {
                box-shadow: 0 0 4px;
              }
              > .image {
                background-color: #{$white};
                border-radius: 50%;
              }
            }
          }
        }
        &.ghost {
          visibility: hidden;
        }
        &.drag {
          border-width: 1px;
          color: #{$pumpkin};
        }
        &.disabled {
          pointer-events: none;
          background-color: #{$light-grey};
          color: #{$brown-gray};
        }
        > .item {
          width: calc(100% - 36px);
          > .item-inside {
            display: flex;
            justify-content: space-between;
            width: 100%;
            > .cell {
              @include cell;
              text-align: right;
              > .tax-exempt {
                font-weight: bold;
                font-feature-settings: 'palt';
              }
              &.prescription {
                box-sizing: border-box;
                display: flex;
                line-height: 29px;
                padding: 0;
                margin: 0 10px;
                border-top: 1px solid $border-color;
                font-size: 11px;
                text-align: left;
                > .icon {
                  width: 8px;
                  margin: 0 5px 0 10px;
                  > .L-shaped-icon {
                    position: relative;
                    > .line {
                      position: absolute;
                      background-color: #{$greyish-brown};
                      &.vertical {
                        top: 6px;
                        left: 0px;
                        width: 1px;
                        height: 8px;
                      }
                      &.horizontal {
                        top: 14px;
                        left: 0px;
                        width: 8px;
                        height: 1px;
                      }
                    }
                  }
                }
                > .label {
                  width: 35px;
                }
                > .value {
                  flex: 1;
                  display: flex;
                  > .text {
                    @include text;
                  }
                }
              }
            }
          }
        }
        > .trash {
          border-left: 1px solid $border-color;
          > .cell {
            @include center;
            width: 36px;
            height: 100%;
            > .image-wrap {
              @include center;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              z-index: 1;
              cursor: pointer;
              &:hover {
                background-color: #{$light-grey};
              }
            }
          }
        }
      }
    }
    > .table-footer {
      box-sizing: border-box;
      @include center;
      width: 100%;
      height: 45px;
      background-color: #{$white};
      border: solid $border-color;
      border-width: 0 1px 1px 1px;
      font-size: 10px;
      color: #999;
    }
  }
}
.header ~ .body {
  border-top: 1px solid $border-color;
}
.cell + .cell {
  border-left: 1px solid $border-color;
}
</style>
