<template>
  <div class="karte-image-list">
    <base-loading :waitFlg="waitFlg" />
    <search-area-image
      :textValue="searchWord"
      :selectedTagIds="selectedTagIds"
      :defaultStartDate="defaultStartDate"
      :defaultEndDate="defaultEndDate"
      :searchWaitFlg="waitFlg"
      v-model="searchWord"
      @click="resetAndGetData"
      @select-tag="selectTag"
      @input-start-date="val => (startDate = val)"
      @input-end-date="val => (endDate = val)"
      @clear="clearDate"
      @clear-selected-tags="clearSelectedTags"
    />
    <div class="order-result">検索結果：{{ count }}件</div>
    <dynamic-scroller
      v-if="displayImages.length > 0"
      :items="displayImages"
      :min-item-size="292"
      :buffer="10"
      key-field="key"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
        >
          <karte-image-list-row :images="item.array" @open="openImagePopup" />
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <div v-show="!waitFlg && targetShow && !isFull" ref="target"></div>
    <enlarged-image-popup
      v-if="imagePopupFlg"
      :image="popupImage"
      @close="closeImagePopup"
      @error="alreadyDeletedAnnounce"
    />
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import SearchAreaImage from '@/components/parts/molecules/SearchAreaImage'
import KarteImageListRow from '@/components/parts/molecules/KarteImageListRow'
import { getAxiosObject } from '@/utils/library'
import _ from 'lodash'
import { decodeBase64fromBuffer } from '@/utils/base64'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import EnlargedImagePopup from '@/components/popups/EnlargedImagePopup'

export default {
  name: 'KarteImageList',

  components: {
    BaseLoading,
    SearchAreaImage,
    KarteImageListRow,
    AnnouncePopup,
    DynamicScroller,
    DynamicScrollerItem,
    EnlargedImagePopup
  },

  mixins: [DetectWindowSize],

  data() {
    return {
      images: [],
      page: 0,
      isFull: false,
      waitFlg: false,
      searchWord: '',
      selectedTagIds: [],
      defaultStartDate: moment()
        .subtract(7, 'd')
        .toDate(),
      defaultEndDate: moment().toDate(),
      startDate: moment()
        .subtract(7, 'd')
        .format('YYYYMMDD'),
      endDate: moment().format('YYYYMMDD'),
      popupFlg: false,
      title: '',
      popupMessage: '',
      type: '',
      buttons: [],
      popupImage: null,
      imagePopupFlg: false,
      count: 0,
      target: null,
      observe: null,
      targetShow: true // 画像の枚数が少ない時にエラーが発生してしまうとIntersectionObserver用のdivが常に表示されてgetDataし続けてしまうのでその対策。
    }
  },

  computed: {
    ...mapGetters({
      uploadImages: 'uploadImages/getData',
      medicalContentImages: 'medicalContentImages/getData',
      getImageTextsByMedicalContentImageId:
        'medicalContentImageTexts/getDataByMedicalContentImageId',
      getImageTag: 'imageTags/getDataById',
      getMedicalContentByOriginalId: 'medicalContents/getDataByOriginalId',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      imageTagsIndexedById: 'imageTags/getDataIndexedById'
    }),
    compoWidth() {
      // 画面幅 - 左メニュー等の幅
      return this.$store.getters['petorelu/get'].leftWideFlg
        ? this.mixinWindowWidth - 353
        : this.mixinWindowWidth - 154
    },
    displayImages() {
      if (this.images.length > 0) {
        const imageWidth = 290
        const size =
          Math.floor(this.compoWidth / imageWidth) <= 6
            ? Math.floor(this.compoWidth / imageWidth)
            : 6
        const arrayChunk = ([...array], size = 1) => {
          return array.reduce(
            (acc, value, index) =>
              index % size ? acc : [...acc, array.slice(index, index + size)],
            []
          )
        }
        return arrayChunk(this.images, size).map((v, i) => {
          return { key: i + 1, array: v }
        })
      } else {
        return []
      }
    }
  },

  mounted() {
    this.target = this.$refs.target
    this.observe = new IntersectionObserver(() => {
      this.addData()
    })
    this.observe.observe(this.target)
  },

  beforeDestroy() {
    this.observe.unobserve(this.target)
  },

  watch: {
    imageTagsIndexedById: {
      handler: function() {
        this.setImageTag()
      },
      deep: true
    }
  },

  methods: {
    selectTag(tagId) {
      this.selectedTagIds.includes(tagId)
        ? (this.selectedTagIds = this.selectedTagIds.filter(v => v !== tagId))
        : this.selectedTagIds.push(tagId)
      this.resetAndGetData()
    },
    async resetAndGetData() {
      this.images = []
      this.page = 0
      this.count = 0
      this.isFull = false
      await this.getData()
    },
    async getData() {
      try {
        this.targetShow = true
        this.waitFlg = true
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/medical-content-images', {
          page: this.page,
          searchWord: this.searchWord,
          startDate: this.startDate,
          endDate: this.endDate,
          tagIds: this.selectedTagIds
        })
        this.$store.dispatch(
          'medicalContents/updateAfter',
          res.data.medicalContents
        )
        this.$store.dispatch(
          'medicalContentImageTexts/updateAfter',
          res.data.medicalContentImageTexts
        )
        this.$store.dispatch(
          'medicalRecords/updateAfter',
          res.data.medicalRecords
        )
        this.count = res.data.count
        this.page += 1
        const medicalContentImages = res.data.medicalContentImages
        const uploadImages = res.data.uploadImages
        this.setImages(medicalContentImages, uploadImages)
        if (medicalContentImages.length === 0 && uploadImages.length === 0) {
          this.isFull = true
        }
        if (this.page >= 10 && this.images.length >= 500) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage = '画像の表示件数が上限の500件に達しました。'
        }
      } catch (err) {
        this.popupMessage =
          err.response?.data?.message === 'no data' &&
          err.response?.data?.extra === 'uploadImage'
            ? '削除された画像があります。'
            : '通信エラーが発生しました'
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.targetShow = false
      } finally {
        this.waitFlg = false
      }
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    },
    setImages(medicalContentImages, uploadImages) {
      const defaultImageSize = 400
      const listImageSize = 250
      const halfSize = listImageSize / defaultImageSize
      let addData = []
      if (medicalContentImages.length !== 0) {
        addData = decodeBase64fromBuffer(medicalContentImages)
        addData = addData.map(datum => {
          const tmp = this.getImageTextsByMedicalContentImageId(datum.id)
            ? this.getImageTextsByMedicalContentImageId(datum.id)
            : []
          const medicalContentImageTexts = tmp.map(mcit => {
            return {
              id: mcit.id,
              text: mcit.text,
              defaultTextStyle: {
                position: 'absolute',
                top: `${mcit.top}px`,
                left: `${mcit.left}px`,
                width: `${mcit.width}px`,
                height: `${mcit.height}px`,
                color: mcit.color,
                overflow: 'hidden',
                'font-size': `${mcit.size}px`,
                'white-space': 'pre-wrap',
                'z-index': 1,
                'word-break': 'break-all'
              },
              textStyle: {
                position: 'absolute',
                top: `${mcit.top * halfSize}px`,
                left: `${mcit.left * halfSize}px`,
                width: `${mcit.width * halfSize}px`,
                height: `${mcit.height * halfSize}px`,
                color: mcit.color,
                overflow: 'hidden',
                'font-size': `${mcit.size * halfSize}px`,
                'white-space': 'pre-wrap',
                'z-index': 1,
                'word-break': 'break-all'
              }
            }
          })
          const medicalContent = this.getMedicalContentByOriginalId(
            datum.medicalContentOriginalId
          )
          const medicalRecord = this.getMedicalRecordByOriginalId(
            medicalContent.medicalRecordOriginalId
          )
          return {
            ...datum,
            thumbnailImage:
              datum.thumbnailImage === null && datum.schemaImageId !== 0
                ? require(`@/assets/images/schemas/${datum.schemaImageId}.jpg`)
                : datum.thumbnailImage,
            imageStyle:
              datum.schemaImageId !== 0 || datum.uploadImageId !== 0
                ? {
                    'max-width': `${listImageSize *
                      (datum.width / defaultImageSize)}px`,
                    'max-height': `${listImageSize *
                      (datum.height / defaultImageSize)}px`
                  }
                : {
                    'max-width': `${listImageSize}px`,
                    'max-height': `${listImageSize}px`
                  },
            medicalContentImageTexts,
            date: medicalRecord.date,
            imageReferenceTable: 'medicalContentImages'
          }
        })
      }
      if (uploadImages.length !== 0) {
        uploadImages = uploadImages.map(v => {
          const medicalContent = this.getMedicalContentByOriginalId(
            v.medicalContentOriginalId
          )
          const medicalRecord = this.getMedicalRecordByOriginalId(
            medicalContent.medicalRecordOriginalId
          )
          return {
            ...v,
            imageStyle: {
              'max-width': `${listImageSize}px`,
              'max-height': `${listImageSize}px`
            },
            date: medicalRecord.date,
            imageReferenceTable: 'uploadImages'
          }
        })
        addData = addData.concat(decodeBase64fromBuffer(uploadImages))
      }
      if (addData.length !== 0) {
        addData = addData.sort((a, b) => (a.date > b.date ? -1 : 1))
      }
      this.images = this.images.concat(addData)
      this.setImageTag()
    },
    setImageTag(tagId) {
      this.images = this.images.map((v, i) => {
        const tagNames = v.imageTagsIds
          ? v.imageTagsIds.split(',').flatMap(id => {
              if (tagId && tagId === Number(id)) return []
              const imageTag = this.getImageTag(id)
              return imageTag && imageTag.delFlg === 0
                ? '# ' + imageTag.name
                : []
            })
          : []
        return { ...v, key: i + 1, tagNames }
      })
    },
    clearDate() {
      this.startDate = null
      this.endDate = null
    },
    async addData() {
      if (!this.isFull && !this.waitFlg) {
        await this.getData()
      }
    },
    async getImageOfUploadImage(uploadImageId) {
      // 画像ポップアップでは、サムネイルではなく高解像度の見やすい画像を表示したいため、
      // バックエンドから高解像度の画像（uploadImagesテーブルのimageカラム）を取得する
      try {
        this.waitFlg = true
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/upload-images/image', {
          params: { uploadImageId }
        })
        this.waitFlg = false
        return res.data.image
      } catch (err) {
        this.popupMessage = '画像取得時に通信エラーが発生しました。'
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.waitFlg = false
        return 'error'
      }
    },
    async openImagePopup(imageObj) {
      let image
      if (imageObj.imageReferenceTable === 'medicalContentImages') {
        if (imageObj.uploadImageId > 0) {
          const bufferImage = await this.getImageOfUploadImage(
            imageObj.uploadImageId
          )
          if (bufferImage === 'error') return // 画像取得に失敗した場合、画像ポップアップは開かない
          image = Buffer.from(bufferImage, 'base64').toString()
        } else if (imageObj.schemaImageId > 0) {
          image = require(`@/assets/images/schemas/${imageObj.schemaImageId}.jpg`)
        } else {
          image = null
        }
      } else {
        const bufferImage = await this.getImageOfUploadImage(imageObj.id)
        if (bufferImage === 'error') return
        image = Buffer.from(bufferImage, 'base64').toString()
      }
      this.popupImage = { ...imageObj, image }
      this.imagePopupFlg = true
    },
    closeImagePopup() {
      this.popupImage = null
      this.imagePopupFlg = false
    },
    clearSelectedTags() {
      this.selectedTagIds = []
    },
    alreadyDeletedAnnounce() {
      this.popupFlg = true
      this.type = 'failure'
      this.title = '失敗'
      this.buttons = ['閉じる']
      this.popupMessage =
        'クリックしたカルテは既に削除されているため、\nカルテ編集画面に移動できません。'
      this.imagePopupFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.karte-image-list {
  > .order-result {
    margin-top: 5px;
    font-size: 13px;
  }
  > .scroller {
    margin-top: 10px;
    width: 100%;
    max-height: 630px;
    overflow-y: auto;
  }
}
</style>
