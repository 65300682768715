<template>
  <div class="medical-record-setting">
    <div class="wrap">
      <div class="title">カルテ</div>
      <div class="contents">
        <div class="tab-area">
          <div class="tab-title-bar">
            <base-setting-tab-title
              v-for="titleObj in tabTitles"
              :key="titleObj.key"
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
        <div class="button-area">
          <base-button-register
            v-if="lookOnlyFlg === 0"
            class="button"
            :text="'カルテ登録'"
            @click="openOwnerPatientListPopup"
          />
        </div>
        <div class="list-area">
          <router-view />
        </div>
      </div>
    </div>
    <owner-patient-list-popup
      v-if="showPopupFlg"
      @close="closeOwnerPatientListPopup"
    />
  </div>
</template>

<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import OwnerPatientListPopup from '@/components/popups/OwnerPatientListPopup'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalRecordSetting',

  components: {
    BaseSettingTabTitle,
    BaseButtonRegister,
    OwnerPatientListPopup
  },

  data() {
    return {
      tabTitles: [
        {
          key: 1,
          title: 'すべて',
          link: { name: 'medical-records-all' },
          names: ['medical-records-all']
        },
        {
          key: 2,
          title: '診療内容',
          link: { name: 'medical-contents' },
          names: ['medical-contents']
        },
        {
          key: 3,
          title: '診療明細',
          link: { name: 'medical-payments' },
          names: ['medical-payments']
        },
        {
          key: 4,
          title: '画像',
          link: { name: 'karte-image-list' },
          names: ['karte-image-list']
        }
      ],
      showPopupFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  methods: {
    openOwnerPatientListPopup() {
      this.showPopupFlg = true
    },
    closeOwnerPatientListPopup() {
      this.showPopupFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-record-setting {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap {
    padding: 24px 48px;
    box-sizing: border-box;
    display: inline-block;
    min-width: 100%;
    > .title {
      font-size: 20px;
      font-weight: bold;
    }
    > .contents {
      margin-top: 20px;
      min-width: 614px;
      > .button-area {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin: 20px 0;
        }
      }
      > .tab-area {
        margin-top: 0px;
        > .tab-title-bar {
          display: flex;
          flex-direction: row;
          height: 26px;
          margin-top: 20px;
          padding-bottom: -4px;
          border-bottom: 1px solid;
          border-color: #{$light-grey};
          box-sizing: border-box;
          > .title {
            width: 150px;
            font-size: 15px;
            text-align: center;
            line-height: 15px;
            @include hover();
          }
          > .active {
            border-bottom: 4px solid;
            border-color: #{$pumpkin};
            color: #{$pumpkin};
            height: 22px;
          }
        }
      }
    }
  }
}
</style>
