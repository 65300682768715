export const existsUpdatedMedicalPaymentAfterMiddleCalculateOrEnd = (
  middleCalculateOrEndMedicalPayment,
  medicalPaymentOriginalIds,
  getMedicalPaymentByOriginalId
) => {
  return medicalPaymentOriginalIds.some(medicalPaymentOriginalId => {
    const mp = getMedicalPaymentByOriginalId(medicalPaymentOriginalId)
    return (
      mp.id !== middleCalculateOrEndMedicalPayment.id &&
      mp.updatedAt > middleCalculateOrEndMedicalPayment.updatedAt
    )
  })
}

export const needsMiddleCalculateOrEndMedicalPaymentUpdate = (
  medicalPayment,
  hospitalizations,
  getMedicalPaymentByOriginalId
) => {
  if (medicalPayment.middleCalculateFlg) {
    const hospitalization = hospitalizations.find(v =>
      v.medicalPaymentOriginalIds.includes(medicalPayment.originalId)
    )
    return hospitalization
      ? existsUpdatedMedicalPaymentAfterMiddleCalculateOrEnd(
          medicalPayment,
          hospitalization.medicalPaymentOriginalIdsUntilMiddleCalculate,
          getMedicalPaymentByOriginalId
        )
        ? '途中精算'
        : ''
      : ''
  } else if (
    medicalPayment.endHospitalizationFlg &&
    !medicalPayment.startHospitalizationFlg
  ) {
    const hospitalization = hospitalizations.find(v =>
      v.medicalPaymentOriginalIds.includes(medicalPayment.originalId)
    )
    if (!hospitalization) return ''

    if (
      hospitalization.middleCalculateMedialPaymentOriginalId &&
      hospitalization.middleCalculateDate &&
      hospitalization.medicalPaymentOriginalIdsUntilMiddleCalculate
    ) {
      //退院までの間に途中精算をしている場合
      const medicalPaymentOriginalIdsAfterMiddleCalculate = hospitalization.medicalPaymentOriginalIds.filter(
        originalId =>
          !hospitalization.medicalPaymentOriginalIdsUntilMiddleCalculate.includes(
            originalId
          )
      )
      return existsUpdatedMedicalPaymentAfterMiddleCalculateOrEnd(
        medicalPayment,
        medicalPaymentOriginalIdsAfterMiddleCalculate,
        getMedicalPaymentByOriginalId
      )
        ? '退院'
        : ''
    } else {
      //退院までの間に途中精算をしていない場合
      return existsUpdatedMedicalPaymentAfterMiddleCalculateOrEnd(
        medicalPayment,
        hospitalization.medicalPaymentOriginalIds,
        getMedicalPaymentByOriginalId
      )
        ? '退院'
        : ''
    }
  } else {
    return ''
  }
}
