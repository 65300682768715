import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  makePriceDetail,
  makeInsuranceType,
  calculateTreatmentItemSubtotal
} from '@/utils/price_calculation'
import {
  counterPaymentNg,
  counterPaymentOk,
  counterPaymentOkOutpatient,
  counterPaymentOkInpatient,
  surgeryNg
} from '@/utils/anicom_response'
import {
  makeHospitalizationDatum,
  makeHospitalizationHistory
} from '@/utils/hospitalization_history'
import {
  isHospitalization,
  isStart,
  isIn,
  isEnd,
  startEndMinMax,
  makeInEndDateTime
} from '@/utils/medical_payment_helper'
import { ANICOM_URL } from '@/utils/define'
import { makePaymentDetail } from '@/utils/price_calculation'
import { dateDiff } from '@/utils/date'

export default {
  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      mixinPopup: { decision: () => {} },
      mixinHospitalizationHistory: [],
      mixinPatient: null,
      mixinAnicomPatient: null,
      mixinIpetPatient: null,
      mixinAnicomCIdCheck: null,
      mixinIpetCheck: null,
      mixinIsCheckingApply: false,
      mixinCanApply: false,
      mixinPatientLatest: null,
      mixinRemoveApplyFlgError: false,
      mixinShouldUpdateInsurance: false
    }
  },

  computed: {
    ...mapGetters({
      getPatient: 'patients/getDataById',
      patients: 'patients/getData',
      diseaseClassByOriginalId: 'diseaseClasses/getDataByOriginalId',
      diseaseClassById: 'diseaseClasses/getDataById',
      diseaseByOriginalId: 'diseases/getDataByOriginalId',
      diseaseById: 'diseases/getDataById',
      treatmentByOriginalId: 'treatments/getDataByOriginalId',
      treatmentById: 'treatments/getDataById',
      medicineByOriginalId: 'medicines/getDataByOriginalId',
      medicineById: 'medicines/getDataById',
      medicalPaymentsByPatientId: 'medicalPayments/getDataByPatientId',
      medicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId',
      medicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      medicalTreatmentItemsByMedicalPaymentId:
        'medicalTreatmentItems/getDataByMedicalPaymentId',
      medicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      paymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      anicomReportsByMedicalPaymentId:
        'anicomReports/getDataByMedicalPaymentId',
      hospitalizationsByPatientId: 'hospitalizations/getDataByPatientId',
      estimateById: 'estimates/getDataById'
    }),
    mixinPriceDetail() {
      if (
        this.medicalPayment.middleCalculateFlg ||
        this.medicalPayment.endHospitalizationFlg
      ) {
        let surgeryCount, hStartDate, hEndDate, hTreatmentItems
        surgeryCount = 0
        hTreatmentItems = []
        this.mixinHospitalizationMedicalPayments.forEach(v => {
          if (v.surgeryFlg === 1) surgeryCount += 1
          //※↓2023/01/23 現状途中精算をした場合、途中精算と退院の診療明細で保険適用はできない仕様にしており、
          //  hStartDateとhEndDateを変えても金額は変わらないので、一律で入院開始日・退院日にしています
          if (v.startHospitalizationFlg === 1) hStartDate = v.date
          if (v.endHospitalizationFlg === 1) hEndDate = v.date
          hTreatmentItems = hTreatmentItems.concat(v.treatmentItems)
        })
        const hDays = dateDiff(hStartDate, hEndDate, 'days') + 1
        const result = makePriceDetail(
          this.medicalPayment,
          surgeryCount,
          hDays,
          hTreatmentItems
        )
        return result
      } else {
        const result = makePriceDetail(
          this.medicalPayment,
          this.medicalPayment.surgeryFlg, //← 0 or 1
          1,
          this.medicalTreatmentItems
        )
        return result
      }
    },
    mixinInvalidApply() {
      return (
        this.mixinInvalidDisease ||
        this.mixinInvalidNoApplyReason ||
        this.mixinInvalidOnsetDate ||
        this.mixinInvalidBill
      )
    },
    mixinInvalidDisease() {
      return (
        this.medicalPayment.applyFlg === 1 &&
        this.medicalPayment.disease1Id === 0 &&
        this.medicalPayment.disease2Id === 0
      )
    },
    mixinInvalidNoApplyReason() {
      const medicalTreatmentItems = [
        ...this.mixinPastHospitalizationMedicalPayments.flatMap(
          v => v.treatmentItems
        ),
        ...this.medicalTreatmentItems
      ]
      return (
        this.medicalPayment.applyFlg === 1 &&
        this.medicalPayment.anicomCIdCheckId > 0 &&
        this.medicalPayment.reason01 === 0 &&
        this.medicalPayment.reason02 === 0 &&
        this.medicalPayment.reason03 === 0 &&
        this.medicalPayment.reason04 === 0 &&
        this.medicalPayment.reason05 === 0 &&
        this.medicalPayment.reason06 === 0 &&
        this.medicalPayment.reason07 === 0 &&
        this.medicalPayment.reason08 === 0 &&
        medicalTreatmentItems.some(v => !v.insuranceFlg)
      )
    },
    mixinInvalidOnsetDate() {
      return (
        this.medicalPayment.applyFlg === 1 &&
        this.medicalPayment.onsetDate === '' &&
        this.medicalPayment.uncertainOnsetFlg === 0
      )
    },
    mixinInvalidBill() {
      return (
        this.medicalPayment.applyFlg === 1 &&
        (this.mixinPriceDetail.insurancePrice <= 0 ||
          this.mixinPriceDetail.ownerBill <= 0)
      )
    },
    mixinIsAnicomOk() {
      const existsKekka = this.mixinAnicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentOk.some(v => {
          return v.kekka1.includes(this.mixinAnicomCIdCheck.kekka1)
        })
      )
    },
    mixinIsAnicomOkOutpatient() {
      const existsKekka = this.mixinAnicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentOkOutpatient.some(v => {
          return v.kekka1.includes(this.mixinAnicomCIdCheck.kekka1)
        })
      )
    },
    mixinIsAnicomOkInpatient() {
      const existsKekka = this.mixinAnicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentOkInpatient.some(v => {
          return v.kekka1.includes(this.mixinAnicomCIdCheck.kekka1)
        })
      )
    },
    mixinIsAnicomNg() {
      const existsKekka = this.mixinAnicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentNg.some(v => {
          return v.kekka1.includes(this.mixinAnicomCIdCheck.kekka1)
        })
      )
    },
    mixinIsSurgeryNg() {
      const existsKekka = this.mixinAnicomCIdCheck?.kekka2.length > 0
      return (
        existsKekka &&
        surgeryNg.some(v => {
          return this.mixinAnicomCIdCheck.kekka2.includes(v)
        })
      )
    },
    mixinDateChangeNg() {
      return this.medicalPayment.applyFlg === 1 ||
        (this.mixinPaymentType && this.mixinPaymentType !== '未会計') ||
        // edit in hospital
        (this.medicalPayment.id &&
          (this.medicalPayment.startHospitalizationFlg === 1 ||
            this.medicalPayment.inHospitalFlg === 1 ||
            this.medicalPayment.endHospitalizationFlg === 1))
        ? true
        : false
    },
    mixinPastHospitalizationMedicalPayments() {
      if (
        this.medicalPayment.middleCalculateFlg ||
        (!this.medicalPayment.startHospitalizationFlg &&
          this.medicalPayment.endHospitalizationFlg)
      ) {
        //登録・編集中の診療明細が途中精算または退院(日帰り入院ではない)の場合
        const { hospitalization } = this.mixinMakeHospitalizationDetail()
        const records = hospitalization?.records || []
        let middleCalculateMedicalPayment = null //途中精算した診療明細
        const hospitalizationMedicalPayments = records.map(v => {
          const medicalRecord = this.medicalRecordByOriginalId(v.originalId)
          const medicalPayment = this.medicalPaymentByOriginalId(
            v.medicalPaymentOriginalId
          )
          const medicalTreatmentItems = (
            this.medicalTreatmentItemsByMedicalPaymentId(medicalPayment.id) ||
            []
          ).filter(
            item =>
              this.treatmentById(item.treatmentId) ||
              this.medicineById(item.medicineId)
          )
          const customMedicalPayment = {
            ...medicalPayment,
            date: medicalRecord.date, // 診療日
            startTime: medicalRecord.startTime,
            endTime: medicalRecord.endTime,
            treatmentItems: medicalTreatmentItems // 診療項目内容
          }
          if (
            customMedicalPayment.middleCalculateFlg &&
            customMedicalPayment.originalId !== this.medicalPayment.originalId
            //↑既存の途中精算の診療明細を退院に変更した場合はmiddleCalculateMedicalPayment変数に値を入れないようにし、
            // 途中精算をしていない場合の処理に行くようにする
          ) {
            middleCalculateMedicalPayment = customMedicalPayment
          }
          return customMedicalPayment
        })
        const pastHospitalizationMedicalPayments = hospitalizationMedicalPayments.filter(
          v => {
            const isBeforeMiddleCalculateOrEndMedicalPayment =
              v.date < this.medicalRecord.date ||
              (v.date === this.medicalRecord.date &&
                v.endTime <= this.medicalRecord.endTime)
            if (this.medicalPayment.middleCalculateFlg) {
              //途中精算の診療明細
              return (
                v.originalId !== this.medicalPayment.originalId &&
                isBeforeMiddleCalculateOrEndMedicalPayment
              )
            } else {
              //退院の診療明細(日帰り入院ではない)
              if (middleCalculateMedicalPayment) {
                //途中精算をしていた場合。途中精算の診療明細より日時が後の診療明細だけに絞る
                const isAfterMiddleCalculateMedicalPayment =
                  middleCalculateMedicalPayment.date < v.date ||
                  (middleCalculateMedicalPayment.date === v.date &&
                    middleCalculateMedicalPayment.endTime <= v.startTime)
                return (
                  v.originalId !== this.medicalPayment.originalId &&
                  isAfterMiddleCalculateMedicalPayment &&
                  isBeforeMiddleCalculateOrEndMedicalPayment
                )
              } else {
                //途中精算をしていない場合
                return (
                  v.originalId !== this.medicalPayment.originalId &&
                  isBeforeMiddleCalculateOrEndMedicalPayment
                )
              }
            }
          }
        )
        return pastHospitalizationMedicalPayments
      } else {
        return []
      }
    },
    mixinHospitalizationMedicalPayments() {
      if (
        this.medicalPayment.middleCalculateFlg ||
        this.medicalPayment.endHospitalizationFlg
      ) {
        const pastHospitalizationMedicalPayments = this
          .mixinPastHospitalizationMedicalPayments
        const hospitalizationMedicalPayments = pastHospitalizationMedicalPayments.concat(
          [
            {
              ...this.medicalPayment,
              date: this.medicalRecord.date,
              startTime: this.medicalRecord.startTime,
              endTime: this.medicalRecord.endTime,
              treatmentItems: this.medicalTreatmentItems
            }
          ]
        )
        return hospitalizationMedicalPayments
      } else {
        return []
      }
    },
    mixinPaymentType() {
      return !this.medicalPayment.id
        ? null
        : (this.medicalPayment.startHospitalizationFlg === 1 ||
            this.medicalPayment.inHospitalFlg === 1) &&
          this.medicalPayment.middleCalculateFlg === 0 &&
          this.medicalPayment.endHospitalizationFlg === 0
        ? null
        : makePaymentDetail(
            this.medicalPayment,
            this.medicalPaymentHistory,
            this.medicalRecordByOriginalId,
            this.paymentsByMedicalPaymentId
          ).type
    },
    mixinStartEndMinMax() {
      const { previous, current, next, same } = this.mixinHospitalizationInfo
      let obj = {
        startTimeMin: '',
        startTimeMax: '',
        endTimeMin: '',
        endTimeMax: ''
      }
      if (isHospitalization(this.medicalPayment)) {
        const hospitalization = same || current
        const hospitalizationData = this.mixinHospitalizationData(
          hospitalization
        )
        obj = startEndMinMax(
          this.medicalRecord,
          this.medicalPayment,
          hospitalizationData,
          previous,
          next,
          this.mixinInitialData.medicalPayment
        )
      }
      return obj
    },
    mixinHospitalizationInfo() {
      const mrStartDateTime =
        this.medicalRecord.date + this.medicalRecord.startTime
      const hospitalizations = (
        this.hospitalizationsByPatientId(this.patientId) || []
      ).sort((a, b) => {
        return a.startDate === b.startDate
          ? a.id - b.id
          : a.startDate < b.startDate
          ? -1
          : 1
      }) // 日付昇順 新しいデータほど先にくる
      let previous // 入院終了がカルテ開始以前で一番近い
      let current // 入院期間がカルテ開始と被っている
      let next // 入院開始がカルテ開始よりも後
      let same // 同じ入院枠
      hospitalizations.forEach(v => {
        const {
          startDateTime,
          endDateTime
        } = this.mixinHospitalizationStartEnd(v)
        if (
          v.medicalPaymentOriginalIds.includes(this.medicalPayment.originalId)
        ) {
          const hospitalizationData = this.mixinHospitalizationData(v, true)
          const inEndDateTime = makeInEndDateTime(hospitalizationData)
          same = {
            ...v,
            startDateTime,
            endDateTime,
            inEndDateTime,
            hospitalizationData
          }
          return
        }
        if (mrStartDateTime < startDateTime) {
          if (next) {
            if (startDateTime < next.startDateTime) {
              next = { startDateTime, endDateTime }
            }
          } else {
            next = { startDateTime, endDateTime }
          }
        }
        if (endDateTime !== '' && endDateTime <= mrStartDateTime) {
          if (previous) {
            if (previous.startDateTime < startDateTime) {
              previous = { startDateTime, endDateTime }
            }
          } else {
            previous = { startDateTime, endDateTime }
          }
        }
        if (
          startDateTime <= mrStartDateTime &&
          ((endDateTime !== '' && mrStartDateTime < endDateTime) ||
            endDateTime === '')
        ) {
          if (!current) {
            const hospitalizationData = this.mixinHospitalizationData(v, true)
            const inEndDateTime = makeInEndDateTime(hospitalizationData)
            current = {
              ...v,
              startDateTime,
              endDateTime,
              inEndDateTime,
              hospitalizationData
            }
          }
        }
      })
      return { previous, current, next, same }
    },
    mixinOverlappedTimeErrorMessage() {
      if (
        (this.mixinHospitalizationInfo.current ||
          this.mixinHospitalizationInfo.same) && //既存の外来の診療明細を入院中にした時、新規と同じように入院開始～退院までの時間を自由に選べる仕様にしているのでチェックが必要
        isHospitalization(this.medicalPayment) //外来の診療明細の場合は時間が重なってもよい仕様にしたのでelseに行くようにする
      ) {
        //入院開始と退院と日帰り入院の明細の場合は診療時間の選択欄で時間を制限しているので基本的に重なるようにはできないが、念のためこの処理でチェックしています
        const hospitalizationData = this.mixinHospitalizationInfo.current
          ? this.mixinHospitalizationInfo.current.hospitalizationData
          : this.mixinHospitalizationInfo.same.hospitalizationData
        return hospitalizationData.reduce((errorMessage, v) => {
          if (this.medicalRecord.date !== v.date) return errorMessage
          const isOverlappedTime = !(
            this.medicalRecord.startTime >= v.endTime ||
            this.medicalRecord.endTime <= v.startTime
          )
          if (!isOverlappedTime) return errorMessage
          const time =
            moment(v.startTime, 'HHmm').format('HH:mm') +
            '～' +
            moment(v.endTime, 'HHmm').format('HH:mm')
          return !errorMessage
            ? `他の入院カルテの時間と重ならないよう時間を変更して下さい。重なっている他のカルテの時間 ⇒ ${time}`
            : errorMessage + `、${time}`
        }, '')
      } else {
        return ''
      }
    },
    mixinHospitalizationUpdateFlg() {
      if (
        this.medicalPayment.startHospitalizationFlg ||
        (!this.mixinHospitalizationInfo?.current &&
          !this.mixinHospitalizationInfo?.same)
      )
        return false
      const { hospitalizationData, endMedicalPaymentOriginalId } =
        this.mixinHospitalizationInfo.current ??
        this.mixinHospitalizationInfo.same
      const dischargeMedicalPayment = this.medicalPaymentByOriginalId(
        endMedicalPaymentOriginalId
      )
      const isPaid = dischargeMedicalPayment
        ? makePaymentDetail(
            dischargeMedicalPayment,
            this.medicalPaymentHistory,
            this.medicalRecordByOriginalId,
            this.paymentsByMedicalPaymentId
          ).type === '会計済'
        : false
      if (isPaid) return false
      if (
        this.medicalPayment.inHospitalFlg ||
        this.medicalPayment.endHospitalizationFlg
      ) {
        const orderedHospitalizationData = hospitalizationData.sort(
          (a, b) => Number(a.date + a.startTime) - Number(b.date + b.startTime)
        )
        const { date, startTime } = this.medicalRecord
        const beforeOpenedRecord = orderedHospitalizationData.filter(
          v => v.date + v.startTime < date + startTime
        )
        const { insurance } = this.mixinPatientLatest
        const currentInsuranceType =
          insurance === 'ipet' || insurance === 'docomo'
            ? 'ipet-docomo'
            : insurance
        const incorrectInsurance = beforeOpenedRecord.flatMap(v => {
          if (v.insuranceType !== currentInsuranceType) return v
          return []
        })
        const previousRecordsSameInsurance = beforeOpenedRecord.every(
          v => v.insuranceType === beforeOpenedRecord[0].insuranceType
        )
        if (
          this.medicalPayment.applyFlg &&
          previousRecordsSameInsurance &&
          this.medicalPayment.insuranceType ===
            beforeOpenedRecord[0].insuranceType
        ) {
          // 開かれた明細が保険が適用された退院明細であり、以前の insuranceType がすべて同じである場合
          return false
        }
        if (
          this.medicalPayment.endHospitalizationFlg &&
          previousRecordsSameInsurance &&
          incorrectInsurance.length !== beforeOpenedRecord.length &&
          this.medicalPayment.id &&
          this.medicalPayment.insuranceType !== currentInsuranceType &&
          this.medicalPayment.applyFlg
        ) {
          // 開かれた明細が保険が適用された退院明細であり、以前のすべての insuranceType が同じであるが、退院明細の insuranceType とは異なる場合
          this.mixinRemoveApplyFlgError = true
          return false
        }
        return incorrectInsurance.length ? true : false
      }
      return false
    }
  },

  created() {
    this.mixinSetHospitalizationHistory()
    this.mixinPatientLatest = this.getPatient(this.patientId)
  },
  mounted() {
    if (this.mixinRemoveApplyFlgError || this.mixinHospitalizationUpdateFlg) {
      const message = this.mixinRemoveApplyFlgError
        ? `この患者の保険は入院開始後に変更されました。
      退院の診療明細を再登録したい場合は一度保険適用のチェックを \n はずしてください。
      登録ボタンが無効になっており入力内容を保存できませんのでご注意ください。`
        : `この患者の保険は入院開始後に変更されました。\n 今回の入院期間の各診療明細を順番に再登録してください。\n 登録ボタンが無効になっており入力内容を保存できませんのでご注意ください。`
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['閉じる']
      this.popupMessage = message
    }
  },

  watch: {
    'medicalRecord.date': function() {
      this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
    },
    'medicalRecord.startTime': function() {
      this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
    },
    patients: function() {
      this.mixinPatientLatest = this.getPatient(this.patientId)
    }
  },

  methods: {
    mixinSetInsurance() {
      this.mixinSetPatient()
      this.mixinSetAnicomPatient()
      this.mixinSetIpetPatient()
      this.mixinSetAnicomCIdCheck()
      this.mixinSetIpetCheck()
      this.mixinSetInsuranceType()
    },
    mixinSetPatient() {
      this.mixinPatient = this.$store.getters['patients/getDataById'](
        this.patientId
      )
    },
    mixinSetAnicomPatient() {
      this.mixinAnicomPatient = this.$store.getters[
        'anicomPatients/getAnicomPatient'
      ](this.patientId)
    },
    mixinSetIpetPatient() {
      this.mixinIpetPatient = this.$store.getters[
        'ipetPatients/getIpetPatient'
      ](this.patientId)
    },
    mixinSetAnicomCIdCheck() {
      this.mixinAnicomCIdCheck = this.$store.getters[
        'anicomCIdChecks/getDataById'
      ](this.medicalPayment.anicomCIdCheckId)
    },
    mixinSetIpetCheck() {
      this.mixinIpetCheck = this.$store.getters['ipetChecks/getDataById'](
        this.medicalPayment.ipetCheckId
      )
    },
    mixinSetInsuranceType() {
      this.medicalPayment.insuranceType = makeInsuranceType(
        this.medicalPayment,
        this.mixinPatient
      )
    },
    async mixinInputApplyFlg(applyFlg) {
      const { insurance } = this.mixinPatient
      this.medicalPayment.applyFlg = applyFlg
      if (this.medicalPayment.applyFlg === 1) {
        this.mixinIsCheckingApply = true
        this.mixinCheckCanApply(true)
        if (!this.mixinCanApply) return
        this.mixinCanApply = false
        if (insurance === 'anicom') {
          await this.mixinCheckAnicomValidity()
        } else if (insurance === 'ipet' || insurance === 'docomo') {
          await this.mixinCheckIpetValidity()
        } else {
          const ngMsg = '現在の保険設定では保険を適用することができません。'
          this.mixinPopupAlert(ngMsg)
        }
      } else {
        this.mixinRemoveApplyFlgError = false
        this.mixinResetMPInsurance()
      }
    },
    mixinCheckCanApply(onInput = false) {
      if (this.medicalPayment.applyFlg === 0) return
      let ngMsg
      if (this.mixinIsApplyNgDate()) {
        ngMsg = '診療日当日のみ保険の適用が可能です。'
      }
      if (this.mixinIsApplyNgHospitalization()) {
        ngMsg =
          '入院診療に保険を適用する際は、退院日の診療にてまとめて保険を適用してください。\nまた、途中精算をしている場合は保険を適用することができません。'
      }
      if (this.mixinIsApplyNgInpatient()) {
        const { kekka1, kekka2 } = this.mixinAnicomCIdCheck
        ngMsg = `${kekka1}\n${kekka2}`
      }
      if (ngMsg) {
        this.mixinIsCheckingApply = true
        this.mixinPopupAlert(ngMsg)
      } else {
        if (onInput) this.mixinCanApply = true
      }
    },
    mixinIsApplyNgDate() {
      return this.medicalRecord.date !== moment().format('YYYYMMDD')
    },
    mixinIsApplyNgHospitalization() {
      const { current, same } = this.mixinHospitalizationInfo
      const hasMiddleCalculate = Boolean(
        current?.middleCalculateDate ||
          (same?.middleCalculateDate &&
            same.middleCalculateMedialPaymentOriginalId !==
              this.medicalPayment.originalId)
      )
      return (
        isStart(this.medicalPayment) ||
        isIn(this.medicalPayment) ||
        (isEnd(this.medicalPayment) && hasMiddleCalculate)
      )
    },
    mixinIsApplyNgInpatient() {
      return (
        this.mixinIsAnicomOkInpatient &&
        this.medicalPayment.endHospitalizationFlg === 0
      )
    },
    async mixinCheckAnicomValidity() {
      this.waitFlg = true
      const res = await this.$store.dispatch('patients/checkAnicomValidity', {
        patientId: this.patientId
      })
      this.waitFlg = false
      const { anicomCIdCheck, errMsg } = res
      let message = ''
      if (anicomCIdCheck) {
        this.mixinAnicomCIdCheck = anicomCIdCheck
        this.mixinCheckRezeptCd(anicomCIdCheck.code)
        if (!this.mixinCanApply) return
        this.mixinCanApply = false
        message = `${anicomCIdCheck.kekka1}\n${anicomCIdCheck.kekka2}`
        this.title = 'メッセージ'
        if (this.mixinIsAnicomOk) {
          this.mixinCanApply = true
          this.type = 'success'
        } else if (this.mixinIsAnicomOkOutpatient) {
          if (this.medicalPayment.endHospitalizationFlg === 1) {
            this.type = 'alert'
          } else {
            this.mixinCanApply = true
            this.type = 'success'
          }
        } else if (this.mixinIsAnicomOkInpatient) {
          if (this.medicalPayment.endHospitalizationFlg === 1) {
            this.mixinCanApply = true
            this.type = 'success'
          } else {
            this.type = 'alert'
          }
        } else if (this.mixinIsAnicomNg) {
          this.type = 'alert'
          this.title = '注意'
          this.showValidityCheckButton = true
        } else {
          this.type = 'failure'
          this.title = '失敗'
          message = '保険情報の取得に失敗しました。'
        }
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (errMsg === 'no data in clinic' || errMsg === 'no patient') {
          message =
            '対象の患者は既に削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (errMsg === 'no insurance setting') {
          message =
            '対象の患者にアニコムの保険情報が設定されていないため保険を適用することができません。'
        } else if (errMsg?.includes('保険を適用できませんでした')) {
          message = errMsg
        } else {
          message = '保険情報の取得に失敗しました。'
        }
      }
      this.leftAlignMessage = message
      this.buttons = ['閉じる']
      this.popupFlg = true
    },
    mixinCheckRezeptCd(code) {
      const today = moment().format('YYYYMMDD')
      const existsSameRezeptCd = this.getMedicalPayments().some(v => {
        const medicalRecord = this.medicalRecordByOriginalId(
          v.medicalRecordOriginalId
        )
        return (
          medicalRecord.date === today &&
          v.id !== this.medicalPayment.id &&
          v.applyFlg === 1 &&
          v.rezeptCd &&
          code &&
          v.rezeptCd === code &&
          v.delFlg === 0
        )
      })
      if (existsSameRezeptCd) {
        const anicomReports = this.getAnicomReportsIncludeDel().filter(
          v => v.rezeptCdDate === today && v.rezeptCd === code
        )
        const anicomReport =
          anicomReports.length > 0
            ? anicomReports.reduce((a, b) => {
                const greater = b.id > a.id ? b : a
                return greater
              })
            : undefined
        const { patientCId = '' } = this.mixinAnicomCIdCheck
        let message
        if (anicomReport?.cancelFlg === 0) {
          message = `証券番号:${patientCId}の契約者は、本日既に他の診療にてオンラインレセプトを送信済みです`
        } else if (anicomReport?.cancelFlg === 1) {
          message = `証券番号:${patientCId}の契約者は、本日既に他の診療にてオンラインレセプトを取消済みです`
        } else {
          message = `証券番号:${patientCId}の契約者は、本日既に他の診療にて未送信のオンラインレセプトを作成済みです`
        }
        this.mixinPopupAlert(message)
      } else {
        this.mixinCanApply = true
      }
    },
    getMedicalPayments() {
      // delFlg:0 のみのデータ
      return this.$store.getters['medicalPayments/getData'] || []
    },
    getAnicomReportsIncludeDel() {
      return this.$store.getters['anicomReports/getDataIncludeDel'] || []
    },
    async mixinCheckIpetValidity() {
      const { current, same } = this.mixinHospitalizationInfo
      const guDate =
        isHospitalization(this.medicalPayment) && (current || same)
          ? current?.startDate || same?.startDate
          : this.medicalRecord.date
      this.waitFlg = true
      const res = await this.$store.dispatch('patients/checkIpetValidity', {
        patientId: this.patientId,
        insurance: this.mixinPatient.insurance,
        guDate
      })
      this.waitFlg = false
      const { ipetCheck, errMsg } = res
      let message = ''
      if (ipetCheck) {
        this.mixinCheckPolicyNo(ipetCheck.policyNo)
        if (!this.mixinCanApply) return
        this.mixinIpetCheck = ipetCheck
        this.type = 'success'
        this.title = 'メッセージ'
        message = `保険を適用しました。補償割合は${ipetCheck.claimRate}%です。`
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (errMsg === 'no data in clinic' || errMsg === 'no patient') {
          message =
            '対象の患者は既に削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (errMsg === 'no insurance setting') {
          const { insurance } = this.mixinPatient
          let target = ''
          if (insurance === 'ipet') target = 'アイペットの'
          else if (insurance === 'docomo') target = 'ドコモの'
          message = `対象の患者に${target}保険情報が設定されていないため保険を適用することができません。`
        } else if (errMsg === 'claimRate 100') {
          message =
            '補償割合が100%の場合、窓口精算ができないため保険を適用することができません。'
        } else if (errMsg?.includes('保険を適用できませんでした')) {
          message = errMsg
        } else {
          message = '保険情報の取得に失敗しました。'
        }
      }
      this.leftAlignMessage = message
      this.buttons = ['閉じる']
      this.popupFlg = true
    },
    mixinCheckPolicyNo(policyNo) {
      const today = moment().format('YYYYMMDD')
      const existsSamePolicyNo = this.getMedicalPayments().some(v => {
        const isSameMP = v.id === this.medicalPayment.id
        if (v.applyFlg === 0 || isSameMP) return false
        const medicalRecord = this.medicalRecordByOriginalId(
          v.medicalRecordOriginalId
        )
        if (medicalRecord.date !== today) return false
        const ipetCheck = this.$store.getters['ipetChecks/getDataById'](
          v.ipetCheckId
        )
        const isSamePolicyNo = ipetCheck?.policyNo === policyNo
        return isSamePolicyNo
      })
      if (existsSamePolicyNo) {
        const message = `証券番号:${policyNo}の契約者は、本日既に他の診療にて保険適用を行っています`
        this.mixinPopupAlert(message)
      } else {
        this.mixinCanApply = true
      }
    },
    mixinPopupAlert(message) {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['閉じる']
      this.leftAlignMessage = message
      this.popupFlg = true
    },
    mixinResetMPInsurance() {
      this.medicalPayment.applyFlg = 0
      this.medicalPayment.anicomCIdCheckId = 0
      this.medicalPayment.ipetCheckId = 0
      this.medicalPayment.rezeptCd = ''
      this.medicalPayment.pledgeRate = 0
      this.medicalPayment.reason01 = 0
      this.medicalPayment.reason02 = 0
      this.medicalPayment.reason03 = 0
      this.medicalPayment.reason04 = 0
      this.medicalPayment.reason05 = 0
      this.medicalPayment.reason06 = 0
      this.medicalPayment.reason07 = 0
      this.medicalPayment.reason08 = 0
      this.medicalPayment.reason09Txt = ''
      this.medicalPayment.surgeryLimitFlg = 0
      this.mixinAnicomCIdCheck = null
      this.mixinIpetCheck = null
      this.mixinSetInsuranceType()
    },
    mixinInputInHospitalFlg(inHospitalFlg, changeMiddleCalculateFlg) {
      this.medicalPayment.inHospitalFlg = inHospitalFlg
      if (inHospitalFlg === 0) {
        this.medicalPayment.endHospitalizationFlg = 0
        if (changeMiddleCalculateFlg) {
          this.medicalPayment.middleCalculateFlg = 0
        }
      } else {
        if (this.medicalPayment.endHospitalizationFlg === 0) {
          this.medicalPayment.discountRate = 0
          this.medicalPayment.discountPrice = 0
        }
      }
      this.mixinCheckCanApply()
    },
    mixinInputStartHospitalizationFlg(startHospitalizationFlg) {
      this.medicalPayment.startHospitalizationFlg = startHospitalizationFlg
      this.medicalPayment.inHospitalFlg = 0
      this.medicalPayment.middleCalculateFlg = 0
      this.medicalPayment.endHospitalizationFlg = 0
      if (startHospitalizationFlg === 1) {
        this.medicalPayment.discountRate = 0
        this.medicalPayment.discountPrice = 0
      }
      this.mixinCheckCanApply()
    },
    mixinInputMiddleCalculateFlg(middleCalculateFlg) {
      this.medicalPayment.middleCalculateFlg = middleCalculateFlg
      if (middleCalculateFlg === 0) {
        this.medicalPayment.discountRate = 0
        this.medicalPayment.discountPrice = 0
      }
    },
    mixinInputEndHospitalizationFlg(endHospitalizationFlg) {
      this.medicalPayment.endHospitalizationFlg = endHospitalizationFlg
      if (endHospitalizationFlg === 0) {
        if (this.medicalPayment.startHospitalizationFlg === 0) {
          this.medicalPayment.inHospitalFlg = 1
        }
        this.medicalPayment.discountRate = 0
        this.medicalPayment.discountPrice = 0
      } else {
        this.medicalPayment.inHospitalFlg = 0
        this.mixinCorrectTime()
      }
      this.mixinCheckCanApply()
    },
    mixinCorrectTime() {
      const { endTime } = this.medicalRecord
      const { endTimeMin } = this.mixinStartEndMinMax
      let correctEnd = ''
      if (
        this.medicalPayment.endHospitalizationFlg &&
        endTimeMin !== '' &&
        endTime < endTimeMin
      ) {
        correctEnd = endTimeMin
      }
      if (correctEnd !== '') {
        this.$set(this.medicalRecord, 'endTime', correctEnd)
      }
    },
    mixinChangeOrder(items) {
      this.medicalTreatmentItems = items
      this.mixinInputData.medicalTreatmentItems = items
    },
    mixinRemoveItem(item) {
      const targetIndex = this.medicalTreatmentItems.findIndex(
        v => v.key === item.key
      )
      this.medicalTreatmentItems.splice(targetIndex, 1)
    },
    mixinAddItems(items) {
      const nextKey =
        this.medicalTreatmentItems.length > 0
          ? this.medicalTreatmentItems.reduce((a, b) => (a.key > b.key ? a : b))
              .key + 1
          : 0
      items.forEach((v, i) => {
        this.medicalTreatmentItems.push({ ...v, key: nextKey + i })
      })
    },
    mixinChangeItem(item) {
      const targetIndex = this.medicalTreatmentItems.findIndex(
        v => v.key === item.key
      )
      this.$set(this.medicalTreatmentItems, targetIndex, item)
    },
    mixinCopyFromEstimate(estimate, medicalTreatmentItems) {
      if (this.estimateById(estimate.id).delFlg === 1) {
        this.popupFlg = true
        this.type = 'alert'
        this.title = '注意'
        this.buttons = ['閉じる']
        this.popupMessage = '選択した見積もりは既に削除されています。'
        return
      }
      if (
        this.medicalPayment.disease1Id === 0 &&
        this.medicalPayment.disease2Id === 0
      ) {
        const disease1 = this.diseaseByOriginalId(estimate.disease1OriginalId)
        const disease2 = this.diseaseByOriginalId(estimate.disease2OriginalId)
        const disease1Id = disease1 ? disease1.id : 0
        const disease2Id = disease2 ? disease2.id : 0
        this.medicalPayment.disease1Id = disease1Id
        this.medicalPayment.disease2Id = disease2Id
        const diseaseClass1 = this.diseaseClassByOriginalId(
          disease1?.delFlg === 0 ? disease1.diseaseClassOriginalId : 0
        )
        const diseaseClass2 = this.diseaseClassByOriginalId(
          disease2?.delFlg === 0 ? disease2.diseaseClassOriginalId : 0
        )
        const diseaseClass1Id =
          diseaseClass1?.delFlg === 0 ? diseaseClass1.id : 0
        const diseaseClass2Id =
          diseaseClass2?.delFlg === 0 ? diseaseClass2.id : 0
        this.medicalPayment.diseaseClass1Id = diseaseClass1Id
        this.medicalPayment.diseaseClass2Id = diseaseClass2Id
      }
      if (this.medicalPayment.surgeryFlg === 0) {
        this.medicalPayment.surgeryFlg = estimate.surgeryFlg
      }
      const items = medicalTreatmentItems.flatMap(v => {
        const treatment = this.treatmentByOriginalId(v.treatmentOriginalId)
        const medicine = this.medicineByOriginalId(v.medicineOriginalId)
        const treatmentId = treatment?.delFlg === 0 ? treatment.id : 0
        const medicineId = medicine?.delFlg === 0 ? medicine.id : 0
        const existsItem = treatmentId !== 0 || medicineId !== 0
        return existsItem
          ? {
              treatmentId,
              medicineId,
              vaccineId: v.vaccineId,
              insuranceFlg: v.insuranceFlg,
              taxExemptFlg: v.taxExemptFlg,
              unitPrice: v.unitPrice,
              amount: v.amount,
              discountRate: v.discountRate,
              discountPrice: v.discountPrice,
              prescription: v.prescription
            }
          : []
      })
      this.mixinAddItems(items)
      this.mixinInputData.medicalPayment = this.medicalPayment
      this.mixinInputData.medicalTreatmentItems = this.medicalTreatmentItems
    },
    mixinConfirmMedicalPaymentCopy(medicalPayment, medicalTreatmentItems) {
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['反映しない', '反映する']
      this.popupMessage = '選択した診療明細を現在の診療明細へ反映しますか？'
      this.decision = () => {
        this.mixinCopyFromMedicalPayment(medicalPayment, medicalTreatmentItems)
      }
    },
    mixinCopyFromMedicalPayment(medicalPayment, medicalTreatmentItems) {
      if (
        this.medicalPayment.disease1Id === 0 &&
        this.medicalPayment.disease2Id === 0
      ) {
        const disease1 = this.diseaseById(medicalPayment.disease1Id)
        const disease2 = this.diseaseById(medicalPayment.disease2Id)
        const disease1Id = disease1?.delFlg === 0 ? disease1.id : 0
        const disease2Id = disease2?.delFlg === 0 ? disease2.id : 0
        this.medicalPayment.disease1Id = disease1Id
        this.medicalPayment.disease2Id = disease2Id
        const diseaseClass1 = this.diseaseClassByOriginalId(
          disease1?.delFlg === 0 ? disease1.diseaseClassOriginalId : 0
        )
        const diseaseClass2 = this.diseaseClassByOriginalId(
          disease2?.delFlg === 0 ? disease2.diseaseClassOriginalId : 0
        )
        const diseaseClass1Id =
          diseaseClass1?.delFlg === 0 ? diseaseClass1.id : 0
        const diseaseClass2Id =
          diseaseClass2?.delFlg === 0 ? diseaseClass2.id : 0
        this.medicalPayment.diseaseClass1Id = diseaseClass1Id
        this.medicalPayment.diseaseClass2Id = diseaseClass2Id
      }
      const items = medicalTreatmentItems.flatMap(v => {
        const treatment = this.treatmentById(v.treatmentId)
        const medicine = this.medicineById(v.medicineId)
        const treatmentId = treatment?.delFlg === 0 ? treatment.id : 0
        const medicineId = medicine?.delFlg === 0 ? medicine.id : 0
        const existsItem = treatmentId !== 0 || medicineId !== 0
        return existsItem
          ? {
              treatmentId,
              medicineId,
              vaccineId: v.vaccineId,
              insuranceFlg: v.insuranceFlg,
              taxExemptFlg: v.taxExemptFlg,
              unitPrice: v.unitPrice,
              amount: v.amount,
              discountRate: v.discountRate,
              discountPrice: v.discountPrice,
              prescription: v.prescription
            }
          : []
      })
      this.mixinAddItems(items)
      this.mixinInputData.medicalPayment = this.medicalPayment
      this.mixinInputData.medicalTreatmentItems = this.medicalTreatmentItems
      this.popupFlg = false
    },
    mixinConfirmMedicalPaymentRegister() {
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['登録しない', '登録する']
      this.popupMessage = '診療明細を登録して会計画面へ遷移しますか？'
      const anicomReports = this.anicomReportsByMedicalPaymentId(
        this.medicalPayment.id
      ) // 送信済みアニコムレセプト
      let anicomReportId = 0 // anicomReportId: 0 はレセプトを送信していないとする。
      if (anicomReports?.length > 0) {
        // 既にレセプト送信済みの場合メッセージを変更
        const isTimeOver = this.mixinIsTimeOverAnicomReportCancel(
          anicomReports[0]
        )
        anicomReportId = isTimeOver ? 0 : anicomReports[0].id
        this.popupMessage = !anicomReportId
          ? '既にレセプト受付済みです。\nレセプトの変更はできませんが診療明細を編集し、会計画面へ遷移しますか？'
          : '既にレセプト送信済みです。\nレセプトをキャンセルして診療明細を編集し、会計画面へ遷移しますか？'
      }
      this.decision = () => {
        this.medicalPayment.id
          ? this.mixinUpdateMedicalPayment(anicomReportId)
          : this.mixinCreateMedicalPayment()
        this.paymentNewFlg = true
      }
    },
    async mixinCreateMedicalPayment() {
      const { validFlg, errorMessage } = this.mixinCheckValidity()
      if (!validFlg) {
        this.type = 'failure'
        this.title = '失敗'
        this.leftAlignMessage = errorMessage
        this.buttons = ['閉じる']
        this.popupFlg = true
        return
      }
      const lockParamsBeforeRes = this.mixinGetLockParams()
      this.waitFlg = true
      const {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      } = this.mixinGetRequestBody()
      const res = await this.$store.dispatch('medicalPayments/create', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '診療明細を登録しました。'
        if (res.medicalContent) {
          this.popupMessage +=
            '\n診療日時が変更された為、診療内容を編集しました。'
        }
        if (
          Object.keys(res.reservation).length !== 0 &&
          res.sendMailFlg &&
          this.medicalRecord.reservationId === 0
        ) {
          this.popupMessage += '\n予約を作成し、メールを送信しました。'
        } else if (
          Object.keys(res.reservation).length !== 0 &&
          res.sendMailFlg &&
          this.medicalRecord.reservationId !== 0
        ) {
          this.popupMessage += '\n予約を変更し、メールを送信しました。'
        }
        this.mixinSetResData(res)
        const needsMedicalPaymentUpdate = this.mixinNeedsMiddleCalculateOrEndMedicalPaymentUpdate()
        if (needsMedicalPaymentUpdate) {
          this.popupMessage += `\n${needsMedicalPaymentUpdate}の診療明細の更新が必要です。\n診療明細一覧で${needsMedicalPaymentUpdate}の診療明細をクリックし再登録してください。`
        }
        this.$store.dispatch(
          'medicalRecords/setOriginalIdSetInTab',
          res.medicalRecord.originalId
        )
        const lockParamsAfterRes = this.mixinGetLockParams()
        this.mixinLockKarteIfChanged(lockParamsBeforeRes, lockParamsAfterRes)
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (res === 'no reservation') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n予約が既にキャンセルまたは削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (res === 'no staff') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n選択したスタッフは既に削除されています。\n他のスタッフを選択して下さい。'
          this.medicalPayment.staffId = 0
          this.mixinInputData.medicalPayment = this.medicalPayment
          this.mixinInitialData.medicalPayment = _.cloneDeep(
            this.medicalPayment
          )
        } else if (res === 'no diseaseClass') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n選択した傷病分類は既に削除されています。\n他の傷病分類を選択して下さい。'
          this.mixinResetDisease()
        } else if (res === 'no disease') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n選択した傷病は既に削除されています。\n他の傷病を選択して下さい。'
          this.mixinResetDisease()
        } else if (res === 'no treatment') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n選択した診療項目は既に削除されています。\n他の診療項目を選択して下さい。'
          this.mixinResetTreatmentItems()
        } else if (res === 'no medicine') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n選択した薬剤物品は既に削除されています。\n他の薬剤物品を選択して下さい。'
          this.mixinResetTreatmentItems()
        } else if (res === 'no patient') {
          this.popupMessage =
            '診療明細登録に失敗しました。\nこのカルテの患者は既に削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (res === 'no medicalRecord') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n編集中のカルテは既に削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (res === 'patient is dead') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n診療日は患者の「死亡日まで」で設定して下さい。'
        } else if (res.includes('invalid hospitalization')) {
          const base = '診療明細登録に失敗しました。\n'
          this.popupMessage = this.mixinHospitalizationErrMsg(base, res)
        } else if (res === 'insuranceType already changed') {
          this.popupMessage =
            '診療明細登録に失敗しました。\n患者の保険設定が変更されているため診療明細に表示されている金額が正しくない可能性があります。\n患者の保険設定を更新しましたので内容をご確認の上、再度登録をお願いします。'
          this.mixinShouldUpdateInsurance = true
        } else {
          this.popupMessage = '診療明細登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.popupFlg = true
    },
    async mixinUpdateMedicalPayment() {
      const { validFlg, errorMessage } = this.mixinCheckValidity()
      if (!validFlg) {
        this.type = 'failure'
        this.title = '失敗'
        this.leftAlignMessage = errorMessage
        this.buttons = ['閉じる']
        this.popupFlg = true
        return
      }
      const lockParamsBeforeRes = this.mixinGetLockParams()
      this.waitFlg = true
      const {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      } = this.mixinGetRequestBody()
      const res = await this.$store.dispatch('medicalPayments/update', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '診療明細を編集しました。'
        if (res.medicalContent) {
          this.popupMessage +=
            '\n診療日時が変更された為、診療内容を編集しました。'
        }
        if (Object.keys(res.reservation).length !== 0 && res.sendMailFlg) {
          this.popupMessage += '\n予約を変更し、メールを送信しました。'
        }
        this.mixinSetResData(res)
        const needsMedicalPaymentUpdate = this.mixinNeedsMiddleCalculateOrEndMedicalPaymentUpdate()
        if (needsMedicalPaymentUpdate) {
          this.popupMessage += `\n${needsMedicalPaymentUpdate}の診療明細の更新が必要です。\n診療明細一覧で${needsMedicalPaymentUpdate}の診療明細をクリックし再登録してください。`
        }
        const lockParamsAfterRes = this.mixinGetLockParams()
        this.mixinLockKarteIfChanged(lockParamsBeforeRes, lockParamsAfterRes)
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (res === 'no reservation') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n予約が既にキャンセルまたは削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (res === 'no staff') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n選択したスタッフは既に削除されています。\n他のスタッフを選択して下さい。'
          this.medicalPayment.staffId = 0
          this.mixinInputData.medicalPayment = this.medicalPayment
          this.mixinInitialData.medicalPayment = _.cloneDeep(
            this.medicalPayment
          )
        } else if (res === 'no diseaseClass') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n選択した傷病分類は既に削除されています。\n他の傷病分類を選択して下さい。'
          this.mixinResetDisease()
        } else if (res === 'no disease') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n選択した傷病は既に削除されています。\n他の傷病を選択して下さい。'
          this.mixinResetDisease()
        } else if (res === 'no treatment') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n選択した診療項目は既に削除されています。\n他の診療項目を選択して下さい。'
          this.mixinResetTreatmentItems()
        } else if (res === 'no medicine') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n選択した薬剤物品は既に削除されています。\n他の薬剤物品を選択して下さい。'
          this.mixinResetTreatmentItems()
        } else if (res === 'no patient') {
          this.popupMessage =
            '診療明細編集に失敗しました。\nこのカルテの患者は既に削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (res === 'limit over') {
          this.popupMessage =
            '診療明細編集に失敗しました。\nレセプトのキャンセル有効期限が過ぎています。\n診療明細のみを編集する場合は再度、「診療明細登録」ボタンを押して下さい。'
        } else if (res === 'no medicalRecord') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n編集中のカルテは既に削除されています。\nメイン画面へ移動します。'
          this.noDataFlg = true
        } else if (res === 'patient is dead') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n診療日は患者の「死亡日まで」で設定して下さい。'
        } else if (res.includes('invalid hospitalization')) {
          const base = '診療明細編集に失敗しました。\n'
          this.popupMessage = this.mixinHospitalizationErrMsg(base, res)
        } else if (res === 'insuranceType already changed') {
          this.popupMessage =
            '診療明細編集に失敗しました。\n患者の保険設定が変更されているため診療明細に表示されている金額が正しくない可能性があります。\n患者の保険設定を更新しましたので内容をご確認の上、再度編集をお願いします。'
          this.mixinShouldUpdateInsurance = true
        } else {
          this.popupMessage = '診療明細編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.popupFlg = true
    },
    mixinIsChangedDate() {
      return (
        this.mixinInitialData.medicalRecord.date !==
        this.mixinInputData.medicalRecord.date
      )
    },
    async mixinCreateUpdateMedicalPayment() {
      await this.mixinSetMailFlg()
      this.medicalPayment.id
        ? await this.mixinUpdateMedicalPayment()
        : await this.mixinCreateMedicalPayment()
    },
    mixinCheckValidity() {
      let validFlg = true
      let errorMessage = ''
      if (this.mixinInvalidApply) {
        validFlg = false
        if (this.mixinInvalidDisease) {
          errorMessage +=
            '・保険適用時は、診断1か診断2のどちらかを入力してください。\n'
        }
        if (this.mixinInvalidNoApplyReason) {
          errorMessage += '・保険対象外事由の入力をお願いします。\n'
        }
        if (this.mixinInvalidOnsetDate) {
          const addition =
            this.medicalPayment.ipetCheckId > 0
              ? '受傷日/発症日が不明な場合は、「不明」にチェックを入れてください。'
              : ''
          errorMessage += `・保険適用時は、受傷日/発症日を入力してください。${addition}\n`
        }
        if (this.mixinInvalidBill) {
          errorMessage +=
            '・請求額または保険請求額が¥0以下の場合は、窓口精算ができません。\n'
        }
      }
      return { validFlg, errorMessage }
    },
    mixinAdjustOnsetValues() {
      if (this.medicalPayment.insuranceType === 'ipet-docomo') {
        this.medicalPayment.onsetAroundFlg = 0
      } else {
        this.medicalPayment.uncertainOnsetFlg = 0
      }
    },
    mixinSetResDataRelatedToMedicalPayment(
      resMedicalRecord,
      resMedicalPayment,
      resMedicalTreatmentItems
    ) {
      const medicalTreatmentItems = resMedicalTreatmentItems.map((v, i) => {
        return { ...v, key: i }
      })
      this.medicalRecord = _.cloneDeep(resMedicalRecord)
      this.medicalPayment = _.cloneDeep(resMedicalPayment)
      this.medicalTreatmentItems = _.cloneDeep(medicalTreatmentItems)
      this.mixinInitialData.medicalRecord = _.cloneDeep(this.medicalRecord)
      this.mixinInitialData.medicalPayment = _.cloneDeep(this.medicalPayment)
      this.mixinInitialData.medicalTreatmentItems = _.cloneDeep(
        this.medicalTreatmentItems
      )
      this.mixinInputData.medicalRecord = this.medicalRecord
      this.mixinInputData.medicalPayment = this.medicalPayment
      this.mixinInputData.medicalTreatmentItems = this.medicalTreatmentItems
      this.mixinSetHospitalizationHistory()
      this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
    },
    mixinResetDisease() {
      const diseaseClass1Id = this.medicalPayment.diseaseClass1Id
      const diseaseClass2Id = this.medicalPayment.diseaseClass2Id
      const disease1Id = this.medicalPayment.disease1Id
      const disease2Id = this.medicalPayment.disease2Id
      if (
        !this.diseaseClassById(diseaseClass1Id) ||
        !this.diseaseById(disease1Id)
      ) {
        this.medicalPayment.diseaseClass1Id = 0
        this.medicalPayment.disease1Id = 0
      }
      if (
        !this.diseaseClassById(diseaseClass2Id) ||
        !this.diseaseById(disease2Id)
      ) {
        this.medicalPayment.diseaseClass2Id = 0
        this.medicalPayment.disease2Id = 0
      }
      this.mixinInitialData.medicalPayment = _.cloneDeep(this.medicalPayment)
      this.mixinInputData.medicalPayment = this.medicalPayment
    },
    mixinResetTreatmentItems() {
      this.medicalTreatmentItems = this.medicalTreatmentItems.filter(
        v =>
          this.treatmentById(v.treatmentId) || this.medicineById(v.medicineId)
      )
      this.mixinInitialData.medicalTreatmentItems = _.cloneDeep(
        this.medicalTreatmentItems
      )
      this.mixinInputData.medicalTreatmentItems = this.medicalTreatmentItems
    },
    mixinSetMedicalTreatmentItemsSubtotals() {
      const medicalTreatmentItems = this.medicalTreatmentItems.map(item => {
        const subtotal = calculateTreatmentItemSubtotal(
          this.medicalPayment.insuranceType,
          item
        )
        return { ...item, subtotal }
      })
      this.medicalTreatmentItems = medicalTreatmentItems
    },
    mixinConfirmAnicomUrlOpen() {
      this.showValidityCheckButton = false
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['キャンセル', 'OK']
      this.leftAlignMessage =
        '有効性確認システムより承認番号を取得した後は、本日の窓口精算ができなくなります！'
      this.decision = () => this.mixinOpenAnicomUrl()
    },
    mixinOpenAnicomUrl() {
      window.open(ANICOM_URL, '_blank')
      this.mixinClosePaymentPopup()
    },
    mixinSetHospitalizationHistory() {
      const hospitalizationData = this.medicalPaymentsByPatientId(
        this.patientId
      )
        ?.filter(
          p =>
            p.startHospitalizationFlg ||
            p.inHospitalFlg ||
            p.endHospitalizationFlg
        )
        .map(p => {
          const record = this.medicalRecordByOriginalId(
            p.medicalRecordOriginalId
          )
          return makeHospitalizationDatum(p, record)
        })
      this.mixinHospitalizationHistory = hospitalizationData
        ? makeHospitalizationHistory(hospitalizationData)
        : []
    },
    mixinMakeHospitalizationDetail() {
      const mrStartDateTime =
        this.medicalRecord.date + this.medicalRecord.startTime
      let hospitalizationStartDate = ''
      let hospitalization = null
      for (let i = this.mixinHospitalizationHistory.length - 1; i >= 0; i--) {
        const h = this.mixinHospitalizationHistory[i]
        const hStartDateTime = h.startDateTime
        const hEndDateTime = h.endDateTime === 'invalid' ? '' : h.endDateTime
        //開いているカルテの開始日時が入院期間中の場合、その期間の入院データを取得
        if (hStartDateTime <= mrStartDateTime) {
          const endRecord = h.records.find(v => v.endFlg === 1)
          const endMedicalPaymentOriginalId = endRecord
            ? endRecord.medicalPaymentOriginalId
            : 0
          if (endMedicalPaymentOriginalId === this.medicalPayment.originalId) {
            hospitalization = h
            hospitalizationStartDate = h.startDate
            break
          } else if (
            (hEndDateTime !== '' && mrStartDateTime < hEndDateTime) ||
            hEndDateTime === ''
          ) {
            hospitalization = h
            hospitalizationStartDate = h.startDate
            break
          }
        }
      }
      return {
        hospitalizationStartDate,
        hospitalization
      }
    },
    mixinClosePaymentPopup() {
      if (this.paymentNewFlg && this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.gotoPaymentPage()
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'main' })
      }
      if (this.mixinShouldUpdateInsurance) {
        this.mixinSetInsurance()
        this.mixinShouldUpdateInsurance = false
      }
      if (this.mixinIsCheckingApply) {
        if (this.mixinCanApply) {
          if (this.mixinAnicomCIdCheck) {
            this.medicalPayment.anicomCIdCheckId = this.mixinAnicomCIdCheck.id
            this.medicalPayment.rezeptCd = this.mixinAnicomCIdCheck.code
            this.medicalPayment.pledgeRate = Number(
              this.mixinAnicomCIdCheck.coverageRate
            )
            if (this.mixinIsSurgeryNg) this.medicalPayment.surgeryLimitFlg = 1
          }
          if (this.mixinIpetCheck) {
            this.medicalPayment.ipetCheckId = this.mixinIpetCheck.id
            this.medicalPayment.pledgeRate = this.mixinIpetCheck.claimRate
          }
        } else {
          this.mixinResetMPInsurance()
        }
        this.mixinSetInsurance()
        this.mixinCanApply = false
        this.mixinIsCheckingApply = false
      }
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.popupMessage = ''
      this.leftAlignMessage = ''
      this.buttons = []
      this.decision = () => {}
      this.showValidityCheckButton = false
    },
    mixinCheckHavingAnicomReport() {
      const anicomReports = this.anicomReportsByMedicalPaymentId(
        this.medicalPayment.id
      ) // 送信済みアニコムレセプト
      anicomReports?.length > 0
        ? this.mixinAlertExistedAnicomReport(anicomReports[0])
        : this.mixinCreateUpdateMedicalPayment()
    },
    mixinIsTimeOverAnicomReportCancel(anicomReport) {
      const sendDate = moment(anicomReport.sendDate).format('YYYY-MM-DD')
      const limitDate = moment(anicomReport.sendDate).isBetween(
        sendDate + ' 00:00',
        sendDate + ' 03:00',
        'hour'
      )
        ? moment(anicomReport.sendDate).format('YYYYMMDD') + '03'
        : moment(anicomReport.sendDate)
            .add(1, 'd')
            .format('YYYYMMDD') + '03'
      return moment(limitDate, 'YYYYMMDDHH').isBefore(moment())
    },
    mixinAlertExistedAnicomReport(anicomReport) {
      const isTimeOver = this.mixinIsTimeOverAnicomReportCancel(anicomReport)
      this.popupFlg = true
      this.title = '注意'
      this.buttons = ['閉じる', '編集する']
      this.type = 'alert'
      this.popupMessage = isTimeOver
        ? '既にレセプト受付済みです。\nレセプトの変更はできませんが診療明細を編集しますか？'
        : '既にレセプト送信済みです。\nレセプトをキャンセルして診療明細を編集しますか？'
      this.decision = () => this.mixinCreateUpdateMedicalPayment()
    },
    mixinCloseAlertPopup() {
      if (this.mixinTitle === '予約変更メール確認') {
        this.mixinMailFlg = false
        this.mixinPopupResolve()
        this.mixinPopupResolve = null
      }
      this.mixinAlertFlg = false
      this.mixinTitle = ''
      this.mixinButtons = ['閉じる']
      this.mixinType = ''
      this.mixinPopupMessage = ''
      this.mixinPopup = {
        decision: () => {}
      }
    },
    mixinNeedsMiddleCalculateOrEndMedicalPaymentUpdate() {
      const hospitalizations =
        this.hospitalizationsByPatientId(this.patientId) || []
      const hospitalization = hospitalizations.find(v =>
        v.medicalPaymentOriginalIds.includes(this.medicalPayment.originalId)
      )
      const isStartOrIsIn =
        (this.medicalPayment.startHospitalizationFlg === 1 ||
          this.medicalPayment.inHospitalFlg === 1) &&
        this.medicalPayment.middleCalculateFlg === 0 &&
        this.medicalPayment.endHospitalizationFlg === 0
      if (
        hospitalization?.medicalPaymentOriginalIdsUntilMiddleCalculate.includes(
          this.medicalPayment.originalId
        ) &&
        isStartOrIsIn
      ) {
        return '途中精算'
      } else if (hospitalization?.endDate.length > 0 && isStartOrIsIn) {
        return '退院'
      } else {
        return ''
      }
    },
    mixinHospitalizationErrMsg(base, res) {
      const str = 'invalid hospitalization: '
      const key = res.replace(str, '').replace(/ /g, '_')
      const converter = {
        cannot_be_outpatient:
          '入院カルテを外来カルテに変更することはできません。',
        cannot_uncheck_end_because_exists_next:
          '次の入院枠があるため退院チェックを外すことができません。',
        cannot_create_hospitalization_because_exists_next:
          '既にある入院枠よりも前に入院枠を作成することはできません。',
        cannot_create_hospitalization_because_exists_other:
          '入力した時間帯には別の入院枠があるため入院枠を作成することができません。',
        cannot_add_in_because_exists_startEnd:
          '入力した時間帯の入院枠には入院中カルテを追加することができません。',
        cannot_be_start_because_prohibited:
          '入院中または退院カルテを入院開始に変更することはできません。',
        cannot_be_end_because_exists_in:
          '同じ入院枠に入院中カルテがあるため退院にすることができません。',
        cannot_be_end_because_exists_end:
          '同じ入院枠に退院カルテがあるため退院にすることができません。',
        not_same_hospitalization:
          '同じ入院枠がありません。入院データを確認してください。',
        not_overlapped_hospitalization:
          '同じ入院枠がありません。入院データを確認してください。',
        exists_overlapped_other_hospitalization:
          '入力した時間帯には別の入院枠があります。',
        startHospitalization_start_greater_than_inH:
          '開始日時が入院中カルテの開始日時よりも大きい値になっています。',
        startHospitalization_start_greater_than_endH:
          '開始日時が退院カルテの開始日時よりも大きい値になっています。',
        startHospitalization_end_greater_than_endH:
          '終了日時が退院カルテの終了日時よりも大きい値になっています。',
        inHospitalization_start_less_than_startH:
          '開始日時が入院開始カルテの開始日時よりも小さい値になっています。',
        inHospitalization_start_greater_than_endH:
          '開始日時が退院カルテの開始日時よりも大きい値になっています。',
        inHospitalization_end_greater_than_endH:
          '終了日時が退院カルテの終了日時よりも大きい値になっています。',
        endHospitalization_start_less_than_startH:
          '開始日時が入院開始カルテの開始日時よりも小さい値になっています。',
        endHospitalization_end_less_than_startH:
          '終了日時が入院開始カルテの終了日時よりも小さい値になっています。',
        endHospitalization_start_less_than_inH:
          '開始日時が入院中カルテの開始日時よりも小さい値になっています。',
        endHospitalization_end_less_than_inH:
          '終了日時が入院中カルテの終了日時よりも小さい値になっています。',
        cannot_be_middle_calculate_because_exists_middle_calculate:
          '同じ入院枠に途中精算したカルテがあるため途中精算にすることができません。\n途中精算は入院中に一回のみできます。',
        cannot_be_middle_calculate_because_exists_end:
          '同じ入院枠に退院カルテがあるため途中精算にすることができません。',
        cannot_uncheck_middle_calculate_because_exists_end:
          '同じ入院枠に退院カルテがあるため途中精算のチェックを外すことができません。',
        insurance_cannot_be_applied_to_middle_calculated_hospitalization:
          '途中精算している入院枠では保険適用することはできません。'
      }
      const main =
        converter[key] ||
        '入院設定でエラーがあります。入院データを確認してください。'
      const errMsg = base + main
      return errMsg
    },
    mixinHospitalizationData(hospitalization, excludeFlg = false) {
      const hospitalizationData = []
      if (hospitalization) {
        hospitalization.medicalPaymentOriginalIds.forEach(mpOId => {
          if (excludeFlg) {
            if (mpOId === this.medicalPayment.originalId) return
          }
          const mp = this.medicalPaymentByOriginalId(mpOId)
          const mr = this.medicalRecordByOriginalId(mp.medicalRecordOriginalId)
          const hospitalizationDatum = makeHospitalizationDatum(mp, mr)
          hospitalizationData.push(hospitalizationDatum)
        })
      }
      return hospitalizationData
    },
    mixinHospitalizationStartEnd(hospitalization) {
      const toStartDateTime = medicalRecord => {
        return medicalRecord.date + medicalRecord.startTime
      }
      const toEndDateTime = medicalRecord => {
        return medicalRecord ? medicalRecord.date + medicalRecord.endTime : ''
      }
      const startMP = this.medicalPaymentByOriginalId(
        hospitalization.startMedicalPaymentOriginalId
      )
      const startMR = this.medicalRecordByOriginalId(
        startMP.medicalRecordOriginalId
      )
      const endMP =
        hospitalization.endMedicalPaymentOriginalId === 0
          ? null
          : this.medicalPaymentByOriginalId(
              hospitalization.endMedicalPaymentOriginalId
            )
      const endMR = endMP
        ? this.medicalRecordByOriginalId(endMP.medicalRecordOriginalId)
        : null
      const startDateTime = toStartDateTime(startMR)
      const endDateTime = toEndDateTime(endMR)
      return {
        startDateTime, // YYYYMMDDHHmm
        endDateTime // YYYYMMDDHHmm
      }
    },
    mixinGetHospitalization() {
      const hospitalizations =
        this.hospitalizationsByPatientId(this.patientId) || []
      const hospitalization = hospitalizations.find(v =>
        v.medicalPaymentOriginalIds.includes(this.medicalPayment.originalId)
      )
      return hospitalization
    },
    mixinGetLockParams() {
      let patientId = 0
      let medicalRecordOriginalId = 0
      let hospitalizationId = 0
      if (this.medicalRecord.originalId) {
        medicalRecordOriginalId = this.medicalRecord.originalId
        const hospitalization = this.mixinGetHospitalization()
        if (hospitalization) {
          hospitalizationId = hospitalization.id
        }
      } else {
        patientId = this.patientId
      }
      const lockParams = {
        patientId,
        medicalRecordOriginalId,
        hospitalizationId
      }
      return lockParams
    },
    mixinLockKarteIfChanged(lockParamsBeforeRes, lockParamsAfterRes) {
      const keys = ['patientId', 'medicalRecordOriginalId', 'hospitalizationId']
      const hasChanged = keys.some(
        key => lockParamsBeforeRes[key] !== lockParamsAfterRes[key]
      )
      if (hasChanged) {
        this.$store.dispatch('auth/lockKarte', lockParamsAfterRes)
      }
    }
  }
}
