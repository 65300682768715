<template>
  <div class="disease-select-form">
    <div class="label">{{ label }}</div>
    <div class="value">
      <div class="disease-class">
        <base-select-box
          :value="diseaseClass.id"
          :selectData="diseaseClassesSelectData"
          :styles="{ width: '200px' }"
          @input="selectDiseaseClass"
        />
      </div>
      <div class="disease">
        <base-select-box
          :value="disease.id"
          :selectData="diseasesSelectData"
          :disabled="diseaseClass.id === 0"
          :styles="{ width: '325px' }"
          @input="selectDisease"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import { mapGetters } from 'vuex'

export default {
  name: 'DiseaseSelectForm',

  components: {
    BaseSelectBox
  },

  props: {
    diseaseOriginalId: { type: Number, default: 0 },
    diseaseId: { type: Number, default: 0 },
    label: { type: String, default: '' },
    idFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      baseDiseaseClass: { id: 0, originalId: 0 },
      baseDisease: { id: 0, originalId: 0, diseaseClassOriginalId: 0 },
      diseaseClass: {},
      disease: {}
    }
  },

  computed: {
    ...mapGetters({
      diseaseById: 'diseases/getDataById',
      diseaseByOriginalId: 'diseases/getDataByOriginalId',
      diseaseClassById: 'diseaseClasses/getDataById',
      diseaseClassByOriginalId: 'diseaseClasses/getDataByOriginalId',
      diseaseClasses: 'diseaseClasses/getData',
      diseases: 'diseases/getData'
    }),
    diseaseClassesSelectData() {
      return [{ id: 0, name: '未選択', showFlg: true }]
        .concat(this.diseaseClasses)
        .filter(v => v.id === this.diseaseClass.id || v.showFlg)
    },
    diseasesSelectData() {
      const diseasesSelectData = this.diseases.filter(v => {
        const isBelongClass =
          v.diseaseClassOriginalId === this.diseaseClass.originalId
        const canShow = this.idFlg
          ? v.id === this.diseaseId || v.showFlg
          : v.originalId === this.diseaseOriginalId || v.showFlg
        return isBelongClass && canShow
      })
      return diseasesSelectData
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    diseaseId: function() {
      this.initialize()
    }
  },

  methods: {
    initialize() {
      let disease = this.idFlg
        ? this.diseaseById(this.diseaseId)
        : this.diseaseByOriginalId(this.diseaseOriginalId)
      disease = disease?.delFlg === 0 ? disease : this.baseDisease
      this.diseaseClass =
        this.diseaseClassByOriginalId(disease.diseaseClassOriginalId) ||
        this.baseDiseaseClass
      this.$nextTick(function() {
        this.disease = disease
      })
    },
    selectDiseaseClass(id) {
      this.diseaseClass = this.diseaseClassById(id) || this.baseDiseaseClass
      this.disease = this.diseasesSelectData[0] || this.baseDisease
      this.emitInput()
    },
    selectDisease(id) {
      this.disease = this.diseaseById(id) || this.baseDisease
      this.emitInput()
    },
    emitInput() {
      this.idFlg
        ? this.$emit('input', {
            diseaseClassId: this.diseaseClass.id,
            diseaseId: this.disease.id
          })
        : this.$emit('input', this.disease.originalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.disease-select-form {
  display: flex;
  align-items: center;
  > .label {
    width: 45px;
    font-size: 13px;
  }
  > .value {
    flex: 1;
    display: flex;
    align-items: center;
    > .disease-class {
      margin-right: 15px;
    }
  }
}
</style>
