<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="estimate-list-popup" @keyup.esc.stop="closePopup" tabindex="-1">
      <popup
        :buttons="['閉じる']"
        :contentStyle="{}"
        @close="closePopup"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">見積書一覧</div>
            <div class="list">
              <estimate-list-table
                v-if="displayEstimates.length > 0"
                :estimates="displayEstimates"
              >
                <template #buttons="{ estimate }">
                  <div class="buttons">
                    <base-button-small-white
                      :styles="{ width: '60px', height: '30px' }"
                      @click="gotoEditPage(estimate)"
                      >編集</base-button-small-white
                    >
                    <base-button-border-orange
                      :styles="{ width: '60px', height: '30px' }"
                      @click="popupEstimate(estimate)"
                      >詳細</base-button-border-orange
                    >
                  </div>
                </template>
              </estimate-list-table>
              <div v-else class="no-date">見積書が見つかりませんでした。</div>
            </div>
          </div>
        </template>
      </popup>
      <estimate-popup
        v-if="estimatePopupFlg"
        :estimate="estimate"
        @close="closeEstimatePopup"
        @copy="copyToMedicalPayment"
      ></estimate-popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import EstimateListTable from '@/components/parts/organisms/EstimateListTable'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import EstimatePopup from '@/components/popups/EstimatePopup'
import { calculateTreatmentItemSubtotal } from '@/utils/price_calculation'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'EstimateListPopup',

  components: {
    Popup,
    EstimateListTable,
    BaseButtonSmallWhite,
    BaseButtonBorderOrange,
    EstimatePopup,
    FocusTrap
  },

  props: {
    patientId: { type: Number }
  },

  data() {
    return {
      estimate: {},
      estimatePopupFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getEstimatesByPatientId: 'estimates/getDataByPatientId',
      getTreatmentItemsByEstimateId:
        'estimateTreatmentItems/getDataByEstimateId',
      getDisease: 'diseases/getDataByOriginalId',
      getDiseaseClass: 'diseaseClasses/getDataByOriginalId',
      getTreatmentByOriginalId: 'treatments/getDataByOriginalId',
      getMedicineByOriginalId: 'medicines/getDataByOriginalId'
    }),
    estimates() {
      return this.getEstimatesByPatientId(this.patientId) || []
    },
    displayEstimates() {
      return this.estimates
        .map(estimate => {
          const disease1 = this.getDisease(estimate.disease1OriginalId)
          const disease2 = this.getDisease(estimate.disease2OriginalId)
          const diseaseName =
            disease1?.delFlg === 0 && disease2?.delFlg === 0
              ? `${disease1.name},${disease2.name}`
              : disease1?.delFlg === 0
              ? disease1.name
              : disease2?.delFlg === 0
              ? disease2.name
              : '診断名なし'
          const diseaseClass1 = this.getDiseaseClass(
            disease1?.delFlg === 0 ? disease1.diseaseClassOriginalId : 0
          )
          const diseaseClass2 = this.getDiseaseClass(
            disease2?.delFlg === 0 ? disease2.diseaseClassOriginalId : 0
          )
          const treatmentItems = (
            this.getTreatmentItemsByEstimateId(estimate.id) || []
          )
            .filter(
              item =>
                this.getTreatmentByOriginalId(item.treatmentOriginalId) ||
                this.getMedicineByOriginalId(item.medicineOriginalId)
            )
            .map(item => {
              const name =
                this.getTreatmentByOriginalId(item.treatmentOriginalId)?.name ||
                this.getMedicineByOriginalId(item.medicineOriginalId)?.name
              const subtotal = calculateTreatmentItemSubtotal(
                estimate.insuranceType,
                item
              )
              return { ...item, name, subtotal }
            })
          return {
            ...estimate,
            date: moment(estimate.date, 'YYYYMMDD').format(
              'YYYY年MM月DD日（dd）'
            ),
            disease1,
            disease2,
            diseaseClass1,
            diseaseClass2,
            diseaseName,
            treatmentItems
          }
        })
        .sort((a, b) => {
          return a.date === b.date ? b.id - a.id : b.date < a.date ? -1 : 1
        })
    }
  },

  methods: {
    closePopup() {
      this.$emit('close')
    },
    gotoEditPage(estimate) {
      this.$router.push({ name: 'estimate-edit', params: { id: estimate.id } })
    },
    popupEstimate(estimate) {
      this.estimate = estimate
      this.estimatePopupFlg = true
    },
    closeEstimatePopup() {
      this.estimatePopupFlg = false
      this.estimate = {}
    },
    copyToMedicalPayment(estimate, estimateTreatmentItems) {
      this.$emit('copy', estimate, estimateTreatmentItems)
      this.closeEstimatePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-list-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .list {
      padding-right: 25px;
      min-width: 1400px;
      overflow: auto;
      margin-top: 30px;
      ::v-deep .table-body {
        max-height: 486px;
      }
      .buttons {
        width: 130px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 20px;
      }
    }
  }
}
</style>
