<template>
  <div class="medical-payments">
    <base-loading :waitFlg="waitFlg" />
    <div class="area search">
      <search-area-detail
        v-if="detailSearchFlg"
        :selectBoxData="detailSelectBoxData"
        :searchConditions="searchConditions"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @hide-detail="toggleDetailSearchFlg"
        @select="setSelectId"
        @input-text="inputText($event)"
        @add-search-condition="addSearchCondition"
        @trash="deleteSearchCondition"
        @search="resetAndGetData"
      />
      <search-area
        v-else
        :textBoxLabel="'検索単語'"
        :textValue="searchText"
        :placeholder="'診断名、診療内容'"
        :periodFlg="true"
        :periodLabel="'期間'"
        :defaultStartDate="defaultStartDate"
        :defaultEndDate="defaultEndDate"
        :toggleDetailSearchFlg="true"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        @show-detail="toggleDetailSearchFlg"
        @search="resetAndGetData"
        v-model="searchText"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">検索結果：{{ hitAllDataCounts }}件</div>
      <div class="order-setting">
        <base-button-small-white
          class="toggle-show-flg-button"
          :styles="{ width: '150px' }"
          @click="toggleFlg('deleteButtonShowFlg')"
          >{{ toggleButtonText }}</base-button-small-white
        >
        <div class="label">並び替え：診療日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <div class="area list">
      <medical-payment-list-table
        v-if="searchedMedicalPayments.length > 0"
        :medicalPayments="searchedMedicalPayments"
        :lastIndex="searchedMedicalPayments.length - 1"
        :initialDisplayFlg="initialDisplayFlg"
        :deleteButtonShowFlg="deleteButtonShowFlg"
        ref="medicalPaymentListTable"
        @scroll-bottom="getNextData"
        @click-item="gotoMedicalRecordEditPage"
        @go-to-payment-show-page="gotoPaymentShowPage"
        @go-to-payment-new-page="gotoPaymentNewPage"
        @open-delete-popup="openDeletePopup"
      >
      </medical-payment-list-table>
    </div>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import SearchArea from '@/components/parts/molecules/SearchArea'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import MedicalPaymentListTable from '@/components/parts/organisms/MedicalPaymentListTable'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { makeHIdByMPOId, compareKarte } from '@/utils/compare'
import { toHanKakuId } from '@/utils/convert_string'

export default {
  name: 'MedicalPayments',

  components: {
    BaseLoading,
    SearchArea,
    SearchAreaDetail,
    BaseButtonSmallWhite,
    BaseSelectBox,
    MedicalPaymentListTable,
    AnnouncePopup
  },

  data() {
    return {
      searchedMedicalPayments: [],
      initialDisplayFlg: true,
      detailSearchFlg: false,
      detailSelectBoxData: [
        { id: 1, name: '飼主名', type: 'textBox' },
        { id: 2, name: '患者名', type: 'textBox' },
        { id: 3, name: '飼主ID', type: 'textBox' },
        { id: 4, name: '患者ID', type: 'textBox' },
        { id: 5, name: '診断名', type: 'textBox' },
        { id: 6, name: '診療内容名', type: 'textBox' },
        { id: 7, name: '診療日', type: 'datePicker' }
      ],
      searchConditions: [{ id: 1, selectId: 0, text: '' }],
      searchText: '',
      startDate: '',
      endDate: '',
      deleteButtonShowFlg: false,
      deleteMedicalPayment: {},
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      decide: () => {},
      waitFlg: false,
      page: 0,
      isFull: true,
      hitAllDataCounts: 0,
      deleteCounts: 0,
      scrollTop: 0,
      deleteOperationFlg: false,
      timeoutId: null
    }
  },

  computed: {
    ...mapGetters({
      medicalPayments: 'medicalPayments/getData',
      getMedicalPayment: 'medicalPayments/getDataByOriginalId',
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById',
      getMedicalTreatmentItemsByMedicalPaymentId:
        'medicalTreatmentItems/getDataByMedicalPaymentId',
      getDisease: 'diseases/getDataById',
      getTreatment: 'treatments/getDataById',
      getMedicine: 'medicines/getDataById',
      hospitalizations: 'hospitalizations/getData'
    }),
    toggleButtonText() {
      return this.deleteButtonShowFlg ? '削除ボタン非表示' : '削除ボタン表示'
    }
  },

  watch: {
    orderType() {
      this.resetAndGetData()
    }
  },

  created() {
    const startMoment = moment().subtract(7, 'd')
    this.startDate = startMoment.format('YYYYMMDD')
    this.defaultStartDate = startMoment.toDate()
    const endMoment = moment()
    this.endDate = endMoment.format('YYYYMMDD')
    this.defaultEndDate = endMoment.toDate()
    this.createSearchedMedicalPayments(this.medicalPayments)
    this.hitAllDataCounts = this.searchedMedicalPayments.length
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    createSearchedMedicalPayments(medicalPayments) {
      const lastKey = this.searchedMedicalPayments.length
      const addData = medicalPayments.flatMap((v, index) => {
        const medicalRecord = this.getMedicalRecord(v.medicalRecordOriginalId)
        const owner = this.getOwner(medicalRecord.ownerId)
        const patient = this.getPatient(medicalRecord.patientId)
        const medicalTreatmentItems = (
          this.getMedicalTreatmentItemsByMedicalPaymentId(v.id) || []
        )
          .filter(
            item =>
              this.getTreatment(item.treatmentId) ||
              this.getMedicine(item.medicineId)
          )
          .map(item => {
            const name =
              this.getTreatment(item.treatmentId)?.name ||
              this.getMedicine(item.medicineId)?.name
            return { ...item, name }
          })
        const displayDatum = {
          ...v,
          ownerId: owner.id,
          ownerFullName: owner.lastName + owner.firstName,
          ownerFullNameKana: owner.lastNameKana + owner.firstNameKana,
          ownerShowId: owner.showId,
          patientId: patient.id,
          patientName: patient.name,
          patientNameKana: patient.nameKana,
          patientShowId: patient.showId,
          speciesName: this.getSpecies(patient.speciesId).name,
          diseaseName: this.makeDiseaseName(v),
          date: medicalRecord.date,
          startTime: medicalRecord.startTime,
          medicalTreatmentItems,
          number: this.initialDisplayFlg ? null : lastKey + index,
          latestMedicalRecordId: medicalRecord.id
        }
        if (this.initialDisplayFlg) {
          return this.filterByDate(
            medicalRecord.date,
            this.startDate,
            this.endDate
          )
            ? displayDatum
            : []
        } else {
          return displayDatum
        }
      })
      if (this.initialDisplayFlg) {
        const hIdByMPOId = makeHIdByMPOId(this.hospitalizations)
        this.searchedMedicalPayments = addData.sort((a, b) =>
          compareKarte(a, b, a, b, this.orderType, hIdByMPOId)
        )
      } else {
        this.searchedMedicalPayments = this.searchedMedicalPayments.concat(
          addData
        )
      }
    },
    makeDiseaseName(medicalPayment) {
      const disease1 = this.getDisease(medicalPayment.disease1Id)
      const disease2 = this.getDisease(medicalPayment.disease2Id)
      const diseaseName =
        disease1 && disease2
          ? `${disease1.name},${disease2.name}`
          : disease1
          ? disease1.name
          : disease2
          ? disease2.name
          : '診断名なし'
      return diseaseName
    },
    filterByDate(medicalRecordDate, startDate, endDate) {
      return startDate !== '' && endDate !== ''
        ? startDate <= medicalRecordDate && medicalRecordDate <= endDate
        : startDate !== ''
        ? startDate <= medicalRecordDate
        : endDate !== ''
        ? medicalRecordDate <= endDate
        : true
    },
    toggleDetailSearchFlg() {
      this.searchText = ''
      this.searchConditions = [{ id: 1, selectId: 0, text: '' }]
      this.detailSearchFlg = !this.detailSearchFlg
    },
    setSelectId(idIndex) {
      const targetSearchCondition = this.searchConditions[idIndex.index]
      targetSearchCondition.selectId = idIndex.id
      targetSearchCondition.text = ''
      this.$set(this.searchConditions, idIndex.index, targetSearchCondition)
    },
    inputText(textObj) {
      const targetSearchCondition = this.searchConditions[textObj.index]
      targetSearchCondition.text = textObj.text
      this.$set(this.searchConditions, textObj.index, targetSearchCondition)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({ id, selectId: 0, text: '' })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    toggleFlg(flgName) {
      this[flgName] = !this[flgName]
    },
    async resetAndGetData() {
      this.searchedMedicalPayments = []
      this.page = 0
      this.isFull = false
      this.deleteCounts = 0
      await this.searchMedicalPayments()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.searchMedicalPayments()
    },
    async searchMedicalPayments() {
      if (this.initialDisplayFlg) this.initialDisplayFlg = false
      this.waitFlg = true
      const trimSearchText = this.searchText.toLowerCase().replace(/\s+/g, '')
      const trimSearchConditions = this.searchConditions.map(v => {
        return v.selectId === 3 || v.selectId === 4
          ? {
              ...v,
              text: toHanKakuId(v.text.toLowerCase().replace(/\s+/g, ''))
            }
          : { ...v, text: v.text.toLowerCase().replace(/\s+/g, '') }
      })
      const res = await this.$store.dispatch('medicalPayments/search', {
        detailSearchFlg: this.detailSearchFlg,
        trimSearchText: trimSearchText,
        period: { startDate: this.startDate, endDate: this.endDate },
        trimSearchConditions: trimSearchConditions,
        page: this.page,
        order: this.orderType === 0 ? 'DESC' : 'ASC',
        deleteCounts: this.deleteCounts
      })
      if (res.result === true) {
        this.page++
        this.hitAllDataCounts = res.hitAllDataCounts
        this.createSearchedMedicalPayments(res.medicalPayments)
        if (
          this.searchedMedicalPayments.length < 100 ||
          res.medicalPayments.length === 0
        ) {
          this.isFull = true
        }
        if (this.page >= 10 && this.searchedMedicalPayments.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage = '診療明細の表示件数が上限の1000件に達しました。'
        }
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    },
    isMultipleHospitalization(medicalPayment) {
      return Boolean(
        (medicalPayment.startHospitalizationFlg &&
          !medicalPayment.endHospitalizationFlg) ||
          medicalPayment.inHospitalFlg ||
          (medicalPayment.endHospitalizationFlg &&
            !medicalPayment.startHospitalizationFlg)
      )
    },
    openDeletePopup(medicalPayment) {
      this.deleteMedicalPayment = medicalPayment
      const params = {
        patientId: 0,
        medicalRecordOriginalId: medicalPayment.medicalRecordOriginalId,
        hospitalizationId: 0
      }
      const isLocked = this.$store.getters['auth/isAnotherClientLocking'](
        params
      )
      if (isLocked) {
        this.$store.dispatch('petorelu/saveNextFunction', this.deleteConfirm)
        this.$store.dispatch('petorelu/showKarteLockPopup', {
          ...params,
          isTryingDelete: true
        })
      } else {
        this.deleteConfirm()
      }
    },
    deleteConfirm() {
      if (this.isMultipleHospitalization(this.deleteMedicalPayment)) {
        this.buttons = ['削除しない', 'すべて削除する']
        this.popupMessage =
          'この診療明細は入院期間の一部となっています。\nこの入院期間の診療明細をすべて削除してもよろしいですか？'
      } else {
        this.buttons = ['削除しない', '削除する']
        this.popupMessage = '削除してもよろしいですか？'
      }
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.decide = () => this.decideDelete()
      this.scrollTop = this.getScrollTop()
    },
    getScrollTop() {
      //テストで下記の処理は$refsを二段階以上で使用していてモック化できなかったため、
      //関数にしてモック化できるようにする
      return this.$refs.medicalPaymentListTable.$refs.scroller.$el.scrollTop
    },
    async decideDelete() {
      this.waitFlg = true
      const res = this.isMultipleHospitalization(this.deleteMedicalPayment)
        ? await this.$store.dispatch(
            'medicalPayments/deleteHospitalization',
            this.deleteMedicalPayment.originalId
          )
        : await this.$store.dispatch(
            'medicalPayments/delete',
            this.deleteMedicalPayment.id
          )
      if (res.result === true) {
        let deletedCount = 0
        res.deleteMedicalPaymentIds.forEach(medicalPaymentId => {
          const deleteIndex = this.searchedMedicalPayments.findIndex(
            v => v.id === medicalPaymentId
          )
          if (deleteIndex !== -1) {
            deletedCount += 1
            this.searchedMedicalPayments.splice(deleteIndex, 1)
          }
        })
        this.deleteCounts += deletedCount
        this.hitAllDataCounts -= deletedCount
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
        this.deleteOperationFlg = true
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (res === 'redo' || res === 'valid payment') {
          this.popupMessage = '会計データがあるため削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    gotoMedicalRecordEditPage(medicalPayment) {
      const ownerId = medicalPayment.ownerId
      const patientId = medicalPayment.patientId
      const medicalRecordOriginalId = medicalPayment.medicalRecordOriginalId
      const medicalRecord = this.getMedicalRecord(medicalRecordOriginalId)
      if (medicalRecord.delFlg === 1) {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          'クリックしたカルテは既に削除されているため、\nカルテ編集画面に移動できません。'
      } else {
        this.$router.push(
          `/main/karte/owners/${ownerId}/patients/${patientId}/medical-records/${medicalRecordOriginalId}/edit`
        )
      }
    },
    gotoPaymentNewPage(medicalPayment) {
      const medicalPaymentOriginalId = medicalPayment.originalId
      const latestMedicalPayment = this.getMedicalPayment(
        medicalPaymentOriginalId
      )
      if (latestMedicalPayment.delFlg === 1) {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          'クリックした診療明細は既に削除されているため、\n会計登録画面に移動できません。'
      } else {
        const patient = this.getPatient(medicalPayment.patientId)
        this.$router.push({
          path: `/main/karte/owners/${patient.ownerId}/patients/${patient.id}/payments/${medicalPaymentOriginalId}/new`
        })
      }
    },
    gotoPaymentShowPage(medicalPayment) {
      const patient = this.getPatient(medicalPayment.patientId)
      this.$router.push({
        path: `/main/karte/owners/${patient.ownerId}/patients/${patient.id}/payments/${medicalPayment.originalId}/show`
      })
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
      if (this.deleteOperationFlg) {
        //データ削除後にスクロール位置が自動的にずれてしまう。
        //原因ははっきりとわかっていないが、virtual-scrollerとfocus-trapの影響で削除ポップアップを閉じた後に
        //focusが別の診療明細の要素に移ったことでスクロールが自動的にされた可能性がある。
        //自動的にずれる処理自体を止める方法が見つからなかったため、その直後に元のスクロール位置に戻すようにした。
        this.$nextTick(() => {
          this.timeoutId = setTimeout(() => {
            this.scrollTo()
          }, 1)
          this.deleteOperationFlg = false
        })
      }
    },
    scrollTo() {
      //テストで下記の処理は$refsを二段階以上で使用していてモック化できなかったため、
      //関数にしてモック化できるようにする
      this.$refs.medicalPaymentListTable?.$refs.scroller.$el.scrollTo(
        0,
        this.scrollTop
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-payments {
  display: inline-block;
  min-width: 100%;
  > .search-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .toggle-show-flg-button {
        margin-right: 20px;
      }
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .list {
    min-width: 1400px;
    margin-top: 15px;
    ::v-deep .table-body {
      max-height: 630px;
    }
  }
}
</style>
