<template>
  <div class="patient-vaccinations">
    <div class="area owner-patient">
      <owner-patient-detail
        :owner="getOwnerById(ownerId)"
        :patient="getPatientById(patientId)"
      />
    </div>
    <div class="area lists">
      <div class="title-row">
        <div class="title">予防接種</div>
      </div>
      <vaccination-list-card
        class="list"
        :previousComingDate="previousComingDate"
        :nextReservationDate="nextReservationDate"
        :vaccinations="vaccinations"
        :vaccineReminders="patientVaccineReminders"
        @click-reminders="remindersPopupFlg = true"
        @click-vaccination-new="vaccinePopupFlg = true"
        @click-vaccination="openEditVaccinePopup"
        @print="openPrintPopup"
      />
      <base-button-small-white
        class="button"
        @click="openGracePopup"
        :style="{ marginTop: '40px' }"
        data-test="print-grace-button"
      >
        猶予証明書</base-button-small-white
      >
      <div class="title-row" :style="{ marginTop: '10px' }">
        <div class="title">予防接種履歴</div>
      </div>
      <list-table
        class="list history"
        v-if="displayVaccinationHistory.length !== 0"
        :headerData="vaccinationHistoryTable.headers"
        :propertyData="vaccinationHistoryTable.properties"
        :bodyData="displayVaccinationHistory"
        :headerItemStyleData="vaccinationHistoryTable.itemStyles"
        :bodyItemStyleData="vaccinationHistoryTable.itemStyles"
        :directOrderFlg="true"
        :addButtonText="lookOnlyFlg === 0 ? '履歴追加' : ''"
        :addButtonDisabled="patientVaccines.length === 0"
        :showPrintButton="true"
        @add="antibodyPopupFlg = true"
        @click="openEditAntibodyPopup"
        @print="openHistoryPrintPopup"
        data-test="vaccination-history"
      />
      <div v-else class="list no-data">
        <div class="number">0件</div>
        <base-button-plus
          v-if="lookOnlyFlg === 0"
          class="add-button"
          :styles="{ width: '130px' }"
          :text="'履歴追加'"
          :disabled="patientVaccines.length === 0"
          @click="antibodyPopupFlg = true"
          data-test="add-vaccination-history"
        />
      </div>
    </div>
    <vaccine-popup
      v-if="vaccinePopupFlg"
      :apiFlg="true"
      :patientId="patientId"
      :patientVaccine="editPatientVaccine"
      :patientVaccines="patientVaccines"
      :patientVaccinePeriods="editPatientVaccinePeriods"
      @close="closeVaccinePopup"
    />
    <vaccine-reminders-popup
      v-if="remindersPopupFlg"
      :patientId="patientId"
      :patientVaccines="patientVaccines"
      :vaccineReminders="patientVaccineReminders"
      @close="remindersPopupFlg = false"
    />
    <print-options-popup
      v-if="printFlg"
      :dataType="'vaccinationCertificate'"
      :data="printVaccination"
      :owner="getOwnerById(ownerId)"
      :patient="printPatient"
      @close="printFlg = false"
    />
    <antibody-popup
      v-if="antibodyPopupFlg"
      :patientVaccines="patientVaccines"
      :antibody="editAntibody"
      :antibodyTypes="editAntibodyTypes"
      @close="closeAntibodiesPopup"
    />
    <grace-popup
      v-if="gracePopupFlg"
      :patientId="patientId"
      :grace="editGrace"
      @close="closeGracePopup"
    />
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import ListTable from '@/components/parts/organisms/ListTable'
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import VaccinationListCard from '@/components/parts/organisms/VaccinationListCard'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import AntibodyPopup from '@/components/popups/AntibodyPopup'
import GracePopup from '@/components/popups/GracePopup'
import PrintOptionsPopup from '@/components/popups/PrintOptionsPopup'
import VaccinePopup from '@/components/popups/VaccinePopup'
import VaccineRemindersPopup from '@/components/popups/VaccineRemindersPopup'
import { antibodyTypesEnToJp } from '@/utils/vaccine_utils'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { makeRepeatPeriods } from '@/utils/vaccine_utils'

export default {
  name: 'PatientVaccinations',

  components: {
    BaseButtonSmallWhite,
    BaseButtonPlus,
    ListTable,
    OwnerPatientDetail,
    VaccinationListCard,
    VaccinePopup,
    VaccineRemindersPopup,
    PrintOptionsPopup,
    AntibodyPopup,
    GracePopup,
    AnnouncePopup
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      vaccinationHistoryTable: {
        headers: ['接種/検査日', '接種/抗体検査情報'],
        properties: ['date', 'content'],
        itemStyles: [{ width: '200px', minWidth: '200px' }]
      },
      remindersPopupFlg: false,
      vaccinePopupFlg: false,
      editPatientVaccine: undefined,
      editPatientVaccinePeriods: [],
      antibodyPopupFlg: false,
      editAntibody: undefined,
      editAntibodyTypes: undefined,
      gracePopupFlg: false,
      editGrace: undefined,
      printFlg: false,
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      decide: () => {},
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      antibodiesByPatientId: 'antibodies/getDataByPatientId',
      antibodyTypesByPatientId: 'antibodyTypes/getDataByPatientId',
      getMasterDatum: 'master/getDataById',
      getOwnerById: 'owners/getDataById',
      getPatientById: 'patients/getDataById',
      getSpeciesById: 'species/getDataById',
      getStaffById: 'staffs/getDataById',
      gracesByPatientId: 'graces/getDataByPatientId',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      medicalRecordsByPatientId: 'medicalRecords/getDataByPatientId',
      patientSexes: 'master/selectPatientSexesZero',
      patientVaccinePeriodsByPatientId:
        'patientVaccinePeriods/getDataByPatientId',
      patientVaccinesByPatientId: 'patientVaccines/getDataByPatientId',
      patientVaccinesByPatientIdIncludeDel:
        'patientVaccines/getDataByPatientIdIncludeDel',
      reservationsByPatientId: 'reservations/getPatientReservations',
      vaccineRemindersByPatientId: 'vaccineReminders/getDataByPatientId'
    }),
    patientVaccines() {
      return this.patientVaccinesByPatientId(this.patientId) || []
    },
    patientVaccinesIncludeDel() {
      return this.patientVaccinesByPatientIdIncludeDel(this.patientId) || []
    },
    patientAntibodies() {
      return this.antibodiesByPatientId(this.patientId) || []
    },
    patientAntibodyTypes() {
      return this.antibodyTypesByPatientId(this.patientId) || []
    },
    patientGraces() {
      return this.gracesByPatientId(this.patientId) || []
    },
    patientVaccinePeriods() {
      return this.patientVaccinePeriodsByPatientId(this.patientId) || []
    },
    patientVaccineReminders() {
      return this.vaccineRemindersByPatientId(this.patientId) || []
    },
    patientMedicalRecords() {
      return this.medicalRecordsByPatientId(this.patientId) || []
    },
    patientReservations() {
      return this.reservationsByPatientId(this.patientId) || []
    },
    previousComingDate() {
      const nowDateTime = moment().format('YYYYMMDDHHmm')
      const pastMedicalRecords = this.patientMedicalRecords.filter(v => {
        const startDateTime = v.date + v.startTime
        return startDateTime <= nowDateTime && v.delFlg === 0
      })
      const latestMedicalRecord = this.getNearDatum(
        pastMedicalRecords,
        'latest'
      )
      return latestMedicalRecord?.date || ''
    },
    nextReservationDate() {
      const nowDateTime = moment().format('YYYYMMDDHHmm')
      const futureReservations = this.patientReservations.filter(v => {
        const startDateTime = v.date + v.startTime
        return (
          nowDateTime < startDateTime && v.cancelFlg === 0 && v.delFlg === 0
        )
      })
      const earliestReservation = this.getNearDatum(
        futureReservations,
        'earliest'
      )
      return earliestReservation?.date || ''
    },
    vaccinations() {
      return this.vaccinationsIncludeDel.filter(
        v => v.patientVaccineDelFlg === 0
      )
    },
    vaccinationsIncludeDel() {
      const vaccinations = this.patientVaccinesIncludeDel.map(
        patientVaccine => {
          const vaccineId = patientVaccine.vaccineId
          const dateAntibodyTypes = this.makeDateAntibodyTypes(
            vaccineId,
            this.patientAntibodies,
            this.patientAntibodyTypes
          )
          const previousDateAntibodyType =
            dateAntibodyTypes[dateAntibodyTypes.length - 1]
          const vaccinePeriods = this.makeVaccinePeriods(
            vaccineId,
            this.patientVaccinePeriods
          )
          const currentPeriod = vaccinePeriods.find(v => v.currentFlg)
          const nextPeriod = vaccinePeriods.find(v => v.nextFlg)
          const completeFlg = this.hasComplete(currentPeriod, dateAntibodyTypes)
          const vaccineReminders = this.makeVaccineReminders(
            vaccineId,
            this.patientVaccineReminders
          )
          const previousVaccineReminder =
            vaccineReminders[vaccineReminders.length - 1]
          const latestVaccinatedAntibody = this.makeLatestVaccinatedAntibody(
            dateAntibodyTypes
          )
          const vaccination = {
            patientVaccineId: patientVaccine.id,
            vaccineName: this.getMasterDatum('vaccines', vaccineId).name,
            previousDateAntibodyType,
            currentPeriod,
            completeFlg,
            nextPeriod,
            previousVaccineReminder,
            latestVaccinatedAntibody,
            lotNoHistory: dateAntibodyTypes.map(v => {
              return {
                date: v.date,
                lotNo: v.lotNo
              }
            }),
            patientVaccineDelFlg: patientVaccine.delFlg
          }

          return vaccination
        }
      )
      return vaccinations
    },
    printPatient() {
      const patient = this.getPatientById(this.patientId)
      const speciesName = this.getSpeciesById(patient.speciesId).name
      const sexName = this.patientSexes.find(v => v.id === patient.sex).name
      return { ...patient, speciesName, sexName }
    },
    displayVaccinationHistory() {
      const today = moment().format('YYYYMMDD')
      const antibodies = [...this.patientAntibodies]
      return antibodies
        .flatMap(antibody => {
          if (today < antibody.date) return []
          const antibodyTypes = this.patientAntibodyTypes.filter(
            v => v.antibodyId === antibody.id
          )
          let content = ''
          let printButtonDisabled = true
          antibodyTypes.forEach(antibodyType => {
            const vaccine = this.getMasterDatum(
              'vaccines',
              antibodyType.vaccineId
            )
            if (antibodyType.type !== '')
              content += `${vaccine.name}（${
                antibodyTypesEnToJp[antibodyType.type]
              }）、`
            if (antibodyType.type === 'vaccinated') printButtonDisabled = false
          })
          return {
            id: antibody.id,
            date: this.formatDate(antibody.date, true),
            content: content.slice(0, -1),
            printButtonDisabled
          }
        })
        .sort((a, b) =>
          a.date === b.date ? b.id - a.id : a.date < b.date ? 1 : -1
        )
    }
  },

  methods: {
    formatDate(date, dayFlg = false) {
      return date
        ? dayFlg
          ? moment(date, 'YYYYMMDD').format('Y年M月D日（dd）')
          : moment(date, 'YYYYMMDD').format('Y年M月D日')
        : ''
    },
    toPeriod(period) {
      if (!period) return ''
      const start = this.formatDate(period.startDate)
      const end = this.formatDate(period.endDate)
      return `${start} ~ ${end}`
    },
    getNearDatum(data, type) {
      const isEarly = type === 'earliest'
      return data.length > 0
        ? data.reduce((a, b) => {
            const compareId = isEarly ? a.id < b.id : a.id > b.id
            const compareTime = isEarly
              ? a.startTime < b.startTime
              : a.startTime > b.startTime
            const compareDate = isEarly ? a.date < b.date : a.date > b.date
            return a.date === b.date
              ? a.startTime === b.startTime
                ? compareId
                  ? a
                  : b
                : compareTime
                ? a
                : b
              : compareDate
              ? a
              : b
          })
        : undefined
    },
    makeDateAntibodyTypes(vaccineId, patientAntibodies, patientAntibodyTypes) {
      const today = moment().format('YYYYMMDD')
      const antibodies = [...patientAntibodies]
      const antibodyTypes = [...patientAntibodyTypes]
      const dateAntibodyTypes = antibodyTypes
        .flatMap(antibodyType => {
          const isValid =
            antibodyType.vaccineId === vaccineId &&
            antibodyType.type !== '' &&
            antibodyType.delFlg === 0
          if (!isValid) return []
          const antibody = antibodies.find(
            antibody => antibody.id === antibodyType.antibodyId
          )
          if (!antibody) return []
          const date = antibody.date
          if (today < date) return []
          return {
            antibodyId: antibodyType.antibodyId,
            date,
            type: antibodyType.type,
            lotNo: antibodyType.lotNo
          }
        })
        .sort((a, b) => {
          return a.date === b.date ? a.id - b.id : a.date < b.date ? -1 : 1
        }) // 昇順
      return dateAntibodyTypes
    },
    makeVaccinePeriods(vaccineId, patientVaccinePeriods) {
      const vaccinePeriods = patientVaccinePeriods.filter(
        patientVaccinePeriod => patientVaccinePeriod.vaccineId === vaccineId
      )
      const sortedPeriods = vaccinePeriods.sort((a, b) =>
        a.startDate < b.startDate ? -1 : 1
      ) // 昇順
      const repeatPeriods = makeRepeatPeriods(sortedPeriods)
      const today = moment().format('YYYYMMDD')
      let hasCurrent = false
      let hasNext = false
      const periods = []
      repeatPeriods.forEach((repeatPeriod, i, array) => {
        if (hasNext) return
        const nextPeriod = array[i + 1]
        const startDate = repeatPeriod.startDate
        const endDate =
          repeatPeriod.endDate === '' && nextPeriod
            ? moment(nextPeriod.startDate, 'YYYYMMDD')
                .add(-1, 'd')
                .format('YYYYMMDD')
            : repeatPeriod.endDate
        if (startDate <= today && (today <= endDate || endDate === ''))
          hasCurrent = true
        if (today < startDate) hasNext = true
        const period = {
          startDate,
          endDate,
          currentFlg: hasCurrent && !hasNext,
          nextFlg: hasNext
        }
        periods.push(period)
      })
      return periods
    },
    hasComplete(currentPeriod, dateAntibodyTypes) {
      if (!currentPeriod) return false
      const today = moment().format('YYYYMMDD')
      const start = currentPeriod.startDate
      const end = currentPeriod.endDate || today
      const hasComplete = dateAntibodyTypes.some(dateAntibodyType => {
        const { date, type } = dateAntibodyType
        const inPeriod = start <= date && date <= end
        const isCompleteType = type === 'vaccinated' || type === 'positive'
        return inPeriod && isCompleteType
      })
      return hasComplete
    },
    makeVaccineReminders(vaccineId, patientVaccineReminders) {
      const vaccineReminders = patientVaccineReminders.filter(v => {
        const vaccineIds = v.vaccineIds
        const hasVaccine = vaccineIds.includes(vaccineId)
        return hasVaccine
      })
      return vaccineReminders
    },
    makeLatestVaccinatedAntibody(dateAntibodyTypes) {
      const vaccinatedAntibodyTypes = dateAntibodyTypes.filter(
        dateAntibodyType => dateAntibodyType.type === 'vaccinated'
      )
      if (vaccinatedAntibodyTypes.length === 0) return undefined
      const latestVaccinatedAntibodyType =
        vaccinatedAntibodyTypes[vaccinatedAntibodyTypes.length - 1]
      const latestVaccinatedAntibody = this.patientAntibodies.find(
        antibody => antibody.id === latestVaccinatedAntibodyType.antibodyId
      )
      return latestVaccinatedAntibody
    },
    openEditVaccinePopup(patientVaccineId) {
      this.editPatientVaccine = this.patientVaccines.find(
        v => v.id === patientVaccineId
      )
      this.editPatientVaccinePeriods = this.patientVaccinePeriods.filter(
        v => v.vaccineId === this.editPatientVaccine.vaccineId
      )
      this.vaccinePopupFlg = true
    },
    closeVaccinePopup() {
      this.vaccinePopupFlg = false
      this.editPatientVaccine = undefined
      this.editPatientVaccinePeriods = []
    },
    openEditAntibodyPopup(antibodyId) {
      this.editAntibody = this.patientAntibodies.find(v => v.id === antibodyId)
      this.editAntibodyTypes = this.patientAntibodyTypes.filter(
        v => v.antibodyId === antibodyId
      )
      this.antibodyPopupFlg = true
    },
    closeAntibodiesPopup() {
      this.antibodyPopupFlg = false
      this.editAntibody = undefined
      this.editAntibodyTypes = undefined
    },
    openHistoryPrintPopup(id) {
      const antibodiesRecord = this.patientAntibodies.find(v => v.id === id) // antibodiesの記録
      const antibodyTypeVaccineIds = this.patientAntibodyTypes.flatMap(v =>
        v.antibodyId === id && v.type === 'vaccinated' ? v.vaccineId : []
      ) // 接種の場合、antibodies の記録のそれぞれの抗体結果の id (antibody_types の記録)
      const vaccines = antibodyTypeVaccineIds.map(a =>
        this.patientVaccinesIncludeDel.find(v => v.vaccineId === a)
      ) // それぞれの抗体のワクチン
      const vaccineData = vaccines.map(a =>
        this.vaccinationsIncludeDel.find(v => v.patientVaccineId === a.id)
      ) // 患者に関連するワクチン接種データ
      const staff = this.getStaffById(antibodiesRecord.staffId)
      const staffName = staff ? staff.lastName + ' ' + staff.firstName : ''
      this.printVaccination = vaccineData.map(v => {
        const targetPeriod =
          v.currentPeriod && !v.completeFlg ? v.currentPeriod : v.nextPeriod
        return {
          name: v.vaccineName,
          date: this.formatDate(antibodiesRecord.date),
          nextDate: this.toPeriod(targetPeriod),
          staffName,
          lotNo: v.lotNoHistory.find(u => u.date === antibodiesRecord.date)
            .lotNo,
          memo: antibodiesRecord.memo
        }
      })
      this.printFlg = true
    },
    openPrintPopup(patientVaccineId) {
      const vaccination = this.vaccinations.find(
        v => v.patientVaccineId === patientVaccineId
      )
      const latestVaccinatedAntibody = vaccination.latestVaccinatedAntibody
      const currentPeriod = vaccination.currentPeriod
      const nextPeriod = vaccination.nextPeriod
      const completeFlg = vaccination.completeFlg
      const targetPeriod =
        currentPeriod && !completeFlg ? currentPeriod : nextPeriod
      const staff = this.getStaffById(latestVaccinatedAntibody.staffId)
      const staffName = staff ? staff.lastName + ' ' + staff.firstName : ''
      this.printVaccination = {
        name: vaccination.vaccineName,
        date: this.formatDate(latestVaccinatedAntibody.date),
        nextDate: this.toPeriod(targetPeriod),
        staffName,
        lotNo: vaccination.lotNoHistory.find(
          u => u.date === latestVaccinatedAntibody.date
        ).lotNo,
        memo: latestVaccinatedAntibody.memo
      }
      this.printFlg = true
    },
    openGracePopup() {
      this.editGrace = this.patientGraces
        .sort((a, b) => b.id - a.id)
        .find(v => true)
      this.gracePopupFlg = true
    },
    closeGracePopup() {
      this.gracePopupFlg = false
      this.editGrace = undefined
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-vaccinations {
  display: inline-block;
  min-width: 100%;
  > .owner-patient {
    margin-top: 30px;
  }
  > .lists {
    font-size: 15px;
    > .title-row {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .title {
        font-weight: bold;
      }
      > .buttons {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
    }
    > .list {
      min-width: 600px;
      margin-top: 20px;
    }
    > .history {
      ::v-deep .table-body {
        max-height: 224px;
      }
    }
    > .no-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      padding-left: 20px;
      box-sizing: border-box;
      border: solid 1px #{$light-grey};
      > .add-button {
        margin-right: 20px;
      }
    }
  }
}
</style>
