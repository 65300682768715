<template>
  <div class="patient-medical-contents">
    <div class="area owner-patient">
      <owner-patient-detail
        :owner="getOwner(ownerId)"
        :patient="getPatient(patientId)"
      />
    </div>
    <div class="area search">
      <search-area
        :textBoxLabel="'検索単語'"
        :textValue="searchText"
        :placeholder="'診療内容'"
        :periodFlg="true"
        :periodLabel="'期間'"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        v-model="searchText"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">
        検索結果：{{ this.searchCounts }}件({{
          displayMedicalContents.length
        }}件中)
      </div>
      <div class="order-setting">
        <base-button-small-white
          class="toggle-show-flg-button"
          :styles="{ width: '150px' }"
          @click="toggleFlg('deleteButtonShowFlg')"
          >{{ toggleButtonText }}</base-button-small-white
        >
        <div class="label">並び替え：診療日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <dynamic-scroller
      class="list-area"
      :items="searchedMedicalContents"
      :min-item-size="248"
      ref="scroller"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
        >
          <medical-content-information
            :medicalContent="item"
            :searchRegExp="searchRegExp"
            :imagesByMedicalContentOriginalId="imagesByMedicalContentOriginalId"
            :patientMedicalContentsFlg="true"
            :deleteButtonShowFlg="deleteButtonShowFlg"
            @click-medical-content="gotoMedicalRecordEditPage"
            @delete-medical-content="openDeletePopup"
          ></medical-content-information>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import SearchArea from '@/components/parts/molecules/SearchArea'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import MedicalContentInformation from '@/components/parts/organisms/MedicalContentInformation'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import {
  COMPONENT_TAG_IN_CONTENT_REGEX,
  UPLOAD_IMAGE_TAG_REGEX,
  MEDICAL_CONTENT_IMAGE_TAG_REGEX,
  CONTENT_IMAGE_ID_ATTRIBUTE_REGEX,
  escapeRegExp,
  FULL_WIDTH_COLON
} from '@/utils/define'
import { compareMPH } from '@/utils/compare'

export default {
  name: 'PatientMedicalContents',

  components: {
    OwnerPatientDetail,
    SearchArea,
    BaseButtonSmallWhite,
    BaseSelectBox,
    MedicalContentInformation,
    AnnouncePopup,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      imagesByMedicalContentOriginalId: {},
      searchText: '',
      startDate: '',
      endDate: '',
      deleteButtonShowFlg: false,
      deleteMedicalContentId: 0,
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      decide: () => {},
      waitFlg: false,
      scrollTop: 0,
      parentScrollTop: 0,
      deleteOperationFlg: false,
      timeoutId: null,
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters({
      getMedicalContentsByPatientId: 'medicalContents/getDataByPatientId',
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getImageTextsByMedicalContentImageId:
        'medicalContentImageTexts/getDataByMedicalContentImageId',
      getKarteUploadImage: 'uploadImages/getKarteUploadImage',
      getKarteMedicalContentImage:
        'medicalContentImages/getKarteMedicalContentImage',
      medicalPaymentsByMROId: 'medicalPayments/getDataByMedicalRecordOriginalId'
    }),
    medicalContents() {
      return this.getMedicalContentsByPatientId(this.patientId) || []
    },
    displayMedicalContents() {
      return this.medicalContents.map(v => {
        const medicalRecord = this.getMedicalRecord(v.medicalRecordOriginalId)
        const replaceSearchContent = v.searchContent.replace(
          new RegExp(FULL_WIDTH_COLON, 'g'),
          '：'
        )
        const displayContent = this.makeDisplayContent(replaceSearchContent)
        return {
          ...v,
          ownerId: medicalRecord.ownerId,
          date: medicalRecord.date,
          formatDate: moment(medicalRecord.date, 'YYYYMMDD').format(
            'YYYY年MM月DD日（dd）'
          ),
          startTime: medicalRecord.startTime,
          searchContent: replaceSearchContent,
          searchAllContent: replaceSearchContent + v.searchImageContent,
          displayContent
        }
      })
    },
    trimSearchText() {
      return this.searchText.toLowerCase().replace(/\s+/g, '')
    },
    searchedMedicalContents() {
      return this.displayMedicalContents
        .filter(
          v =>
            this.filterByContent(v, this.trimSearchText) &&
            this.filterByDate(v, this.startDate, this.endDate)
        )
        .sort((a, b) => this.sortByOrderType(a, b))
    },
    searchCounts() {
      return this.searchedMedicalContents.length
    },
    searchRegExp() {
      const escapedText = escapeRegExp(this.trimSearchText)
      return escapedText === '' ? null : new RegExp(escapedText, 'ig')
    },
    toggleButtonText() {
      return this.deleteButtonShowFlg ? '削除ボタン非表示' : '削除ボタン表示'
    }
  },

  created() {
    this.setImages()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'medicalContents/updateAfter') {
        this.setImages()
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
    clearTimeout(this.timeoutId)
  },

  methods: {
    makeDisplayContent(replaceSearchContent) {
      if (!this.searchRegExp) {
        return replaceSearchContent
      } else {
        const content = replaceSearchContent
        const searchTextIndexes = []
        let result
        while ((result = this.searchRegExp.exec(content))) {
          searchTextIndexes.push(result.index)
        }
        if (searchTextIndexes.length === 0) {
          //画像内のテキストだけヒットした場合でも、冒頭の300文字を表示するようにする
          return content.length > 300 ? content.slice(0, 300) + '...' : content
        }
        let searchContent = ''
        let endIndex = 0
        searchTextIndexes.forEach((searchTextIndex, i) => {
          if (searchTextIndex >= endIndex) {
            const startIndex =
              searchTextIndex - 10 > 0 ? searchTextIndex - 10 : 0
            endIndex = startIndex + 200
            let sliceContent = content.slice(startIndex, endIndex)
            if (
              sliceContent.length === 200 &&
              content.slice(endIndex + 1).length > 0
            ) {
              sliceContent = sliceContent + '...'
            }
            searchContent += i === 0 ? sliceContent : '　　' + sliceContent
          }
        })
        return searchContent
      }
    },
    setImages() {
      const uploadImages = []
      const medicalContentImages = []
      this.medicalContents.forEach(v => {
        const componentTag = v.content.match(COMPONENT_TAG_IN_CONTENT_REGEX)
        if (componentTag === null) return
        const componentTagText = componentTag[0]
        if (UPLOAD_IMAGE_TAG_REGEX.test(componentTagText)) {
          const uploadImageId = Number(componentTagText.replace(/[^0-9]/g, ''))
          uploadImages.push(this.getKarteUploadImage(uploadImageId))
        } else if (MEDICAL_CONTENT_IMAGE_TAG_REGEX.test(componentTagText)) {
          const attributeText = componentTagText.match(
            CONTENT_IMAGE_ID_ATTRIBUTE_REGEX
          )[0]
          const medicalContentImageId = Number(
            attributeText.replace(/[^0-9]/g, '')
          )
          medicalContentImages.push(
            this.getKarteMedicalContentImage(medicalContentImageId)
          )
        }
      })
      const calculatedMedicalContentImages = this.calculateMedicalContentImages(
        medicalContentImages
      )
      const images = uploadImages.concat(calculatedMedicalContentImages)
      this.imagesByMedicalContentOriginalId = Object.assign(
        {},
        ...images.map(datum => ({
          [datum.medicalContentOriginalId]: datum
        }))
      )
    },
    calculateMedicalContentImages(medicalContentImages) {
      const defaultImageSize = 400
      const listImageSize = 150
      const size = listImageSize / defaultImageSize
      let addData = []
      addData = medicalContentImages.map(datum => {
        const tmp = this.getImageTextsByMedicalContentImageId(datum.id)
          ? this.getImageTextsByMedicalContentImageId(datum.id)
          : []
        const medicalContentImageTexts = tmp.map(mcit => {
          return {
            id: mcit.id,
            text: mcit.text,
            textStyle: {
              position: 'absolute',
              top: `${mcit.top * size}px`,
              left: `${mcit.left * size}px`,
              width: `${mcit.width * size}px`,
              height: `${mcit.height * size}px`,
              color: mcit.color,
              overflow: 'hidden',
              'font-size': `${mcit.size * size}px`,
              'white-space': 'pre-wrap',
              'z-index': 1,
              'word-break': 'break-all'
            }
          }
        })
        return {
          ...datum,
          thumbnailImage:
            datum.schemaImageId !== 0
              ? require(`@/assets/images/schemas/${datum.schemaImageId}.jpg`)
              : datum.uploadImageId !== 0
              ? this.getKarteUploadImage(datum.uploadImageId).thumbnailImage
              : null,
          medicalContentImageTexts
        }
      })
      return addData
    },
    filterByContent(medicalContent, searchText) {
      return medicalContent.searchAllContent.toLowerCase().includes(searchText)
    },
    filterByDate(medicalContent, startDate, endDate) {
      return startDate !== '' && endDate !== ''
        ? startDate <= medicalContent.date && medicalContent.date <= endDate
        : startDate !== ''
        ? startDate <= medicalContent.date
        : endDate !== ''
        ? medicalContent.date <= endDate
        : true
    },
    sortByOrderType(a, b) {
      let result = 0
      if (a.date === b.date) {
        if (a.startTime === b.startTime) {
          const aMP = this.getMedicalPayment(a.medicalRecordOriginalId)
          const bMP = this.getMedicalPayment(b.medicalRecordOriginalId)
          if (aMP && bMP) {
            result = compareMPH(aMP, bMP)
          } else {
            result = b.medicalRecordOriginalId - a.medicalRecordOriginalId
          }
        } else {
          result = b.startTime < a.startTime ? -1 : 1
        }
      } else {
        result = b.date < a.date ? -1 : 1
      }
      return this.orderType === 0 ? result : -result
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    toggleFlg(flgName) {
      this[flgName] = !this[flgName]
    },
    gotoMedicalRecordEditPage(medicalContent) {
      const ownerId = medicalContent.ownerId
      const patientId = medicalContent.patientId
      const medicalRecordOriginalId = medicalContent.medicalRecordOriginalId
      this.$router.push(
        `/main/karte/owners/${ownerId}/patients/${patientId}/medical-records/${medicalRecordOriginalId}/edit`
      )
    },
    openDeletePopup(medicalContent) {
      this.deleteMedicalContentId = medicalContent.id
      const params = {
        patientId: 0,
        medicalRecordOriginalId: medicalContent.medicalRecordOriginalId,
        hospitalizationId: 0
      }
      const isLocked = this.$store.getters['auth/isAnotherClientLocking'](
        params
      )
      if (isLocked) {
        this.$store.dispatch('petorelu/saveNextFunction', this.deleteConfirm)
        this.$store.dispatch('petorelu/showKarteLockPopup', {
          ...params,
          isTryingDelete: true
        })
      } else {
        this.deleteConfirm()
      }
    },
    deleteConfirm() {
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.decide = () => this.decideDelete()
      this.scrollTop = this.$refs.scroller.$el.scrollTop
      this.parentScrollTop = this.$parent.$el.scrollTop
    },
    async decideDelete() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'medicalContents/delete',
        this.deleteMedicalContentId
      )
      if (res?.result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
        this.deleteOperationFlg = true
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
      if (this.deleteOperationFlg) {
        //データ削除後にスクロール位置が自動的にずれてしまう。
        //原因ははっきりとわかっていないが、virtual-scrollerとfocus-trapの影響で削除ポップアップを閉じた後に
        //focusが別の診療内容の要素に移ったことでスクロールが自動的にされた可能性がある。
        //自動的にずれる処理自体を止める方法が見つからなかったため、その直後に元のスクロール位置に戻すようにした。
        this.$nextTick(() => {
          this.timeoutId = setTimeout(() => {
            this.$refs.scroller.$el.scrollTo(0, this.scrollTop)
            this.$parent.$el.scrollTo(0, this.parentScrollTop) //大元の方のスクロールの位置もずれるので調整する
          }, 1)
          this.deleteOperationFlg = false
        })
      }
    },
    getMedicalPayment(medicalRecordOriginalId) {
      let medicalPayment
      const medicalPayments = this.medicalPaymentsByMROId(
        medicalRecordOriginalId
      )
      if (medicalPayments) {
        medicalPayment = medicalPayments.find(v => v.delFlg === 0)
      }
      return medicalPayment
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-medical-contents {
  display: inline-block;
  min-width: 100%;
  > .owner-patient {
    margin-top: 10px;
  }
  > .search {
    margin-top: 40px;
  }
  > .search-order {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .toggle-show-flg-button {
        margin-right: 20px;
      }
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .list-area {
    max-height: 670px;
  }
}
</style>
