<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="estimate-templates-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="$emit('close')"
        @click-close-mark="$emit('close')"
      >
        <template v-slot:content>
          <div class="content-wrap">
            <div class="header">
              <div class="title">見積書備考テンプレートリスト</div>
              <base-button-register :text="'新規登録'" @click="openNewPopup" />
            </div>
            <div class="list">
              <div class="list-header">
                <div class="cell name">
                  <div class="text">テンプレート名</div>
                </div>
                <div class="cell content">
                  <div class="text">内容</div>
                </div>
              </div>
              <div class="list-body">
                <div
                  class="row"
                  v-for="item in estimateTemplates"
                  :key="item.id"
                  @click="select(item.content)"
                >
                  <div class="cell name" :title="item.name">
                    <div class="text">{{ item.name }}</div>
                  </div>
                  <div class="cell content" :title="item.content">
                    <div class="text">{{ item.content }}</div>
                  </div>
                  <div class="cell button">
                    <base-button-small-white
                      @click.stop="openEditPopup(item.id)"
                      >編集</base-button-small-white
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <estimate-template-new-popup
        v-if="newPopupFlg"
        @close="newPopupFlg = false"
      />
      <estimate-template-edit-popup
        v-if="editPopupFlg"
        :id="targetId"
        @close="editPopupFlg = false"
      />
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import EstimateTemplateNewPopup from '@/components/popups/EstimateTemplateNewPopup'
import EstimateTemplateEditPopup from '@/components/popups/EstimateTemplateEditPopup'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'EstimateTemplatesPopup',

  components: {
    FocusTrap,
    Popup,
    BaseButtonRegister,
    BaseButtonSmallWhite,
    EstimateTemplateNewPopup,
    EstimateTemplateEditPopup
  },

  data() {
    return {
      newPopupFlg: false,
      editPopupFlg: false,
      targetId: 0
    }
  },

  computed: {
    ...mapGetters({
      estimateTemplates: 'estimateTemplates/getData'
    })
  },

  methods: {
    openNewPopup() {
      this.newPopupFlg = true
    },
    select(content) {
      this.$emit('select', content)
      this.$emit('close')
    },
    openEditPopup(id) {
      this.targetId = id
      this.editPopupFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$very-light-grey};
.estimate-templates-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content-wrap {
    box-sizing: border-box;
    width: 650px;
    > .header {
      display: flex;
      justify-content: space-between;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
        text-align: left;
      }
    }
    > .list {
      margin-top: 20px;
      width: 590px;
      text-align: left;
      > .list-header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 25px;
        border: 1px solid transparent;
        > .cell {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 0 5px;
          & + .cell {
            border-left: 1px solid transparent;
          }
          &.name {
            width: 230px;
          }
          &.content {
            flex: 1;
          }
          > .text {
            flex: 1;
            width: 0;
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      > .list-body {
        box-sizing: border-box;
        max-height: 401px;
        border: 1px solid $border-color;
        overflow-y: auto;
        > .row {
          box-sizing: border-box;
          display: flex;
          height: 40px;
          background-color: #{$gray_fc};
          @include hover();
          &:first-child {
            margin-top: -1px;
            border-top: 1px solid transparent;
          }
          &:nth-child(even) {
            background-color: #{$white};
          }
          & ~ .row {
            border-top: 1px solid $border-color;
          }
          > .cell {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0 5px;
            & + .cell:not(.button) {
              border-left: 1px solid $border-color;
            }
            &.name {
              width: 230px;
            }
            &.content {
              flex: 1;
            }
            &.button {
              width: 50px;
              justify-content: center;
              border-left: 1px solid transparent;
              ::v-deep .base-button-small-white {
                > button {
                  width: 40px;
                  height: 25px;
                  border: 1px solid #{$light-grey};
                  > .text {
                    font-size: 12px;
                    font-weight: normal;
                    color: #{$black_6};
                  }
                }
              }
            }
            > .text {
              flex: 1;
              width: 0;
              font-size: 13px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
