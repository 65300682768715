<template>
  <div class="medical-payment-input-form" :style="formStyle">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="white">
        <div class="title-scale">
          <div class="title">診療明細</div>
          <div
            v-if="expansionFlg"
            class="owner-patient"
            data-test="owner-patient"
            :style="
              hospitalizationUpdateFlg || removeApplyFlgError
                ? { maxWidth: '465px' }
                : {}
            "
          >
            <div class="owner" data-test="owner">
              <span class="text">飼主ID</span>
              <span class="text">{{ displayOwner.showId }}</span>
              <span class="text">氏名</span>
              <span
                class="text"
                data-test="owner-name"
                :title="displayOwner.lastName + ' ' + displayOwner.firstName"
                >{{
                  displayOwner.lastName + ' ' + displayOwner.firstName
                }}</span
              >
            </div>
            <div class="patient" data-test="patient">
              <span class="text">患者ID</span>
              <span class="text">{{ displayPatient.showId }}</span>
              <span class="text">なまえ</span>
              <span
                class="text"
                data-test="patient-name"
                :title="displayPatient.name"
                >{{ displayPatient.name }}</span
              >
            </div>
          </div>
          <div
            v-if="hospitalizationUpdateFlg"
            class="hospitalization-error"
            data-test="hospitalization-error"
          >
            ※登録できません。 この患者の保険は入院開始後に変更されました。<br />
            今回の入院期間の各診療明細を順番に再登録してください。
          </div>
          <div
            v-else-if="removeApplyFlgError"
            class="apply-error"
            data-test="apply-error"
          >
            ※登録できません。 この患者の保険は入院開始後に変更されました。<br />
            再登録したい場合は一度保険適用のチェックをはずしてください。
          </div>
          <img
            v-if="!expansionFlg"
            src="@/assets/images/expansion_allow.png"
            class="expansion-allow"
            width="17px"
            height="17px"
            alt="expansion-allow"
            @click="$emit('expansion')"
          />
          <img
            v-if="expansionFlg"
            src="@/assets/images/shrink_allow.png"
            class="shrink-allow"
            width="17px"
            height="17px"
            alt="shrink-allow"
            @click="$emit('reduction')"
          />
        </div>
        <div class="date-staff-past">
          <div class="item date">
            <div class="label">診療日</div>
            <div class="value">{{ formatDate(medicalRecord.date) }}</div>
          </div>
          <div class="item staff">
            <div class="label">担当者</div>
            <div class="value">
              <base-select-box
                :selectData="staffsSelectData"
                :styles="{ width: '140px' }"
                v-model.number="medicalPayment.staffId"
              />
            </div>
          </div>
          <div class="item past">
            <base-button-border-orange
              class="button"
              :styles="{ width: '102px' }"
              @click="$emit('click-medical-payments')"
              >明細の履歴</base-button-border-orange
            >
          </div>
        </div>
        <div class="diseases">
          <disease-select-form
            :diseaseId="medicalPayment.disease1Id"
            :label="'診断1'"
            :idFlg="true"
            @input="inputDisease(1, $event)"
          />
          <disease-select-form
            :diseaseId="medicalPayment.disease2Id"
            :label="'診断2'"
            :idFlg="true"
            @input="inputDisease(2, $event)"
          />
        </div>
        <div class="insurance">
          <insurance-apply-form
            :disabled="isAnicomApplyNg"
            :patient="patient"
            :anicomPatient="anicomPatient"
            :ipetPatient="ipetPatient"
            :medicalPayment="medicalPayment"
            :anicomCIdCheck="anicomCIdCheck"
            :ipetCheck="ipetCheck"
            @input="inputApplyFlg"
            @click-no-apply-reason="noApplyReasonPopupFlg = true"
            @click-insurance-setting="insurancePopupFlg = true"
          />
        </div>
        <div class="onset">
          <div class="label">受傷日/発症日</div>
          <div class="value">
            <base-date-picker
              data-test="date-picker onset"
              :clearFlg="true"
              :disabled="isOnsetDateDisabled"
              :popoverProps="{ visibility: 'click' }"
              @clear="medicalPayment.onsetDate = ''"
              v-model="medicalPayment.onsetDate"
            />
          </div>
          <div class="value">
            <base-check-box
              v-if="isIpetDocomoType"
              data-test="checkbox uncertain"
              :isChecked="medicalPayment.uncertainOnsetFlg"
              :labelText="'不明'"
              :labelStyle="{ marginLeft: '3px' }"
              :numFlg="true"
              @input="inputUncertainOnsetFlg"
            />
            <base-check-box
              v-else
              data-test="checkbox around"
              :isChecked="medicalPayment.onsetAroundFlg"
              :labelText="'頃'"
              :labelStyle="{ marginLeft: '3px' }"
              :numFlg="true"
              v-model="medicalPayment.onsetAroundFlg"
            />
          </div>
          <base-button-border-orange
            class="button"
            data-test="button onset"
            :disabled="isOnsetDateDisabled"
            :styles="{ width: '50px' }"
            @click="onsetDateListPopupFlg = true"
            >取得</base-button-border-orange
          >
        </div>
        <div class="surgery-hospitalization">
          <div class="surgery">
            <div class="item">
              <base-check-box
                :isChecked="medicalPayment.surgeryFlg"
                :labelText="'手術'"
                :labelStyle="{ marginLeft: '3px' }"
                :numFlg="true"
                v-model="medicalPayment.surgeryFlg"
              />
            </div>
            <div class="item" v-if="medicalPayment.surgeryFlg">
              {{ formatDate(medicalRecord.date, false) }}
            </div>
          </div>
          <hospitalization-input-form
            class="hospitalization"
            :initialMedicalPayment="initialMedicalPayment"
            :medicalPayment="medicalPayment"
            :medicalRecord="medicalRecord"
            :hospitalizationInfo="hospitalizationInfo"
            @input-start-hospitalization-flg="inputStartHospitalizationFlg"
            @input-in-hospital-flg="inputInHospitalFlg"
            @input-middle-calculate-flg="inputMiddleCalculateFlg"
            @input-end-hospitalization-flg="inputEndHospitalizationFlg"
          />
        </div>
        <div class="treatment-items">
          <div class="header" :class="{ pc: isPc }">
            <div class="wrapper">
              <div
                class="cell"
                v-for="(item, i) in headers"
                :key="item"
                :style="headerCellStyles[i]"
              >
                <div class="text">{{ item }}</div>
              </div>
            </div>
            <div class="trash"></div>
          </div>
          <div
            class="body normal"
            data-test="body-normal"
            v-if="
              medicalPayment.endHospitalizationFlg === 0 &&
                medicalPayment.middleCalculateFlg === 0
            "
          >
            <div class="wrapper">
              <div class="item target">
                <treatment-items-set-table
                  v-bind="treatmentItemData"
                  :items="displayTreatmentItems"
                  :sortItems="medicalTreatmentItems"
                  :scrollFlg="false"
                  :headerFlg="false"
                  @order="items => $emit('order', items)"
                  @click-trash="item => $emit('click-trash', item)"
                  @click-plus="selectPopupFlg = true"
                  @click-item="clickItem"
                />
              </div>
            </div>
          </div>
          <div
            class="body end-hospitalization"
            data-test="body-end-hospitalization"
            v-else
          >
            <div class="wrapper">
              <div class="item target">
                <div class="date">{{ formatDatetime(medicalRecord.date) }}</div>
                <treatment-items-set-table
                  v-bind="treatmentItemData"
                  :items="displayTreatmentItems"
                  :sortItems="medicalTreatmentItems"
                  :scrollFlg="false"
                  :headerFlg="false"
                  @order="items => $emit('order', items)"
                  @click-trash="item => $emit('click-trash', item)"
                  @click-plus="selectPopupFlg = true"
                  @click-item="clickItem"
                />
              </div>
              <div
                class="item history"
                v-for="hMedicalPayment in displayPastHospitalizationMedicalPayments"
                :key="hMedicalPayment.id"
              >
                <div class="date">{{ formatDatetime(hMedicalPayment) }}</div>
                <div class="items">
                  <treatment-items-set-table
                    v-if="hMedicalPayment.treatmentItems.length > 0"
                    v-bind="treatmentItemData"
                    :items="hMedicalPayment.treatmentItems"
                    :sortItems="hMedicalPayment.treatmentItems"
                    :scrollFlg="false"
                    :headerFlg="false"
                    :disabled="true"
                    @order="items => $emit('order', items)"
                    @click-trash="item => $emit('click-trash', item)"
                    @click-plus="selectPopupFlg = true"
                    @click-item="clickItem"
                  />
                  <div v-else class="no-items">診療項目なし</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pale">
        <div class="price-table">
          <price-input-table
            :priceItem="medicalPayment"
            :treatmentItems="medicalTreatmentItems"
            :priceDetail="priceDetail"
            :isInHospital="isInHospital"
          />
        </div>
        <div class="bill">
          <div
            class="item insurance"
            :class="{ disabled: isInHospital }"
            data-test="insurance"
          >
            <div class="label">保険請求額</div>
            <div class="value" data-test="insurance-value">
              {{ dispPrice(priceDetail.insurancePrice) }}
            </div>
          </div>
          <div
            class="item owner"
            :class="{ disabled: isInHospital }"
            data-test="burden-amount"
          >
            <div class="label">飼主請求額</div>
            <div class="value" data-test="burden-amount-value">
              {{ dispPrice(priceDetail.ownerBill) }}
            </div>
          </div>
        </div>
        <div class="buttons">
          <base-button-border-orange
            class="button"
            :styles="{ width: '165px' }"
            @click="estimateListPopupFlg = true"
            >見積書</base-button-border-orange
          >
          <base-button-border-orange
            class="button"
            :disabled="
              isInHospital || hospitalizationUpdateFlg || removeApplyFlgError
            "
            :styles="{ width: '165px' }"
            data-test="button payment"
            @click="$emit('click-payment')"
            >会計</base-button-border-orange
          >
          <base-button-small-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="
              invalid ||
                Boolean(overlappedTimeErrorMessage) ||
                hospitalizationUpdateFlg ||
                removeApplyFlgError ||
                waitFlg ||
                waitFlgForGetUploadImages ||
                isCheckingApply
            "
            :styles="{ width: '240px', fontSize: '13px' }"
            @click="$emit('decision')"
          >
            診療明細登録
          </base-button-small-orange>
        </div>
      </div>
      <insurance-popup
        v-if="insurancePopupFlg"
        :patient="patient"
        :anicomPatient="anicomPatient"
        :ipetPatient="ipetPatient"
        @close="insurancePopupFlg = false"
        @updated="updatedInsurance"
      />
      <no-apply-reason-popup
        v-if="noApplyReasonPopupFlg"
        :medicalPayment="medicalPayment"
        @cancel="noApplyReasonPopupFlg = false"
        @decision="inputNoApplyReason"
      />
      <onset-date-list-popup
        v-if="onsetDateListPopupFlg"
        :patientId="patientId"
        @close="onsetDateListPopupFlg = false"
        @select="inputOnsetDate"
      />
      <treatment-items-select-popup
        v-if="selectPopupFlg"
        :patient="patient"
        :insuranceType="medicalPayment.insuranceType"
        @cancel="selectPopupFlg = false"
        @decision="addTreatmentItems"
      />
      <treatment-items-input-popup
        v-if="inputPopupFlg"
        :treatmentItems="[targetTreatmentItem]"
        :idFlg="true"
        :insuranceType="medicalPayment.insuranceType"
        @cancel="inputPopupFlg = false"
        @decision="changeTreatmentItem"
      />
      <estimate-list-popup
        v-if="estimateListPopupFlg"
        :patientId="patientId"
        :insuranceType="medicalPayment.insuranceType"
        @close="estimateListPopupFlg = false"
        @copy="copyToMedicalPayment"
      ></estimate-list-popup>
      <announce-popup
        v-if="announcePopupFlg"
        :title="announcePopupData.title"
        :buttons="announcePopupData.buttons"
        :type="announcePopupData.type"
        @cancel="announcePopupData.cancel"
        @decision="announcePopupData.decision"
        @close="closeAnnouncePopup"
        >{{ announcePopupData.message }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import DiseaseSelectForm from '@/components/parts/molecules/DiseaseSelectForm'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox.vue'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import TreatmentItemsSetTable from '@/components/parts/organisms/TreatmentItemsSetTable'
import PriceInputTable from '@/components/parts/organisms/PriceInputTable'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import InsuranceApplyForm from '@/components/parts/organisms/InsuranceApplyForm'
import HospitalizationInputForm from '@/components/parts/molecules/HospitalizationInputForm'
import InsurancePopup from '@/components/popups/InsurancePopup'
import NoApplyReasonPopup from '@/components/popups/NoApplyReasonPopup'
import OnsetDateListPopup from '@/components/popups/OnsetDateListPopup'
import TreatmentItemsSelectPopup from '@/components/popups/TreatmentItemsSelectPopup'
import TreatmentItemsInputPopup from '@/components/popups/TreatmentItemsInputPopup'
import EstimateListPopup from '@/components/popups/EstimateListPopup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { getDevice } from '@/utils/get_device'
import {
  isHospitalization,
  isStartEnd,
  isStart,
  isIn,
  isEnd,
  hasSameStartEnd
} from '@/utils/medical_payment_helper'
import { compareMPH } from '@/utils/compare'
import { calculateTreatmentItemSubtotal } from '@/utils/price_calculation'

const treatmentItemStyles = [
  { width: '28%', textAlign: 'left', padding: '0 10px' },
  { width: '7%' },
  { width: '15%' },
  { width: '10%' },
  { width: '10%' },
  { width: '15%' },
  { width: '15%' }
].map(v => {
  return { ...v, fontSize: '11px' }
})

export default {
  name: 'MedicalPaymentInputForm',

  components: {
    BaseSelectBox,
    BaseButtonBorderOrange,
    DiseaseSelectForm,
    BaseCheckBox,
    BaseDatePicker,
    TreatmentItemsSetTable,
    PriceInputTable,
    BaseButtonSmallOrange,
    InsuranceApplyForm,
    HospitalizationInputForm,
    InsurancePopup,
    NoApplyReasonPopup,
    OnsetDateListPopup,
    TreatmentItemsSelectPopup,
    TreatmentItemsInputPopup,
    EstimateListPopup,
    AnnouncePopup,
    ValidationObserver
  },

  props: {
    patientId: { type: Number },
    patient: { type: Object, default: null },
    displayOwner: { type: Object },
    anicomPatient: { type: Object, default: null },
    ipetPatient: { type: Object, default: null },
    medicalRecord: { type: Object },
    medicalPayment: { type: Object },
    medicalTreatmentItems: { type: Array },
    anicomCIdCheck: { type: Object, default: null },
    ipetCheck: { type: Object, default: null },
    waitFlg: { type: Boolean, default: false },
    waitFlgForGetUploadImages: { type: Boolean, default: false },
    isCheckingApply: { type: Boolean, default: false },
    expansionFlg: { type: Boolean, default: false },
    pastHospitalizationMedicalPayments: { type: Array },
    priceDetail: { type: Object },
    hospitalizationInfo: { type: Object },
    initialMedicalPayment: { type: [Object, null], default: null },
    overlappedTimeErrorMessage: { type: String },
    hospitalizationUpdateFlg: { type: Boolean },
    removeApplyFlgError: { type: Boolean }
  },

  data() {
    return {
      treatmentItemData: {
        keys: [
          'name',
          'insuranceFlg',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headerCellStyles: treatmentItemStyles,
        bodyCellStyles: treatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 1 ? 'center' : v.textAlign }
        })
      },
      headers: [
        '診療項目内容',
        '保険',
        '単価（￥）',
        '数量',
        '割引（%）',
        '値引（￥）',
        '小計（￥）'
      ],
      headerCellStyles: treatmentItemStyles,
      insurancePopupFlg: false,
      noApplyReasonPopupFlg: false,
      onsetDateListPopupFlg: false,
      selectPopupFlg: false,
      inputPopupFlg: false,
      targetTreatmentItem: {},
      estimateListPopupFlg: false,
      announcePopupFlg: false,
      announcePopupData: {
        title: '',
        buttons: ['閉じる'],
        type: '',
        message: ''
      },
      autoHospitalizationFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      staffsSelectData: 'staffs/selectDataZero',
      getPatient: 'patients/getDataById',
      getTreatment: 'treatments/getDataById',
      getMedicine: 'medicines/getDataById'
    }),
    formStyle() {
      return this.expansionFlg ? { width: '100%' } : {}
    },
    displayPatient() {
      return this.getPatient(this.patientId) ?? {}
    },
    isAnicomApplyNg() {
      const today = moment().format('YYYYMMDD')
      const isToday = today === this.medicalRecord.date
      const dateNg = !isToday ? true : false
      const { current, same } = this.hospitalizationInfo
      const existMiddleCalculateMedicalPayment =
        current?.middleCalculateDate ||
        (same?.middleCalculateDate &&
          same.middleCalculateMedialPaymentOriginalId !==
            this.medicalPayment.originalId)
      const hospitalizationNg =
        isStart(this.medicalPayment) ||
        isIn(this.medicalPayment) ||
        (isEnd(this.medicalPayment) && existMiddleCalculateMedicalPayment)
          ? true
          : false
      return dateNg || hospitalizationNg ? true : false
    },
    isIpetDocomoType() {
      return this.medicalPayment.insuranceType === 'ipet-docomo'
    },
    isOnsetDateDisabled() {
      return (
        this.isIpetDocomoType && this.medicalPayment.uncertainOnsetFlg === 1
      )
    },
    displayTreatmentItems() {
      return this.medicalTreatmentItems.map(item => {
        const base =
          this.getTreatment(item.treatmentId) ||
          this.getMedicine(item.medicineId)
        const name = base?.name || ''
        const subtotal = calculateTreatmentItemSubtotal(
          this.medicalPayment.insuranceType,
          item
        )
        return {
          ...item,
          name,
          insuranceFlg: item.insuranceFlg === 1 ? '◯' : '✕',
          unitPrice: `￥${item.unitPrice.toLocaleString()}`,
          discountRate: `${item.discountRate.toLocaleString()} %`,
          discountPrice: `￥${item.discountPrice.toLocaleString()}`,
          subtotal: `￥${subtotal.toLocaleString()}`
        }
      })
    },
    displayPastHospitalizationMedicalPayments() {
      const medicalPayments = [...this.pastHospitalizationMedicalPayments]
      return medicalPayments
        .sort((a, b) => this.compare(a, b))
        .map(v1 => {
          const displayTreatmentItems = v1.treatmentItems.map(item => {
            const base =
              this.getTreatment(item.treatmentId) ||
              this.getMedicine(item.medicineId)
            const name = base?.name || ''
            const subtotal = calculateTreatmentItemSubtotal(
              this.medicalPayment.insuranceType,
              item
            )
            return {
              ...item,
              name,
              insuranceFlg: item.insuranceFlg === 1 ? '◯' : '✕',
              unitPrice: `￥${item.unitPrice.toLocaleString()}`,
              discountRate: `${item.discountRate.toLocaleString()} %`,
              discountPrice: `￥${item.discountPrice.toLocaleString()}`,
              subtotal: `￥${subtotal.toLocaleString()}`
            }
          })
          return { ...v1, treatmentItems: displayTreatmentItems }
        })
    },
    isPc() {
      return getDevice() === 'pc' ? true : false
    },
    isInHospital() {
      return (
        (this.medicalPayment.startHospitalizationFlg === 1 ||
          this.medicalPayment.inHospitalFlg === 1) &&
        this.medicalPayment.middleCalculateFlg === 0 &&
        this.medicalPayment.endHospitalizationFlg === 0
      )
    },
    dispPrice() {
      return price => {
        return this.isInHospital ? '' : '￥' + price
      }
    }
  },

  created() {
    if (!this.medicalPayment.id) {
      this.autoHospitalizationFlg = true
    }
  },

  watch: {
    'medicalRecord.date': function() {
      this.setHospitalization()
    },
    'medicalRecord.startTime': function() {
      this.setHospitalization()
    },
    'hospitalizationInfo.current': function() {
      this.setHospitalization()
    },
    'hospitalizationInfo.next': function() {
      this.setHospitalization()
    },
    'hospitalizationInfo.same': function() {
      this.setHospitalization()
    }
  },

  methods: {
    formatDate(date, dayFlg = true) {
      return dayFlg
        ? moment(date, 'YYYYMMDD').format('YYYY年MM月DD日（dd）')
        : moment(date, 'YYYYMMDD').format('YYYY年MM月DD日')
    },
    formatDatetime(medicalPayment) {
      const datetimeItem = medicalPayment.date
        ? medicalPayment
        : this.medicalRecord
      const date = datetimeItem.date
      const start = datetimeItem.startTime
      const end = datetimeItem.endTime
      return `${moment(date, 'YYYYMMDD').format(
        'YYYY年MM月DD日（dd）'
      )} ${moment(start, 'HHmm').format('HH:mm')} ～ ${moment(
        end,
        'HHmm'
      ).format('HH:mm')}`
    },
    inputDisease(num, idObj) {
      this.medicalPayment[`diseaseClass${num}Id`] = idObj.diseaseClassId
      this.medicalPayment[`disease${num}Id`] = idObj.diseaseId
    },
    inputApplyFlg(applyFlg) {
      this.$emit('input-apply-flg', applyFlg)
    },
    updatedInsurance() {
      this.$emit('updated-insurance')
      this.insurancePopupFlg = false
    },
    inputNoApplyReason(checkList, reason09Txt) {
      checkList.forEach(v => {
        this.medicalPayment[v.key] = v.value
      })
      this.medicalPayment.reason09Txt = reason09Txt
      this.noApplyReasonPopupFlg = false
    },
    inputOnsetDate(onsetDate) {
      this.medicalPayment.onsetDate = onsetDate
      this.onsetDateListPopupFlg = false
    },
    inputUncertainOnsetFlg(uncertainOnsetFlg) {
      this.medicalPayment.uncertainOnsetFlg = uncertainOnsetFlg
      if (uncertainOnsetFlg === 1) {
        this.medicalPayment.onsetDate = ''
      }
    },
    inputInHospitalFlg(inHospitalFlg) {
      this.$emit('input-in-hospital-flg', inHospitalFlg, false)
      if (this.autoHospitalizationFlg && inHospitalFlg === 0) {
        this.announcePopupFlg = true
        this.announcePopupData = {
          title: '注意',
          type: 'alert',
          message: '現在、患者様が入院中です。\n外来に変更してよろしいですか？',
          buttons: ['入院中', '外来'],
          cancel: () => {
            this.$emit('input-in-hospital-flg', 1, false)
            this.closeAnnouncePopup()
          },
          decision: () => {
            this.autoHospitalizationFlg = false
            this.$emit('input-in-hospital-flg', 0, true)
            this.closeAnnouncePopup()
          }
        }
      }
      if (inHospitalFlg === 1) {
        this.autoHospitalizationFlg = true
      }
    },
    inputStartHospitalizationFlg(startHospitalizationFlg) {
      this.$emit('input-start-hospitalization-flg', startHospitalizationFlg)
    },
    inputMiddleCalculateFlg(middleCalculateFlg) {
      this.$emit('input-middle-calculate-flg', middleCalculateFlg)
    },
    inputEndHospitalizationFlg(endHospitalizationFlg) {
      this.$emit('input-end-hospitalization-flg', endHospitalizationFlg)
    },
    clickItem(treatmentItem) {
      this.targetTreatmentItem = this.medicalTreatmentItems.find(
        v => v.key === treatmentItem.key
      )
      this.inputPopupFlg = true
    },
    addTreatmentItems(treatmentItems) {
      this.$emit('add-items', treatmentItems)
    },
    changeTreatmentItem(treatmentItems) {
      this.$emit('change-item', treatmentItems[0])
      this.inputPopupFlg = false
    },
    copyToMedicalPayment(estimate, estimateTreatmentItems) {
      this.$emit('copy', estimate, estimateTreatmentItems)
      this.estimateListPopupFlg = false
    },
    closeAnnouncePopup() {
      this.announcePopupFlg = false
      this.announcePopupData = {
        title: '',
        buttons: ['閉じる'],
        type: '',
        message: ''
      }
    },
    setHospitalization() {
      const { current, next, same } = this.hospitalizationInfo
      if (!same) {
        // 新規明細または外来
        const mp = this.medicalPayment
        if (current) {
          // 入院開始 <= カルテ開始 < 入院終了
          if (isStartEnd(mp)) {
            this.medicalPayment.startHospitalizationFlg = 0
            this.medicalPayment.endHospitalizationFlg = 0
          }
          if (isStart(mp)) {
            this.medicalPayment.startHospitalizationFlg = 0
            this.medicalPayment.middleCalculateFlg = 0
          }
          if (
            isIn(mp) &&
            mp.middleCalculateFlg === 1 &&
            (current.endDateTime !== '' || current.middleCalculateDate !== '')
          ) {
            this.medicalPayment.middleCalculateFlg = 0
          }
          if (isEnd(mp)) {
            if (current.endDateTime !== '') {
              this.medicalPayment.endHospitalizationFlg = 0
            } else {
              const mrEndDateTime =
                this.medicalRecord.date + this.medicalRecord.endTime
              if (mrEndDateTime < current.inEndDateTime) {
                this.medicalPayment.endHospitalizationFlg = 0
              }
            }
          }
          if (this.autoHospitalizationFlg) {
            if (!hasSameStartEnd(current) && !isHospitalization(mp)) {
              this.medicalPayment.inHospitalFlg = 1
            }
          }
        } else {
          if (next && isHospitalization(mp)) {
            this.medicalPayment.startHospitalizationFlg = 0
            this.medicalPayment.inHospitalFlg = 0
            this.medicalPayment.middleCalculateFlg = 0
            this.medicalPayment.endHospitalizationFlg = 0
          }
        }
      }
    },
    compare(a, b) {
      let result = 0
      if (a.date === b.date) {
        if (a.startTime === b.startTime) {
          result = compareMPH(a, b)
        } else {
          result = b.startTime < a.startTime ? -1 : 1
        }
      } else {
        result = b.date < a.date ? -1 : 1
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-payment-input-form {
  box-sizing: border-box;
  width: 650px;
  min-width: 650px;
  height: 881px;
  border: solid 1px #{$light-grey};
  > .validation-observer {
    > .white {
      box-sizing: border-box;
      height: 665px;
      padding: 17px 20px 15px 20px;
      background-color: #{$white};
      > .title-scale {
        display: flex;
        align-items: center;
        gap: 0 20px;
        height: 40px;
        > .title {
          font-size: 15px;
          font-weight: bold;
          text-align: left;
          color: #{$greyish-brown};
        }
        > .owner-patient {
          > .owner,
          .patient {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            > .text {
              padding-left: 1em;
            }
          }
        }
        > .hospitalization-error,
        .apply-error {
          color: #{$tomato};
          font-size: 15px;
          font-weight: bold;
        }
        > .expansion-allow,
        .shrink-allow {
          margin-left: auto;
          cursor: pointer;
        }
      }
      > .date-staff-past {
        margin-top: 10px;
        display: flex;
        align-items: center;
        height: 58px;
        background-color: #{$gray_f8};
        > .item {
          display: flex;
          align-items: center;
          > .label {
            width: 55px;
            margin-left: 10px;
            font-size: 13px;
          }
          > .value {
            margin-right: 28px;
            font-size: 16px;
          }
        }
      }
      > .diseases {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 107px;
        padding: 0 10px;
        background-color: #{$white};
      }
      > .onset {
        display: flex;
        align-items: center;
        width: 100%;
        height: 59px;
        padding: 0 10px;
        font-size: 13px;
        box-sizing: border-box;
        > .label {
          margin-right: 15px;
        }
        > .value {
          margin-right: 10px;
        }
      }
      > .surgery-hospitalization {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 0 10px;
        font-size: 13px;
        box-sizing: border-box;
        background-color: #{$gray_f8};
        > .surgery {
          display: flex;
          width: 175px;
          > .item {
            margin-right: 10px;
          }
        }
      }
      > .treatment-items {
        margin-top: 15px;
        > .header {
          box-sizing: border-box;
          display: flex;
          width: 100%;
          height: 30px;
          border: solid transparent;
          border-width: 1px 1px 0 1px;
          &.pc {
            padding-right: 17px;
          }
          > .wrapper {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 36px);
            > .cell {
              box-sizing: border-box;
              display: flex;
              width: 100%;
              line-height: 29px;
              padding: 0;
              font-size: 11px;
              text-align: center;
              font-weight: bold;
              > .text {
                flex: 1;
                width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-feature-settings: 'palt';
              }
            }
            > .cell + .cell {
              border-color: transparent;
            }
          }
        }
        > .body {
          max-height: 210px;
          overflow-y: scroll;
          overflow-x: hidden;
          > .wrapper {
            height: 100%;
            > .item {
              &.history {
                margin-top: 10px;
              }
              > .date {
                font-size: 13px;
              }
              > .items {
                > .no-items {
                  line-height: 30px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    > .pale {
      box-sizing: border-box;
      height: 215px;
      padding: 5px 20px 15px 20px;
      background-color: rgba(252, 225, 204, 0.25);
      > .bill {
        box-sizing: border-box;
        margin-top: 18px;
        display: flex;
        height: 33px;
        border: 1px solid #{$pumpkin};
        background-color: #{$white};
        > .item {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          &.owner {
            color: #{$pumpkin};
          }
          > .label {
            font-size: 13px;
            font-weight: bold;
          }
          > .value {
            font-size: 15px;
          }
        }
        > .disabled {
          background-color: #{$white_f8};
        }
        > .item ~ .item {
          border-left: 1px solid #{$pumpkin};
        }
      }
      > .buttons {
        margin-top: 18px;
        display: flex;
        justify-content: center;
        > .button ~ .button {
          margin-left: 20px;
        }
      }
    }
  }
}
::v-deep .base-button-border-orange,
::v-deep .base-button-small-orange {
  > button span {
    height: auto;
    line-height: 20px;
    font-size: 13px;
    font-weight: normal;
    display: inline;
    vertical-align: baseline;
  }
}
</style>
