<template>
  <div class="patient-medical-record-images">
    <div class="area owner-patient">
      <owner-patient-detail
        :owner="getOwner(ownerId)"
        :patient="getPatient(patientId)"
      />
    </div>
    <div class="area search">
      <search-area-image
        :textValue="searchWord"
        :selectedTagIds="selectedTagIds"
        :searchButtonFlg="false"
        v-model="searchWord"
        @select-tag="selectTag"
        @input-start-date="val => (startDate = val)"
        @input-end-date="val => (endDate = val)"
        @clear="clearDate"
        @clear-selected-tags="clearSelectedTags"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">
        検索結果：{{ filteredImages.length }}件({{ images.length }}件中)
      </div>
      <div class="order-setting">
        <div class="label">並び替え：診療日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <div class="area list">
      <dynamic-scroller
        v-if="displayDates.length > 0"
        :items="displayDates"
        :min-item-size="292"
        :buffer="10"
        key-field="key"
      >
        <template v-slot="{ item, index, active }">
          <dynamic-scroller-item
            :item="item"
            :active="active"
            :data-index="index"
          >
            <patient-medical-record-image-list-row
              v-if="getImagesByDate(item.date)"
              :date="item.date"
              :images="getImagesByDate(item.date)"
              @edit="openImageEditPopup"
            />
          </dynamic-scroller-item>
        </template>
      </dynamic-scroller>
    </div>
    <image-edit-popup
      v-if="imageEditPopupFlg"
      :image="editImage"
      @close="closeImageEditPopup"
      @update="updateImages"
    ></image-edit-popup>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import SearchAreaImage from '@/components/parts/molecules/SearchAreaImage'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import PatientMedicalRecordImageListRow from '@/components/parts/molecules/PatientMedicalRecordImageListRow'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ImageEditPopup from '@/components/popups/ImageEditPopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

export default {
  name: 'PatientMedicalRecordImages',

  components: {
    OwnerPatientDetail,
    SearchAreaImage,
    BaseSelectBox,
    PatientMedicalRecordImageListRow,
    DynamicScroller,
    DynamicScrollerItem,
    AnnouncePopup,
    ImageEditPopup
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      searchWord: '',
      selectedTagIds: [],
      images: [],
      startDate: null,
      endDate: null,
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      waitFlg: false,
      popupFlg: false,
      type: '',
      title: '',
      buttons: [],
      popupMessage: '',
      decide: () => {},
      imageEditPopupFlg: false,
      editImage: null,
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters({
      karteUploadImages: 'uploadImages/getKarteUploadImages',
      getKarteUploadImage: 'uploadImages/getKarteUploadImage',
      karteMedicalContentImages:
        'medicalContentImages/getKarteMedicalContentImages',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getImageTag: 'imageTags/getDataById',
      getMedicalContentByOriginalId: 'medicalContents/getDataByOriginalId',
      getMedicalContentsByPatientId: 'medicalContents/getDataByPatientId',
      getMedicalRecordById: 'medicalRecords/getDataById',
      getImageTextsByMedicalContentImageId:
        'medicalContentImageTexts/getDataByMedicalContentImageId',
      imageTagsIndexedById: 'imageTags/getDataIndexedById'
    }),
    uploadImages() {
      return Object.values(this.karteUploadImages).filter(
        kui => kui.patientId === this.patientId
      )
    },
    medicalContentImages() {
      return Object.values(this.karteMedicalContentImages).filter(
        kmci => kmci.patientId === this.patientId
      )
    },
    filteredImages() {
      return this.images.length > 0
        ? this.filterFunc(this.images, this.selectedTagIds)
        : []
    },
    displayDates() {
      if (this.filteredImages.length > 0) {
        const dates = new Set(this.filteredImages.map(v => v.date))
        return [...dates]
          .map((v, i) => {
            return { key: i, date: v }
          })
          .sort((a, b) => {
            return this.orderType === 0 ? b.date - a.date : a.date - b.date
          })
      } else {
        return []
      }
    },
    imagesPerDate() {
      return this.images.length > 0
        ? _.groupBy(this.filteredImages, 'date')
        : []
    }
  },

  created() {
    this.setImages()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'medicalContents/updateAfter') {
        this.setImages()
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  watch: {
    imageTagsIndexedById: {
      handler: function() {
        this.setImageTag()
      },
      deep: true
    }
  },

  methods: {
    getImagesByDate(date) {
      return this.imagesPerDate[date]
    },
    filterFunc(images, selectedTagIds) {
      return images.filter(image => {
        const imageTexts =
          image.originalId &&
          this.getImageTextsByMedicalContentImageId(image.id)
            ? this.getImageTextsByMedicalContentImageId(image.id)
            : []
        return (
          // 画像タイトルまたは画像内テキストのfilter
          (image.title.includes(this.searchWord) ||
            imageTexts.some(imageText =>
              imageText.text.includes(this.searchWord)
            )) &&
          // タグのfilter
          selectedTagIds.every(tagId => image.imageTagsIds.includes(tagId)) &&
          // 期間のfilter
          (this.startDate !== null && this.endDate !== null
            ? this.startDate <= image.date && image.date <= this.endDate
            : this.startDate !== null
            ? this.startDate <= image.date
            : this.endDate !== null
            ? image.date <= this.endDate
            : true)
        )
      })
    },
    selectTag(tagId) {
      this.selectedTagIds.includes(tagId)
        ? (this.selectedTagIds = this.selectedTagIds.filter(v => v !== tagId))
        : this.selectedTagIds.push(tagId)
    },
    clearDate() {
      this.startDate = null
      this.endDate = null
    },
    setImages() {
      const medicalContentImages = this.medicalContentImages
      const uploadImages = this.uploadImages
      const defaultImageSize = 400
      const listImageSize = 250
      const halfSize = listImageSize / defaultImageSize
      let addData = []
      const filteredMedicalContentImages = medicalContentImages.filter(
        v => v.delFlg === 0 && v.medicalContentOriginalId !== 0
      )
      if (filteredMedicalContentImages.length !== 0) {
        addData = filteredMedicalContentImages
        addData = addData.map(datum => {
          const tmp = this.getImageTextsByMedicalContentImageId(datum.id)
            ? this.getImageTextsByMedicalContentImageId(datum.id)
            : []
          const medicalContentImageTexts = tmp.map(mcit => {
            return {
              id: mcit.id,
              text: mcit.text,
              defaultTextStyle: {
                position: 'absolute',
                top: `${mcit.top}px`,
                left: `${mcit.left}px`,
                width: `${mcit.width}px`,
                height: `${mcit.height}px`,
                color: mcit.color,
                overflow: 'hidden',
                'font-size': `${mcit.size}px`,
                'white-space': 'pre-wrap',
                'z-index': 1,
                'word-break': 'break-all'
              },
              textStyle: {
                position: 'absolute',
                top: `${mcit.top * halfSize}px`,
                left: `${mcit.left * halfSize}px`,
                width: `${mcit.width * halfSize}px`,
                height: `${mcit.height * halfSize}px`,
                color: mcit.color,
                overflow: 'hidden',
                'font-size': `${mcit.size * halfSize}px`,
                'white-space': 'pre-wrap',
                'z-index': 1,
                'word-break': 'break-all'
              }
            }
          })
          const medicalContent = this.getMedicalContentByOriginalId(
            datum.medicalContentOriginalId
          )
          const medicalRecord = this.getMedicalRecordById(
            medicalContent.medicalRecordId
          )
          const uploadImage = this.getKarteUploadImage(datum.uploadImageId)
          const width = uploadImage ? uploadImage.width : 400
          const height = uploadImage
            ? uploadImage.height
            : datum.schemaImageId !== 32
            ? 400
            : 339
          return {
            ...datum,
            thumbnailImage:
              datum.schemaImageId !== 0
                ? require(`@/assets/images/schemas/${datum.schemaImageId}.jpg`)
                : datum.uploadImageId !== 0
                ? uploadImage.thumbnailImage
                : null,
            imageStyle:
              datum.schemaImageId !== 0 || datum.uploadImageId !== 0
                ? {
                    'max-width': `${listImageSize *
                      (width / defaultImageSize)}px`,
                    'max-height': `${listImageSize *
                      (height / defaultImageSize)}px`
                  }
                : {
                    'max-width': `${listImageSize}px`,
                    'max-height': `${listImageSize}px`
                  },
            defaultImageStyle: {
              'max-width': `${defaultImageSize}px`,
              'max-height': `${defaultImageSize}px`
            },
            medicalContentImageTexts,
            imageType: 'medicalContentImage',
            date: medicalRecord.date
          }
        })
      }
      let filteredUploadImages = uploadImages.filter(
        v => v.delFlg === 0 && v.medicalContentOriginalId !== 0
      )
      if (filteredUploadImages.length !== 0) {
        filteredUploadImages = filteredUploadImages.map(v => {
          const medicalContent = this.getMedicalContentByOriginalId(
            v.medicalContentOriginalId
          )
          const medicalRecord = this.getMedicalRecordById(
            medicalContent.medicalRecordId
          )
          return {
            ...v,
            imageStyle: {
              'max-width': `${listImageSize}px`,
              'max-height': `${listImageSize}px`
            },
            defaultImageStyle: {
              'max-width': `${defaultImageSize}px`,
              'max-height': `${defaultImageSize}px`
            },
            imageType: 'uploadImage',
            date: medicalRecord.date
          }
        })
        addData = addData.concat(filteredUploadImages)
      }
      if (addData.length !== 0) {
        addData = addData.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      }
      this.images = addData
      this.setImageTag()
    },
    setImageTag(tagId) {
      const makeTagNames = array => {
        return array.flatMap(id => {
          if (tagId && tagId === Number(id)) return []
          const imageTag = this.getImageTag(id)
          return imageTag && imageTag.delFlg === 0 ? imageTag.name : []
        })
      }
      this.images = this.images.map((v, i) => {
        if (!Array.isArray(v.imageTagsIds)) {
          v.imageTagsIds = v.imageTagsIds.split(',')
        }
        const tagNames =
          v.imageTagsIds.length > 0 ? makeTagNames(v.imageTagsIds) : []
        return {
          ...v,
          key: v.originalId
            ? 'medicalContentImage' + v.id
            : 'uploadImage' + v.id,
          tagNames,
          imageTagsIds: v.imageTagsIds,
          createdAt: moment(v.createdAt).format('YYYYMMDD')
        }
      })
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
      this.decide = () => {}
    },
    async openImageEditPopup(imageObj) {
      let showImage = true
      let image = null
      if (imageObj.imageType === 'medicalContentImage') {
        if (imageObj.schemaImageId) {
          image = imageObj.thumbnailImage
        } else if (imageObj.uploadImageId) {
          image = await this.getImageOfUploadImage(imageObj.uploadImageId)
        } else {
          showImage = false
        }
      } else {
        image = await this.getImageOfUploadImage(imageObj.id)
      }
      if (showImage && !image) {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '画像の取得に失敗しました'
        this.buttons = ['閉じる']
        return
      }
      this.editImage = { ...imageObj, image }
      this.imageEditPopupFlg = true
    },
    async getImageOfUploadImage(uploadImageId) {
      this.waitFlg = true
      let image = null
      const karteUploadImages = this.$store.getters[
        'uploadImages/getKarteUploadImages'
      ]
      const acquiredImage = karteUploadImages[uploadImageId]?.image
      if (acquiredImage) {
        image = acquiredImage
      } else {
        const uploadImage = await this.$store.dispatch(
          'uploadImages/get',
          uploadImageId
        )
        if (uploadImage) image = uploadImage.image
      }
      this.waitFlg = false
      return image
    },
    closeImageEditPopup() {
      this.editImage = null
      this.imageEditPopupFlg = false
    },
    updateImages(editData) {
      this.images = this.images.map(image => {
        return image.key === editData.key ? editData : image
      })
    },
    clearSelectedTags() {
      this.selectedTagIds = []
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-medical-record-images {
  display: inline-block;
  min-width: 100%;
  > .owner-patient {
    margin-top: 40px;
  }
  > .search {
    margin-top: 40px;
  }
  > .search-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .label {
        margin-right: 10px;
      }
    }
  }
}
</style>
