var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"problem-list-row"},[_c('div',{staticClass:"item name",attrs:{"title":_vm.problem.name}},[_vm._v(_vm._s(_vm.problem.name))]),_c('div',{staticClass:"item injury-date"},[_vm._v(" "+_vm._s(_vm.problem.formatInjuryDate)+" ")]),_c('div',{staticClass:"item healing-date"},[_vm._v(" "+_vm._s(_vm.problem.formatHealingDate)+" ")]),_c('div',{staticClass:"button"},[_c('base-button-small-white',{attrs:{"styles":{
        width: '40px',
        height: '25px',
        border: '1px solid #cecece'
      },"textStyles":{
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#666'
      },"disabled":_vm.sortModeFlg},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', _vm.problem.id)}}},[_vm._v("編集")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }