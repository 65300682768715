<template>
  <focus-trap
    v-model="activeTrap"
    :escapeDeactivates="false"
    :clickDeactivates="false"
  >
    <div class="kick-out-popup" @keyup.esc.stop="close" tabindex="-1">
      <announce-popup
        v-if="showFlg"
        :title="'画面強制移動'"
        :type="'alert'"
        :layerNumber="layerNumber"
        @close="close"
      >
        画面の編集権が他の端末に移動しました。<br />この画面から移動します。
      </announce-popup>
    </div>
  </focus-trap>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'KickOutPopup',

  components: {
    AnnouncePopup,
    FocusTrap
  },

  props: {
    layerNumber: { type: Number, default: 1 }
  },

  data() {
    return {
      activeTrap: false,
      showFlg: false,
      unwatch: {}
    }
  },

  computed: {
    ...mapGetters({
      lockingClientKeys: 'auth/lockingClientKeys',
      flashLockParams: 'auth/flashLockParams'
    })
  },

  watch: {
    showFlg: function() {
      this.activeTrap = this.showFlg
    },
    lockingClientKeys: function() {
      this.showPopupIfLocked()
    }
  },

  created() {
    this.watchFlashLock()
  },

  methods: {
    watchFlashLock() {
      this.unwatch.flashLockParams = this.$watch(
        'flashLockParams',
        this.showPopupIfFlashLocked
      )
    },
    showPopupIfLocked() {
      const selfLockParams = this.$store.getters['auth/selfLockParams']
      const isLockingClientChanged = this.$store.getters[
        'auth/isAnotherClientLocking'
      ](selfLockParams)
      if (isLockingClientChanged) {
        this.showFlg = true
      }
    },
    showPopupIfFlashLocked() {
      const selfLockParams = this.$store.getters['auth/selfLockParams']
      const isFlashLocked = this.$store.getters['auth/isFlashLocked'](
        selfLockParams
      )
      if (isFlashLocked) {
        this.showFlg = true
      }
      this.resetFlashLockParams()
    },
    resetFlashLockParams() {
      // flashLockParams を初期化するため一時的に監視を解除
      this.unwatch.flashLockParams()
      this.$store.dispatch('auth/setFlashLockParams', {
        patientId: 0,
        medicalRecordOriginalId: 0,
        hospitalizationId: 0
      })
      this.watchFlashLock()
    },
    close() {
      this.$store.dispatch('petorelu/okLeave')
      this.$router.push('/main')
    }
  }
}
</script>
<style lang="scss" scoped></style>
