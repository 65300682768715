<template>
  <div class="patient-medical-record-image-list-row">
    <div class="date">
      <p>{{ displayDate }}</p>
    </div>
    <div class="list">
      <div class="row" v-for="imageObj in images" :key="imageObj.key">
        <div
          class="image-frame"
          @click="$emit('edit', imageObj)"
          data-test="image-frame"
        >
          <span
            v-for="textObj in imageObj.medicalContentImageTexts"
            :key="textObj.id"
            :style="textObj.textStyle"
            >{{ textObj.text }}</span
          >
          <div class="image-area">
            <img
              class="under-image"
              :src="imageObj.thumbnailImage"
              :style="imageObj.imageStyle"
              data-test="under-image"
            />
            <img
              v-if="imageObj.overImage"
              class="over-image"
              :src="imageObj.overImage"
            />
          </div>
        </div>
        <div class="title" :title="imageObj.title">{{ imageObj.title }}</div>
        <div class="tags">
          <div class="tag" v-for="(tagName, i) in imageObj.tagNames" :key="i">
            <span>{{ `# ${tagName}` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PatientMedicalRecordImageListRow',

  props: {
    date: { type: String },
    images: { type: Array }
  },

  computed: {
    displayDate() {
      return moment(this.date, 'YYYYMMDD').format('YYYY.MM.DD')
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-medical-record-image-list-row {
  > .date {
    display: flex;
    align-items: center;
    &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      display: block;
      margin-left: 0.4em;
      background: #{$light-grey};
    }
  }
  > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 0 40px;
    > .row {
      position: relative;
      > .image-frame {
        width: 250px;
        height: 250px;
        position: relative;
        @include hover();
        > .image-area {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          width: 250px;
          height: 250px;
          > img {
            width: auto;
            height: auto;
            vertical-align: top;
          }
          > .over-image {
            max-width: 250px;
            max-height: 250px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      > .title {
        width: 250px;
        font-size: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      > .tags {
        width: 250px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px 10px;
        > .tag {
          display: inline-block;
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          color: #{$gray};
          border: 1px solid #{$gray};
          padding: 0 3px;
          border-radius: 50px;
        }
      }
    }
  }
}
</style>
