<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="owner-patient-list-popup"
      @keyup.esc.stop="closePopup"
      tabindex="-1"
    >
      <popup
        :title="'患者選択'"
        :buttons="['閉じる']"
        :contentStyle="{}"
        @close="closePopup"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="area search">
              <search-area
                v-if="!isDetail"
                :placeholder="'飼主ID、飼主名、患者ID、患者名、住所'"
                :textBoxLabel="'検索単語'"
                :textValue="searchWord"
                :textBoxStyles="{ width: '300px' }"
                :toggleDetailSearchFlg="true"
                :searchButtonFlg="true"
                @input="inputSearchWord"
                @show-detail="toggleIsDetail"
                @search="search"
              />
              <search-area-detail
                v-if="isDetail"
                :selectBoxData="detailSelectBoxData"
                :searchConditions="searchConditions"
                :searchButtonFlg="true"
                @hide-detail="toggleIsDetail"
                @select="setSelectId"
                @input-text="inputText(1, $event)"
                @input-text2="inputText(2, $event)"
                @add-search-condition="addSearchCondition"
                @trash="deleteSearchCondition"
                @search="search"
              />
            </div>
            <div class="area search-order">
              <div class="order-result">
                検索結果： {{ orderedPatients.length }}件(
                {{ patients.length }}件中)
              </div>
              <div class="order-setting">
                <div class="label">並び替え</div>
                <base-select-box
                  class="select-sort-type"
                  :selectData="sortTypesSelectData"
                  v-model="sortType"
                />
                <base-select-box
                  :selectData="orderTypesSelectData"
                  v-model="orderType"
                />
              </div>
            </div>
            <div class="area list">
              <dynamic-scroller
                class="table-body"
                v-if="orderedPatients.length > 0"
                :items="groupedPatients"
                :min-item-size="minItemSize"
                :buffer="buffer"
                key-field="ownerId"
              >
                <template v-slot="{ item, index, active }">
                  <dynamic-scroller-item
                    :item="item"
                    :active="active"
                    :data-index="index"
                  >
                    <owner-patient-list-item
                      class="item"
                      :patientGroup="item.patientGroup"
                      :patientImagesByPatientId="patientImagesByPatientId"
                      @intersect="intersect(item.ownerId)"
                  /></dynamic-scroller-item>
                </template>
              </dynamic-scroller>
              <div v-else>対象のデータはありませんでした</div>
            </div>
            <announce-popup
              v-if="announcePopup.show"
              :type="announcePopup.type"
              :title="announcePopup.title"
              :buttons="announcePopup.buttons"
              :layerNumber="2"
              @close="closeAnnouncePopup"
              >{{ announcePopup.message }}</announce-popup
            >
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import OwnerPatientListItem from '@/components/parts/molecules/OwnerPatientListItem'
import Popup from '@/components/popups/Popup'
import SearchArea from '@/components/parts/molecules/SearchArea'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { kataToHira, hiraToKata } from '@/utils/convert_string'
import { compareShowId } from '@/utils/sort_show_id'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import { getAxiosObject } from '@/utils/library'
import { decodeBase64fromBuffer } from '@/utils/base64'
import moment from 'moment'

export default {
  name: 'OwnerPatientListPopup',

  components: {
    BaseSelectBox,
    DynamicScroller,
    DynamicScrollerItem,
    FocusTrap,
    OwnerPatientListItem,
    Popup,
    SearchArea,
    SearchAreaDetail,
    AnnouncePopup
  },

  data() {
    return {
      groupedPatients: [],
      orderedPatients: [],
      searchWord: '',
      sortType: 0,
      sortTypesSelectData: [
        { id: 0, name: '飼主ID順' },
        { id: 1, name: '飼主名順' },
        { id: 2, name: '住所順' }
      ],
      orderType: 0,
      orderTypesSelectData: [
        { id: 0, name: '昇順' },
        { id: 1, name: '降順' }
      ],
      isDetail: false,
      detailSelectBoxData: [
        { id: 1, name: '飼主名', type: 'textBox' },
        { id: 2, name: '患者名', type: 'textBox' },
        { id: 3, name: '飼主ID', type: 'textBox' },
        { id: 4, name: '患者ID', type: 'textBox' },
        { id: 5, name: '住所', type: 'textBox' },
        { id: 12, name: '電話番号', type: 'textBox' },
        { id: 13, name: '患者の性別', type: 'selectBox' },
        { id: 14, name: '患者の種別', type: 'selectBox' },
        { id: 15, name: '患者の品種', type: 'textBox' },
        { id: 17, name: '患者の年齢', type: 'textBoxes' },
        { id: 18, name: '患者の主治医', type: 'selectBox' },
        { id: 19, name: '最終診療日', type: 'datePicker' }
      ],
      searchConditions: [{ id: 1, selectId: 0, text: '', text2: '' }],
      minItemSize: 100,
      buffer: 10,
      intersectionTimeoutId: null,
      acquiredOwnerIds: new Set(),
      patientImagesByPatientId: {},
      announcePopup: {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      patients: 'patients/getData',
      owners: 'owners/getData',
      getOwnerById: 'owners/getDataById',
      getMasterDatum: 'master/getDataById',
      prefectures: 'master/getPrefectures',
      getMedicalRecordsByPatientId: 'medicalRecords/getDataByPatientId'
    })
  },

  watch: {
    owners: function() {
      this.groupedPatients = this.makeGroupedPatients()
    },
    patients: function() {
      this.groupedPatients = this.makeGroupedPatients()
    },
    sortType: function() {
      this.groupedPatients = this.makeGroupedPatients()
    },
    orderType: function() {
      this.groupedPatients = this.makeGroupedPatients()
    }
  },

  created() {
    this.groupedPatients = this.makeGroupedPatients()
  },

  beforeDestroy() {
    clearTimeout(this.intersectionTimeoutId)
  },

  methods: {
    search() {
      this.groupedPatients = this.makeGroupedPatients()
    },
    makeGroupedPatients() {
      const orderedPatients = this.makeOrderedPatients()
      this.orderedPatients = orderedPatients
      if (orderedPatients.length === 0) return []
      else {
        let groupedPatients = []
        let ownerId = orderedPatients[0].ownerId
        let patientGroup = []
        orderedPatients.forEach(p => {
          if (p.ownerId === ownerId) {
            patientGroup.push(p)
          } else {
            groupedPatients.push({ ownerId, patientGroup })
            ownerId = p.ownerId
            patientGroup = [p]
          }
        })
        groupedPatients.push({ ownerId, patientGroup })
        return groupedPatients
      }
    },
    makeOrderedPatients() {
      const sortedPatients = this.makeSortedPatients().slice()
      return this.orderType === 1 ? sortedPatients.reverse() : sortedPatients
    },
    makeSortedPatients() {
      const list = this.makeFilteredPatients().slice()
      switch (this.sortType) {
        case 1: // 飼主名順
          return list.sort((a, b) => {
            if (a.ownerLastNameKana === b.ownerLastNameKana) {
              if (a.ownerLastName === b.ownerLastName) {
                if (a.ownerFirstNameKana === b.ownerFirstNameKana) {
                  return a.ownerFirstName < b.ownerFirstName ? -1 : 1
                } else {
                  return a.ownerFirstNameKana < b.ownerFirstNameKana ? -1 : 1
                }
              } else {
                return a.ownerLastName < b.ownerLastName ? -1 : 1
              }
            } else {
              return a.ownerLastNameKana < b.ownerLastNameKana ? -1 : 1
            }
          })
        case 2: // 住所順
          return list.sort((a, b) => {
            if (a.prefectureId && !b.prefectureId) {
              return -1
            } else if (!a.prefectureId && b.prefectureId) {
              return 1
            } else {
              if (a.prefectureId === b.prefectureId) {
                if (a.address === b.address) {
                  if (a.building === b.building) {
                    return compareShowId(a.ownerShowId, b.ownerShowId)
                  } else {
                    return a.building < b.building ? -1 : 1
                  }
                } else {
                  return a.address < b.address ? -1 : 1
                }
              } else {
                return a.prefectureId < b.prefectureId ? -1 : 1
              }
            }
          })
        default:
          // 飼主ID順
          return list.sort((a, b) =>
            compareShowId(a.ownerShowId, b.ownerShowId)
          )
      }
    },
    makeFilteredPatients() {
      const patientData = this.makePatientData()
      if (this.isDetail) {
        return this.searchConditions.reduce((patients, searchCondition) => {
          return patients.filter(v =>
            this.filterBySearchCondition(v, searchCondition)
          )
        }, patientData)
      } else {
        const searchTerm = this.searchWord.replace(/\s+/g, '')
        return patientData.filter(v => {
          return (
            v.ownerShowId.includes(searchTerm) ||
            v.ownerFullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            v.ownerFullNameKana.includes(searchTerm) ||
            kataToHira(v.ownerFullNameKana).includes(searchTerm) ||
            v.noNumFullAddress.includes(searchTerm) ||
            v.showId.includes(searchTerm) ||
            v.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            v.nameKana.includes(searchTerm) ||
            kataToHira(v.nameKana).includes(searchTerm)
          )
        })
      }
    },
    makePatientData() {
      return this.patients.map(p => {
        const owner = this.getOwnerById(p.ownerId)
        const ownerData = this.makeOwnerData(owner)
        return {
          ...ownerData,
          id: p.id,
          showId: p.showId,
          name: p.name,
          nameKana: p.nameKana,
          speciesId: p.speciesId,
          sex: p.sex,
          breed: p.breed,
          birthday: p.birthday,
          deathDate: p.deathDate,
          staffId: p.staffId,
          lastTreatmentDate: this.makeLastTreatmentDate(p)
        }
      })
    },
    makeOwnerData(owner) {
      const prefName =
        this.getMasterDatum('prefectures', owner.prefectureId)?.name || ''
      const fullAddress = prefName + owner.address + owner.building
      return {
        ownerId: owner.id,
        ownerShowId: owner.showId,
        ownerFirstName: owner.firstName,
        ownerLastName: owner.lastName,
        ownerFullName: owner.lastName + owner.firstName,
        ownerFirstNameKana: owner.firstNameKana,
        ownerLastNameKana: owner.lastNameKana,
        ownerFullNameKana: owner.lastNameKana + owner.firstNameKana,
        tel: owner.tel,
        prefectureId: owner.prefectureId,
        address: owner.address,
        building: owner.building,
        fullAddress,
        noNumFullAddress: fullAddress.replace(/[0-9０-９]/g, '')
      }
    },
    makeLastTreatmentDate(patient) {
      const records = this.getMedicalRecordsByPatientId(patient.id)
      let lastDate = ''
      records?.forEach(r => {
        if (r.date > lastDate && r.date <= moment().format('YYYYMMDD')) {
          lastDate = r.date
        }
      })
      return lastDate
    },
    filterBySearchCondition(patient, searchCondition) {
      const selectId = searchCondition.selectId
      const searchText =
        typeof searchCondition.text === 'string'
          ? searchCondition.text.toLowerCase().replace(/\s+/g, '')
          : searchCondition.text
      const searchText2 = searchCondition.text2
        .toLowerCase()
        .replace(/\s+/g, '')
      return selectId === 1
        ? this.filterByOwnerName(patient, searchText)
        : selectId === 2
        ? this.filterByPatientName(patient, searchText)
        : selectId === 3
        ? this.filterByProp('ownerShowId', patient, searchText)
        : selectId === 4
        ? this.filterByProp('showId', patient, searchText)
        : selectId === 5
        ? this.filterByProp('fullAddress', patient, searchText)
        : selectId === 12
        ? this.filterByProp('tel', patient, searchText)
        : selectId === 13
        ? this.filterByProp('sex', patient, searchText)
        : selectId === 14
        ? this.filterByProp('speciesId', patient, searchText)
        : selectId === 15
        ? this.filterByBreed(patient, searchText)
        : selectId === 17
        ? this.filterByAge(patient, searchText, searchText2)
        : selectId === 18
        ? this.filterByProp('staffId', patient, searchText)
        : selectId === 19
        ? this.filterByLastTreatmentDate(patient, searchText)
        : true
    },
    filterByOwnerName(patient, searchText) {
      return (
        patient.ownerFullName.toLowerCase().includes(searchText) ||
        patient.ownerFullNameKana.includes(searchText) ||
        kataToHira(patient.ownerFullNameKana).includes(searchText)
      )
    },
    filterByPatientName(patient, searchText) {
      return (
        patient.name.toLowerCase().includes(searchText) ||
        patient.nameKana.includes(searchText) ||
        kataToHira(patient.nameKana).includes(searchText)
      )
    },
    filterByProp(propName, patient, searchText) {
      return typeof searchText === 'string'
        ? patient[propName].includes(searchText)
        : patient[propName] === searchText
    },
    filterByBreed(patient, searchText) {
      return (
        patient.breed.toLowerCase().includes(searchText) ||
        kataToHira(patient.breed).includes(searchText) ||
        hiraToKata(patient.breed).includes(searchText)
      )
    },
    filterByAge(patient, searchText, searchText2) {
      if (searchText === '' && searchText2 === '') return true
      else {
        const youngAge = Number(searchText)
        const oldAge = Number(searchText2)
        const latestDate = patient.deathDate
          ? moment(patient.deathDate)
          : moment()
        let age
        if (patient.birthday.length === 8 || patient.birthday.length === 6) {
          age =
            latestDate.diff(moment(patient.birthday), 'years') > 0
              ? latestDate.diff(moment(patient.birthday), 'years')
              : 0
        } else if (patient.birthday.length === 4) {
          age = latestDate.year() - moment(patient.birthday).year()
        }
        if (
          youngAge === 0 &&
          oldAge === 0 &&
          searchText === '0' &&
          searchText2 === '0'
        ) {
          return age === 0 ? true : false
        } else if (!oldAge) {
          return youngAge <= age ? true : false
        } else if (oldAge) {
          return youngAge <= age && age <= oldAge ? true : false
        }
      }
    },
    filterByLastTreatmentDate(patient, searchText) {
      const startDate = searchText.slice(0, 8)
      const endDate = searchText.slice(9, 17)
      return startDate !== '' && endDate !== ''
        ? startDate <= patient.lastTreatmentDate &&
            patient.lastTreatmentDate <= endDate
        : startDate !== ''
        ? startDate <= patient.lastTreatmentDate
        : endDate !== ''
        ? patient.lastTreatmentDate <= endDate
        : true
    },
    inputSearchWord(text) {
      this.searchWord = text
    },
    toggleIsDetail() {
      this.searchWord = ''
      this.searchConditions = [{ id: 1, selectId: 0, text: '', text2: '' }]
      this.isDetail = !this.isDetail
    },
    setSelectId(idIndex) {
      const targetSearchCondition = this.searchConditions[idIndex.index]
      targetSearchCondition.selectId = idIndex.id
      if (idIndex.id === 13 || idIndex.id === 14 || idIndex.id === 18) {
        targetSearchCondition.text = 0
      } else targetSearchCondition.text = ''
      targetSearchCondition.text2 = ''
      this.$set(this.searchConditions, idIndex.index, targetSearchCondition)
    },
    setText(num, textObj) {
      const text = num === 1 ? 'text' : `text${num}`
      const targetSearchCondition = this.searchConditions[textObj.index]
      targetSearchCondition[text] = textObj.text
      this.$set(this.searchConditions, textObj.index, targetSearchCondition)
    },
    inputText(num, textObj) {
      this.setText(num, textObj)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({ id, selectId: 0, text: '', text2: '' })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    closePopup() {
      this.$emit('close')
    },
    closeAnnouncePopup() {
      this.announcePopup.show = false
      this.$emit('close')
    },
    intersect(ownerId) {
      const index = this.groupedPatients.findIndex(v => v.ownerId === ownerId)
      clearTimeout(this.intersectionTimeoutId)
      const delay = 300
      this.intersectionTimeoutId = setTimeout(async () => {
        await this.getImages(index)
      }, delay)
    },
    async getImages(index) {
      try {
        const beforeAfter = 5
        const startIndex = Math.max(0, index - beforeAfter)
        const endIndex = Math.min(
          startIndex + 2 * beforeAfter,
          this.groupedPatients.length
        )
        let unObtainedPatientIds = []
        for (let i = startIndex; i < endIndex; i++) {
          const ownerId = this.groupedPatients[i].ownerId
          if (!this.acquiredOwnerIds.has(ownerId)) {
            const ownerPatientIds = this.groupedPatients[i].patientGroup.map(
              v => v.id
            )
            unObtainedPatientIds = unObtainedPatientIds.concat(ownerPatientIds)
            this.acquiredOwnerIds.add(ownerId)
          }
        }
        if (unObtainedPatientIds.length !== 0) {
          const axiosObject = getAxiosObject()
          const res = await axiosObject.get('/patients/image/medical-records', {
            params: { patientIds: unObtainedPatientIds }
          })
          if (res.data.patients.length !== 0) {
            this.setImages(res.data.patients)
          }
        }
      } catch (err) {
        this.announcePopup = {
          show: true,
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message: '画像取得時に通信エラーが発生しました。'
        }
      }
    },
    setImages(patients) {
      const decodedPatients = decodeBase64fromBuffer(patients)
      decodedPatients.forEach(v => {
        if (v.image) this.$set(this.patientImagesByPatientId, v.id, v.image)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-patient-list-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    flex-direction: column;
    > .search {
      padding-right: 25px;
      min-width: 1100px;
    }
    > .search-order {
      min-width: 1100px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      > .order-setting {
        display: flex;
        align-items: center;
        > .label,
        .select-sort-type {
          margin-right: 10px;
        }
      }
    }
    > .list {
      min-width: 1100px;
      margin-top: 20px;
      flex-grow: 1;
      overflow: auto;
      .table-body {
        height: 430px;
        .item {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
