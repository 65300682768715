import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      mixinProblemListPopupFlg: false,
      mixinExaminationListPopupFlg: false,
      mixinExaminationPopupFlg: false,
      mixinChangeExaminationResultGroupId: 0,
      mixinChangeResultTreatment: null,
      mixinChangeResultFormat: null,
      mixinExaminationTableCursorPosition: 0,
      mixinWaitFlg: false,
      mixinAlertFlg: false,
      mixinType: '',
      mixinTitle: '',
      mixinPopupMessage: '',
      mixinButtons: ['閉じる'],
      mixinPopupResolve: null,
      mixinNoDataFlg: false,
      mixinToRecordNewFlg: false,
      mixinToOwnerShowFlg: false,
      mixinUploadImagePopupFlg: false,
      mixinSchemaPopupFlg: false,
      mixinMedicalContentImageId: 0,
      mixinMedicalContentTemplatesPopupFlg: false,
      mixinMedicalContentsHistoryFootPrintFlg: false,
      mixinMovedStyleMedicalContentsHistory: {},
      mixinMedicalPaymentsHistoryFootPrintFlg: false,
      mixinMovedStyleMedicalPaymentsHistory: {},
      mixinExpansionMedicalContentInputFormFlg: false,
      mixinExpansionMedicalPaymentInputFormFlg: false,
      mixinMedicalContentInputFormShowFlg: true,
      mixinMedicalPaymentInputFormShowFlg: true,
      mixinMedicalPaymentsHistoryShowFlg: true,
      mixinMedicalContentsHistoryShowFlg: true,
      mixinMedicalPaymentsHistoryFlg: false,
      mixinClearMargin: {},
      mixinMedicalContentsHistoryFlg: false,
      mixinMedicalContentsHistoryMovedFlg: false,
      mixinMedicalPaymentsHistoryMovedFlg: false,
      mixinMailFlg: true
    }
  },
  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      reservationSetting: 'reservationSetting/getData'
    }),
    mixinOwner() {
      return this.getOwner(this.ownerId) ?? {}
    },
    mixinContentInfo() {
      return {
        content: this.$store.getters['richTextEditor/getContent'],
        contentJsonObj: this.$store.getters['richTextEditor/getJson']
      }
    }
  },
  watch: {
    mixinContentInfo: function(newContentInfo) {
      this.medicalContent.content = newContentInfo.content
      const examinationResultGroupIds = newContentInfo.contentJsonObj.content
        .filter(v => v.type === 'VueRichTextEditorExaminationTable')
        .map(v => v.attrs.examinationResultGroupId)
      if (
        examinationResultGroupIds.toString() !==
        Object.keys(this.examinationResultGroups).toString()
      ) {
        const examinationResultGroups = {}
        examinationResultGroupIds.forEach(v => {
          const addDatum = this.examinationResultGroupsIncludeDel[v]
          examinationResultGroups[
            addDatum[0].examinationResultGroupId
          ] = addDatum
        })
        this.examinationResultGroups = examinationResultGroups
        this.$store.dispatch(
          'examinationResults/setNewExaminationResultGroups',
          examinationResultGroups
        )
        this.mixinInputData.examinationResultGroups = examinationResultGroups
      }
    }
  },
  methods: {
    mixinInputMedicalRecord(data) {
      if (data.value) {
        if (data.field === 'date') {
          this.$set(this.medicalRecord, 'date', data.value)
        }
        if (data.field === 'startTime') {
          this.$set(this.medicalRecord, 'startTime', data.value)
        }
        if (data.field === 'endTime') {
          this.$set(this.medicalRecord, 'endTime', data.value)
        }
      }
    },
    mixinShowKarteRelatedContent(menuItemName) {
      if (menuItemName === 'プロブレムリスト') {
        this.mixinProblemListPopupFlg = true
      } else if (menuItemName === '検査登録') {
        this.mixinExaminationListPopupFlg = true
      } else if (menuItemName === '画像登録') {
        this.mixinUploadImagePopupFlg = true
      } else if (menuItemName === 'シェーマ') {
        this.mixinMedicalContentImageId = 0
        this.mixinSchemaPopupFlg = true
      } else if (menuItemName === 'テンプレート') {
        this.mixinMedicalContentTemplatesPopupFlg = true
      } else if (menuItemName === 'カルテの履歴') {
        this.mixinMedicalContentsHistoryFlg = !this
          .mixinMedicalContentsHistoryFlg
      }
    },
    mixinClickExaminationResultTable(tableInfo) {
      const format = this.$store.getters['examinationFormats/getDataById'](
        tableInfo.format.id
      )
      this.mixinExaminationTableCursorPosition =
        tableInfo.examinationTableCursorPosition
      if (format.delFlg === 0) {
        this.mixinChangeExaminationResultGroupId =
          tableInfo.examinationResultGroupId
        this.mixinChangeResultTreatment = tableInfo.treatment
        this.mixinChangeResultFormat = tableInfo.format
        this.mixinExaminationPopupFlg = true
      } else {
        this.mixinType = 'alert'
        this.mixinTitle = '注意'
        this.mixinPopupMessage =
          '検査フォーマットが変更されたので編集できません'
        this.mixinAlertFlg = true
        this.$store.dispatch(
          'richTextEditor/moveCursorPosition',
          this.mixinExaminationTableCursorPosition
        )
      }
    },
    mixinSetExaminationResults(examinationResultGroup) {
      this.$store.dispatch(
        'examinationResults/changeNewExaminationResultGroup',
        examinationResultGroup
      )
      this.$store.dispatch(
        'richTextEditor/examinationTable',
        examinationResultGroup[0].examinationResultGroupId
      )
      this.examinationResultGroups[
        examinationResultGroup[0].examinationResultGroupId
      ] = examinationResultGroup
      this.examinationResultGroupsIncludeDel[
        examinationResultGroup[0].examinationResultGroupId
      ] = examinationResultGroup
      this.mixinExaminationListPopupFlg = false
    },
    mixinCloseExaminationPopup() {
      this.mixinExaminationPopupFlg = false
      this.mixinChangeExaminationResultGroupId = 0
      this.mixinChangeResultTreatment = null
      this.mixinChangeResultFormat = null
      this.$store.dispatch(
        'richTextEditor/moveCursorPosition',
        this.mixinExaminationTableCursorPosition
      )
    },
    mixinChangeExaminationResults(examinationResultGroup) {
      this.$store.dispatch(
        'examinationResults/changeNewExaminationResultGroup',
        examinationResultGroup
      )
      this.examinationResultGroups[
        examinationResultGroup[0].examinationResultGroupId
      ] = examinationResultGroup

      this.examinationResultGroupsIncludeDel[
        examinationResultGroup[0].examinationResultGroupId
      ] = examinationResultGroup
      this.mixinExaminationPopupFlg = false
      this.mixinChangeExaminationResultGroupId = 0
      this.mixinChangeResultTreatment = null
      this.mixinChangeResultFormat = null
      this.$store.dispatch(
        'richTextEditor/moveCursorPosition',
        this.mixinExaminationTableCursorPosition
      )
    },
    mixinFindDeleteExaminationResults() {
      let deleteExaminationResults = []
      Object.keys(this.examinationResultGroupsIncludeDel).forEach(
        examinationResultGroupId => {
          if (
            !this.examinationResultGroups[examinationResultGroupId] &&
            this.examinationResultGroupsIncludeDel[
              examinationResultGroupId
            ].every(v => v.id)
          ) {
            deleteExaminationResults = deleteExaminationResults.concat(
              this.examinationResultGroupsIncludeDel[examinationResultGroupId]
            )
          }
        }
      )
      deleteExaminationResults = deleteExaminationResults.map(v => {
        return { ...v, delFlg: 1 }
      })
      return deleteExaminationResults
    },
    mixinGetRequestBody() {
      const resizedUploadImages = this.$store.getters[
        'uploadImages/getResizedUploadImages'
      ]
      const resizedMedicalContentImages = this.$store.getters[
        'medicalContentImages/getResizedMedicalContentImages'
      ]
      this.medicalPayment.burdenAmount = Number(
        this.mixinPriceDetail.ownerBill.replace(/,/g, '')
      )
      this.mixinSetMedicalTreatmentItemsSubtotals()
      this.mixinAdjustOnsetValues()
      const anicomReports = this.anicomReportsByMedicalPaymentId(
        this.medicalPayment.id
      )
      const anicomReport = anicomReports?.length > 0 ? anicomReports[0] : null
      return {
        medicalRecord: this.medicalRecord,
        medicalContent: this.medicalContent,
        contentJsonObj: this.mixinContentInfo.contentJsonObj,
        examinationResultGroups: this.examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults: this.mixinFindDeleteExaminationResults(),
        medicalPayment: this.medicalPayment,
        medicalTreatmentItems: this.medicalTreatmentItems,
        anicomReport,
        mailFlg: this.mixinMailFlg
      }
    },
    mixinSetResData(res) {
      if (res.medicalContent) {
        this.mixinSetResDataRelatedToMedicalContent(
          res.medicalRecord,
          res.medicalContent,
          res.examinationResults
        )
        this.mixinResetEditor()
      }
      if (res.medicalPayment) {
        this.mixinSetResDataRelatedToMedicalPayment(
          res.medicalRecord,
          res.medicalPayment,
          res.medicalTreatmentItems
        )
      }
    },
    async mixinCreateMedicalContent() {
      const dateTimeDiff = ['date', 'startTime', 'endTime'].some(
        v => this.mixinInitialData.medicalRecord[v] !== this.medicalRecord[v]
      )
      if (this.medicalPayment.id && dateTimeDiff) {
        const { validFlg, errorMessage } = this.mixinCheckValidity()
        if (!validFlg) {
          this.type = 'failure'
          this.title = '失敗'
          this.popupMessage =
            '診療日時が変更された為、診療明細の更新が必要です。\n 以下を変更して再度登録してください。'
          this.leftAlignMessage = errorMessage
          this.buttons = ['閉じる']
          this.popupFlg = true
          return
        }
      }
      const lockParamsBeforeRes = this.mixinGetLockParams()
      this.waitFlg = true
      const {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      } = this.mixinGetRequestBody()
      const res = await this.$store.dispatch('medicalContents/create', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.result === true) {
        this.mixinType = 'success'
        this.mixinTitle = '完了'
        this.mixinPopupMessage = '診療内容を登録しました。'
        if (res.medicalPayment) {
          this.mixinPopupMessage +=
            '\n診療日時が変更された為、診療明細を編集しました。'
        }
        if (
          Object.keys(res.reservation).length !== 0 &&
          res.sendMailFlg &&
          medicalRecord.reservationId === 0
        ) {
          this.mixinPopupMessage += '\n予約を作成し、メールを送信しました。'
        } else if (
          Object.keys(res.reservation).length !== 0 &&
          res.sendMailFlg &&
          medicalRecord.reservationId !== 0
        ) {
          this.mixinPopupMessage += '\n予約を変更し、メールを送信しました。'
        }
        this.mixinSetResData(res)
        this.$store.dispatch(
          'medicalRecords/setOriginalIdSetInTab',
          res.medicalRecord.originalId
        )
        this.mixinSetHospitalizationHistory()
        this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
        const lockParamsAfterRes = this.mixinGetLockParams()
        this.mixinLockKarteIfChanged(lockParamsBeforeRes, lockParamsAfterRes)
      } else {
        this.mixinType = 'failure'
        this.mixinTitle = '失敗'
        if (res === 'no reservation') {
          this.mixinPopupMessage =
            '診療内容登録に失敗しました。\n予約が既にキャンセルまたは削除されています。\nメイン画面へ移動します。'
          this.mixinNoDataFlg = true
        } else if (res === 'no patient') {
          this.mixinPopupMessage =
            '診療内容登録に失敗しました。\nこのカルテの患者は既に削除されています。\nメイン画面へ移動します。'
          this.mixinNoDataFlg = true
        } else if (res === 'no medicalRecord') {
          this.mixinPopupMessage =
            '診療内容登録に失敗しました。\n編集中のカルテは既に削除されています。\nメイン画面へ移動します。'
          this.mixinNoDataFlg = true
        } else if (res === 'patient is dead') {
          this.mixinPopupMessage =
            '診療内容登録に失敗しました。\n診療日は患者の「死亡日まで」で設定して下さい。'
        } else if (res.includes('invalid hospitalization')) {
          const base = '診療内容登録に失敗しました。\n'
          this.mixinPopupMessage = this.mixinHospitalizationErrMsg(base, res)
        } else if (res === 'insuranceType already changed') {
          this.mixinPopupMessage =
            '診療内容登録に失敗しました。\n患者の保険設定が変更されているため診療明細に表示されている金額が正しくない可能性があります。\n患者の保険設定を更新しましたので内容をご確認の上、再度登録をお願いします。'
          this.mixinShouldUpdateInsurance = true
        } else {
          this.mixinPopupMessage = '診療内容登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.mixinAlertFlg = true
    },
    async mixinUpdateMedicalContent() {
      const dateTimeDiff = ['date', 'startTime', 'endTime'].some(
        v => this.mixinInitialData.medicalRecord[v] !== this.medicalRecord[v]
      )
      if (this.medicalPayment.id && dateTimeDiff) {
        const { validFlg, errorMessage } = this.mixinCheckValidity()
        if (!validFlg) {
          this.type = 'failure'
          this.title = '失敗'
          this.popupMessage =
            '診療日時が変更された為、診療明細の更新が必要です。\n 以下を変更して再度登録してください。'
          this.leftAlignMessage = errorMessage
          this.buttons = ['閉じる']
          this.popupFlg = true
          return
        }
      }
      const lockParamsBeforeRes = this.mixinGetLockParams()
      this.waitFlg = true
      const {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      } = this.mixinGetRequestBody()
      const res = await this.$store.dispatch('medicalContents/update', {
        medicalRecord,
        medicalContent,
        contentJsonObj,
        examinationResultGroups,
        resizedUploadImages,
        resizedMedicalContentImages,
        deleteExaminationResults,
        medicalPayment,
        medicalTreatmentItems,
        anicomReport,
        mailFlg
      })
      if (res.result === true) {
        this.mixinType = 'success'
        this.mixinTitle = '完了'
        this.mixinPopupMessage = '診療内容を編集しました。'
        if (res.medicalPayment) {
          this.mixinPopupMessage +=
            '\n診療日時が変更された為、診療明細を編集しました。'
        }
        if (Object.keys(res.reservation).length !== 0 && res.sendMailFlg) {
          this.mixinPopupMessage += '\n予約を変更し、メールを送信しました。'
        }
        this.mixinSetResData(res)
        this.mixinSetHospitalizationHistory()
        this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
        const lockParamsAfterRes = this.mixinGetLockParams()
        this.mixinLockKarteIfChanged(lockParamsBeforeRes, lockParamsAfterRes)
      } else {
        this.mixinType = 'failure'
        this.mixinTitle = '失敗'
        if (res === 'no medicalRecord') {
          this.mixinPopupMessage =
            '診療内容編集に失敗しました。\n編集中のカルテは既に削除されています。\nメイン画面へ移動します。'
          this.mixinNoDataFlg = true
        } else if (res === 'no medicalContent') {
          this.mixinPopupMessage =
            '診療内容編集に失敗しました。\n編集中の診療内容は既に削除されています。'
        } else if (res === 'no reservation') {
          this.mixinPopupMessage =
            '診療内容編集に失敗しました。\n予約が既にキャンセルまたは削除されています。\nメイン画面へ移動します。'
          this.mixinNoDataFlg = true
        } else if (res === 'patient is dead') {
          this.mixinPopupMessage =
            '診療内容編集に失敗しました。\n診療日は患者の「死亡日まで」で設定して下さい。'
        } else if (res.includes('invalid hospitalization')) {
          const base = '診療内容編集に失敗しました。\n'
          this.mixinPopupMessage = this.mixinHospitalizationErrMsg(base, res)
        } else if (res === 'no patient') {
          this.mixinPopupMessage =
            '診療内容編集に失敗しました。\nこのカルテの患者は既に削除されています。\nメイン画面へ移動します。'
          this.mixinNoDataFlg = true
        } else if (res === 'insuranceType already changed') {
          this.mixinPopupMessage =
            '診療内容編集に失敗しました。\n患者の保険設定が変更されているため診療明細に表示されている金額が正しくない可能性があります。\n患者の保険設定を更新しましたので内容をご確認の上、再度編集をお願いします。'
          this.mixinShouldUpdateInsurance = true
        } else {
          this.mixinPopupMessage = '診療内容編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.mixinAlertFlg = true
    },
    mixinIsChangedDate() {
      return (
        this.mixinInitialData.medicalRecord.date !==
        this.mixinInputData.medicalRecord.date
      )
    },
    async mixinSetMailFlg() {
      const mailType = this.medicalRecord.reservationId
        ? 'changeMailAuto'
        : 'reservationMailAuto'
      const mailSetting = this.reservationSetting[mailType]
      if (mailSetting === 'auto') {
        this.mixinMailFlg = true
      } else if (mailSetting === 'off') {
        this.mixinMailFlg = false
      } else if (mailSetting === 'check') {
        const {
          date: initialDate,
          startTime: initialTime
        } = this.mixinInitialData.medicalRecord
        const {
          date: inputDate,
          startTime: inputTime
        } = this.mixinInputData.medicalRecord
        const dateTimeChanged =
          initialDate + initialTime !== inputDate + inputTime
        const upcomingReservation =
          inputDate + inputTime >= moment().format('YYYYMMDDHHmm')
        const reservationMailCondition =
          mailType === 'reservationMailAuto' &&
          this.mixinOwner.email !== '' &&
          upcomingReservation
        const changeMailCondition =
          mailType === 'changeMailAuto' &&
          this.mixinOwner.email !== '' &&
          dateTimeChanged &&
          upcomingReservation
        if (reservationMailCondition || changeMailCondition) {
          this.mixinPopupMessage = `予約を${
            reservationMailCondition ? '作成' : '変更'
          }しました。飼主様に確認メールを送信しますか？`
          await this.mixinOpenEmailConfirmationPopup()
        }
      }
    },
    async mixinCreateUpdateMedicalContent() {
      await this.mixinSetMailFlg()
      this.medicalContent.id
        ? await this.mixinUpdateMedicalContent()
        : await this.mixinCreateMedicalContent()
    },
    mixinMakeExaminationResultGroups(resExaminationResults) {
      const examinationResults = resExaminationResults.map(result => {
        const treatment = this.$store.getters['treatments/getDataById'](
          result.treatmentId
        )
        const topicClass = this.$store.getters[
          'examinationTopicClasses/getDataById'
        ](result.examinationTopicClassId)
        const topic = this.$store.getters['examinationTopics/getDataById'](
          result.examinationTopicId
        )
        const examinationSpeciesDatum = this.$store.getters[
          'examinationSpecies/getDataById'
        ](result.examinationSpeciesId)
        const species = this.$store.getters['species/getDataById'](
          examinationSpeciesDatum.speciesId
        )
        const reference = examinationSpeciesDatum.reference
        const comments = JSON.parse(topic.comments)
        const selectData =
          topic.examinationType === '選択'
            ? comments.options.map((v, i) => {
                return { id: i, name: v[0] }
              })
            : []
        const selectDatum = selectData.find(v => v.name === result.result)
        const numFlg = topic.examinationType === '数値' && reference !== ''
        const min = numFlg ? reference.split(',')[0] : ''
        const max = numFlg ? reference.split(',')[1] : ''
        const datum = {
          ...result,
          treatmentName: treatment.name,
          topicClassName: topicClass.name,
          topicName: topic.name,
          unit: topic.unit,
          speciesName: species.name,
          speciesId: species.id,
          reference: min === '' && max === '' ? reference : `${min} – ${max}`
        }
        if (selectDatum) {
          datum.selectId = selectDatum.id
        }
        return datum
      })
      return _.groupBy(examinationResults, 'examinationResultGroupId')
    },
    mixinSetResDataRelatedToMedicalContent(
      resMedicalRecord,
      resMedicalContent,
      resExaminationResults
    ) {
      this.medicalRecord = _.cloneDeep(resMedicalRecord)
      this.medicalContent = _.cloneDeep(resMedicalContent)
      this.examinationResultGroups = this.mixinMakeExaminationResultGroups(
        _.cloneDeep(resExaminationResults)
      )
      this.examinationResultGroupsIncludeDel = this.examinationResultGroups
      this.$store.dispatch(
        'examinationResults/setNewExaminationResultGroups',
        this.examinationResultGroups
      )

      this.mixinInitialData.medicalRecord = _.cloneDeep(this.medicalRecord)
      //↓medicalContentは、mixinSetToInitialMedicalContentAfterWatch処理で値を再セットする
      this.mixinInitialData.medicalContent = _.cloneDeep(this.medicalContent)
      this.mixinInitialData.examinationResultGroups = _.cloneDeep(
        this.examinationResultGroups
      )
      this.mixinInputData.medicalRecord = this.medicalRecord
      this.mixinInputData.medicalContent = this.medicalContent
      this.mixinInputData.examinationResultGroups = this.examinationResultGroups
    },
    mixinResetEditor() {
      //テストで下記の処理は$refsを二段階以上で使用していてモック化できなかったため、
      //関数にしてモック化できるようにする
      this.$refs.medicalContentInputForm.$refs.richTextEditor.resetEditor()
    },
    mixinSetToInitialMedicalContentAfterWatch() {
      //リッチテキストエディターを作成後contentの値が変わり、
      //確認ポップアップが出てしまうため、watch処理後に再セットする必要がある
      this.$nextTick(() => {
        this.mixinInitialData.medicalContent = _.cloneDeep(this.medicalContent)
      })
    },
    mixinSetUploadImage(uploadImageId) {
      this.$store.dispatch('richTextEditor/uploadImage', uploadImageId)
      this.mixinUploadImagePopupFlg = false
    },
    mixinFailedGetImage() {
      this.mixinAlertFlg = true
      this.mixinType = 'alert'
      this.mixinTitle = '失敗'
      this.mixinPopupMessage =
        '画像の取得に失敗しました。\n暫く待ってから再度お試し下さい。\nメイン画面へ移動します。'
      this.mixinNoDataFlg = true
    },
    mixinCloseSchemaPopup() {
      this.mixinSchemaPopupFlg = false
      this.mixinMedicalContentImageId = 0
    },
    mixinSetMedicalContentImage({ medicalContentImageId, uploadImageId }) {
      const inputType = this.mixinMedicalContentImageId === 0 ? 'new' : 'update'
      this.$store.dispatch('richTextEditor/medicalContentImage', {
        medicalContentImageId,
        uploadImageId,
        inputType
      })
      this.mixinSchemaPopupFlg = false
      this.mixinMedicalContentImageId = 0
    },
    mixinEditMedicalContentImage(medicalContentImageId) {
      this.mixinMedicalContentImageId = medicalContentImageId
      this.mixinSchemaPopupFlg = true
    },
    mixinFailedGetHistoryImages(popupMessage) {
      this.mixinAlertFlg = true
      this.mixinType = 'alert'
      this.mixinTitle = '失敗'
      this.mixinPopupMessage = popupMessage
    },
    mixinSetTemplate(content) {
      this.$store.dispatch('richTextEditor/setTemplate', content)
    },
    mixinCloseMedicalContentsHistory() {
      this.mixinMedicalContentsHistoryFlg = false
      this.mixinMedicalContentsHistoryFootPrintFlg = false
      this.mixinMedicalContentsHistoryMovedFlg = false
      this.mixinMovedStyleMedicalContentsHistory = {}
    },
    mixinMoveMedicalContentsHistory() {
      const vc = this
      vc.mixinMedicalContentsHistoryFootPrintFlg = true
      vc.mixinMedicalContentsHistoryMovedFlg = true
      const baseX = vc.$refs.windows.getBoundingClientRect().left
      const baseY = vc.$refs.windows.getBoundingClientRect().top
      const adjustX = 610
      const adjustY = 10
      const minX = -630
      const minY = -130
      // const zIndex = vc.mixinMovedStyleMedicalPaymentsHistory.zIndex
      //   ? vc.mixinMovedStyleMedicalPaymentsHistory.zIndex + 1
      //   : 200
      vc.mixinMovedStyleMedicalPaymentsHistory.zIndex = 200
      const move = e => {
        const y = e.pageY - baseY - adjustY
        const x = e.pageX - baseX - adjustX
        vc.mixinMovedStyleMedicalContentsHistory = {
          position: 'absolute',
          top: y <= minY ? minY : y + 'px',
          left: x <= minX ? minX : x + 'px',
          zIndex: 201,
          margin: '0px'
        }
        // スクロールのサンプル
        // if (e.clientX < 400) {
        //   vc.$parent.$refs.karteSetting.scrollBy(-10, 0)
        // }
      }
      const finish = () => {
        document.removeEventListener('mousemove', move)
        document.removeEventListener('mouseup', finish)
      }
      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', finish)
    },
    mixinCloseMedicalPaymentsHistory() {
      this.mixinMedicalPaymentsHistoryFlg = false
      this.mixinMedicalPaymentsHistoryFootPrintFlg = false
      this.mixinMedicalPaymentsHistoryMovedFlg = false
      this.mixinMovedStyleMedicalPaymentsHistory = {}
    },
    mixinMoveMedicalPaymentsHistory() {
      const vc = this
      vc.mixinMedicalPaymentsHistoryFootPrintFlg = true
      vc.mixinMedicalPaymentsHistoryMovedFlg = true
      const baseX = vc.$refs.windows.getBoundingClientRect().left
      const baseY = vc.$refs.windows.getBoundingClientRect().top
      const adjustX = 610
      const adjustY = 10
      const minX = -630
      const minY = -130
      vc.mixinMovedStyleMedicalContentsHistory.zIndex = 200
      const move = e => {
        const y = e.pageY - baseY - adjustY
        const x = e.pageX - baseX - adjustX
        vc.mixinMovedStyleMedicalPaymentsHistory = {
          position: 'absolute',
          top: y <= minY ? minY : y + 'px',
          left: x <= minX ? minX : x + 'px',
          zIndex: 201,
          margin: '0px'
        }
      }
      const finish = () => {
        document.removeEventListener('mousemove', move)
        document.removeEventListener('mouseup', finish)
      }
      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', finish)
    },
    mixinExpansionMedicalContentInputForm() {
      this.mixinExpansionMedicalContentInputFormFlg = true
      this.mixinMedicalPaymentInputFormShowFlg = false
      this.mixinMedicalPaymentsHistoryShowFlg = false
      this.mixinMedicalContentsHistoryShowFlg = false
      if (this.mixinMedicalPaymentsHistoryMovedFlg)
        this.mixinMedicalPaymentsHistoryFootPrintFlg = false
      if (this.mixinMedicalContentsHistoryMovedFlg)
        this.mixinMedicalContentsHistoryFootPrintFlg = false
    },
    mixinReductionMedicalContentInputForm() {
      this.mixinExpansionMedicalContentInputFormFlg = false
      this.mixinMedicalPaymentInputFormShowFlg = true
      this.mixinMedicalPaymentsHistoryShowFlg = true
      this.mixinMedicalContentsHistoryShowFlg = true
      if (this.mixinMedicalPaymentsHistoryMovedFlg)
        this.mixinMedicalPaymentsHistoryFootPrintFlg = true
      if (this.mixinMedicalContentsHistoryMovedFlg)
        this.mixinMedicalContentsHistoryFootPrintFlg = true
    },
    mixinExpansionMedicalPaymentInputForm() {
      this.mixinExpansionMedicalPaymentInputFormFlg = true
      this.mixinMedicalContentInputFormShowFlg = false
      this.mixinMedicalPaymentsHistoryShowFlg = false
      this.mixinMedicalContentsHistoryShowFlg = false
      this.mixinClearMargin = { 'margin-left': '0px' }
      if (this.mixinMedicalPaymentsHistoryMovedFlg)
        this.mixinMedicalPaymentsHistoryFootPrintFlg = false
      if (this.mixinMedicalContentsHistoryMovedFlg)
        this.mixinMedicalContentsHistoryFootPrintFlg = false
    },
    mixinReductionMedicalPaymentInputForm() {
      this.mixinExpansionMedicalPaymentInputFormFlg = false
      this.mixinMedicalContentInputFormShowFlg = true
      this.mixinMedicalPaymentsHistoryShowFlg = true
      this.mixinMedicalContentsHistoryShowFlg = true
      this.mixinClearMargin = {}
      if (this.mixinMedicalPaymentsHistoryMovedFlg)
        this.mixinMedicalPaymentsHistoryFootPrintFlg = true
      if (this.mixinMedicalContentsHistoryMovedFlg)
        this.mixinMedicalContentsHistoryFootPrintFlg = true
    },
    mixinCloseContentPopup() {
      this.mixinAlertFlg = false
      if (this.mixinNoDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'main' })
      } else if (this.mixinToRecordNewFlg) {
        this.$router.push({
          name: 'medical-record-new',
          params: { ownerId: this.ownerId, patientId: this.patientId }
        })
        this.$store.dispatch('medicalRecords/resetOriginalIdSetInTab')
      } else if (this.mixinToOwnerShowFlg) {
        this.$router.push({
          name: 'owner',
          params: { id: this.ownerId },
          hash: `#patient-${this.patientId}`
        })
        this.$store.dispatch('medicalRecords/resetOriginalIdSetInTab')
      }
      if (this.mixinShouldUpdateInsurance) {
        this.mixinSetInsurance()
        this.mixinShouldUpdateInsurance = false
      }
    },
    async mixinOpenEmailConfirmationPopup() {
      this.mixinAlertFlg = true
      this.mixinType = 'alert'
      this.mixinTitle = 'メール確認'
      this.mixinButtons = ['いいえ', 'はい']
      this.mixinPopup = {
        decision: () => {
          this.mixinMailFlg = true
          this.mixinPopupResolve()
          this.mixinPopupResolve = null
          this.mixinAlertFlg = false
          this.mixinTitle = ''
          this.mixinButtons = ['閉じる']
          this.mixinType = ''
          this.mixinPopupMessage = ''
          this.mixinPopup = {
            decision: () => {}
          }
        }
      }
      await new Promise(resolve => (this.mixinPopupResolve = resolve))
    }
  }
}
