<template>
  <div class="estimates">
    <div class="area buttons">
      <base-button-register
        v-if="lookOnlyFlg === 0"
        :styles="{ width: '135px' }"
        :text="'見積作成'"
        @click="gotoNewPage"
      />
    </div>
    <div class="area owner-patient">
      <owner-patient-detail :owner="getOwner(ownerId)" :patient="patient" />
    </div>
    <div class="area search">
      <search-area
        :textBoxLabel="'検索単語'"
        :textValue="searchWord"
        :placeholder="'診断名、診療内容'"
        :periodFlg="true"
        :periodLabel="'期間'"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        v-model="searchWord"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">
        検索結果：{{ displayEstimates.length }}件({{ estimates.length }}件中)
      </div>
      <div class="order-setting">
        <div class="label">並び替え：見積日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <div class="area list">
      <estimate-list-table
        v-if="displayEstimates.length > 0"
        :estimates="displayEstimates"
      >
        <template #buttons="{ estimate }">
          <div class="buttons">
            <base-button-trash
              v-if="lookOnlyFlg === 0"
              :styles="{ width: '60px', height: '30px' }"
              @click="openDeletePopup(estimate)"
            />
            <base-button-small-white
              v-if="lookOnlyFlg === 0"
              :styles="{ width: '60px', height: '30px' }"
              @click="gotoEditPage(estimate)"
              >編集</base-button-small-white
            >
            <base-button-border-orange
              v-if="filterPrescriptionItems(estimate.treatmentItems)"
              :styles="{ width: '120px', height: '30px' }"
              @click="openPrintPopup('prescription', estimate)"
              >処方箋印刷</base-button-border-orange
            >
            <base-button-border-orange
              :styles="{ width: '120px', height: '30px' }"
              @click="openPrintPopup('estimate', estimate)"
              >見積書印刷</base-button-border-orange
            >
          </div>
        </template>
      </estimate-list-table>
    </div>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
    <print-options-popup
      v-if="printFlg"
      :dataType="printDataType"
      :data="printData"
      :owner="getOwner(ownerId)"
      :patient="patient"
      @close="() => (printFlg = false)"
    />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import SearchArea from '@/components/parts/molecules/SearchArea'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import EstimateListTable from '@/components/parts/organisms/EstimateListTable'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import PrintOptionsPopup from '@/components/popups/PrintOptionsPopup'
import { calculateTreatmentItemSubtotal } from '@/utils/price_calculation'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Estimates',

  components: {
    BaseButtonRegister,
    OwnerPatientDetail,
    SearchArea,
    BaseSelectBox,
    EstimateListTable,
    BaseButtonTrash,
    BaseButtonSmallWhite,
    BaseButtonBorderOrange,
    AnnouncePopup,
    PrintOptionsPopup
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      searchWord: '',
      startDate: '',
      endDate: '',
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      decide: () => {},
      waitFlg: false,
      printFlg: false,
      printDataType: '',
      printData: {}
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getEstimatesByPatientId: 'estimates/getDataByPatientId',
      getTreatmentItemsByEstimateId:
        'estimateTreatmentItems/getDataByEstimateId',
      getDisease: 'diseases/getDataByOriginalId',
      getTreatmentByOriginalId: 'treatments/getDataByOriginalId',
      getMedicineByOriginalId: 'medicines/getDataByOriginalId'
    }),
    patient() {
      return this.getPatient(this.patientId)
    },
    estimates() {
      return this.getEstimatesByPatientId(this.patientId) || []
    },
    displayEstimates() {
      return this.estimates
        .filter(v =>
          this.startDate !== '' && this.endDate !== ''
            ? this.startDate <= v.date && v.date <= this.endDate
            : this.startDate !== ''
            ? this.startDate <= v.date
            : this.endDate !== ''
            ? v.date <= this.endDate
            : true
        ) // 期間によるフィルタリング
        .map(estimate => {
          const disease1 = this.getDisease(estimate.disease1OriginalId)
          const disease2 = this.getDisease(estimate.disease2OriginalId)
          const diseaseName =
            disease1?.delFlg === 0 && disease2?.delFlg === 0
              ? `${disease1.name},${disease2.name}`
              : disease1?.delFlg === 0
              ? disease1.name
              : disease2?.delFlg === 0
              ? disease2.name
              : '診断名なし'
          const treatmentItems = (
            this.getTreatmentItemsByEstimateId(estimate.id) || []
          )
            .filter(
              item =>
                this.getTreatmentByOriginalId(item.treatmentOriginalId) ||
                this.getMedicineByOriginalId(item.medicineOriginalId)
            )
            .map(item => {
              const name =
                this.getTreatmentByOriginalId(item.treatmentOriginalId)?.name ||
                this.getMedicineByOriginalId(item.medicineOriginalId)?.name
              const subtotal = calculateTreatmentItemSubtotal(
                estimate.insuranceType,
                item
              )
              return { ...item, name, subtotal }
            })
          return {
            ...estimate,
            jpFormatDate: this.toJpDateFormat(estimate.date),
            diseaseName,
            treatmentItems
          }
        })
        .filter(
          estimate =>
            estimate.diseaseName
              .toLowerCase()
              .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
              -1 ||
            estimate.treatmentItems.some(
              item =>
                item.name
                  .toLowerCase()
                  .indexOf(
                    this.searchWord.toLowerCase().replace(/\s+/g, '')
                  ) !== -1
            )
        ) // 診断名と診療内容によるフィルタリング
        .sort((a, b) => {
          if (this.orderType === 0) {
            return a.date === b.date ? b.id - a.id : b.date < a.date ? -1 : 1
          } else {
            return a.date === b.date ? a.id - b.id : a.date < b.date ? -1 : 1
          }
        })
    }
  },

  methods: {
    toJpDateFormat(YYYYMMDD) {
      return moment(YYYYMMDD, 'YYYYMMDD').format('Y年M月D日（dd）')
    },
    gotoNewPage() {
      this.$router.push({ path: 'estimates/new' })
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    filterPrescriptionItems(items) {
      return items.filter(item => item.medicineOriginalId !== 0).length > 0
    },
    openDeletePopup(estimate) {
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.decide = () => this.decideDelete(estimate)
    },
    async decideDelete(estimate) {
      this.waitFlg = true
      const result = await this.$store.dispatch('estimates/delete', estimate.id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    gotoEditPage(estimate) {
      this.$router.push({ name: 'estimate-edit', params: { id: estimate.id } })
    },
    openPrintPopup(dataType, data) {
      this.printDataType = dataType
      this.printFlg = true
      if (dataType === 'prescription') {
        this.printData = {
          prescriptionItems: data.treatmentItems.filter(
            item => item.medicineOriginalId !== 0
          ),
          estimateDate: data.date
        }
      } else if (dataType === 'estimate') {
        this.printData = data
      }
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.estimates {
  display: inline-block;
  min-width: 100%;
  > .buttons {
    display: flex;
    justify-content: flex-end;
  }
  > .owner-patient {
    margin-top: 30px;
  }
  > .search {
    margin-top: 40px;
  }
  > .search-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .list {
    min-width: 1400px;
    margin-top: 15px;
    ::v-deep .table-body {
      max-height: 630px;
    }
    .buttons {
      height: 100%;
      display: flex;
      align-items: flex-end;
      column-gap: 5px;
      padding-right: 20px;
    }
  }
}
</style>
