<template>
  <div class="price-input-table">
    <div class="table">
      <div class="table-header">
        <div class="cell sum-subtotal">診療費 小計</div>
        <div class="cell discount-rate">割引適用額</div>
        <div class="cell discount-price">値引適用額</div>
        <div class="cell consumption-tax">消費税</div>
        <div class="cell bill">請求額</div>
      </div>
      <div class="table-body">
        <div class="row upper">
          <div class="cell text sum-subtotal" data-test="sum-subtotal"></div>
          <div v-if="!isInHospital" class="cell input discount-rate">
            <div class="text-start">割引率</div>
            <div class="input">
              <validation-provider
                class="validation-provider"
                :rules="{ intRule: true, percentRule: true }"
                v-slot="{ errors }"
                ><base-text-box
                  data-test="discount-rate-input"
                  :value="priceItem.discountRate"
                  :maxlength="3"
                  :disabled="isInHospital"
                  :styles="textBoxStyle"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  @input="inputNum($event, 'discountRate')"
                  @blur="blur('discountRate')"
                />
                <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
              </validation-provider>
            </div>
            <div class="text-end">%</div>
          </div>
          <div v-else class="cell disabled" data-test="discount-rate"></div>
          <div v-if="!isInHospital" class="cell input discount-price">
            <div class="text-start">値引額 ￥</div>
            <div class="input">
              <validation-provider
                class="validation-provider"
                :rules="{ intRule: true }"
                v-slot="{ errors }"
                ><base-text-box
                  data-test="discount-price-input"
                  :value="priceItem.discountPrice"
                  :maxlength="7"
                  :disabled="isInHospital"
                  :styles="textBoxStyle"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  @input="inputNum($event, 'discountPrice')"
                  @blur="blur('discountPrice')"
                />
                <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
              </validation-provider>
            </div>
          </div>
          <div v-else class="cell disabled" data-test="discount-price"></div>
          <div
            class="cell text consumption-tax"
            :class="{ disabled: isInHospital }"
            data-test="consumption-tax"
          >
            {{ dispPrice(priceDetail.consumptionTax) }}
          </div>
          <div
            class="cell text bill"
            :class="{ disabled: isInHospital }"
            data-test="bill"
          ></div>
        </div>
        <div class="row under">
          <div class="cell text sum-subtotal" data-test="sum-subtotal-value">
            ￥{{ priceDetail.totalPrice }}
          </div>
          <div
            class="cell text discount-rate"
            :class="{ disabled: isInHospital }"
            data-test="discount-rate-value"
          >
            {{
              priceItem.insuranceType === 'ipet-docomo'
                ? ''
                : dispPrice(priceDetail.rateDiscountedPrice)
            }}
          </div>
          <div
            class="cell text discount-price"
            :class="{ disabled: isInHospital }"
            data-test="discount-price-value"
          >
            {{ dispPrice(priceDetail.fixedDiscountedPrice) }}
          </div>
          <div
            class="cell text consumption-tax"
            :class="{ disabled: isInHospital }"
            data-test="consumption-tax-value"
          >
            {{ dispPrice(priceDetail.taxIncludedPrice) }}
          </div>
          <div
            class="cell text bill"
            :class="{ disabled: isInHospital }"
            data-test="bill-value"
          >
            {{ dispPrice(priceDetail.taxIncludedPrice) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'PriceInputTable',

  components: {
    BaseTextBox,
    ValidationProvider
  },

  props: {
    priceItem: { type: Object },
    treatmentItems: { type: Array },
    priceDetail: { type: Object },
    isInHospital: { type: Boolean, default: false }
  },

  data() {
    return {
      textBoxStyle: {
        width: '55px',
        height: '24px',
        textAlign: 'right',
        fontSize: '11px'
      }
    }
  },

  computed: {
    dispPrice() {
      return price => {
        return this.isInHospital ? '' : '￥' + price
      }
    }
  },

  methods: {
    blur(field) {
      if (this.priceItem[field] === '') this.priceItem[field] = 0
    },
    inputNum(value, field) {
      const isInt = /^([0-9]\d*)$/.test(value)
      const newValue = isInt ? Number(value) : value
      this.priceItem[field] = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
$table-border-color: #{$very-light-grey};
.price-input-table {
  width: 100%;
  font-size: 11px;
  > .table {
    width: 100%;
    > .table-header {
      width: 100%;
      height: 30px;
      box-sizing: border-box;
      display: flex;
      > .cell {
        flex: 1;
        box-sizing: border-box;
        line-height: 29px;
        padding: 0 8px;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
      }
    }
    > .table-body {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid $table-border-color;
      > .row {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        height: 30px;
        background-color: #{$gray_fc};
        &:nth-child(even) {
          background-color: #{$white};
        }
        > .cell {
          flex: 1;
          box-sizing: border-box;
          line-height: 29px;
          padding: 0 6px;
          &.text {
            text-align: right;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &.input {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 10px;
            > .text-start {
              margin-right: 5px;
            }
            > .text-end {
              margin-left: 5px;
            }
          }
        }
        > .disabled {
          background-color: #{$white_f8};
        }
        > .cell + .cell {
          border-left: 1px solid $table-border-color;
        }
      }
      > .row ~ .row {
        border-top: 1px solid $table-border-color;
      }
    }
  }
}
.validation-provider {
  position: relative;
  > .error {
    position: absolute;
    height: 18px;
    line-height: 18px;
    padding: 0 3px;
    background-color: #{$white};
    color: #{$tomato};
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
  }
}
</style>
