<template>
  <div class="vaccination-list-card">
    <div class="information">
      <div class="dates">
        <div class="date previous">
          <div class="label">前回来院</div>
          <div class="value">
            {{ toDate(previousComingDate, 'なし') }}
          </div>
        </div>
        <div class="date next">
          <div class="label">次回予約</div>
          <div class="value">
            {{ toDate(nextReservationDate, 'なし') }}
          </div>
        </div>
      </div>
      <div class="buttons">
        <div class="button">
          <base-button-plus
            class="button"
            :styles="{ width: '150px', fontSize: '13px' }"
            :text="'予防接種追加'"
            @click="clickVaccinationNew"
          />
        </div>
        <div class="button">
          <base-button-border-orange
            class="button"
            :styles="{ width: '100px' }"
            @click="clickReminders"
            >連絡履歴</base-button-border-orange
          >
        </div>
      </div>
    </div>
    <div class="vaccinations">
      <div v-if="vaccinations.length > 0" class="table">
        <div class="table-header">
          <div class="cell vaccine-name">ワクチン</div>
          <div class="cell previous-period">前回の接種/抗体検査</div>
          <div class="cell ">現在の接種期間</div>
          <div class="cell next-period">次回の接種期間</div>
          <div class="cell previous-reminder">前回の連絡</div>
          <div class="cell copy-button"></div>
        </div>
        <div class="table-body">
          <div
            class="table-body-row"
            v-for="vaccination in vaccinations"
            :key="`${vaccination.patientVaccineId}`"
            @click="clickVaccination(vaccination)"
          >
            <div class="cell vaccine-name">
              <div class="text">{{ vaccination.vaccineName }}</div>
            </div>
            <div class="cell previous-date-antibody-type">
              <div class="text">
                {{ toDateType(vaccination.previousDateAntibodyType) }}
              </div>
            </div>
            <div
              class="cell current-period"
              :class="{
                incomplete:
                  vaccination.currentPeriod && !vaccination.completeFlg
              }"
            >
              <div class="text">
                {{ toPeriod(vaccination.currentPeriod, '期間外') }}
              </div>
            </div>
            <div class="cell next-period">
              <div class="text">
                {{ toPeriod(vaccination.nextPeriod, '未設定') }}
              </div>
            </div>
            <div class="cell previous-reminder">
              <div class="text">
                {{ toDateMedia(vaccination.previousVaccineReminder) }}
              </div>
            </div>
            <div class="cell copy-button">
              <div class="button" @click.stop="() => {}">
                <base-button-border-orange
                  :disabled="!vaccination.latestVaccinatedAntibody"
                  :styles="{ width: '120px' }"
                  @click.stop="clickPrint(vaccination)"
                  >証明書印刷</base-button-border-orange
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <span>設定されている予防接種はありません</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import { antibodyTypesEnToJp, mediaEnToJp } from '@/utils/vaccine_utils'
import moment from 'moment'

export default {
  name: 'VaccinationListCard',

  components: {
    BaseButtonPlus,
    BaseButtonBorderOrange
  },

  props: {
    previousComingDate: { type: String },
    nextReservationDate: { type: String },
    vaccineReminders: { type: Array },
    vaccinations: { type: Array }
  },

  methods: {
    toDate(date, text) {
      return date ? moment(date, 'YYYYMMDD').format('YYYY/MM/DD') : text || ''
    },
    toDateType(dateAntibodyType) {
      if (!dateAntibodyType) return 'なし'
      const date = this.toDate(dateAntibodyType.date)
      const jpType = antibodyTypesEnToJp[dateAntibodyType.type]
      return `${date} ${jpType}`
    },
    toPeriod(period, text) {
      if (!period) return text || ''
      const start = this.toDate(period.startDate)
      const end = this.toDate(period.endDate)
      return `${start} ～ ${end}`
    },
    toDateMedia(vaccineReminder) {
      if (!vaccineReminder) return 'なし'
      const date = this.toDate(vaccineReminder.date)
      const jpMedia = mediaEnToJp[vaccineReminder.media]
      return `${date} ${jpMedia}`
    },
    clickReminders() {
      this.$emit('click-reminders')
    },
    clickVaccinationNew() {
      this.$emit('click-vaccination-new')
    },
    clickVaccination(vaccination) {
      const patientVaccineId = vaccination.patientVaccineId
      this.$emit('click-vaccination', patientVaccineId)
    },
    clickPrint(vaccination) {
      const patientVaccineId = vaccination.patientVaccineId
      this.$emit('print', patientVaccineId)
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
.vaccination-list-card {
  padding: 15px;
  background: #{$very-light-shade-brown};
  border: solid 1px #{$light-grey};
  > .information {
    display: flex;
    justify-content: right;
    > .dates {
      margin-right: 10px;
      > .date {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 145px;
        line-height: 24px;
        font-size: 14px;
        > .label {
          margin-right: 5px;
        }
        > .value {
          flex: 1;
        }
      }
    }
    > .buttons {
      display: flex;
      > .button ~ .button {
        margin-left: 10px;
      }
    }
  }
  > .vaccinations {
    margin-top: 15px;
    > .table {
      width: 100%;
      > .table-header {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        background-color: #{$gray_f0};
        border: solid $border-color;
        border-width: 1px 1px 0 1px;
        > .cell {
          box-sizing: border-box;
          width: 100%;
          min-width: 170px;
          line-height: 29px;
          padding: 0 8px;
          font-size: 13px;
          text-align: center;
          + .cell {
            border-left: 1px solid $border-color;
          }
        }
      }
      > .table-body {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $border-color;
        background-color: #{$white};
        > .table-body-row {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-top: 1px solid #{$light-grey};
          background-color: #{$white};
          &:hover {
            cursor: pointer;
            > .cell:not(.copy-button) {
              opacity: 0.8;
            }
          }
          &:first-child {
            border-top: none;
          }
          > .cell {
            box-sizing: border-box;
            width: 100%;
            min-width: 170px;
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            text-align: center;
            + .cell {
              border-left: 1px solid $border-color;
            }
            &.incomplete {
              color: #{$tomato};
            }
            > .button {
              cursor: default;
            }
          }
        }
      }
    }
  }
}
</style>
