<template>
  <div class="history-patient-detail">
    <div class="row upper">
      <div class="item show-id">
        <div class="label">患者ID</div>
        <div class="value">
          <span class="emphasis">{{ displayPatient.showId }}</span>
        </div>
      </div>
      <div class="item name">
        <div class="label">なまえ</div>
        <div class="value" :title="displayPatient.name">
          <span class="emphasis">{{ displayPatient.name }}</span>
        </div>
      </div>
      <div
        class="item age-birthday"
        v-if="displayPatient.age !== '' && displayPatient.birthday !== ''"
      >
        <div class="value">
          <span class="emphasis">{{ displayPatient.age }}</span> /
          {{ displayPatient.birthday }}
        </div>
      </div>
    </div>
    <div class="row under">
      <div class="item species">
        <div class="label">種類</div>
        <div
          class="value"
          :title="`${displayPatient.speciesName}/${displayPatient.breed}`"
        >
          <span class="emphasis">{{ displayPatient.speciesName }}</span> /
          {{ displayPatient.breed }}
        </div>
      </div>
      <div class="item sex-name">
        <div class="label">性別</div>
        <div class="value">{{ displayPatient.sexName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeBirthday, calcAge } from '@/utils/patient_info'

export default {
  name: 'HistoryPatientDetail',

  props: {
    patient: { type: Object, required: true }
  },

  computed: {
    ...mapGetters({
      patientSexesZero: 'master/selectPatientSexesZero',
      getOneSpecies: 'species/getDataById'
    }),
    displayPatient() {
      const patient = this.patient
      if (patient) {
        const patientSexes = this.patientSexesZero
        const speciesName = this.getOneSpecies(patient.speciesId)?.name || ''
        return {
          ...patient,
          age: calcAge(patient),
          birthday: makeBirthday(patient),
          speciesName,
          sexName: patientSexes.find(v => v.id === patient.sex).name
        }
      } else {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.history-patient-detail {
  height: 100%;
  > .row {
    display: flex;
    height: 22px;
    > .item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-right: 20px;
      font-size: 12px;
      &.show-id {
        max-width: 200px;
        > .label {
          width: 34px;
        }
      }
      &.name {
        max-width: 260px;
        > .label {
          width: 35px;
        }
      }
      &.age-birthday {
        max-width: 158px;
      }
      &.species {
        max-width: 548px;
        > .label {
          width: 24px;
        }
      }
      &.sex-name {
        max-width: 70px;
      }
      > .label {
        margin-right: 10px;
        font-size: 11px;
      }
      > .value {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .emphasis {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
