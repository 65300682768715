<template>
  <div class="payment-list-table">
    <div class="table-header">
      <div class="cell button"></div>
      <div class="cell owner" v-if="allFlg">
        <div>飼主ID</div>
        <div>飼主名</div>
      </div>
      <div class="cell patient" v-if="allFlg">
        <div>患者ID</div>
        <div>患者名</div>
      </div>
      <div class="cell date">診療日</div>
      <div class="cell pay-date">会計日</div>
      <div class="cell burden-amount">請求額（￥）</div>
      <div class="cell unpaid">未収金（￥）</div>
      <div class="cell cash">現金（￥）</div>
      <div class="cell card">カード決済（￥）</div>
    </div>
    <dynamic-scroller
      v-if="payments.length > 0"
      class="table-body"
      :items="payments"
      :min-item-size="minItemSize"
      :buffer="buffer"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
        >
          <payment-list-table-row
            :allFlg="allFlg"
            :payment="item"
            :index="index"
            :lastIndex="lastIndex"
            :initialDisplayFlg="initialDisplayFlg"
            :patientPaymentsFlg="patientPaymentsFlg"
            @go-to-show-payment-page="
              (displayPayment, index) =>
                $emit('go-to-show-payment-page', displayPayment, index)
            "
            @go-to-new-payment-page="
              (displayPayment, index) =>
                $emit('go-to-new-payment-page', displayPayment, index)
            "
            @scroll-bottom="$emit('scroll-bottom')"
          ></payment-list-table-row>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <div v-else><div v-if="!waitFlg">対象のデータはありませんでした</div></div>
  </div>
</template>

<script>
import PaymentListTableRow from '@/components/parts/molecules/PaymentListTableRow'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

export default {
  name: 'PaymentListTable',

  components: {
    PaymentListTableRow,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    allFlg: { type: Boolean, default: false },
    payments: { type: Array },
    lastIndex: { type: Number, default: 0 },
    initialDisplayFlg: { type: Boolean },
    patientPaymentsFlg: { type: Boolean, default: false },
    waitFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      minItemSize: 68,
      buffer: 10
    }
  }
}
</script>

<style lang="scss" scoped>
$box-border-color: #{$light-grey};
.payment-list-table {
  > .table-header {
    font-size: 15px;
    box-sizing: border-box;
    display: flex;
    height: 56px;
    border: 1px solid #{$light-grey};
    background-color: #{$pale};
    > .cell {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 152px;
      flex: 1;
      &.button {
        max-width: 120px;
      }
      &.card {
        margin-right: 18px;
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-bottom: 0px;
    border-width: 0px 1px 1px 1px;
  }
}
</style>
