<template>
  <div
    class="insurance-apply-form"
    :class="{
      'medical-payment': medicalPayment,
      estimate: estimate
    }"
  >
    <div class="left">
      <div class="part apply">
        <base-check-box
          data-test="checkbox"
          :disabled="disabled || (!hasInsurance && applyFlg === 0)"
          :isChecked="applyFlg"
          :labelText="'保険適用'"
          :labelStyle="{ marginLeft: '5px' }"
          :numFlg="true"
          @input="inputApplyFlg"
        />
      </div>
      <div class="part insurance">
        <div class="label">保険</div>
        <div class="value">{{ insuranceInfo.jpInsurance }}</div>
      </div>
      <div
        v-if="hasInsurance || isApplying"
        class="part policy-number"
        data-test="policy-number"
      >
        <div class="label">証券番号</div>
        <div class="value">{{ insuranceInfo.policyNumber }}</div>
      </div>
      <div
        v-if="medicalPayment && isApplyingAnicom"
        class="part authorization-number"
        data-test="authorization-number"
      >
        <div class="label">承認番号</div>
        <div class="value">{{ insuranceInfo.authorizationNumber }}</div>
      </div>
      <div
        v-if="medicalPayment && isApplyingAnicom"
        class="part no-apply-reason"
        data-test="no-apply-reason"
      >
        <div class="text" data-test="text" @click="clickNoApplyReason">
          保険対象外事由
        </div>
      </div>
    </div>
    <div class="right">
      <base-button-border-orange
        data-test="setting-btn"
        :disabled="applyFlg === 1 || isMidEndHospitalization"
        :styles="{ width: '90px' }"
        @click="clickInsuranceSetting"
        >保険設定</base-button-border-orange
      >
    </div>
  </div>
</template>

<script>
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'

export default {
  name: 'InsuranceApplyForm',

  components: {
    BaseButtonBorderOrange,
    BaseCheckBox
  },

  props: {
    disabled: { type: Boolean },
    patient: { type: Object },
    anicomPatient: { type: Object, default: null },
    ipetPatient: { type: Object, default: null },
    medicalPayment: { type: Object, default: null },
    estimate: { type: Object, default: null },
    anicomCIdCheck: { type: Object, default: null },
    ipetCheck: { type: Object, default: null }
  },

  computed: {
    applyItem() {
      return this.medicalPayment ?? this.estimate ?? {}
    },
    hasInsurance() {
      return ['anicom', 'ipet', 'docomo'].includes(this.patient.insurance)
    },
    applyFlg() {
      const { applyFlg = 0 } = this.applyItem
      return applyFlg
    },
    isApplying() {
      return this.isApplyingAnicom || this.isApplyingIpet
    },
    isApplyingAnicom() {
      const { anicomCIdCheckId = 0 } = this.applyItem
      return Boolean(this.applyFlg === 1 && anicomCIdCheckId !== 0)
    },
    isApplyingIpet() {
      const { ipetCheckId = 0 } = this.applyItem
      return Boolean(this.applyFlg === 1 && ipetCheckId !== 0)
    },
    insuranceInfo() {
      let jpInsurance = '未設定'
      let policyNumber = ''
      let authorizationNumber = ''
      if (this.isApplying) {
        if (this.anicomCIdCheck) {
          jpInsurance = 'アニコム'
          policyNumber = this.anicomCIdCheck.patientCId
          authorizationNumber = this.anicomCIdCheck.code
        } else if (this.ipetCheck) {
          const { insurance } = this.ipetCheck
          if (insurance === 'ipet') jpInsurance = 'アイペット'
          else if (insurance === 'docomo') jpInsurance = 'ドコモ'
          policyNumber = this.ipetCheck.policyNo
        }
      } else {
        const { insurance } = this.patient
        if (insurance === 'anicom') {
          jpInsurance = 'アニコム'
          policyNumber = this.anicomPatient?.cId ?? ''
        } else if (insurance === 'ipet' || insurance === 'docomo') {
          if (insurance === 'ipet') jpInsurance = 'アイペット'
          else if (insurance === 'docomo') jpInsurance = 'ドコモ'
          policyNumber = this.ipetPatient?.policyNo ?? ''
        }
      }
      return { jpInsurance, policyNumber, authorizationNumber }
    },
    isMidEndHospitalization() {
      return !this.medicalPayment?.startHospitalizationFlg &&
        (this.medicalPayment?.inHospitalFlg ||
          this.medicalPayment?.endHospitalizationFlg)
        ? true
        : false
    }
  },

  methods: {
    inputApplyFlg(applyFlg) {
      this.$emit('input', applyFlg)
    },
    clickNoApplyReason() {
      this.$emit('click-no-apply-reason')
    },
    clickInsuranceSetting() {
      this.$emit('click-insurance-setting')
    }
  }
}
</script>

<style lang="scss" scoped>
.insurance-apply-form {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 57px;
  padding: 0 10px;
  font-size: 13px;
  &.medical-payment {
    background-color: #{$gray_f8};
  }
  &.estimate {
    background-color: #{$white};
  }
  > .left {
    display: flex;
    align-items: center;
    gap: 0 20px;
    > .part {
      > .label {
        height: 20px;
        font-weight: bold;
      }
      > .value {
        height: 20px;
      }
    }
    > .no-apply-reason {
      > .text {
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }
  > .right {
    display: flex;
    align-items: center;
    gap: 0 20px;
  }
}
</style>
