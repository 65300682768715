<template>
  <div
    class="payment-list-table-row"
    :class="{ even: (index + 1) % 2 === 0 }"
    ref="target"
  >
    <div class="cell button">
      <base-button-round
        v-if="displayPayment.type === '会計済'"
        :styles="{ borderColor: '#1976d2' }"
        :textStyles="{ color: '#1976d2' }"
        @click="goToPaymentPage(displayPayment, index)"
        >会計済</base-button-round
      >
      <base-button-round
        v-if="displayPayment.type === '再会計'"
        :styles="{ borderColor: '#de3d3d' }"
        :textStyles="{ color: '#de3d3d' }"
        @click="goToPaymentPage(displayPayment, index)"
        >再会計</base-button-round
      >
      <base-button-round
        v-if="displayPayment.type === '未会計'"
        :styles="{ borderColor: '#de3d3d' }"
        :textStyles="{ color: '#de3d3d' }"
        @click="goToPaymentPage(displayPayment, index)"
        >未会計</base-button-round
      >
      <base-button-round
        v-if="displayPayment.type === '未収金'"
        :styles="{ borderColor: '#de3d3d' }"
        :textStyles="{ color: '#de3d3d' }"
        @click="goToPaymentPage(displayPayment, index)"
        >未収金</base-button-round
      >
    </div>
    <div
      class="cell owner"
      data-test="cell"
      v-if="allFlg"
      :title="`${displayPayment.ownerShowId}\n${displayPayment.ownerFullName}`"
    >
      <div class="text" data-test="text">{{ displayPayment.ownerShowId }}</div>
      <div class="text" data-test="text">
        {{ displayPayment.ownerFullName }}
      </div>
    </div>
    <div
      class="cell patient"
      data-test="cell"
      v-if="allFlg"
      :title="
        `${displayPayment.patientShowId}\n${makePatientNameSpecies(
          displayPayment
        )}`
      "
    >
      <div class="text" data-test="text">
        {{ displayPayment.patientShowId }}
      </div>
      <div class="text" data-test="text">
        {{ makePatientNameSpecies(displayPayment) }}
      </div>
    </div>
    <div class="cell date" data-test="cell">
      {{ displayPayment.formatDate }}
    </div>
    <div class="cell pay-date" data-test="cell">
      {{ displayPayment.formatPayDate }}
    </div>
    <div class="cell burden-amount right-align" data-test="cell">
      {{ displayPayment.formatBurdenAmount }}
    </div>
    <div
      class="cell unpaid right-align"
      :class="{ red: displayPayment.red }"
      data-test="cell"
    >
      {{ displayPayment.formatUnpaidAmount }}
    </div>
    <div class="cell cash right-align" data-test="cell">
      {{ displayPayment.formatCash }}
    </div>
    <div class="cell card right-align" data-test="cell">
      {{ displayPayment.formatCard }}
    </div>
  </div>
</template>

<script>
import BaseButtonRound from '@/components/parts/atoms/BaseButtonRound'
import moment from 'moment'
export default {
  name: 'PaymentListTableRow',

  components: { BaseButtonRound },

  props: {
    allFlg: { type: Boolean, default: false },
    payment: { type: Object },
    index: { type: Number },
    lastIndex: { type: Number, default: 0 },
    initialDisplayFlg: { type: Boolean },
    patientPaymentsFlg: { type: Boolean, default: false }
  },

  computed: {
    displayPayment() {
      return {
        ...this.payment,
        formatDate:
          this.payment.date !== ''
            ? moment(this.payment.date, 'YYYYMMDD').format(
                'YYYY年MM月DD日（dd）'
              )
            : '',
        formatPayDate:
          this.payment.payDateTime !== ''
            ? moment(this.payment.payDateTime, 'YYYYMMDDHHmmss').format(
                'YYYY年MM月DD日（dd）'
              )
            : '',
        formatBurdenAmount: `￥${this.payment.burdenAmount.toLocaleString()}`,
        formatUnpaidAmount:
          this.payment.unpaidAmount < 0
            ? `(超過) ￥${Math.abs(this.payment.unpaidAmount).toLocaleString()}`
            : `￥${this.payment.unpaidAmount.toLocaleString()}`,
        formatCash: `￥${this.payment.cash.toLocaleString()}`,
        formatCard: `￥${this.payment.card.toLocaleString()}`
      }
    }
  },

  mounted() {
    if (!this.patientPaymentsFlg) {
      const target = this.$refs.target
      let observer = new IntersectionObserver(() => {
        this.emitScrollBottom()
      })
      observer.observe(target)
    }
  },

  methods: {
    emitScrollBottom() {
      if (!this.initialDisplayFlg && this.payment.number === this.lastIndex) {
        this.$emit('scroll-bottom')
      }
    },
    makePatientNameSpecies(payment) {
      return payment.speciesName
        ? `${payment.patientName} （${payment.speciesName}）`
        : `${payment.patientName}`
    },
    goToPaymentPage(displayPayment, index) {
      if (displayPayment.type === '未会計') {
        this.$emit('go-to-new-payment-page', displayPayment, index)
      } else {
        this.$emit('go-to-show-payment-page', displayPayment, index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-list-table-row {
  display: flex;
  height: 68px;
  border-bottom: 1px solid #{$light-grey};
  &.even {
    background-color: #{$white_f7};
  }
  &.active {
    background-color: red;
  }
  > .cell {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 152px;
    flex: 1;
    padding: 0 20px;
    &.button {
      max-width: 120px;
    }
    &.right-align {
      align-items: flex-end;
    }
    &.red {
      font-weight: bold;
      color: #{$tomato};
      text-align: right;
    }
    > .text {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
