<template>
  <div class="image-edit-popup" @keyup.esc.stop="checkDiff" tabindex="-1">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <popup
        :invalid="invalid || waitFlg || lookOnlyFlg === 1"
        @cancel="checkDiff"
        @decision="update"
        @click-close-mark="checkDiff"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">画像編集</div>
            <div class="input-form">
              <div class="left">
                <div class="image-frame">
                  <span
                    v-for="textObj in editData.medicalContentImageTexts"
                    :key="textObj.id"
                    :style="textObj.defaultTextStyle"
                    >{{ textObj.text }}</span
                  >
                  <div class="image-area">
                    <img
                      class="under-image"
                      :src="editData.image"
                      :style="editData.defaultImageStyle"
                    />
                    <img
                      v-if="editData.overImage"
                      class="over-image"
                      :src="editData.overImage"
                    />
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="input title">
                  <validation-provider
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-text-box
                      :placeholder="'タイトル'"
                      :maxlength="30"
                      :styles="textBoxStyle"
                      v-model="editData.title"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <image-tag-area
                  :newTags="newTags"
                  :tagIds="editData.imageTagsIds"
                  :tagNames="editData.tagNames"
                  :styles="textBoxStyle"
                  :height="{ 'max-height': '294px' }"
                  @set="setNewTag"
                  @delete="deleteTag"
                />
              </div>
            </div>
          </div>
        </template>
      </popup>
      <announce-popup
        v-if="popup.popupFlg"
        v-bind="popup"
        @cancel="resetPopup"
        @decision="closePopup"
        @close="closePopup"
        >{{ popup.content }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import ImageTagArea from '@/components/parts/molecules/ImageTagArea'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import '@/utils/validation_rules'

export default {
  name: 'ImageEditPopup',

  components: {
    Popup,
    BaseTextBox,
    ImageTagArea,
    AnnouncePopup,
    ValidationProvider,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    image: { type: Object }
  },

  data() {
    return {
      newTag: '',
      newTags: [],
      initialData: {},
      editData: {},
      textBoxStyle: { width: '225px', height: '33px' },
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 2,
        content: ''
      },
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      imageTags: 'imageTags/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.editData)
    }
  },

  created() {
    this.editData = _.cloneDeep(this.image)
    this.initialData = _.cloneDeep(this.editData)
  },

  methods: {
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 2,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.closePopup()
      }
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 2,
        content: ''
      }
    },
    closePopup() {
      this.popup.type !== 'failure' ? this.$emit('close') : this.resetPopup()
    },
    setNewTag(obj) {
      this.editData.tagNames.push(obj.tagName)
      if (obj.newTag) this.newTags.push(obj.newTag)
      if (obj.imageTagId)
        this.editData.imageTagsIds.push(Number(obj.imageTagId))
    },
    deleteTag(obj) {
      this.editData.tagNames = this.editData.tagNames.filter(
        v => v !== obj.tagName
      )
      this.editData.imageTagsIds = this.editData.imageTagsIds.filter(
        v => v !== obj.imageTagId
      )
      this.newTags = this.newTags.filter(v => v !== obj.tagName)
    },
    async update() {
      this.waitFlg = true
      const target =
        this.editData.imageType === 'medicalContentImage'
          ? 'medicalContentImages'
          : 'uploadImages'
      const res = await this.$store.dispatch(`${target}/update`, {
        image: this.editData,
        newTags: this.newTags
      })
      if (res === 'success') {
        this.popup.type = 'success'
        this.popup.title = '成功'
        this.popup.content = '編集しました'
        const imageTagsIds = this.editData.tagNames.map(tagName => {
          const sameNameImageTg = this.imageTags.find(
            imageTag => imageTag.name === tagName
          )
          return sameNameImageTg.id
        })
        this.$set(this.editData, 'imageTagsIds', imageTagsIds)
        this.$emit('update', this.editData)
        this.initialData = _.cloneDeep(this.editData)
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '編集に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-edit-popup {
  .content {
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .input-form {
      margin-top: 15px;
      display: flex;
      > .left {
        > .image-frame {
          width: 400px;
          height: 400px;
          object-fit: cover;
          border: solid 1px #{$light-grey};
          position: relative;
          > .image-area {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            width: 400px;
            height: 400px;
            > img {
              width: auto;
              height: auto;
              vertical-align: top;
            }
            > .over-image {
              max-width: 400px;
              max-height: 400px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
      > .left + .right {
        margin-left: 15px;
      }
      > .right {
        > .title {
          margin-bottom: 20px;
        }
      }
    }
  }
}
.input {
  @include validate-message();
}
</style>
