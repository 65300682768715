<template>
  <div class="patient-payment-show">
    <payment-show
      :medicalPaymentOriginalId="medicalPaymentOriginalId"
      @cancel="goToListPage"
    />
  </div>
</template>

<script>
import PaymentShow from '@/components/main_contents/payments/PaymentShow'

export default {
  name: 'PatientPaymentShow',

  components: {
    PaymentShow
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number },
    medicalPaymentOriginalId: { type: Number, required: true }
  },

  methods: {
    goToListPage() {
      this.$router.push({
        name: 'patient-payments',
        params: { ownerId: this.ownerId, patientId: this.patientId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-payment-show {
  width: 100%;
}
</style>
