<template>
  <div class="payment-show">
    <div class="wrap">
      <div class="area page-title">
        会計
      </div>
      <div class="area owner-patient">
        <payment-owner-patient-detail
          ref="detail"
          :owner="owner"
          :patient="patient"
          :medicalPayment="medicalPayment"
          :adjustableFlg="adjustableFlg"
          :completeFlg="completeFlg"
          :lookOnlyFlg="lookOnlyFlg"
          :showFlg="true"
          :reservationButtonShowFlg="true"
          :needsMiddleCalculateOrEndMedicalPaymentUpdateFlg="
            needsMiddleCalculateOrEndMedicalPaymentUpdateFlg
          "
          @redo="openDeletePopup"
          @adjust="gotoNewPaymentPage"
        />
      </div>
      <div
        class="area needs-update"
        v-if="needsMiddleCalculateOrEndMedicalPaymentUpdateFlg"
      >
        ※入院開始または入院中の診療明細の変更がされたため、{{
          needsMiddleCalculateOrEndMedicalPaymentUpdate
        }}の診療明細の更新が必要です。<br />再会計や未収金精算をしたい場合は、「カルテ」ボタンから{{
          needsMiddleCalculateOrEndMedicalPaymentUpdate
        }}の診療明細を開いて再登録後にお願いします。
      </div>
      <div class="area tables">
        <div class="group active">
          <div
            class="payment"
            v-for="payment in paymentHistory[0]"
            :key="payment.key"
          >
            <payment-data-table :payment="mixinMakeDisplayPayment(payment)" />
          </div>
          <div
            class="image-wrap"
            v-if="paymentHistory.length > 1"
            @click="mixinToggleHistoryShowFlg"
          >
            <img
              src="@/assets/images/allow3.png"
              class="bottom-allow"
              v-show="mixinHistoryShowFlg"
            />
            <img
              src="@/assets/images/allow4.png"
              class="right-allow"
              v-show="!mixinHistoryShowFlg"
            />
          </div>
        </div>
        <div class="past" v-show="mixinHistoryShowFlg">
          <div
            class="group"
            v-for="paymentGroup in paymentHistory.slice(1)"
            :key="paymentGroup[0].cancelDateTime"
          >
            <div
              class="payment"
              v-for="payment in paymentGroup"
              :key="payment.key"
            >
              <payment-data-table :payment="mixinMakeDisplayPayment(payment)" />
            </div>
            <div class="button-wrap">
              <base-button-border-orange
                class="button"
                :styles="{ width: '120px', height: '30px' }"
                @click="mixinPrintMedicalPayment(paymentGroup)"
                >旧診療明細印刷</base-button-border-orange
              >
            </div>
          </div>
        </div>
      </div>
      <div class="area buttons">
        <base-button-border-orange
          class="button"
          :styles="{ width: '200px', fontSize: '14px' }"
          @click="$emit('cancel')"
          >戻る</base-button-border-orange
        >
      </div>
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        :disabled="waitFlg"
        @close="handleClosePopup"
        @cancel="closePopup"
        @decision="redoPayment"
        >{{ popupMessage }}</announce-popup
      >
    </div>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import PaymentDataTable from '@/components/parts/organisms/PaymentDataTable'
import PaymentCommons from '@/components/mixins/PaymentCommons'
import PaymentOwnerPatientDetail from '@/components/parts/molecules/PaymentOwnerPatientDetail'
import { mapGetters } from 'vuex'
import { makePaymentDetail } from '@/utils/price_calculation'
import { needsMiddleCalculateOrEndMedicalPaymentUpdate } from '@/utils/hospitalization_utils'

export default {
  name: 'PaymentShow',

  components: {
    AnnouncePopup,
    BaseButtonBorderOrange,
    PaymentDataTable,
    PaymentOwnerPatientDetail
  },

  mixins: [PaymentCommons],

  props: {
    medicalPaymentOriginalId: { type: Number, required: true }
  },

  data() {
    return {
      paymentDetail: {},
      paymentHistory: [],
      alertFlg: false,
      waitFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      noDataFlg: false,
      handleClosePopup: this.closePopup,
      redoSucceeded: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getMedicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      hospitalizations: 'hospitalizations/getData'
    }),
    medicalPayment() {
      return this.getMedicalPaymentByOriginalId(this.medicalPaymentOriginalId)
    },
    patient() {
      return this.getPatient(this.medicalPayment?.patientId) || {}
    },
    owner() {
      return this.getOwner(this.patient?.ownerId) || {}
    },
    payments() {
      return this.paymentDetail.payments || []
    },
    validPayments() {
      return this.payments.filter(p => p.cancelDateTime === '0'.repeat(14))
    },
    totalPaymentReceived() {
      return this.paymentDetail.totalPaymentReceived || 0
    },
    totalUnpaidAmount() {
      return this.medicalPayment?.burdenAmount - this.totalPaymentReceived
    },
    adjustableFlg() {
      return this.totalUnpaidAmount > 0 && this.medicalPayment?.redoFlg === 0
    },
    completeFlg() {
      return (
        this.medicalPayment?.redoFlg === 0 &&
        this.validPayments.length > 0 &&
        this.totalUnpaidAmount === 0
      )
    },
    needsMiddleCalculateOrEndMedicalPaymentUpdate() {
      return needsMiddleCalculateOrEndMedicalPaymentUpdate(
        this.medicalPayment,
        this.hospitalizations,
        this.getMedicalPaymentByOriginalId
      )
    },
    needsMiddleCalculateOrEndMedicalPaymentUpdateFlg() {
      return Boolean(this.needsMiddleCalculateOrEndMedicalPaymentUpdate)
    }
  },

  async created() {
    this.paymentDetail = makePaymentDetail(
      this.medicalPayment,
      this.getMedicalPaymentHistory,
      this.getMedicalRecordByOriginalId,
      this.getPaymentsByMedicalPaymentId
    )
    this.paymentHistory = this.makePaymentHistory()
  },

  methods: {
    makePaymentHistory() {
      if (Object.keys(this.paymentDetail).length > 0) {
        let history = []
        let groupCancelDateTime = this.payments[0].cancelDateTime
        let [data, unpaid] = this.mixinMakePaymentData(this.payments[0], 0, 0)
        let previousUnpaidAmount = unpaid
        let paymentGroup = [data]
        for (let i = 1; i < this.payments.length; i++) {
          const payment = this.payments[i]
          if (payment.cancelDateTime !== groupCancelDateTime) {
            // end of group, insert canceled payment (取消)
            const [data, _] = this.mixinMakePaymentData(
              this.payments[i - 1],
              previousUnpaidAmount,
              1,
              this.payments
            )
            previousUnpaidAmount = 0
            paymentGroup.push(data)
            history.push(paymentGroup.reverse())
            paymentGroup = []
            groupCancelDateTime = payment.cancelDateTime
          }
          const [data, unpaid] = this.mixinMakePaymentData(
            payment,
            previousUnpaidAmount,
            0
          )
          paymentGroup.push(data)
          previousUnpaidAmount = unpaid
        }
        history.push(paymentGroup.reverse())
        return history.reverse()
      } else return []
    },
    deleteConfirm() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['取消しない', '取消する']
      this.popupMessage = '会計を取消してもよろしいですか？'
      this.alertFlg = true
    },
    openDeletePopup() {
      const params = {
        patientId: 0,
        medicalRecordOriginalId: this.medicalPayment.medicalRecordOriginalId,
        hospitalizationId: 0
      }
      const isLocked = this.$store.getters['auth/isAnotherClientLocking'](
        params
      )
      if (isLocked) {
        this.$store.dispatch('petorelu/saveNextFunction', this.deleteConfirm)
        this.$store.dispatch('petorelu/showKarteLockPopup', {
          ...params,
          isTryingDelete: true
        })
      } else {
        this.deleteConfirm()
      }
    },
    async redoPayment() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'payments/cancel',
        this.paymentHistory[0][0].id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '会計を取り消しました。'
        this.handleClosePopup = this.gotoNewPaymentPage
        this.redoSucceeded = true
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'no data' || result === 'no data in clinic') {
          this.popupMessage =
            '取消に失敗しました。\n再会計中の会計は既に取消されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '取消に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closePopup() {
      this.alertFlg = false
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToPaymentsPage()
      }
      this.title = ''
      this.type = ''
      this.buttons = ['閉じる']
      this.popupMessage = ''
    },
    gotoNewPaymentPage() {
      if (this.redoSucceeded) {
        this.$store.dispatch('auth/canGoToLockedPage', true)
        this.redoSucceeded = false
      }
      this.$router.push({
        name: 'patient-payment-new',
        params: { medicalPaymentOriginalId: this.medicalPaymentOriginalId }
      })
    },
    goToPaymentsPage() {
      this.$router.push({ name: 'patient-payments' })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-show {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap {
    min-width: 1380px;
    > .page-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    > .needs-update {
      margin-top: 5px;
      white-space: pre-line;
      color: #{$tomato};
    }
    .group {
      .image-wrap {
        width: 16px;
        padding-right: 10px;
        margin: 20px 10px 0px 10px;
        cursor: pointer;
        > .bottom-allow {
          margin-top: 5px;
          width: 15px;
          height: 9px;
        }
        > .right-allow {
          width: 9px;
          height: 15px;
        }
      }
      .button-wrap {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin: 20px;
        }
      }
    }
    .group ~ .group {
      border-top: 1px solid #{$light-grey};
    }
    > .buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      > .button {
        margin: 0 20px;
      }
      ::v-deep .base-button-border-orange,
      ::v-deep .base-button-small-orange {
        > button span {
          height: auto;
          line-height: 20px;
          font-size: 13px;
          display: inline;
          vertical-align: baseline;
        }
      }
    }
  }
}
</style>
