<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="treatment-items-input-popup"
      @keyup.esc.stop="checkDiff"
      tabindex="-2"
    >
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          :invalid="invalid"
          :contentStyle="{}"
          @cancel="checkDiff"
          @click-close-mark="checkDiff"
          @decision="registerTreatmentItems"
        >
          <template v-slot:content>
            <div class="content">
              <div class="title">診療内容 登録</div>
              <div class="treatment-item-table">
                <treatment-item-input-form
                  class="input-table"
                  v-for="(treatmentItem, i) in inputTreatmentItems"
                  :key="i"
                  :treatmentItem="treatmentItem"
                  :originalPopupPreventLeaveFlg="diffFlg"
                  :insuranceType="insuranceType"
                  @click-trash="clearPrescription(treatmentItem)"
                  @click-cross="clearPrescription(treatmentItem)"
                  @decision="registerDetail"
                />
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        v-if="popup.show"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        :layerNumber="3"
        @cancel="closePopup"
        @decision="$emit('cancel')"
        >{{ popup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import TreatmentItemInputForm from '@/components/parts/molecules/TreatmentItemInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'TreatmentItemsInputPopup',

  components: {
    Popup,
    TreatmentItemInputForm,
    FocusTrap,
    ValidationObserver,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    treatmentItems: { type: Array },
    idFlg: { type: Boolean, default: false },
    insuranceType: { type: String, default: '' }
  },

  data() {
    return {
      inputTreatmentItems: [],
      initialData: [],
      popup: {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      getTreatmentById: 'treatments/getDataById',
      getMedicineById: 'medicines/getDataById',
      getTreatmentByOriginalId: 'treatments/getDataByOriginalId',
      getMedicineByOriginalId: 'medicines/getDataByOriginalId'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputTreatmentItems)
    }
  },

  created() {
    if (this.idFlg) {
      this.inputTreatmentItems = this.treatmentItems.map((v, i) => {
        const base =
          this.getTreatmentById(v.treatmentId) ||
          this.getMedicineById(v.medicineId)
        const name = base?.name || ''
        const unitTypeId = base?.unitTypeId || ''
        return { ...v, name, unitTypeId, inputKey: i }
      })
    } else {
      this.inputTreatmentItems = this.treatmentItems.map((v, i) => {
        const base =
          this.getTreatmentByOriginalId(v.treatmentOriginalId) ||
          this.getMedicineByOriginalId(v.medicineOriginalId)
        const name = base?.name || ''
        const unitTypeId = base?.unitTypeId || ''
        return { ...v, name, unitTypeId, inputKey: i }
      })
    }
    this.initialData = _.cloneDeep(this.inputTreatmentItems)
  },

  methods: {
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          show: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          message:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.$emit('cancel')
      }
    },
    closePopup() {
      this.popup = {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      }
    },
    clearPrescription(treatmentItem) {
      treatmentItem.prescription = ''
    },
    registerDetail(treatmentItem) {
      const targetIndex = this.inputTreatmentItems.findIndex(
        v => v.inputKey === treatmentItem.inputKey
      )
      this.$set(this.inputTreatmentItems, targetIndex, treatmentItem)
    },
    registerTreatmentItems() {
      this.inputTreatmentItems.forEach(v => {
        delete v.name
        delete v.inputKey
      })
      this.$emit('decision', this.inputTreatmentItems)
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-items-input-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    min-width: 650px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .treatment-item-table {
      margin-top: 25px;
      width: 850px;
      max-height: 328px;
      padding-right: 1px;
      overflow-y: auto;
      overflow-x: hidden;
      > .input-table ~ .input-table {
        margin-top: 20px;
      }
      > .input-table:last-child {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
