<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="vaccine-reminder-edit-popup"
      tabindex="-2"
      @keyup.esc.stop="confirmClose"
    >
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          :layerNumber="2"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          :buttons="footerButtons"
          @click-close-mark="confirmClose"
          @close="confirmClose"
          @cancel="confirmClose"
          @decision="updateData"
        >
          <template v-slot:content>
            <div class="content-wrap">
              <div class="header">
                <div class="title">予防接種連絡編集</div>
                <base-button-small-red
                  v-if="lookOnlyFlg === 0 && isEditable"
                  @click="openDeletePopup"
                  >削除</base-button-small-red
                >
              </div>
              <div class="input-form">
                <div v-if="patientName" class="row name">
                  <div class="label">患者名</div>
                  <div class="value">
                    <span>{{ patientName }}</span>
                  </div>
                </div>
                <div class="row date">
                  <div class="label">連絡日</div>
                  <base-date-picker
                    v-if="isEditable"
                    :maxDate="today"
                    :clearFlg="false"
                    v-model="vaccineReminder.date"
                  />
                  <div class="value" v-else>
                    <span>{{ toJpDate(vaccineReminder.date) }}</span>
                  </div>
                </div>
                <div class="row media">
                  <div class="label">連絡方法</div>
                  <div class="value">
                    <span>{{ toJpMedia(vaccineReminder.media) }}</span>
                  </div>
                </div>
                <div class="row top vaccine">
                  <div class="label top">ワクチン</div>
                  <div class="list">
                    <base-check-box
                      class="check-box"
                      v-for="item in checkList"
                      :key="item.id"
                      :isChecked="item.value"
                      :labelText="item.name"
                      :disabled="!isEditable"
                      @input="selectVaccine(item.id, $event)"
                    />
                  </div>
                </div>
                <div class="row top content">
                  <div class="label top">内容</div>
                  <validation-provider
                    v-if="isEditable"
                    class="provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      class="textarea"
                      :styles="textAreaStyle"
                      v-model="vaccineReminder.content"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                  <div class="value content" v-else>
                    <div class="frame">
                      <div class="text">
                        <span>{{ vaccineReminder.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        class="popup"
        v-if="popup.flg"
        v-bind="popup"
        @close="close"
        >{{ popup.message }}</announce-popup
      >
      <announce-popup
        class="delete-popup"
        v-if="deletePopup.flg"
        v-bind="deletePopup"
        :disabled="waitFlg"
        @close="closeDeletePopup"
        @cancel="cancelDelete"
        @decision="decideDelete"
        >{{ deletePopup.message }}</announce-popup
      >
      <announce-popup
        class="register-popup"
        v-if="registerPopup.flg"
        v-bind="registerPopup"
        @close="closeRegisterPopup"
        >{{ registerPopup.message }}</announce-popup
      >
      <announce-popup
        class="unsaved-popup"
        v-if="unsavedPopup.flg"
        v-bind="unsavedPopup"
        @cancel="closeUnsavedPopup"
        @decision="close"
        >{{ unsavedPopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

const basePopup = {
  flg: false,
  type: '',
  title: '',
  message: '',
  buttons: ['閉じる'],
  layerNumber: 3
}

export default {
  name: 'VaccineReminderEditPopup',

  components: {
    Popup,
    BaseButtonSmallRed,
    BaseDatePicker,
    BaseCheckBox,
    BaseMultipleLinesTextBox,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientName: { type: String, default: '' },
    patientVaccines: { type: Array },
    editVaccineReminder: { type: Object }
  },

  data() {
    return {
      initialData: {},
      inputData: {},
      vaccineReminder: {},
      today: moment().format('YYYYMMDD'),
      checkList: [],
      textAreaStyle: {
        width: '100%',
        height: '200px',
        fontSize: '13px'
      },
      popup: { ...basePopup },
      deletePopup: { ...basePopup },
      registerPopup: { ...basePopup },
      unsavedPopup: { ...basePopup },
      updatedFlg: true,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      masterDatumById: 'master/getDataById'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    },
    isEditable() {
      const media = this.vaccineReminder.media
      return media === 'tel' || media === 'letter' || media === 'other'
    },
    footerButtons() {
      return this.isEditable ? ['閉じる', '登録'] : ['閉じる']
    }
  },

  created() {
    this.$nextTick(() => {
      this.$refs.observer.validate()
      this.$refs.observer.reset()
    })
    this.vaccineReminder = { ...this.editVaccineReminder }
    const reminderVaccineIds = this.vaccineReminder.vaccineIds
    const patientVaccines = this.patientVaccines
    const patientVaccineVaccineIds = patientVaccines.map(v => v.vaccineId)
    const vaccineIds = Array.from(
      new Set(patientVaccineVaccineIds.concat(reminderVaccineIds))
    )
    this.checkList = vaccineIds.map(v => {
      const vaccine = this.masterDatumById('vaccines', v)
      const isMatched = reminderVaccineIds.find(id => id === v)
      return { id: vaccine.id, name: vaccine.name, value: isMatched }
    })
    this.inputData = this.vaccineReminder
    this.initialData = { ...this.inputData }
  },

  methods: {
    makeVaccineIds() {
      const vaccineIds = this.checkList.flatMap(v => {
        if (!v.value) return []
        return v.id
      })
      return vaccineIds
    },
    makePopup(symbol, type, title, message, buttons) {
      const popup =
        symbol === 'd'
          ? this.deletePopup
          : symbol === 'r'
          ? this.registerPopup
          : symbol === 'u'
          ? this.unsavedPopup
          : this.popup
      popup.type = type || popup.type
      popup.title = title || popup.title
      popup.message = message || popup.message
      popup.buttons = buttons || popup.buttons
      popup.flg = true
    },
    toJpDate(date) {
      return moment(date, 'YYYYMMDD').format('YYYY年MM月DD日')
    },
    toJpMedia(media) {
      const conversion = {
        mail: 'メール',
        tel: '電話',
        letter: '郵便',
        other: 'その他'
      }
      return conversion[media]
    },
    close() {
      this.$emit('close')
    },
    confirmClose() {
      const message =
        '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
      const buttons = ['キャンセル', '閉じる']
      this.diffFlg
        ? this.makePopup('u', 'alert', '確認', message, buttons)
        : this.close()
    },
    closeUnsavedPopup() {
      this.unsavedPopup.flg = false
    },
    openDeletePopup() {
      const message = '削除してもよろしいですか？'
      const buttons = ['削除しない', '削除する']
      this.makePopup('d', 'alert', '注意', message, buttons)
    },
    cancelDelete() {
      this.deletePopup.flg = false
    },
    selectVaccine(id, value) {
      const item = this.checkList.find(v => v.id === id)
      item.value = value
      const vaccineIds = this.makeVaccineIds()
      this.vaccineReminder.vaccineIds = vaccineIds
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'vaccineReminders/delete',
        this.editVaccineReminder.id
      )
      this.deletePopup.buttons = ['閉じる']
      if (result === true) {
        this.initialData = { ...this.inputData }
        this.makePopup('d', 'success', '完了', '削除しました', '')
        this.updatedFlg = true
      } else {
        this.makePopup('d', 'failure', '失敗', '削除に失敗しました', '')
        if (result === 'no data in clinic') this.updatedFlg = true
      }
      this.waitFlg = false
    },
    closeDeletePopup() {
      this.deletePopup.flg = false
      if (this.updatedFlg) {
        this.close()
        this.$emit('updated')
      }
    },
    async updateData() {
      this.waitFlg = true
      const vaccineIds = this.vaccineReminder.vaccineIds.join(',')
      const vaccineReminder = { ...this.vaccineReminder, vaccineIds }
      const result = await this.$store.dispatch(
        'vaccineReminders/update',
        vaccineReminder
      )
      if (result === true) {
        this.initialData = { ...this.inputData }
        this.makePopup('r', 'success', '完了', '編集しました', ['閉じる'])
        this.updatedFlg = true
      } else {
        if (result === 'no vaccineReminder' || result === 'no data in clinic') {
          this.registerPopup.message =
            '編集に失敗しました。\n編集中の予防接種連絡は既に削除されています。'
          this.updatedFlg = true
        } else if (result === 'no patient') {
          this.registerPopup.message =
            '編集に失敗しました。\n対象の患者は既に削除されています。'
          this.updatedFlg = true
        } else {
          this.registerPopup.message = '編集に失敗しました'
        }
        this.makePopup('r', 'failure', '失敗', '', ['閉じる'])
      }
      this.waitFlg = false
    },
    closeRegisterPopup() {
      this.registerPopup.flg = false
      if (this.updatedFlg) {
        this.close()
        this.$emit('updated')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccine-reminder-edit-popup {
  .content-wrap {
    box-sizing: border-box;
    width: 640px;
    margin-bottom: 20px;
    padding: 0 5px 0;
    > .header {
      display: flex;
      justify-content: space-between;
      > .title {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
      }
    }
    > .input-form {
      margin-top: 25px;
      > .row {
        display: flex;
        align-items: center;
        @include validate-message();
        &.top {
          align-items: normal;
        }
        > .provider .error {
          // date-pickerと被らないようにするため
          z-index: 0;
        }
        ~ .row {
          margin-top: 20px;
        }
        > .label {
          display: flex;
          width: 140px;
          font-size: 15px;
          &.top {
            line-height: 33px;
          }
        }
        > .value {
          flex: 1;
          width: 0;
          font-size: 15px;
        }
        > .provider {
          flex: 1;
        }
        > .list {
          margin-top: 3px;
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          max-height: 150px;
          overflow-y: auto;
          > .check-box {
            display: flex;
            align-items: center;
            height: 30px;
            width: 50%;
          }
        }
        > .content {
          margin-top: 3px;
          flex: 1;
          width: 0;
          > .frame {
            box-sizing: border-box;
            border-radius: 4px;
            border: solid 1px #{$light-grey};
            overflow: hidden;
            > .text {
              box-sizing: border-box;
              padding: 6px 8px;
              height: 200px;
              font-size: 13px;
              white-space: pre-wrap;
              word-wrap: break-word;
              overflow-y: auto;
            }
          }
        }
      }
    }
  }
}
</style>
