<template>
  <div class="base-button-in-medical-content">
    <button
      class="button"
      type="button"
      :style="styles"
      @click="$emit('click', text)"
    >
      <img
        v-if="img"
        class="icon"
        :src="require(`@/assets/images/${img}`)"
        alt="`${img}`"
      />
      <div v-if="text" class="button-text">{{ text }}</div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonInMedicalContent',

  props: {
    img: { type: String, default: '' },
    text: { type: String, default: '' },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-in-medical-content {
  > .button {
    width: 108px;
    height: 91px;
    border-radius: 5px;
    border: solid 1px #{$pumpkin};
    background-color: #{$white};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      height: 89px;
      margin: 2px auto 0 auto;
    }
    > .icon {
      display: block;
      margin: 0 auto;
    }
    > .button-text {
      margin: 15px 0 0;
      font-size: 10px;
      text-align: center;
      color: #{$pumpkin};
    }
  }
}
</style>
