import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      mixinHistoryShowFlg: true
    }
  },

  computed: {
    ...mapGetters({
      getMedicalPaymentById: 'medicalPayments/getDataById'
    })
  },

  methods: {
    mixinMakePaymentData(
      payment,
      previousUnpaidAmount,
      cancelFlg = 0,
      payments = []
    ) {
      const burdenAmount =
        previousUnpaidAmount === 0 || cancelFlg
          ? this.getMedicalPaymentById(payment.medicalPaymentId).burdenAmount
          : 0
      const billedAmount = previousUnpaidAmount || burdenAmount
      const cancelAmount = burdenAmount - previousUnpaidAmount
      const paymentReceived =
        cancelFlg === 1 ? cancelAmount : payment.cash + payment.card //④入金額。キャンセルの場合はcashと同じになる。
      const unpaidAmount = billedAmount - paymentReceived + payment.change
      let cash = null
      let card = null
      if (cancelFlg === 1) {
        const groupPayments = payments.filter(
          v => v.cancelDateTime === payment.cancelDateTime
        )
        const paid = groupPayments.reduce(
          (paid, payment) => {
            paid.cash += payment.cash - payment.change
            paid.card += payment.card
            return paid
          },
          { cash: 0, card: 0 }
        )
        cash = paid.cash
        card = paid.card
      }
      return [
        {
          ...payment,
          cash: cash || payment.cash,
          card: card || payment.card,
          billedAmount,
          burdenAmount,
          paymentReceived,
          unpaidAmount,
          previousUnpaidAmount,
          cancelFlg,
          cancelAmount,
          orangeFlg: payment.cancelDateTime === '0'.repeat(14),
          key: 2 * payment.id + cancelFlg
        },
        unpaidAmount
      ]
    },
    mixinMakeDisplayPayment(payment) {
      const cancelDateTime = moment(payment.cancelDateTime, 'YYYYMMDDHHmmss')
      return {
        ...payment,
        payDateTime: moment(payment.payDateTime, 'YYYYMMDDHHmmss').format(
          'YYYY年MM月DD日HH時mm分'
        ),
        cancelDateTime: cancelDateTime.isValid()
          ? cancelDateTime.format('YYYY年MM月DD日HH時mm分')
          : ''
      }
    },
    mixinToggleHistoryShowFlg() {
      this.mixinHistoryShowFlg = !this.mixinHistoryShowFlg
    },
    mixinPrintMedicalPayment(paymentGroup) {
      const pastMedicalPayment = this.getMedicalPaymentById(
        paymentGroup[0].medicalPaymentId
      )
      const pastPaymentCreatedAt = moment(paymentGroup[0].createdAt).format(
        'YYYYMMDDHHmmss'
      )
      this.$refs.detail.openPrintPopup(
        'medicalPayment',
        pastMedicalPayment,
        pastPaymentCreatedAt
      )
    }
  }
}
