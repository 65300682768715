<template>
  <div class="payment-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="wrap">
        <div class="area page-title">
          会計
        </div>
        <div class="area owner-patient">
          <payment-owner-patient-detail
            ref="detail"
            :owner="owner"
            :patient="patient"
            :medicalPayment="medicalPayment"
            :lookOnlyFlg="lookOnlyFlg"
          />
        </div>
        <div class="area needs-update">
          <div
            v-if="needsMiddleCalculateOrEndMedicalPaymentUpdateFlg"
            data-test="payment-update"
          >
            ※入院開始または入院中の診療明細の変更がされたため、{{
              needsMiddleCalculateOrEndMedicalPaymentUpdate
            }}の診療明細の更新が必要です。 <br />「カルテ」ボタンから{{
              needsMiddleCalculateOrEndMedicalPaymentUpdate
            }}の診療明細を開いて再登録後、会計をお願いします。
          </div>
          <div v-else-if="needsInsuranceUpdate" data-test="insurance-update">
            ※「診療明細」タブから開いてそれぞれの診療明細を再登録した後、会計をお願いします。
          </div>
        </div>
        <div class="area tables">
          <payment-data-table
            class="new-payment"
            :payment="displayNewPayment"
            :inputPayment="newPayment"
            :inputFlg="true"
            :needsMiddleCalculateOrEndMedicalPaymentUpdateFlg="
              needsMiddleCalculateOrEndMedicalPaymentUpdateFlg
            "
            :needsInsuranceUpdate="needsInsuranceUpdate"
          />
          <div
            class="image-wrap"
            v-if="
              paymentHistory.length > 0 &&
                paymentHistory[0][0].cancelDateTime !== '0'.repeat(14)
            "
            @click="mixinToggleHistoryShowFlg"
          >
            <img
              src="@/assets/images/allow3.png"
              class="bottom-allow"
              v-show="mixinHistoryShowFlg"
            />
            <img
              src="@/assets/images/allow4.png"
              class="right-allow"
              v-show="!mixinHistoryShowFlg"
            />
          </div>
          <div
            class="group latest-payment-group"
            v-if="paymentHistory.length > 0"
            v-show="
              paymentHistory[0][0].cancelDateTime === '0'.repeat(14) ||
                mixinHistoryShowFlg
            "
          >
            <div
              class="payment"
              v-for="payment in paymentHistory[0]"
              :key="payment.key"
            >
              <payment-data-table :payment="mixinMakeDisplayPayment(payment)" />
            </div>
            <div
              class="image-wrap"
              v-if="paymentHistory[0][0].cancelDateTime === '0'.repeat(14)"
              @click="mixinToggleHistoryShowFlg"
            >
              <img
                src="@/assets/images/allow3.png"
                class="bottom-allow"
                v-show="mixinHistoryShowFlg"
              />
              <img
                src="@/assets/images/allow4.png"
                class="right-allow"
                v-show="!mixinHistoryShowFlg"
              />
            </div>
            <div
              class="button-wrap"
              v-if="paymentHistory[0][0].cancelDateTime !== '0'.repeat(14)"
            >
              <base-button-border-orange
                class="button"
                :styles="{ width: '120px', height: '30px' }"
                @click="mixinPrintMedicalPayment(paymentHistory[0])"
                >旧診療明細印刷</base-button-border-orange
              >
            </div>
          </div>
          <div
            class="past-payment-groups"
            v-if="paymentHistory.length > 1"
            v-show="mixinHistoryShowFlg"
          >
            <div
              class="group"
              v-for="paymentGroup in paymentHistory.slice(1)"
              :key="paymentGroup[0].cancelDateTime"
            >
              <div
                class="payment"
                v-for="payment in paymentGroup"
                :key="payment.key"
              >
                <payment-data-table
                  :payment="mixinMakeDisplayPayment(payment)"
                />
              </div>
              <div class="button-wrap">
                <base-button-border-orange
                  class="button"
                  :styles="{ width: '120px', height: '30px' }"
                  @click="mixinPrintMedicalPayment(paymentGroup)"
                  >旧診療明細印刷</base-button-border-orange
                >
              </div>
            </div>
          </div>
        </div>
        <div class="area buttons">
          <base-button-border-orange
            class="button"
            :styles="{ width: '200px', fontSize: '14px' }"
            @click="$emit('cancel')"
            >戻る</base-button-border-orange
          >
          <base-button-small-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="
              invalid ||
                waitFlg ||
                needsMiddleCalculateOrEndMedicalPaymentUpdateFlg ||
                needsInsuranceUpdate
            "
            :styles="{ width: '200px', fontSize: '14px' }"
            @click="handleCreatePaymentClick"
            >登録
          </base-button-small-orange>
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="announcePopupFlg"
        :type="announcePopupData.type"
        :title="announcePopupData.title"
        :buttons="announcePopupData.buttons"
        :disabled="waitFlg"
        @close="closePopup"
        @cancel="cancelPopup"
        @decision="announcePopupData.decision"
        >{{ announcePopupData.popupMessage }}</announce-popup
      >
      <kick-out-popup />
    </validation-observer>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import PaymentDataTable from '@/components/parts/organisms/PaymentDataTable'
import PaymentCommons from '@/components/mixins/PaymentCommons'
import PaymentOwnerPatientDetail from '@/components/parts/molecules/PaymentOwnerPatientDetail'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import KickOutPopup from '@/components/popups/KickOutPopup'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import { makePaymentDetail } from '@/utils/price_calculation'
import { needsMiddleCalculateOrEndMedicalPaymentUpdate } from '@/utils/hospitalization_utils'
import { makeInsuranceType } from '@/utils/price_calculation'

export default {
  name: 'PaymentNew',

  components: {
    AnnouncePopup,
    BaseButtonBorderOrange,
    BaseButtonSmallOrange,
    PaymentDataTable,
    PaymentOwnerPatientDetail,
    UnsavedLeavePopup,
    KickOutPopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, PaymentCommons],

  props: {
    medicalPaymentOriginalId: { type: Number, required: true }
  },

  data() {
    return {
      newPayment: {
        ownerId: null,
        patientId: null,
        medicalPaymentId: null,
        payDateTime: '',
        billedAmount: 0,
        burdenAmount: 0,
        unpaidAmount: 0,
        previousUnpaidAmount: 0,
        cash: 0,
        card: 0,
        orangeFlg: true
      },
      displayNewPayment: {},
      payments: [],
      paymentHistory: [],
      waitFlg: false,
      announcePopupFlg: false,
      announcePopupData: {
        type: '',
        title: '',
        popupMessage: '',
        buttons: ['閉じる'],
        decision: () => {}
      },
      completeFlg: false,
      noDataFlg: false,
      insuranceType: ''
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      patients: 'patients/getData',
      getMedicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      hospitalizations: 'hospitalizations/getData'
    }),
    medicalPayment() {
      return this.getMedicalPaymentByOriginalId(this.medicalPaymentOriginalId)
    },
    patient() {
      return this.getPatient(this.medicalPayment.patientId)
    },
    owner() {
      return this.getOwner(this.patient.ownerId)
    },
    needsMiddleCalculateOrEndMedicalPaymentUpdate() {
      return needsMiddleCalculateOrEndMedicalPaymentUpdate(
        this.medicalPayment,
        this.hospitalizations,
        this.getMedicalPaymentByOriginalId
      )
    },
    needsMiddleCalculateOrEndMedicalPaymentUpdateFlg() {
      return Boolean(this.needsMiddleCalculateOrEndMedicalPaymentUpdate)
    },
    needsInsuranceUpdate() {
      if (
        !this.medicalPayment.endHospitalizationFlg &&
        !this.medicalPayment.middleCalculateFlg
      )
        return false
      if (this.medicalPayment.insuranceType !== this.insuranceType) return true
      const medicalPaymentOriginalIds = this.hospitalizations.find(
        v =>
          v.endMedicalPaymentOriginalId === this.medicalPaymentOriginalId ||
          v.middleCalculateMedialPaymentOriginalId ===
            this.medicalPaymentOriginalId
      ).medicalPaymentOriginalIds
      const medicalPaymentsInsuranceTypes = medicalPaymentOriginalIds.map(
        v => this.getMedicalPaymentByOriginalId(v).insuranceType
      )
      const insuranceDiff = medicalPaymentsInsuranceTypes.some(
        v => v !== this.medicalPayment.insuranceType
      )
      return insuranceDiff
    }
  },

  watch: {
    newPayment: {
      deep: true,
      handler() {
        this.displayNewPayment = this.makeDisplayNewPayment(this.newPayment)
        this.newPayment.change = Math.max(
          0,
          this.newPayment.cash +
            this.newPayment.card -
            this.newPayment.billedAmount
        )
      }
    },
    patients: function() {
      this.insuranceType = makeInsuranceType(
        this.medicalPayment,
        this.getPatient(this.patient.id)
      )
    }
  },

  async created() {
    this.payments = makePaymentDetail(
      this.medicalPayment,
      this.getMedicalPaymentHistory,
      this.getMedicalRecordByOriginalId,
      this.getPaymentsByMedicalPaymentId
    ).payments
    this.paymentHistory = this.makePaymentHistory()
    const latestPayment =
      this.paymentHistory.length > 0 ? this.paymentHistory[0][0] : null
    const previousUnpaidAmount =
      latestPayment?.cancelDateTime === '0'.repeat(14)
        ? latestPayment.unpaidAmount
        : 0
    const burdenAmount = this.medicalPayment.burdenAmount
    const billedAmount = previousUnpaidAmount || burdenAmount
    this.newPayment = {
      ...this.newPayment,
      ownerId: this.owner.id,
      patientId: this.patient.id,
      billedAmount,
      burdenAmount: previousUnpaidAmount ? 0 : burdenAmount,
      medicalPaymentId: this.medicalPayment.id,
      unpaidAmount: billedAmount,
      previousUnpaidAmount
    }
    this.displayNewPayment = this.makeDisplayNewPayment(this.newPayment)
    this.mixinInputData = this.newPayment
    this.mixinSetInitialData()
    this.insuranceType = makeInsuranceType(
      this.medicalPayment,
      this.getPatient(this.patient.id)
    )
  },

  methods: {
    makeDisplayNewPayment(payment) {
      const paymentReceived = payment.cash + payment.card || 0 //入金額
      const unpaidAmount = Math.max(0, payment.billedAmount - paymentReceived)
      const change = Math.max(0, paymentReceived - payment.billedAmount)
      return { ...payment, paymentReceived, change, unpaidAmount }
    },
    makePaymentHistory() {
      if (this.payments.length > 0) {
        let history = []
        let groupCancelDateTime = this.payments[0].cancelDateTime
        let [data, unpaid] = this.mixinMakePaymentData(this.payments[0], 0, 0)
        let previousUnpaidAmount = unpaid
        let paymentGroup = [data]
        for (let i = 1; i < this.payments.length; i++) {
          const payment = this.payments[i]
          if (payment.cancelDateTime !== groupCancelDateTime) {
            // end of group, insert canceled payment (取消)
            const [data, _] = this.mixinMakePaymentData(
              this.payments[i - 1],
              previousUnpaidAmount,
              1,
              this.payments
            )
            previousUnpaidAmount = 0
            paymentGroup.push(data)
            history.push(paymentGroup.reverse())
            paymentGroup = []
            groupCancelDateTime = payment.cancelDateTime
          }
          const [data, unpaid] = this.mixinMakePaymentData(
            payment,
            previousUnpaidAmount,
            0
          )
          paymentGroup.push(data)
          previousUnpaidAmount = unpaid
        }
        const paymentsLength = this.payments.length
        if (
          this.payments[paymentsLength - 1].cancelDateTime !== '0'.repeat(14)
        ) {
          const [data, _] = this.mixinMakePaymentData(
            this.payments[paymentsLength - 1],
            previousUnpaidAmount,
            1,
            this.payments
          )
          paymentGroup.push(data)
        }
        history.push(paymentGroup.reverse())
        return history.reverse()
      } else return []
    },
    handleCreatePaymentClick() {
      if (
        this.newPayment.billedAmount >
        this.newPayment.cash + this.newPayment.card
      ) {
        this.announcePopupData = {
          title: '注意',
          type: 'alert',
          popupMessage: '未収金があります。\nこのまま登録してよろしいですか？',
          buttons: ['登録しない', '登録する'],
          decision: this.createPayment
        }
        this.announcePopupFlg = true
      } else this.createPayment()
    },
    async createPayment() {
      this.waitFlg = true
      const {
        orangeFlg,
        previousUnpaidAmount,
        billedAmount,
        burdenAmount,
        unpaidAmount,
        ...sendData
      } = this.newPayment
      const result = await this.$store.dispatch('payments/create', {
        payment: sendData,
        cancelFlg:
          this.paymentHistory.length > 0 &&
          this.paymentHistory[0][0].cancelDateTime !== '0'.repeat(14)
      })
      if (result === true) {
        this.mixinSetInitialData()
        this.announcePopupData.type = 'success'
        this.announcePopupData.title = '完了'
        this.announcePopupData.popupMessage = '登録しました'
        this.announcePopupData.buttons = ['閉じる']
        this.completeFlg = true
      } else {
        this.announcePopupData.type = 'failure'
        this.announcePopupData.title = '失敗'
        this.announcePopupData.buttons = ['閉じる']
        if (result === 'no data in clinic') {
          this.announcePopupData.popupMessage =
            '登録に失敗しました。\n診療明細は既に取消されています。'
          this.noDataFlg = true
        } else if (result === 'no medicalPayment') {
          this.announcePopupData.popupMessage =
            '登録に失敗しました。\n診療明細は既に取消されています。'
          this.noDataFlg = true
        } else {
          this.announcePopupData.popupMessage = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.announcePopupFlg = true
    },
    closePopup() {
      this.announcePopupFlg = false
      if (this.announcePopupData.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        if (this.completeFlg) {
          this.$router.push({
            name: 'patient-payment-show',
            params: { medicalPaymentOriginalId: this.medicalPaymentOriginalId }
          })
        }
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToPaymentsPage()
      }
      this.announcePopupData = {
        title: '',
        buttons: ['閉じる'],
        type: '',
        popupMessage: '',
        decision: () => {}
      }
    },
    cancelPopup() {
      this.announcePopupFlg = false
      this.announcePopupData = {
        title: '',
        buttons: ['閉じる'],
        type: '',
        popupMessage: '',
        decision: () => {}
      }
    },
    goToPaymentsPage() {
      this.$router.push({ name: 'patient-payments' })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-new {
  width: 100%;
  text-align: left;
  overflow: auto;
  .wrap {
    min-width: 1380px;
    > .page-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    > .needs-update {
      margin-top: 5px;
      white-space: pre-line;
      color: #{$tomato};
    }
    > .buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      > .button {
        margin: 0 20px;
      }
      ::v-deep .base-button-border-orange,
      ::v-deep .base-button-small-orange {
        > button span {
          height: auto;
          line-height: 20px;
          font-size: 13px;
          display: inline;
          vertical-align: baseline;
        }
      }
    }
    .group {
      .button-wrap {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin: 20px;
        }
      }
    }
    .group ~ .group {
      border-top: 1px solid #{$light-grey};
    }
    .image-wrap {
      width: 16px;
      padding-right: 10px;
      margin: 20px 10px 0px 10px;
      cursor: pointer;
      > .bottom-allow {
        margin-top: 5px;
        width: 15px;
        height: 9px;
      }
      > .right-allow {
        width: 9px;
        height: 15px;
      }
    }
  }
}
</style>
