<template>
  <div class="prescription-detail-input-form">
    <div class="title">
      <span class="image-allow" @click="$emit('click')">
        <img
          src="@/assets/images/allow3.png"
          class="bottom-allow"
          v-show="showFlg"
        />
        <img
          src="@/assets/images/allow4.png"
          class="right-allow"
          v-show="!showFlg"
        />
      </span>
      処方入力補助フォーム
    </div>
    <div class="prescription-table" v-show="showFlg">
      <div class="row single-dose">
        <div class="title">1回量</div>
        <div class="content">
          <div class="text-box">
            <base-text-box
              v-model="singleDose.text"
              :styles="{
                width: '120px',
                height: '30px',
                margin: '10px 0 0 20px'
              }"
            />
            <div class="select-box-area">
              <base-select-box
                :value="singleDose.selectUnitType"
                v-model="singleDose.selectUnitType"
                :selectData="unitTypesSelectData"
                :styles="{ width: '80px', height: '30px' }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row daily-dose">
        <div class="title">1日量</div>
        <div class="content">
          <div class="row-button one">
            <base-radio-button
              v-for="option in optionsRow1"
              :key="option.id"
              :option="option"
              :inputStyles="dailyDoseInputStyles"
              v-model="dailyDose.selectValue"
            />
          </div>
          <div class="row-button two">
            <base-radio-button
              v-for="option in optionsRow2"
              :key="option.id"
              :option="option"
              :styles="
                option.id === 5 || option.id === 6
                  ? { marginRight: '17px' }
                  : {}
              "
              :inputStyles="dailyDoseInputStyles"
              v-model="dailyDose.selectValue"
            />
          </div>
          <div class="row-button three">
            <base-radio-button
              v-for="option in optionsRow3"
              :key="option.id"
              :option="option"
              :styles="option.id === 8 ? { marginRight: '17px' } : {}"
              :inputStyles="dailyDoseInputStyles"
              v-model="dailyDose.selectValue"
            />
          </div>
          <div class="row-button four">
            <base-radio-button
              :option="options[10]"
              :styles="{ marginRight: '2px' }"
              :inputStyles="dailyDoseInputStyles"
              v-model="dailyDose.selectValue"
            />
            <base-text-box
              :styles="{ width: '220px', height: '30px', marginTop: '-5px' }"
              v-model="dailyDose.freeText"
            />
          </div>
        </div>
      </div>
      <div class="row taking-time">
        <div class="title">服用時期</div>
        <div class="content">
          <div class="row one">
            <base-check-box
              :isChecked="takingTime.morningFlg"
              v-model="takingTime.morningFlg"
              :labelText="'朝'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.noonFlg"
              v-model="takingTime.noonFlg"
              :labelText="'昼'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.nightFlg"
              v-model="takingTime.nightFlg"
              :labelText="'夜'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.beforeMealFlg"
              v-model="takingTime.beforeMealFlg"
              :labelText="'食前'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.afterMealFlg"
              v-model="takingTime.afterMealFlg"
              :labelText="'食後'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.betweenMealFlg"
              v-model="takingTime.betweenMealFlg"
              :labelText="'食間'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.beforeSleepFlg"
              v-model="takingTime.beforeSleepFlg"
              :labelText="'寝る前'"
              :styles="checkBoxStyles"
            />
            <base-check-box
              :isChecked="takingTime.useAsNeededFlg"
              v-model="takingTime.useAsNeededFlg"
              :labelText="'頓服'"
              :styles="checkBoxStyles"
            />
          </div>
          <div class="row two">
            <base-check-box
              :isChecked="takingTime.takingTimeFreeFlg"
              v-model="takingTime.takingTimeFreeFlg"
              :labelText="''"
            />
            <base-text-box
              :styles="{
                width: '220px',
                height: '30px',
                margin: '-5px 0 0 7px'
              }"
              v-model="takingTime.takingTimeFree"
            />
          </div>
        </div>
      </div>
      <div class="row oral">
        <div class="title">経路</div>
        <div class="content">
          <div class="row one">
            <base-check-box
              :isChecked="oral.oralAdministrationFlg"
              v-model="oral.oralAdministrationFlg"
              :labelText="'経口投与'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.subcutaneousAdministrationFlg"
              v-model="oral.subcutaneousAdministrationFlg"
              :labelText="'皮下投与'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.subcutaneousDripFlg"
              v-model="oral.subcutaneousDripFlg"
              :labelText="'皮下点滴'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.noseDropsFlg"
              v-model="oral.noseDropsFlg"
              :labelText="'点鼻'"
              :styles="checkBoxStyles2"
            />
          </div>
          <div class="row two">
            <base-check-box
              :isChecked="oral.earDropsBothFlg"
              v-model="oral.earDropsBothFlg"
              :labelText="'点耳(両)'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.earDropsLeftFlg"
              v-model="oral.earDropsLeftFlg"
              :labelText="'点耳(左)'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.earDropsRightFlg"
              v-model="oral.earDropsRightFlg"
              :labelText="'点耳(右)'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.eyesBothFlg"
              v-model="oral.eyesBothFlg"
              :labelText="'眼(両)'"
              :styles="{ width: '80px' }"
            />
            <base-check-box
              :isChecked="oral.eyesLeftFlg"
              v-model="oral.eyesLeftFlg"
              :labelText="'眼(左)'"
              :styles="{ width: '80px' }"
            />
            <base-check-box
              :isChecked="oral.eyesRightFlg"
              v-model="oral.eyesRightFlg"
              :labelText="'眼(右）'"
            />
          </div>
          <div class="row three">
            <base-check-box
              :isChecked="oral.rectalAdministrationFlg"
              v-model="oral.rectalAdministrationFlg"
              :labelText="'直腸内投与'"
              :styles="checkBoxStyles2"
            />
            <base-check-box
              :isChecked="oral.intravaginalAdministrationFlg"
              v-model="oral.intravaginalAdministrationFlg"
              :labelText="'膣内投与'"
              :styles="checkBoxStyles2"
            />
          </div>
          <div class="row four">
            <base-check-box
              :isChecked="oral.oralFreeFlg"
              v-model="oral.oralFreeFlg"
              :labelText="''"
            />
            <base-text-box
              :styles="{
                width: '220px',
                height: '30px',
                margin: '-5px 0 0 7px'
              }"
              v-model="oral.oralFree"
            />
          </div>
        </div>
      </div>
      <div class="row period">
        <div class="title">期間</div>
        <div class="content">
          <div class="text-box">
            <base-text-box
              v-model="period.text"
              :styles="{
                width: '120px',
                height: '30px',
                margin: '10px 0 0 20px'
              }"
            />
            <div class="select-box-area">
              <base-select-box
                :value="period.selectPeriod"
                v-model="period.selectPeriod"
                :selectData="periodTypeSelectData"
                :styles="{ width: '80px', height: '30px' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox.vue'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton.vue'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import { mapGetters } from 'vuex'

export default {
  name: 'PrescriptionDetailInputForm',

  components: {
    BaseTextBox,
    BaseSelectBox,
    BaseRadioButton,
    BaseCheckBox
  },

  props: {
    showFlg: { type: Boolean, default: true },
    singleDose: { type: Object },
    dailyDose: { type: Object },
    options: { type: Array },
    takingTime: { type: Object },
    oral: { type: Object },
    period: { type: Object },
    periodTypeSelectData: { type: Array }
  },

  data() {
    return {
      dailyDoseInputStyles: { paddingLeft: '15px', paddingBottom: '2px' },
      checkBoxStyles: { marginRight: '15px' },
      checkBoxStyles2: { width: '100px' }
    }
  },
  computed: {
    ...mapGetters({
      getMasterData: 'master/getData'
    }),
    unitTypesSelectData() {
      return this.getMasterData('unitTypes')
    },
    optionsRow1() {
      return this.options.filter(v => [1, 2, 3, 4].includes(v.id))
    },
    optionsRow2() {
      return this.options.filter(v => [5, 6, 7].includes(v.id))
    },
    optionsRow3() {
      return this.options.filter(v => [8, 9, 10].includes(v.id))
    }
  }
}
</script>

<style lang="scss" scoped>
.prescription-detail-input-form {
  > .title {
    font-size: 15px;
    > .image-allow {
      display: inline-block;
      width: 16px;
      padding-right: 5px;
      cursor: pointer;
      > .right-allow {
        margin-left: 5px;
      }
    }
  }
  > .prescription-table {
    margin-top: 5px;
    width: 740px;
    box-sizing: border-box;
    border: solid 1px #{$light-grey};
    > .row {
      display: flex;
      border-bottom: solid 1px #{$light-grey};
      font-size: 13px;
      > .title {
        width: 150px;
        text-align: center;
        background-color: #{$very-light-shade-orange};
      }
      > .content {
        width: 590px;
      }
      &.single-dose {
        height: 50px;
        > .title {
          line-height: 50px;
        }
        > .content {
          > .text-box {
            display: flex;
            > .select-box-area {
              margin: 10px 0 0 20px;
            }
          }
        }
      }
      &.daily-dose {
        height: 150px;
        > .title {
          line-height: 150px;
        }
        > .content {
          > .row-button {
            display: flex;
            margin: 11px 0 0 20px;
            &.one {
              margin: 19px 0 0 20px;
            }
          }
        }
      }
      &.taking-time {
        height: 98px;
        > .title {
          line-height: 98px;
        }
        > .content {
          > .row {
            display: flex;
            &.one {
              margin: 23px 0 0 21px;
            }
            &.two {
              margin: 10px 0 0 21px;
            }
          }
        }
      }
      &.oral {
        height: 152px;
        > .title {
          line-height: 152px;
        }
        > .content {
          > .row {
            display: flex;
            margin: 10px 0 0 21px;
            &.one {
              margin: 23px 0 0 21px;
            }
          }
        }
      }
      &.period {
        height: 50px;
        border-bottom: none;
        > .title {
          line-height: 50px;
        }
        > .content {
          > .text-box {
            display: flex;
            text-align: left;
            > .select-box-area {
              margin: 10px 0 0 20px;
            }
          }
        }
      }
    }
  }
}
</style>
