<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="problem-list-popup"
      @keyup.esc.self.stop="checkDiff"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="checkDiff"
        @click-close-mark="checkDiff"
      >
        <template v-slot:content>
          <div class="content">
            <div class="header">
              <div class="title">プロブレムリスト</div>
              <div class="buttons" v-if="!sortModeFlg">
                <base-button-register
                  class="new-button"
                  v-if="lookOnlyFlg === 0"
                  :text="'新規登録'"
                  @click="newPopupFlg = true"
                />
                <base-sort-button
                  class="sort-button"
                  v-if="lookOnlyFlg === 0"
                  @click="sortModeFlg = true"
                />
              </div>
              <div class="area buttons" v-else>
                <base-button-small-white
                  class="cancel-button"
                  @click="sortModeFlg = false"
                  >キャンセル</base-button-small-white
                >
                <base-button-small-orange
                  class="sort-button"
                  v-if="lookOnlyFlg === 0"
                  @click="updateOrder"
                  :disabled="waitFlg"
                  >並び順登録</base-button-small-orange
                >
              </div>
            </div>
            <div class="problem-list-area">
              <div class="treatment-priority">治療優先度</div>
              <div class="problem-list">
                <div class="list-header">
                  <div class="item name">プロブレム</div>
                  <div class="item injury-date">受傷日</div>
                  <div class="item healing-date">治癒日</div>
                  <div class="item space"></div>
                </div>
                <div v-if="!sortModeFlg" class="list-body">
                  <problem-list-row
                    class="row"
                    v-for="problem in displayProblems"
                    :key="problem.id"
                    :problem="problem"
                    :sortModeFlg="sortModeFlg"
                    @click="problemId => openEditPopup(problemId)"
                  />
                </div>
                <draggable
                  v-else
                  class="list-body draggable"
                  v-bind="options"
                  v-model="displayProblems"
                >
                  <problem-list-row
                    class="row drag-element"
                    v-for="problem in displayProblems"
                    :key="problem.id"
                    :problem="problem"
                    :sortModeFlg="sortModeFlg"
                  />
                </draggable>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <problem-new-popup
        v-if="newPopupFlg"
        :patientId="patientId"
        @close="newPopupFlg = false"
      />
      <problem-edit-popup
        v-if="editPopupFlg"
        :id="targetProblemId"
        @close="closeEditPopup"
      />
      <announce-popup
        v-if="popup.popupFlg"
        v-bind="popup"
        @cancel="resetPopup"
        @decision="closePopup"
        @close="closePopup"
        >{{ popup.content }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import ProblemListRow from '@/components/parts/organisms/ProblemListRow'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import ProblemNewPopup from '@/components/popups/ProblemNewPopup'
import ProblemEditPopup from '@/components/popups/ProblemEditPopup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ProblemListPopup',

  components: {
    FocusTrap,
    Popup,
    ProblemListRow,
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    ProblemNewPopup,
    ProblemEditPopup,
    AnnouncePopup,
    Draggable
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientId: { type: Number }
  },

  data() {
    return {
      options: {
        animation: 200,
        draggable: '.drag-element',
        ghostClass: 'ghost',
        dragClass: 'drag',
        forceFallback: true
      },
      newPopupFlg: false,
      editPopupFlg: false,
      targetProblemId: null,
      waitFlg: false,
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: ['閉じる'],
        layerNumber: 2,
        content: ''
      },
      sortModeFlg: false,
      inputData: [],
      initialData: []
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getProblemsByPatientId: 'problems/getDataByPatientId'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    },
    patientProblems() {
      return this.getProblemsByPatientId(this.patientId) || []
    },
    displayProblems: {
      get() {
        const data = this.sortModeFlg ? this.inputData : this.patientProblems
        return data.map(v => {
          return {
            ...v,
            formatInjuryDate: moment(v.injuryDate, 'YYYYMMDD').format(
              'YYYY年MM月DD日'
            ),
            formatHealingDate:
              v.healingDate === ''
                ? ''
                : moment(v.healingDate, 'YYYYMMDD').format('YYYY年MM月DD日')
          }
        })
      },
      set(data) {
        this.inputData = data.map(v1 =>
          this.inputData.find(v2 => v2.id === v1.id)
        )
      }
    }
  },

  watch: {
    sortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.inputData = _.cloneDeep(this.patientProblems)
      this.initialData = _.cloneDeep(this.inputData)
    },
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 2,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.closePopup()
      }
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: ['閉じる'],
        layerNumber: 2,
        content: ''
      }
    },
    closePopup() {
      this.popup.type === 'failure' || this.popup.type === 'success'
        ? this.resetPopup()
        : this.$emit('close')
    },
    openEditPopup(id) {
      this.targetProblemId = id
      this.editPopupFlg = true
    },
    closeEditPopup() {
      this.editPopupFlg = false
      this.targetProblemId = null
    },
    async updateOrder() {
      this.waitFlg = true
      const res = await this.$store.dispatch('problems/order', {
        orderIds: this.inputData.map(v => v.id),
        patientId: this.patientId
      })
      if (res) {
        this.initialData = _.cloneDeep(this.inputData)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '並び順を登録しました'
        this.sortModeFlg = false
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '並び順登録に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.problem-list-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    > .header {
      display: flex;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
        text-align: left;
      }
      > .buttons {
        margin: 0 0 0 auto;
        display: flex;
        > .sort-button {
          margin-left: 10px;
        }
      }
    }
    > .problem-list-area {
      display: flex;
      margin-top: 20px;
      > .treatment-priority {
        writing-mode: vertical-lr;
        width: 18px;
        margin: 27px 10px 0 0;
        padding: 7px 0 0 0;
        border-radius: 2px;
        background-image: linear-gradient(to bottom, #{$light-red}, #{$white});
        font-size: 13px;
        letter-spacing: 2px;
        color: #{$white};
      }
      > .problem-list {
        text-align: left;
        width: 595px;
        min-height: 226px;
        > .list-header {
          box-sizing: border-box;
          display: flex;
          height: 25px;
          border: 1px solid transparent;
          font-size: 13px;
          font-weight: bold;
          > .item {
            box-sizing: border-box;
            padding: 0 5px;
            &:not(.space) {
              border-right: 1px solid transparent;
            }
            &.name {
              width: 240px;
            }
            &.injury-date {
              width: 140px;
            }
            &.healing-date {
              width: 140px;
            }
            &.space {
              flex: 1;
            }
          }
        }
        > .list-body {
          box-sizing: border-box;
          max-height: 401px;
          border: 1px solid#{$very-light-grey};
          overflow-y: auto;
          > .row {
            box-sizing: border-box;
            display: flex;
            height: 40px;
            background-color: #{$gray_fc};
            font-size: 13px;
            & ~ .row {
              border-top: 1px solid #{$very-light-grey};
            }
            &:first-child {
              margin-top: -1px;
              border-top: 1px solid transparent;
            }
            &:nth-child(even) {
              background-color: #{$white};
            }
            &.drag-element {
              cursor: move;
            }
            &.ghost {
              visibility: hidden;
            }
            &.drag {
              color: #{$pumpkin};
            }
          }
        }
      }
    }
  }
}
</style>
