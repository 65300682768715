<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="onset-date-list-popup"
      @keyup.esc.stop="closePopup"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="closePopup"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">傷病/発症日一覧</div>
            <div class="table" v-if="onsetDates.length > 0">
              <div class="upper-frame">
                <div class="number">{{ onsetDates.length }}件</div>
              </div>
              <div class="table-header">
                <div class="cell diagnosis-1">傷病分類</div>
                <div class="cell diagnosis-2">傷病名</div>
                <div class="cell onset-date">受傷日/発症日</div>
              </div>
              <div class="table-body">
                <div class="row-wrapper">
                  <div
                    class="row"
                    data-test="row"
                    v-for="item in onsetDates"
                    :key="item.id"
                    @click="select(item)"
                  >
                    <div class="cell diagnosis-1" data-test="cell">
                      <div class="text" :title="item.diagnosis1">
                        {{ item.diagnosis1 }}
                      </div>
                    </div>
                    <div class="cell diagnosis-2" data-test="cell">
                      <div class="text" :title="item.diagnosis2">
                        {{ item.diagnosis2 }}
                      </div>
                    </div>
                    <div class="cell onset-date" data-test="cell">
                      <div class="text">{{ formatDate(item.onsetDate) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-data" v-else>対象のデータはありませんでした</div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'OnsetDateListPopup',

  components: {
    FocusTrap,
    Popup
  },

  props: {
    patientId: { type: Number }
  },

  computed: {
    ...mapGetters({
      medicalPaymentsByPatientId: 'medicalPayments/getDataByPatientId',
      anicomReportsByMedicalPaymentId: 'anicomReports/getDataByMedicalPaymentId'
    }),
    onsetDates() {
      const medicalPaymentsByPatientId =
        this.medicalPaymentsByPatientId(this.patientId) || []
      const anicomReports = medicalPaymentsByPatientId.flatMap(
        medicalPayment => {
          const anicomReportsByMedicalPaymentId =
            this.anicomReportsByMedicalPaymentId(medicalPayment.id) || []
          const sentAnicomReports = anicomReportsByMedicalPaymentId.filter(
            anicomReport => anicomReport.cancelFlg === 0
          )
          if (sentAnicomReports.length === 0) return []
          return sentAnicomReports.map(anicomReport => {
            return {
              id: anicomReport.id,
              diagnosis1: anicomReport.diagnosis1,
              diagnosis2: anicomReport.diagnosis2,
              onsetDate: anicomReport.onsetDate
            }
          })
        }
      )
      const onsetDates = anicomReports.slice().sort((a, b) => {
        const dateA = moment(a.onsetDate, 'YYYY/MM/DD').format('YYYYMMDD')
        const dateB = moment(b.onsetDate, 'YYYY/MM/DD').format('YYYYMMDD')
        return dateA === dateB ? b.id - a.id : dateA > dateB ? -1 : 1
      })
      return onsetDates
    }
  },

  methods: {
    formatDate(date) {
      return moment(date, 'YYYY/MM/DD').format('YYYY年MM月DD日（dd）')
    },
    closePopup() {
      this.$emit('close')
    },
    select(item) {
      const onsetDate = moment(item.onsetDate, 'YYYY/MM/DD').format('YYYYMMDD')
      this.$emit('select', onsetDate)
    }
  }
}
</script>

<style lang="scss" scoped>
.onset-date-list-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .table {
      margin-top: 20px;
      overflow-y: auto;
      > .upper-frame {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        border: solid #{$light-grey};
        border-width: 1px 1px 0px 1px;
        > .number {
          margin-left: 20px;
          font-size: 13px;
        }
      }
      > .table-header {
        box-sizing: border-box;
        display: flex;
        height: 40px;
        padding-right: 18px;
        border: 1px solid #{$light-grey};
        font-size: 15px;
        background-color: #{$pale};
        > .cell {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          &.diagnosis-1 {
            width: 200px;
          }
          &.diagnosis-2 {
            width: 300px;
          }
          &.onset-date {
            width: 180px;
          }
        }
        > .cell ~ .cell {
          border-left: 1px solid #{$skin};
        }
      }
      > .table-body {
        box-sizing: border-box;
        width: 100%;
        border: solid #{$light-grey};
        border-width: 0px 1px 1px 1px;
        max-height: 255px;
        overflow-y: scroll;
        overflow-x: hidden;
        > .row-wrapper {
          > .row {
            display: flex;
            min-height: 50px;
            cursor: pointer;
            &:nth-child(even) {
              background-color: #{$white_f7};
            }
            > .cell {
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px;
              &.diagnosis-1 {
                width: 200px;
              }
              &.diagnosis-2 {
                width: 300px;
              }
              &.onset-date {
                width: 180px;
              }
              > .text {
                display: inline-block;
                width: 100%;
                font-size: 15px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            > .cell + .cell {
              border-left: 1px solid;
              border-color: transparent;
            }
          }
          > .row ~ .row {
            border-top: 1px solid #{$light-grey};
          }
        }
      }
    }
    > .no-data {
      margin-top: 20px;
      width: 400px;
      font-size: 15px;
    }
  }
}
</style>
