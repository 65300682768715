<template>
  <div
    class="medical-content-input-form"
    :class="{ expansion: expansionDisplayFlg }"
  >
    <div class="medical-content-title-area">
      <div class="title">診療内容</div>
      <img
        v-if="!expansionDisplayFlg"
        src="@/assets/images/expansion_allow.png"
        class="expansion-allow"
        width="17px"
        height="17px"
        alt="expansion-allow"
        @click="$emit('expansion')"
      />
      <img
        v-if="expansionDisplayFlg"
        src="@/assets/images/shrink_allow.png"
        class="shrink-allow"
        width="17px"
        height="17px"
        alt="shrink-allow"
        @click="$emit('reduction')"
      />
    </div>
    <div class="patient-info-and-button">
      <div class="patient-info">
        <medical-content-patient-info
          :patientId="patientId"
          :displayOwner="displayOwner"
          :medicalRecord="medicalRecord"
          :styles="medicalContentPatientInfoStyles"
          :dateDisabled="dateDisabled"
          :startEndMinMax="startEndMinMax"
          :overlappedTimeErrorMessage="overlappedTimeErrorMessage"
          @input-medical-record="data => $emit('input-medical-record', data)"
        />
      </div>
      <div class="buttons">
        <base-button-in-medical-content
          v-for="buttonDatum in buttonData"
          :key="buttonDatum.id"
          :text="buttonDatum.text"
          :img="buttonDatum.img"
          class="button"
          @click="menuItemName => $emit('click-menu-item', menuItemName)"
        />
      </div>
    </div>
    <div class="tool-bar-and-button">
      <div class="tool-bar">
        <rich-text-editor-tool-bar :content="medicalContent.content" />
      </div>
      <div class="zoom">
        <div class="text">ズーム</div>
        <span class="minus-icon" @click="zoomOut">
          <span class="minus-icon-line horizontal"></span>
        </span>
        <div class="magnification">
          <input
            class="magnification-input"
            :value="magnification"
            @input="changeMagnification"
            type="number"
            ref="magnification"
          />
          <span> %</span>
        </div>
        <span class="plus-icon" @click="zoomIn">
          <span class="plus-icon-line vertical"></span>
          <span class="plus-icon-line horizontal"></span>
        </span>
      </div>
      <div class="medical-content-register">
        <base-button-medium-orange
          v-if="lookOnlyFlg === 0"
          :disabled="
            waitFlg ||
              waitFlgForGetUploadImages ||
              isCheckingApply ||
              invalidContent ||
              Boolean(overlappedTimeErrorMessage) ||
              hospitalizationUpdateFlg ||
              removeApplyFlgError
          "
          :styles="{ width: '130px' }"
          @click="$emit('create-medical-content')"
          >診療内容登録</base-button-medium-orange
        >
      </div>
    </div>
    <div class="editor-area">
      <rich-text-editor
        class="editor"
        ref="richTextEditor"
        :content="medicalContent.content"
        :richTextEditorStyles="richTextEditorStyles"
        :editorContentStyles="editorContentStyles"
        @invalid-content="val => (invalidContent = val)"
        @click-examination-result-table="
          tableInfo => $emit('click-examination-result-table', tableInfo)
        "
        @set-to-initial-medical-content="
          $emit('set-to-initial-medical-content')
        "
        @click-upload-image="image => $emit('click-upload-image', image)"
        @edit-medical-content-image="editMedicalContentImage"
        @unsaved-resized-image="$emit('unsaved-resized-image')"
      />
    </div>
  </div>
</template>

<script>
import MedicalContentPatientInfo from '@/components/parts/molecules/MedicalContentPatientInfo'
import BaseButtonInMedicalContent from '@/components/parts/atoms/BaseButtonInMedicalContent'
import RichTextEditorToolBar from '@/components/parts/organisms/RichTextEditorToolBar'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import RichTextEditor from '@/components/parts/organisms/RichTextEditor'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalContentInputForm',

  components: {
    MedicalContentPatientInfo,
    BaseButtonInMedicalContent,
    RichTextEditorToolBar,
    BaseButtonMediumOrange,
    RichTextEditor
  },

  props: {
    patientId: { type: Number },
    displayOwner: { type: Object },
    medicalRecord: { type: Object },
    medicalContent: { type: Object },
    waitFlg: { type: Boolean, default: false },
    waitFlgForGetUploadImages: { type: Boolean, default: false },
    isCheckingApply: { type: Boolean, default: false },
    expansionDisplayFlg: { type: Boolean, default: false },
    dateDisabled: { type: Boolean, default: false },
    startEndMinMax: { type: Object },
    overlappedTimeErrorMessage: { type: String },
    hospitalizationUpdateFlg: { type: Boolean },
    removeApplyFlgError: { type: Boolean }
  },

  data() {
    return {
      buttonData: [
        { id: 1, text: 'プロブレムリスト', img: 'problem_list.png' },
        { id: 2, text: '画像登録', img: 'camera.png' },
        { id: 3, text: '検査登録', img: 'examination.png' },
        { id: 4, text: 'シェーマ', img: 'shema.png' },
        { id: 5, text: 'テンプレート', img: 'template.png' },
        { id: 6, text: 'カルテの履歴', img: 'past_karte.png' }
      ],
      invalidContent: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
      // magnification: 'richTextEditor/getMagnification'
    }),
    magnification: {
      get() {
        return this.$store.getters['richTextEditor/getMagnification']
      },
      set() {}
    },
    medicalContentPatientInfoStyles() {
      return this.expansionDisplayFlg ? { width: '100%' } : {}
    },
    richTextEditorStyles() {
      return this.expansionDisplayFlg
        ? { height: '500px' }
        : { width: '844px', height: '500px' }
    },
    editorContentStyles() {
      const scaleValue = this.magnification / 100
      return {
        transform: `scale(${scaleValue})`,
        '--min-height': '500px',
        '--border': 'none'
      }
    }
  },

  methods: {
    zoomOut() {
      const setValue = this.magnification - 10
      this.watchMagnification(setValue)
      this.$store.dispatch('richTextEditor/changeMagnification', setValue)
    },
    zoomIn() {
      const setValue = this.magnification + 10
      this.watchMagnification(setValue)
      this.$store.dispatch('richTextEditor/changeMagnification', setValue)
    },
    changeMagnification(e) {
      const setValue = Number(e.target.value)
      this.watchMagnification(setValue)
      this.$store.dispatch('richTextEditor/changeMagnification', setValue)
    },
    watchMagnification(setValue) {
      this.$refs.magnification.value =
        setValue <= 0 ? 0 : 0 < setValue && setValue <= 1000 ? setValue : 1000
    },
    editMedicalContentImage(medicalContentImageId) {
      this.$emit('edit-medical-content-image', medicalContentImageId)
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-input-form {
  width: 903px;
  min-width: 903px;
  height: 881px;
  padding: 24px 30px 0 29px;
  box-sizing: border-box;
  background: rgba(252, 225, 204, 0.25);
  &.expansion {
    width: 100%;
  }
  > .medical-content-title-area {
    display: flex;
    justify-content: space-between;
    margin: 0 0 18px 0;
    align-items: center;
    > .title {
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      color: #{$greyish-brown};
    }
    > .expansion-allow {
      cursor: pointer;
      margin: -4px -10px 0 0;
    }
    > .shrink-allow {
      cursor: pointer;
      margin: -4px -10px 0 0;
    }
  }
  > .patient-info-and-button {
    display: flex;
    > .patient-info {
      flex: 1;
    }
    > .buttons {
      margin-left: 18px;
      width: 344px;
      display: flex;
      flex-wrap: wrap;
      > .button {
        &:nth-child(2) {
          margin: 0px 10px;
        }
        &:nth-child(5) {
          margin: 0px 10px;
        }
        &:nth-child(n + 4) {
          margin-top: 10px;
        }
      }
    }
  }
  > .tool-bar-and-button {
    margin-top: 31px;
    display: flex;
    > .tool-bar {
      margin-top: 6px;
    }
    > .zoom {
      margin: 0 0 0 10px;
      display: flex;
      align-items: center;
      > .text {
        font-size: 12px;
        font-weight: bold;
      }
      > .minus-icon {
        position: relative;
        width: 16px;
        height: 16px;
        margin: 0 18px;
        cursor: pointer;
        > .minus-icon-line {
          position: absolute;
          background-color: #{$greyish-brown};
          border-radius: 1px;
          &.horizontal {
            top: 7px;
            left: 0px;
            width: 16px;
            height: 2px;
          }
        }
      }
      > .plus-icon {
        position: relative;
        width: 16px;
        height: 16px;
        margin-left: 18px;
        cursor: pointer;
        > .plus-icon-line {
          position: absolute;
          background-color: #{$greyish-brown};
          border-radius: 1px;
          &.vertical {
            top: 0px;
            left: 7px;
            width: 2px;
            height: 16px;
          }
          &.horizontal {
            top: 7px;
            left: 0px;
            width: 16px;
            height: 2px;
          }
        }
      }
      > .magnification {
        font-size: 12px;
        font-weight: bold;
        .magnification-input {
          width: 60px;
          height: 24px;
          line-height: 24px;
          border-radius: 20px;
          border: solid 1px #{$light-grey};
          text-align: center;
          box-sizing: border-box;
          font-size: 13px;
          &:focus {
            outline: none;
          }
        }
      }
    }
    > .medical-content-register {
      margin-left: auto;
    }
  }
  > .editor-area {
    margin-top: 30px;
    display: flex;
    > .editor {
      flex: 1;
      width: 0;
    }
  }
}
</style>
