<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="problem-edit-popup" @keyup.esc.stop="checkDiff" tabindex="-1">
        <popup
          :invalid="invalid || waitFlg"
          :layerNumber="2"
          @cancel="checkDiff"
          @decision="update"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">プロブレム編集</div>
                <base-button-small-red
                  class="delete-button"
                  v-if="lookOnlyFlg === 0"
                  @click="openDeletePopup"
                  >削除</base-button-small-red
                >
              </div>
              <div class="input-form-wrapper">
                <problem-input-form
                  :problem="editProblem"
                  @input-injury-date="inputDate($event, 'injury')"
                  @input-healing-date="inputDate($event, 'healing')"
                  @clear-healing-date="clearHealingDate"
                />
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popup.popupFlg"
          v-bind="popup"
          :disabled="waitFlg"
          @cancel="resetPopup"
          @decision="popup.decide"
          @close="closePopup"
          >{{ popup.content }}</announce-popup
        >
      </div>
    </validation-observer>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ProblemInputForm from '@/components/parts/organisms/ProblemInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { ValidationObserver } from 'vee-validate'
import { FocusTrap } from 'focus-trap-vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ProblemEditPopup',

  components: {
    FocusTrap,
    Popup,
    BaseButtonSmallRed,
    ProblemInputForm,
    ValidationObserver,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    id: { type: Number, default: 0 }
  },

  data() {
    return {
      initialData: {},
      editProblem: {},
      waitFlg: false,
      popup: {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: '',
        decide: () => {}
      }
    }
  },

  computed: {
    ...mapGetters({
      getProblem: 'problems/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.editProblem)
    }
  },

  created() {
    const problem = this.getProblem(this.id)
    this.editProblem = _.cloneDeep(problem)
    this.initialData = _.cloneDeep(this.editProblem)
  },

  methods: {
    inputDate(date, dateType) {
      dateType === 'injury'
        ? this.$set(this.editProblem, 'injuryDate', date)
        : this.$set(this.editProblem, 'healingDate', date)
    },
    clearHealingDate() {
      this.$set(this.editProblem, 'healingDate', '')
    },
    checkDiff() {
      if (this.diffFlg) {
        this.popup = {
          popupFlg: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 3,
          content:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
          decide: () => this.closePopup()
        }
      } else {
        this.closePopup()
      }
    },
    resetPopup() {
      this.popup = {
        popupFlg: false,
        type: '',
        title: '',
        buttons: [],
        layerNumber: 3,
        content: '',
        decide: () => {}
      }
    },
    closePopup() {
      this.popup.type !== 'failure' ? this.$emit('close') : this.resetPopup()
    },
    async update() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'problems/update',
        this.editProblem
      )
      if (res === true) {
        this.initialData = _.cloneDeep(this.editProblem)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '編集しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '編集に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    },
    openDeletePopup() {
      this.popup = {
        popupFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        content: '削除してもよろしいですか？',
        layerNumber: 3,
        decide: () => this.decideDelete()
      }
    },
    async decideDelete() {
      this.waitFlg = true
      const res = await this.$store.dispatch('problems/delete', this.id)
      if (res === true) {
        this.initialData = _.cloneDeep(this.editProblem)
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.content = '削除しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.content = '削除に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.popupFlg = true
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.problem-edit-popup {
  .content {
    min-width: 680px;
    > .header {
      display: flex;
      > .title {
        font-size: 16px;
        font-weight: bold;
      }
      > .delete-button {
        margin: 0 0 0 auto;
      }
    }
    > .input-form-wrapper {
      margin-top: 15px;
    }
  }
}
</style>
