<template>
  <div class="problem-list-row">
    <div class="item name" :title="problem.name">{{ problem.name }}</div>
    <div class="item injury-date">
      {{ problem.formatInjuryDate }}
    </div>
    <div class="item healing-date">
      {{ problem.formatHealingDate }}
    </div>
    <div class="button">
      <base-button-small-white
        :styles="{
          width: '40px',
          height: '25px',
          border: '1px solid #cecece'
        }"
        :textStyles="{
          fontSize: '12px',
          fontWeight: 'normal',
          color: '#666'
        }"
        :disabled="sortModeFlg"
        @click.stop="$emit('click', problem.id)"
        >編集</base-button-small-white
      >
    </div>
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
export default {
  name: 'ProblemListRow',

  components: {
    BaseButtonSmallWhite
  },

  props: {
    problem: { type: Object },
    sortModeFlg: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.problem-list-row {
  > .item {
    box-sizing: border-box;
    line-height: 39px;
    border-right: 1px solid #{$very-light-grey};
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.name {
      width: 240px;
    }
    &.injury-date {
      width: 140px;
      text-align: center;
    }
    &.healing-date {
      width: 140px;
      text-align: center;
    }
  }
  > .button {
    flex: 1;
    display: flex;
    justify-content: center;
    line-height: 39px;
  }
}
</style>
