<template>
  <div
    class="medical-record-row"
    @click="pushToKartePage"
    ref="target"
    data-test="medical-record-row"
  >
    <div class="owner-info">
      <div class="item owner-show-id">
        <div class="label">飼主ID</div>
        <div class="value owner-show-id">{{ ownerShowId }}</div>
      </div>
      <div class="item owner-full-name">
        <div class="label">氏 名</div>
        <div class="value owner-full-name" :title="ownerFullName">
          {{ ownerFullName }}
        </div>
      </div>
      <div class="item tel">
        <div class="label">TEL</div>
        <div class="value">{{ tel }}</div>
      </div>
      <div class="item address">
        <div class="label"><img src="@/assets/images/home.png" /></div>
        <div class="value" :title="address">{{ address }}</div>
      </div>
    </div>
    <div class="patient-info">
      <div class="img">
        <div class="inner">
          <img v-if="patientImage" :src="patientImage" />
          <img v-else src="@/assets/images/no_patient.png" />
        </div>
      </div>
      <div class="header h-id">患者ID</div>
      <div class="cell id" :title="patientShowId">{{ patientShowId }}</div>
      <div class="header h-name">なまえ</div>
      <div class="cell name" :title="patientName">{{ patientName }}</div>
      <div class="header h-age">年齢</div>
      <div class="cell age" :title="patientBirthAge">{{ patientBirthAge }}</div>
      <div class="header h-sex">性別</div>
      <div class="cell sex" :title="patientSex">{{ patientSex }}</div>
      <div class="header h-species">表示種別</div>
      <div class="cell species" :title="patientSpecies">
        {{ patientSpecies }}
      </div>
      <div class="header h-medical-treatment-date">診療日</div>
      <div class="cell medical-treatment-date" :title="medicalTreatmentDate">
        {{ medicalTreatmentDate }}
      </div>
      <div class="header h-diseases">診断名</div>
      <div class="cell diseases" :title="diseaseNames">{{ diseaseNames }}</div>
      <div class="header h-medical-expenses">診療費（￥）</div>
      <div class="cell medical-expenses" :title="medicalExpenses">
        {{ medicalExpenses }}
      </div>
      <div class="header h-pay-date">入金日</div>
      <div class="cell pay-date" :title="payDateTime">{{ payDateTime }}</div>
      <div class="header h-unpaid">未収金（￥）</div>
      <div class="cell unpaid" :style="unpaidStyle" :title="unpaid">
        {{ unpaid }}
      </div>
      <div class="header h-staff">担当者</div>
      <div class="cell staff" :title="staffFullName">{{ staffFullName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicalRecordRow',

  props: {
    medicalRecordOriginalId: { type: Number, default: 0 },
    ownerId: { type: Number, default: 0 },
    ownerShowId: { type: String, default: '' },
    ownerFullName: { type: String, default: '' },
    tel: { type: String, default: '' },
    address: { type: String, default: '' },
    patientId: { type: Number, default: 0 },
    patientShowId: { type: String, default: '' },
    patientName: { type: String, default: '' },
    patientBirthAge: { type: String, default: '' },
    patientSex: { type: String, default: '' },
    patientSpecies: { type: String, default: '' },
    medicalTreatmentDate: { type: String, default: '' },
    staffId: { type: Number, default: 0 },
    staffFullName: { type: String, default: '' },
    diseaseNames: { type: String, default: '' },
    medicalExpenses: { type: String, default: '' },
    payDateTime: { type: String, default: '' },
    unpaid: { type: String, default: '' },
    unpaidStyle: { type: Object },
    patientImage: { type: [String, null] },
    index: { type: Number, default: 0 },
    lastIndex: { type: Number, default: 0 }
  },

  mounted() {
    const target = this.$refs.target
    let observer = new IntersectionObserver(() => {
      this.$emit('intersect')
      this.emitScrollBottom()
    })
    observer.observe(target)
  },

  methods: {
    pushToKartePage() {
      this.$emit('click', {
        ownerId: this.ownerId,
        patientId: this.patientId,
        medicalRecordOriginalId: this.medicalRecordOriginalId
      })
    },
    emitScrollBottom() {
      if (this.index === this.lastIndex) {
        this.$emit('scroll-bottom')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-record-row {
  width: 100%;
  background: #{$very-light-shade-brown};
  border: solid 1px #{$light-grey};
  box-sizing: border-box;
  padding: 15px;
  @include hover();
  > .owner-info {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: solid 1px #{$light-grey};
    > .item {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 15px;
      &.owner-show-id {
        max-width: 200px;
      }
      &.owner-full-name {
        flex: 1;
        width: 0;
        max-width: fit-content;
        > .label {
          font-size: 13px;
        }
      }
      &.tel {
        max-width: 160px;
      }
      &.address {
        flex: 1;
        width: 0;
        max-width: fit-content;
      }
      &:last-child {
        padding-right: 0;
      }
      > .label {
        margin-right: 5px;
        font-size: 11px;
        > img {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      > .value {
        flex: 1;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.owner-show-id {
          font-weight: bold;
          font-size: 11px;
        }
        &.owner-full-name {
          font-weight: bold;
        }
      }
    }
  }
  > .patient-info {
    height: 121px;
    margin-top: 15px;
    background: #{$white};
    border: solid 1px #{$light-grey};
    border-bottom: none;
    box-sizing: border-box;
    font-size: 13px;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(4, 30px);
    grid-template-areas:
      'img h-id h-id h-id h-name h-name h-name h-name h-age h-age h-age h-sex h-sex h-sex h-species h-species h-species h-medical-treatment-date h-medical-treatment-date h-medical-treatment-date'
      'img id id id name name name name age age age sex sex sex species species species medical-treatment-date medical-treatment-date medical-treatment-date'
      'h-diseases h-diseases h-diseases h-diseases h-diseases h-diseases h-diseases h-diseases h-medical-expenses h-medical-expenses h-medical-expenses h-pay-date h-pay-date h-pay-date h-unpaid h-unpaid h-unpaid h-staff h-staff h-staff'
      'diseases diseases diseases diseases diseases diseases diseases diseases medical-expenses medical-expenses medical-expenses pay-date pay-date pay-date unpaid unpaid unpaid staff staff staff';
    .img {
      border-right: 1px solid #{$light-grey};
      border-bottom: 1px solid #{$light-grey};
      box-sizing: border-box;
    }
    .header {
      text-align: center;
      line-height: 30px;
      background: #{$gray_f0};
      border-right: 1px solid #{$light-grey};
      border-bottom: 1px solid #{$light-grey};
      box-sizing: border-box;
    }
    .cell {
      text-align: center;
      line-height: 30px;
      border-right: 1px solid #{$light-grey};
      border-bottom: 1px solid #{$light-grey};
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 5px;
    }
    > .cell {
      text-align: center;
      line-height: 30px;
    }
    .img {
      grid-area: img;
      position: relative;
      > .inner {
        position: absolute;
        left: 50%;
        top: 50%;
        > img {
          transform: translate(-50%, -50%);
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
    .h-id {
      grid-area: h-id;
    }
    .h-name {
      grid-area: h-name;
    }
    .h-age {
      grid-area: h-age;
    }
    .h-sex {
      grid-area: h-sex;
    }
    .h-species {
      grid-area: h-species;
    }
    .h-medical-treatment-date {
      grid-area: h-medical-treatment-date;
      border-right: none;
    }
    .id {
      grid-area: id;
    }
    .name {
      grid-area: name;
    }
    .age {
      grid-area: age;
    }
    .sex {
      grid-area: sex;
    }
    .species {
      grid-area: species;
    }
    .medical-treatment-date {
      grid-area: medical-treatment-date;
      border-right: none;
    }
    .h-diseases {
      grid-area: h-diseases;
    }
    .h-medical-expenses {
      grid-area: h-medical-expenses;
    }
    .h-pay-date {
      grid-area: h-pay-date;
    }
    .h-unpaid {
      grid-area: h-unpaid;
    }
    .h-staff {
      grid-area: h-staff;
      border-right: none;
    }
    .diseases {
      grid-area: diseases;
    }
    .medical-expenses {
      grid-area: medical-expenses;
    }
    .pay-date {
      grid-area: pay-date;
    }
    .unpaid {
      grid-area: unpaid;
    }
    .staff {
      grid-area: staff;
      border-right: none;
    }
  }
}
</style>
