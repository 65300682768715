<template>
  <div class="history-search">
    <div class="text-search">
      <div class="label">検索単語</div>
      <validation-provider
        class="validation-provider"
        tag="div"
        :rules="{ notSurrogatePair: true }"
        v-slot="{ errors }"
        ><base-text-box
          class="text-box"
          :placeholder="placeholder"
          :styles="textBoxStyles"
          v-model="inputText"
        />
        <div v-if="errors[0]" class="error" data-test="error">
          {{ errors[0] }}
        </div>
      </validation-provider>
    </div>
    <div class="period-search">
      <div class="label">診療日期間</div>
      <div class="input-field">
        <v-date-picker
          data-test="v-date-picker start"
          :input-props="{ class: 'v-date-picker start', readonly: true }"
          is-required
          v-model="inputStartDate"
        />
        <div class="wavy-line">～</div>
        <v-date-picker
          data-test="v-date-picker end"
          :input-props="{ class: 'v-date-picker end', readonly: true }"
          is-required
          v-model="inputEndDate"
        />
        <base-button-small-white
          class="clear-button"
          :styles="{ width: '80px' }"
          @click="() => $emit('clear')"
          >クリア</base-button-small-white
        >
      </div>
    </div>
    <div class="check-search type" v-if="medicalPaymentFlg">
      <div class="label">分類</div>
      <div class="checkboxes">
        <base-check-box
          data-test="checkbox outpatient"
          :isChecked="outpatientFlg"
          :labelText="'外来'"
          :styles="checkBoxStyle"
          :labelStyle="checkBoxLabelStyle"
          @input="check('outpatientFlg', $event)"
        />
        <base-check-box
          data-test="checkbox inpatient"
          :isChecked="inpatientFlg"
          :labelText="'入院'"
          :styles="checkBoxStyle"
          :labelStyle="checkBoxLabelStyle"
          @input="check('inpatientFlg', $event)"
        />
      </div>
    </div>
    <div class="check-search surgery" v-if="medicalPaymentFlg">
      <div class="label">手術</div>
      <div class="checkboxes">
        <base-check-box
          data-test="checkbox surgery"
          :isChecked="surgeryFlg"
          :labelText="'有り'"
          :styles="checkBoxStyle"
          :labelStyle="checkBoxLabelStyle"
          @input="check('surgeryFlg', $event)"
        />
        <base-check-box
          data-test="checkbox no-surgery"
          :isChecked="noSurgeryFlg"
          :labelText="'無し'"
          :styles="checkBoxStyle"
          :labelStyle="checkBoxLabelStyle"
          @input="check('noSurgeryFlg', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'HistorySearch',

  components: {
    BaseTextBox,
    BaseButtonSmallWhite,
    BaseCheckBox,
    ValidationProvider
  },

  props: {
    text: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    textBoxStyles: { type: Object },
    startDate: { type: String, default: '' },
    endDate: { type: String, default: '' },
    medicalPaymentFlg: { type: Boolean, default: false },
    outpatientFlg: { type: Boolean, default: true },
    inpatientFlg: { type: Boolean, default: true },
    surgeryFlg: { type: Boolean, default: true },
    noSurgeryFlg: { type: Boolean, default: true }
  },

  data() {
    return {
      checkBoxStyle: { marginRight: '20px' },
      checkBoxLabelStyle: { marginLeft: '5px' }
    }
  },

  computed: {
    inputText: {
      get() {
        return this.text
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    inputStartDate: {
      get() {
        return this.startDate === ''
          ? null
          : moment(this.startDate, 'YYYYMMDD').toDate()
      },
      set(val) {
        const emitDate = val ? moment(val).format('YYYYMMDD') : ''
        this.$emit('input-start-date', emitDate)
      }
    },
    inputEndDate: {
      get() {
        return this.endDate === ''
          ? null
          : moment(this.endDate, 'YYYYMMDD').toDate()
      },
      set(val) {
        const emitDate = val ? moment(val).format('YYYYMMDD') : ''
        this.$emit('input-end-date', emitDate)
      }
    }
  },

  methods: {
    check(key, value) {
      this.$emit('check', key, value)
    }
  }
}
</script>

<style lang="scss" scoped>
.history-search {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  > .text-search {
    display: flex;
    align-items: center;
    font-size: 13px;
    > .label {
      width: 75px;
    }
    > .validation-provider {
      position: relative;
      > .error {
        position: absolute;
        color: #{$tomato};
        font-size: 12px;
      }
    }
  }
  > .period-search {
    display: flex;
    align-items: center;
    font-size: 13px;
    > .label {
      width: 75px;
    }
    > .input-field {
      display: flex;
      align-items: center;
      gap: 0 10px;
      ::v-deep {
        > span {
          .v-date-picker {
            text-align: right;
            padding-right: 12px;
            width: 140px;
            height: 33px;
            border-radius: 5px;
            border: solid 2px #{$light-grey};
            text-indent: 5px;
            cursor: pointer;
            background-image: url('../../../assets/images/calendar.png');
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: 5px center;
          }
        }
      }
    }
  }
  > .check-search {
    display: flex;
    align-items: center;
    height: 33px;
    > .label {
      width: 75px;
      font-size: 13px;
    }
    > .checkboxes {
      display: flex;
      align-items: center;
    }
  }
}
</style>
