<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="upload-image-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる', 'カルテ']"
        @cancel="$emit('close')"
        @decision="pushToKartePage"
        @click-close-mark="$emit('close')"
      >
        <template v-slot:content>
          <div class="content">
            <div class="image-frame">
              <span
                v-for="textObj in image.medicalContentImageTexts"
                :key="textObj.id"
                :style="textObj.defaultTextStyle"
                >{{ textObj.text }}</span
              >
              <div class="image-area">
                <img
                  class="under-image"
                  :src="image.image"
                  :style="image.defaultImageStyle"
                />
                <img
                  v-if="image.overImage"
                  class="over-image"
                  :src="image.overImage"
                />
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'EnlargedImagePopup',

  components: {
    FocusTrap,
    Popup
  },

  props: {
    image: { type: Object }
  },

  computed: {
    ...mapGetters({
      getMedicalContents: 'medicalContents/getDataByOriginalId',
      getMedicalRecords: 'medicalRecords/getDataByOriginalId',
      getPatient: 'patients/getDataById'
    })
  },

  methods: {
    pushToKartePage() {
      const medicalContent = this.getMedicalContents(
        this.image.medicalContentOriginalId
      )
      const medicalRecord = this.getMedicalRecords(
        medicalContent.medicalRecordOriginalId
      )
      const patientId = medicalContent.patientId
      const ownerId = this.getPatient(patientId).ownerId
      if (medicalRecord.delFlg === 1) {
        this.$emit('error')
      } else {
        this.$router.push({
          path: `/main/karte/owners/${ownerId}/patients/${patientId}/medical-records/${medicalRecord.originalId}/edit`
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-image-popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  .content {
    > .image-frame {
      width: 400px;
      height: 400px;
      object-fit: cover;
      border: solid 1px #{$light-grey};
      position: relative;
      > .image-area {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        width: 400px;
        height: 400px;
        > img {
          max-width: 400px;
          max-height: 400px;
          width: auto;
          height: auto;
          vertical-align: top;
        }
        > .over-image {
          max-width: 400px;
          max-height: 400px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
