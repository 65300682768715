<template>
  <div class="medical-content-patient-info" :style="styles">
    <div class="medical-content-patient-form">
      <div class="select-area">
        <div class="date-time-input-field">
          <date-time-input-form
            :date="medicalRecord.date"
            :startTime="medicalRecord.startTime"
            :endTime="medicalRecord.endTime"
            :dateDisabled="dateDisabled"
            :popover="{ visibility: 'click' }"
            v-bind="startEndMinMax"
            @input-data="data => $emit('input-medical-record', data)"
          />
        </div>
        <div class="reservation-column-select">
          <base-select-box
            v-model="medicalRecord.reservationColumnId"
            :selectData="sortedReservationColumnsSelect"
            :styles="{ width: '100px' }"
          />
        </div>
      </div>
      <div
        class="error"
        v-if="overlappedTimeErrorMessage"
        data-test="over-lapped-time-error"
      >
        {{ overlappedTimeErrorMessage }}
      </div>
    </div>
    <div class="owner-patient-wrapper" @click="pushToOwnerShow">
      <div class="owner">
        <div class="show-id">
          飼主ID<span class="text" :title="displayOwner.showId">{{
            displayOwner.showId
          }}</span>
        </div>
        <div class="name">
          氏名<span
            class="text"
            :title="displayOwner.lastName + ' ' + displayOwner.firstName"
            >{{ displayOwner.lastName + ' ' + displayOwner.firstName }}</span
          >
        </div>
      </div>
      <div class="patient">
        <div class="show-id">
          患者ID<span class="text" :title="displayPatient.showId">{{
            displayPatient.showId
          }}</span>
        </div>
        <div class="name">
          なまえ<span class="text" :title="displayPatient.name">{{
            displayPatient.name
          }}</span>
        </div>
        <div class="age">
          <span class="text">{{ displayPatient.age }}</span
          >/ {{ displayPatient.birthday || '生年月日未登録' }}
        </div>
      </div>
      <div class="species">
        <div class="name">
          種類<span class="text" :title="displayPatient.speciesName">{{
            displayPatient.speciesName
          }}</span
          >/<span :title="displayPatient.breed">{{
            displayPatient.breed
          }}</span>
        </div>
        <div class="sex">
          性別<span class="text">{{ displayPatient.sexName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimeInputForm from '@/components/parts/molecules/DateTimeInputForm'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import { mapGetters } from 'vuex'
import { makeBirthday, calcAge } from '@/utils/patient_info'

export default {
  name: 'MedicalContentPatientInfo',

  components: {
    DateTimeInputForm,
    BaseSelectBox
  },

  props: {
    patientId: { type: Number },
    displayOwner: { type: Object },
    medicalRecord: { type: Object },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    dateDisabled: { type: Boolean, default: false },
    startEndMinMax: { type: Object },
    overlappedTimeErrorMessage: { type: String }
  },

  computed: {
    ...mapGetters({
      reservationColumnsSelect: 'reservationColumns/selectData',
      getStaffReservationColumn: 'staffReservationColumns/getDataByColumnId',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById',
      getMasterDatum: 'master/getDataById',
      patientSexes: 'master/selectPatientSexesZero'
    }),
    sortedReservationColumnsSelect() {
      return this.reservationColumnsSelect
        .map(v => {
          const staffReservationColumn = this.getStaffReservationColumn(v.id)
          return { ...v, order: staffReservationColumn.order }
        })
        .sort((a, b) => (a.order < b.order ? -1 : 1))
    },
    displayPatient() {
      const patient = this.getPatient(this.patientId)
      if (patient) {
        const speciesName = this.getSpecies(patient.speciesId)?.name || ''
        return {
          ...patient,
          age: calcAge(patient),
          birthday: makeBirthday(patient),
          speciesName,
          sexName: this.patientSexes.find(v => v.id === patient.sex).name
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    pushToOwnerShow() {
      this.$router.push({
        path: `/main/owners/${this.displayOwner.id}`,
        hash: `#patient-${this.patientId}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-content-patient-info {
  width: 480px;
  height: 192px;
  padding: 20px 14px 18px 11.6px;
  border: solid 1px #{$light-grey};
  background-color: #{$white_f7};
  box-sizing: border-box;
  > .medical-content-patient-form {
    position: relative;
    > .select-area {
      display: flex;
      > .reservation-column-select {
        margin-left: 45px;
      }
    }
    > .error {
      position: absolute;
      z-index: 1;
      color: #{$tomato};
      font-size: 12px;
      line-height: 1;
    }
  }
  > .owner-patient-wrapper {
    margin-top: 22px;
    font-size: 13px;
    @include hover();
    > .owner {
      display: flex;
      width: 100%;
      height: 22px;
      margin: 10px 14.4px 10px 1px;
      letter-spacing: 0.6px;
      text-align: left;
      > .show-id {
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          margin-left: 10px;
          font-weight: bold;
        }
      }
      > .name {
        margin-left: 21px;
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
    > .patient {
      display: flex;
      width: 100%;
      height: 22px;
      margin: 10px 14.4px 10px 1px;
      letter-spacing: 0.6px;
      text-align: left;
      display: flex;
      > .show-id {
        max-width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          margin-left: 10px;
          font-weight: bold;
        }
      }
      > .name {
        margin-left: 21px;
        max-width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          margin-left: 10px;
          font-weight: bold;
        }
      }
      > .age {
        margin-left: 10px;
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          font-weight: bold;
        }
      }
    }
    > .species {
      display: flex;
      width: 100%;
      height: 22px;
      margin: 10px 14.4px 10px 1px;
      letter-spacing: 0.6px;
      text-align: left;
      > .name {
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          margin-left: 10px;
          font-weight: bold;
        }
      }
      > .sex {
        margin-left: 21px;
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > .text {
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
