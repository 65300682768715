<template>
  <div class="estimates">
    <div class="area page-title">見積書 編集</div>
    <div class="area owner-patient">
      <owner-patient-detail :owner="owner" :patient="patient" />
    </div>
    <div class="area input-form">
      <estimate-input-form
        :patient="patient"
        :anicomPatient="anicomPatient"
        :ipetPatient="ipetPatient"
        :estimate="estimate"
        :estimateTreatmentItems="estimateTreatmentItems"
        :anicomCIdCheck="anicomCIdCheck"
        :ipetCheck="ipetCheck"
        :lookOnlyFlg="lookOnlyFlg"
        :waitFlg="waitFlg"
        :isCheckingApply="isCheckingApply"
        @input-apply-flg="inputApplyFlg"
        @updated-insurance="setInsurance"
        @order="changeOrder"
        @click-trash="removeItem"
        @add-items="addItems"
        @change-item="changeItem"
        @cancel="gotoListPage"
        @decision="updateData"
      />
    </div>
    <announce-popup v-if="popup.isOpened" v-bind="popup" @close="closePopup">{{
      popup.message
    }}</announce-popup>
    <announce-popup
      class="apply-popup"
      v-if="applyPopup.isOpened"
      v-bind="applyPopup"
      @close="closeApplyPopup"
      @cancel="closeApplyPopup"
      @decision="applyPopup.decision"
      >{{ applyPopup.message }}
      <template #button v-if="showValidityCheckButton">
        <base-button-border-orange
          class="slot-button"
          @click="confirmAnicomUrlOpen"
          >有効性確認システム</base-button-border-orange
        >
      </template></announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import EstimateInputForm from '@/components/parts/organisms/EstimateInputForm'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  counterPaymentNg,
  counterPaymentOk,
  counterPaymentOkOutpatient,
  counterPaymentOkInpatient,
  surgeryNg
} from '@/utils/anicom_response'
import { ANICOM_URL } from '@/utils/define'
import { makeInsuranceType } from '@/utils/price_calculation'

const basePopup = {
  isOpened: false,
  type: '',
  title: '',
  message: '',
  buttons: ['閉じる']
}

export default {
  name: 'EstimateEdit',

  components: {
    OwnerPatientDetail,
    EstimateInputForm,
    BaseButtonBorderOrange,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number },
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      owner: {},
      patient: {},
      anicomPatient: null,
      ipetPatient: null,
      estimate: {},
      estimateTreatmentItems: [],
      deleteEstimateTreatmentItems: [],
      anicomCIdCheck: null,
      ipetCheck: null,
      popup: { ...basePopup },
      applyPopup: { ...basePopup, decision: () => {} },
      isCheckingApply: false,
      canApply: false,
      noDataFlg: false,
      noPatientFlg: false,
      showValidityCheckButton: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      estimateById: 'estimates/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      medicineByOriginalId: 'medicines/getDataByOriginalId',
      treatmentByOriginalId: 'treatments/getDataByOriginalId',
      treatmentItemsByEstimateId: 'estimateTreatmentItems/getDataByEstimateId'
    }),
    isAnicomOk() {
      const existsKekka = this.anicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentOk.some(v => {
          return v.kekka1.includes(this.anicomCIdCheck.kekka1)
        })
      )
    },
    isAnicomOkOutpatient() {
      const existsKekka = this.anicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentOkOutpatient.some(v => {
          return v.kekka1.includes(this.anicomCIdCheck.kekka1)
        })
      )
    },
    isAnicomOkInpatient() {
      const existsKekka = this.anicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentOkInpatient.some(v => {
          return v.kekka1.includes(this.anicomCIdCheck.kekka1)
        })
      )
    },
    isAnicomNg() {
      const existsKekka = this.anicomCIdCheck?.kekka1.length > 0
      return (
        existsKekka &&
        counterPaymentNg.some(v => {
          return v.kekka1.includes(this.anicomCIdCheck.kekka1)
        })
      )
    },
    isSurgeryNg() {
      const existsKekka = this.anicomCIdCheck?.kekka2.length > 0
      return (
        existsKekka &&
        surgeryNg.some(v => {
          return this.anicomCIdCheck.kekka2.includes(v)
        })
      )
    }
  },

  created() {
    this.owner = this.$store.getters['owners/getDataById'](this.ownerId)
    this.estimate = { ...this.estimateById(this.id) }
    this.estimateTreatmentItems = [...this.getTreatmentItems()].map((v, i) => {
      return { ...v, key: i }
    })
    this.resetTreatmentItems()
    this.setInsurance()
    this.mixinInputData = {
      estimate: this.estimate,
      estimateTreatmentItems: this.estimateTreatmentItems
    }
    this.mixinSetInitialData()
  },

  methods: {
    getTreatmentItems() {
      return this.treatmentItemsByEstimateId(this.estimate.id) || []
    },
    setInsurance() {
      this.setPatient()
      this.setAnicomPatient()
      this.setIpetPatient()
      this.setAnicomCIdCheck()
      this.setIpetCheck()
      this.setInsuranceType()
    },
    setPatient() {
      this.patient = this.$store.getters['patients/getDataById'](this.patientId)
    },
    setAnicomPatient() {
      this.anicomPatient = this.$store.getters[
        'anicomPatients/getAnicomPatient'
      ](this.patientId)
    },
    setIpetPatient() {
      this.ipetPatient = this.$store.getters['ipetPatients/getIpetPatient'](
        this.patientId
      )
    },
    setAnicomCIdCheck() {
      this.anicomCIdCheck = this.$store.getters['anicomCIdChecks/getDataById'](
        this.estimate.anicomCIdCheckId
      )
    },
    setIpetCheck() {
      this.ipetCheck = this.$store.getters['ipetChecks/getDataById'](
        this.estimate.ipetCheckId
      )
    },
    setInsuranceType() {
      this.estimate.insuranceType = makeInsuranceType(
        this.estimate,
        this.patient
      )
    },
    async inputApplyFlg(applyFlg) {
      const { insurance } = this.patient
      this.estimate.applyFlg = applyFlg
      if (this.estimate.applyFlg === 1) {
        this.isCheckingApply = true
        if (insurance === 'anicom') {
          await this.checkAnicomValidity()
        } else if (insurance === 'ipet' || insurance === 'docomo') {
          await this.checkIpetValidity()
        } else {
          this.applyPopup.type = 'alert'
          this.applyPopup.title = '注意'
          this.applyPopup.buttons = ['閉じる']
          this.applyPopup.message =
            '現在の保険設定では保険を適用することができません。'
          this.applyPopup.isOpened = true
        }
      } else {
        this.resetEstimateInsurance()
      }
    },
    async checkAnicomValidity() {
      this.waitFlg = true
      const res = await this.$store.dispatch('patients/checkAnicomValidity', {
        patientId: this.patientId
      })
      this.waitFlg = false
      const { anicomCIdCheck, errMsg } = res
      let message = ''
      if (anicomCIdCheck) {
        this.anicomCIdCheck = anicomCIdCheck
        message = `${anicomCIdCheck.kekka1}\n${anicomCIdCheck.kekka2}`
        this.applyPopup.title = 'メッセージ'
        if (this.isAnicomOk || this.isAnicomOkOutpatient) {
          this.canApply = true
          this.applyPopup.type = 'success'
        } else if (this.isAnicomOkInpatient) {
          const warning =
            '※見積書では、「入院のみ窓口精算可能」の場合、保険を適用することができません。'
          this.applyPopup.type = 'alert'
          message = message + '\n' + warning
        } else if (this.isAnicomNg) {
          this.applyPopup.type = 'alert'
          this.applyPopup.title = '注意'
          this.showValidityCheckButton = true
        } else {
          this.applyPopup.type = 'failure'
          this.applyPopup.title = '失敗'
          message = '保険情報の取得に失敗しました。'
        }
      } else {
        this.applyPopup.type = 'failure'
        this.applyPopup.title = '失敗'
        if (errMsg === 'no data in clinic' || errMsg === 'no patient') {
          message =
            '対象の患者は既に削除されています。\nメイン画面へ移動します。'
          this.noPatientFlg = true
        } else if (errMsg === 'no insurance setting') {
          message =
            '対象の患者にアニコムの保険情報が設定されていないため保険を適用することができません。'
        } else if (errMsg?.includes('保険を適用できませんでした')) {
          message = errMsg
        } else {
          message = '保険情報の取得に失敗しました。'
        }
      }
      this.applyPopup.message = message
      this.applyPopup.buttons = ['閉じる']
      this.applyPopup.isOpened = true
    },
    async checkIpetValidity() {
      this.waitFlg = true
      const res = await this.$store.dispatch('patients/checkIpetValidity', {
        patientId: this.patientId,
        insurance: this.patient.insurance,
        guDate: moment().format('YYYYMMDD')
      })
      this.waitFlg = false
      const { ipetCheck, errMsg } = res
      let message = ''
      if (ipetCheck) {
        this.ipetCheck = ipetCheck
        this.canApply = true
        this.applyPopup.type = 'success'
        this.applyPopup.title = 'メッセージ'
        message = `保険を適用しました。補償割合は${ipetCheck.claimRate}%です。`
      } else {
        this.applyPopup.type = 'failure'
        this.applyPopup.title = '失敗'
        if (errMsg === 'no data in clinic' || errMsg === 'no patient') {
          message =
            '対象の患者は既に削除されています。\nメイン画面へ移動します。'
          this.noPatientFlg = true
        } else if (errMsg === 'no insurance setting') {
          const { insurance } = this.patient
          let target = ''
          if (insurance === 'ipet') target = 'アイペットの'
          else if (insurance === 'docomo') target = 'ドコモの'
          message = `対象の患者に${target}保険情報が設定されていないため保険を適用することができません。`
        } else if (errMsg === 'claimRate 100') {
          message =
            '補償割合が100%の場合、窓口精算ができないため保険を適用することができません。'
        } else if (errMsg?.includes('保険を適用できませんでした')) {
          message = errMsg
        } else {
          message = '保険情報の取得に失敗しました。'
        }
      }
      this.applyPopup.message = message
      this.applyPopup.buttons = ['閉じる']
      this.applyPopup.isOpened = true
    },
    resetEstimateInsurance() {
      this.estimate.applyFlg = 0
      this.estimate.anicomCIdCheckId = 0
      this.estimate.ipetCheckId = 0
      this.estimate.pledgeRate = 0
      this.estimate.surgeryLimitFlg = 0
      this.anicomCIdCheck = null
      this.ipetCheck = null
      this.setInsuranceType()
    },
    changeOrder(items) {
      this.estimateTreatmentItems = items
      this.mixinInputData.estimateTreatmentItems = items
    },
    removeItem(item) {
      const targetIndex = this.estimateTreatmentItems.findIndex(
        v => v.key === item.key
      )
      const target = this.estimateTreatmentItems[targetIndex]
      if (target.id) {
        this.estimateTreatmentItems.splice(targetIndex, 1)
        this.deleteEstimateTreatmentItems.push({ ...target, delFlg: 1 })
      } else {
        this.estimateTreatmentItems.splice(targetIndex, 1)
      }
    },
    addItems(items) {
      const nextKey =
        this.estimateTreatmentItems.length > 0
          ? this.estimateTreatmentItems.reduce((a, b) =>
              a.key > b.key ? a : b
            ).key + 1
          : 0
      items.forEach((v, i) => {
        this.estimateTreatmentItems.push({ ...v, key: nextKey + i })
      })
    },
    changeItem(item) {
      const targetIndex = this.estimateTreatmentItems.findIndex(
        v => v.key === item.key
      )
      this.$set(this.estimateTreatmentItems, targetIndex, item)
    },
    async updateData() {
      const estimateTreatmentItems = this.formatItems()
      this.waitFlg = true
      const result = await this.$store.dispatch('estimates/update', {
        estimate: this.estimate,
        estimateTreatmentItems,
        deleteEstimateTreatmentItems: this.deleteEstimateTreatmentItems
      })
      if (result === true) {
        this.mixinSetInitialData()
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '編集しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        if (result === 'no treatment') {
          this.popup.message =
            '編集に失敗しました。\n選択した診療項目は既に削除されています。\n他の診療項目を選択して下さい。'
          this.resetTreatmentItems(true)
        } else if (result === 'no medicine') {
          this.popup.message =
            '編集に失敗しました。\n選択した薬剤物品は既に削除されています。\n他の薬剤物品を選択して下さい。'
          this.resetTreatmentItems(true)
        } else if (result === 'no estimate') {
          this.popup.message =
            '編集に失敗しました。\n編集中の見積書は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popup.message = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.popup.isOpened = true
    },
    formatItems() {
      return this.estimateTreatmentItems.map(item => {
        const { key, ...newItem } = item
        return newItem.id
          ? newItem
          : { ...newItem, estimateId: this.estimate.id }
      })
    },
    resetTreatmentItems(initializeFlg = false) {
      this.estimateTreatmentItems = this.estimateTreatmentItems.filter(
        v =>
          this.treatmentByOriginalId(v.treatmentOriginalId) ||
          this.medicineByOriginalId(v.medicineOriginalId)
      )
      if (initializeFlg) {
        this.deleteEstimateTreatmentItems = this.deleteEstimateTreatmentItems.filter(
          v =>
            this.treatmentByOriginalId(v.treatmentOriginalId) ||
            this.medicineByOriginalId(v.medicineOriginalId)
        )
        this.mixinInitialData.estimateTreatmentItems = [
          ...this.estimateTreatmentItems
        ]
        this.mixinInputData.estimateTreatmentItems = this.estimateTreatmentItems
      }
    },
    closePopup() {
      if (this.popup.type === 'success' || this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.gotoListPage()
      }
      this.popup.isOpened = false
    },
    gotoListPage() {
      this.$router.push({
        name: 'estimates',
        params: { ownerId: this.ownerId, patientId: this.patientId }
      })
    },
    closeApplyPopup() {
      if (this.noPatientFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'main' })
      }
      if (this.isCheckingApply) {
        if (this.canApply && this.anicomCIdCheck) {
          this.estimate.anicomCIdCheckId = this.anicomCIdCheck.id
          this.estimate.pledgeRate = Number(this.anicomCIdCheck.coverageRate)
          if (this.isSurgeryNg) this.estimate.surgeryLimitFlg = 1
        } else if (this.canApply && this.ipetCheck) {
          this.estimate.ipetCheckId = this.ipetCheck.id
          this.estimate.pledgeRate = this.ipetCheck.claimRate
        } else {
          this.resetEstimateInsurance()
        }
        this.setInsurance()
        this.canApply = false
        this.isCheckingApply = false
      }
      this.applyPopup = { ...basePopup, decision: () => {} }
      this.showValidityCheckButton = false
    },
    confirmAnicomUrlOpen() {
      this.showValidityCheckButton = false
      this.applyPopup.isOpened = true
      this.applyPopup.type = 'alert'
      this.applyPopup.title = '注意'
      this.applyPopup.buttons = ['キャンセル', 'OK']
      this.applyPopup.message =
        '有効性確認システムより承認番号を取得した後は、本日の窓口精算ができなくなります！'
      this.applyPopup.decision = () => {
        this.openAnicomUrl()
      }
    },
    openAnicomUrl() {
      window.open(ANICOM_URL, '_blank')
      this.closeApplyPopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.estimates {
  display: inline-block;
  min-width: 100%;
  > .page-title {
    font-size: 15px;
    font-weight: bold;
  }
  > .owner-patient {
    margin-top: 30px;
  }
  > .input-form {
    margin-top: 30px;
  }
}
.apply-popup {
  ::v-deep .popup-content {
    > .text {
      text-align: left;
      font-size: 15px;
    }
  }
}
.slot-button {
  display: flex;
  justify-content: center;
  margin: 0px 20px 20px 20px;
}
</style>
