<template>
  <div class="medical-payments-history">
    <div class="top-right">
      <div class="history-type-select">
        <div class="label">変更履歴</div>
        <base-select-box :selectData="showTypes" v-model="showType" />
      </div>
      <div class="icons">
        <img
          src="@/assets/images/float_window.png"
          @mousedown="$emit('move')"
          data-test="float-window"
        />
        <img src="@/assets/images/cross.png" @click="() => $emit('close')" />
      </div>
    </div>
    <div class="search">
      <history-search
        :text="searchText"
        :placeholder="'診断名、診療項目内容名'"
        :startDate="startDate"
        :endDate="endDate"
        :medicalPaymentFlg="true"
        :outpatientFlg="outpatientFlg"
        :inpatientFlg="inpatientFlg"
        :surgeryFlg="surgeryFlg"
        :noSurgeryFlg="noSurgeryFlg"
        @input-start-date="inputStartDate"
        @input-end-date="inputEndDate"
        @clear="clearDate"
        @check="check"
        v-model="searchText"
      />
      <div class="order">
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <div class="list">
      <div class="patient">
        <history-patient-detail :patient="patientById(patientId)" />
      </div>
      <dynamic-scroller
        class="medical-payments"
        v-if="displayMedicalPayments.length > 0"
        :items="displayMedicalPayments"
        :min-item-size="650"
        :buffer="2000"
      >
        <template v-slot="{ item, index, active }">
          <dynamic-scroller-item
            :item="item"
            :active="active"
            :data-index="index"
            :size-dependencies="[item.medicalTreatmentItems]"
          >
            <medical-payments-history-detail
              :medicalPayment="item"
              @click.native="copy(item)"
            />
          </dynamic-scroller-item>
        </template>
      </dynamic-scroller>
      <div v-else class="no-data">対象のデータはありません。</div>
    </div>
  </div>
</template>

<script>
import HistorySearch from '@/components/parts/molecules/HistorySearch'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import HistoryPatientDetail from '@/components/parts/molecules/HistoryPatientDetail'
import MedicalPaymentsHistoryDetail from '@/components/parts/organisms/MedicalPaymentsHistoryDetail'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalPaymentsHistory',

  components: {
    HistorySearch,
    BaseSelectBox,
    HistoryPatientDetail,
    MedicalPaymentsHistoryDetail,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    patientId: { type: Number }
  },

  data() {
    return {
      searchText: '',
      startDate: '',
      endDate: '',
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      showType: 0,
      showTypes: [
        { id: 0, name: '非表示' },
        { id: 1, name: '表示' }
      ],
      outpatientFlg: true,
      inpatientFlg: true,
      surgeryFlg: true,
      noSurgeryFlg: true
    }
  },

  computed: {
    ...mapGetters({
      patientById: 'patients/getDataById',
      medicalRecordById: 'medicalRecords/getDataById',
      medicalPaymentsByPatientId: 'medicalPayments/getDataByPatientId',
      medicalPaymentsByPatientIdIncludeDel:
        'medicalPayments/getDataByPatientIdIncludeDel',
      medicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      medicalTreatmentItemsByMedicalPaymentIdIncludeDel:
        'medicalTreatmentItems/getDataByMedicalPaymentIdIncludeDel',
      diseaseClassById: 'diseaseClasses/getDataById',
      diseaseById: 'diseases/getDataById',
      treatmentById: 'treatments/getDataById',
      medicineById: 'medicines/getDataById',
      paymentByMedicalPaymentId: 'payments/getDataByMedicalPaymentId'
    }),
    trimmedSearchText() {
      return this.searchText.toLowerCase().replace(/\s+/g, '')
    },
    medicalPayments() {
      return this.showType === 0
        ? this.medicalPaymentsByPatientId(this.patientId) || []
        : this.medicalPaymentsByPatientIdIncludeDel(this.patientId) || []
    },
    displayMedicalPayments() {
      return this.medicalPayments
        .map(v => {
          const medicalRecord = this.medicalRecordById(v.medicalRecordId)
          const diseaseClass1 = this.diseaseClassById(v.diseaseClass1Id)
          const disease1 = this.diseaseById(v.disease1Id)
          const diseaseClass2 = this.diseaseClassById(v.diseaseClass2Id)
          const disease2 = this.diseaseById(v.disease2Id)
          const medicalTreatmentItems = this.makeTreatmentItems(v)
          const history = this.medicalPaymentHistory(v.originalId).slice()
          //↓this.medicalPaymentHistoryのデータはid昇順を保証していないので、sortでid昇順に並び替える
          history.sort((a, b) => a.id - b.id)
          const latest = history[history.length - 1]
          const latestDate = this.medicalRecordById(latest.medicalRecordId).date
          return {
            ...v,
            date: medicalRecord.date,
            diseaseClass1,
            disease1,
            diseaseClass2,
            disease2,
            medicalTreatmentItems,
            latest: {
              id: latest.id,
              date: latestDate,
              delFlg: latest.delFlg
            },
            original: {
              id: history[0].id,
              inputStaffId: history[0].inputStaffId,
              createdAt: history[0].createdAt
            }
          }
        })
        .filter(v => this.filterBySearch(v))
        .sort((a, b) => this.sortByDate(a, b))
    }
  },

  methods: {
    inputStartDate(date) {
      this.startDate = date
    },
    inputEndDate(date) {
      this.endDate = date
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    check(key, value) {
      this[key] = value
    },
    makeTreatmentItems(medicalPayment) {
      const medicalTreatmentItemsByMedicalPaymentIdIncludeDel =
        this.medicalTreatmentItemsByMedicalPaymentIdIncludeDel(
          medicalPayment.id
        ) || []
      const medicalTreatmentItems = medicalTreatmentItemsByMedicalPaymentIdIncludeDel
        .filter(
          item =>
            this.treatmentById(item.treatmentId) ||
            this.medicineById(item.medicineId)
        )
        .map(item => {
          const name =
            this.treatmentById(item.treatmentId)?.name ||
            this.medicineById(item.medicineId)?.name
          return { ...item, name }
        })
      return medicalTreatmentItems
    },
    filterByDate(medicalPayment) {
      return this.startDate !== '' && this.endDate !== ''
        ? this.startDate <= medicalPayment.date &&
            medicalPayment.date <= this.endDate
        : this.startDate !== ''
        ? this.startDate <= medicalPayment.date
        : this.endDate !== ''
        ? medicalPayment.date <= this.endDate
        : true
    },
    filterByName(item) {
      return item?.name.toLowerCase().includes(this.trimmedSearchText)
    },
    filterByDisease(medicalPayment) {
      return (
        this.filterByName(medicalPayment.diseaseClass1) ||
        this.filterByName(medicalPayment.diseaseClass2) ||
        this.filterByName(medicalPayment.disease1) ||
        this.filterByName(medicalPayment.disease2)
      )
    },
    filterByTreatmentItem(medicalPayment) {
      return this.trimmedSearchText.length > 0
        ? medicalPayment.medicalTreatmentItems.some(item =>
            this.filterByName(item)
          )
        : true
    },
    isInpatient(medicalPayment) {
      return (
        medicalPayment.startHospitalizationFlg === 1 ||
        medicalPayment.inHospitalFlg === 1 ||
        medicalPayment.endHospitalizationFlg === 1
      )
    },
    filterByOutpatient(medicalPayment) {
      return this.outpatientFlg && !this.isInpatient(medicalPayment)
    },
    filterByInpatient(medicalPayment) {
      return this.inpatientFlg && this.isInpatient(medicalPayment)
    },
    filterBySurgery(medicalPayment) {
      return this.surgeryFlg && medicalPayment.surgeryFlg === 1
    },
    filterByNoSurgery(medicalPayment) {
      return this.noSurgeryFlg && medicalPayment.surgeryFlg === 0
    },
    filterByHospitalizationStatus(medicalPayment) {
      const outpatient = this.filterByOutpatient(medicalPayment)
      const inpatient = this.filterByInpatient(medicalPayment)
      return outpatient || inpatient
    },
    filterBySurgeryStatus(medicalPayment) {
      const surgery = this.filterBySurgery(medicalPayment)
      const noSurgery = this.filterByNoSurgery(medicalPayment)
      return surgery || noSurgery
    },
    filterBySearch(medicalPayment) {
      return (
        this.filterByDate(medicalPayment) &&
        (this.filterByDisease(medicalPayment) ||
          this.filterByTreatmentItem(medicalPayment)) &&
        this.filterByHospitalizationStatus(medicalPayment) &&
        this.filterBySurgeryStatus(medicalPayment)
      )
    },
    sortByDate(a, b) {
      return a.originalId === b.originalId
        ? a.delFlg !== b.delFlg
          ? a.delFlg - b.delFlg
          : a.deleteStaffId !== 0 //delFlg:1同士の比較になる。同じoriginalIdで最新の診療明細(delFlg:0)は1つだけなので、delFlg:0同士の比較はない
          ? -1
          : b.deleteStaffId !== 0
          ? 1
          : this.orderType === 0
          ? b.id - a.id
          : a.id - b.id
        : this.sortByOrderType(
            { date: a.latest.date, originalId: a.originalId },
            { date: b.latest.date, originalId: b.originalId }
          )
    },
    sortByOrderType(a, b) {
      if (this.orderType === 0) {
        return a.date === b.date
          ? b.originalId - a.originalId
          : b.date < a.date
          ? -1
          : 1
      } else {
        return a.date === b.date
          ? a.originalId - b.originalId
          : a.date < b.date
          ? -1
          : 1
      }
    },
    copy(medicalPayment) {
      const medicalTreatmentItems = medicalPayment.medicalTreatmentItems
      this.$emit('copy', medicalPayment, medicalTreatmentItems)
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-payments-history {
  position: relative;
  box-sizing: border-box;
  width: 650px;
  min-width: 650px;
  height: 881px;
  border: solid 1px #{$light-grey};
  background-color: #{$white};
  display: flex;
  flex-direction: column;
  > .top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 235px;
    > .history-type-select {
      display: flex;
      align-items: center;
      padding-top: 2px;
      > .label {
        font-size: 13px;
        padding-right: 10px;
      }
    }
    > .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 46px;
      > img {
        cursor: pointer;
      }
    }
  }
  > .search {
    box-sizing: border-box;
    position: relative;
    padding: 12px 15px;
    background-color: rgba(252, 225, 204, 0.4);
    > .order {
      position: absolute;
      right: 15px;
      bottom: 12px;
    }
  }
  > .list {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    height: 0px;
    display: flex;
    flex-direction: column;
    background-color: #{$white_f7};
    > .patient {
      padding: 8px 15px;
    }
    > .medical-payments {
      box-sizing: border-box;
      width: 100%;
      flex: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 0 5px 15px;
    }
    > .no-data {
      padding: 15px;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
</style>
