<template>
  <div class="karte-image-list-row" ref="target">
    <div class="row" v-for="imageObj in images" :key="imageObj.key">
      <div
        class="image-frame"
        @click="$emit('open', imageObj)"
        data-test="image-frame"
      >
        <span
          v-for="textObj in imageObj.medicalContentImageTexts"
          :key="textObj.id"
          :style="textObj.textStyle"
          >{{ textObj.text }}</span
        >
        <div class="image-area">
          <img
            class="under-image"
            :src="imageObj.thumbnailImage"
            :style="imageObj.imageStyle"
            data-test="under-image"
          />
          <img
            v-if="imageObj.overImage"
            class="over-image"
            :src="imageObj.overImage"
          />
        </div>
      </div>
      <div class="title" :title="imageObj.title">
        {{ imageObj.title }}
      </div>
      <div class="tags">
        <div class="tag" v-for="(tagName, i) in imageObj.tagNames" :key="i">
          {{ tagName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KarteImageListRow',

  props: {
    images: { type: Array }
  }
}
</script>

<style lang="scss" scoped>
.karte-image-list-row {
  display: flex;
  > .row {
    margin-right: 40px;
    position: relative;
    > .image-frame {
      width: 250px;
      height: 250px;
      position: relative;
      @include hover();
      > .image-area {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        width: 250px;
        height: 250px;
        > img {
          width: auto;
          height: auto;
          vertical-align: top;
        }
        > .over-image {
          max-width: 250px;
          max-height: 250px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    > .title {
      width: 250px;
      font-size: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > .tags {
      width: 250px;
      display: flex;
      flex-wrap: wrap;
      > .tag {
        display: inline-block;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        color: #{$gray};
        border: 1px solid #{$gray};
        padding: 0 3px;
        border-radius: 50px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
