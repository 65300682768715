<template>
  <div class="patient-payments">
    <div class="area owner-patient">
      <owner-patient-detail
        :owner="getOwner(ownerId)"
        :patient="getPatient(patientId)"
      />
    </div>
    <div class="area search">
      <search-area
        ref="search-area"
        :periodFlg="true"
        :periodLabel="'診療日期間'"
        :defaultStartDate="new Date()"
        :defaultEndDate="new Date()"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
      />
    </div>
    <div class="area checkboxes">
      <base-check-box
        class="checkbox"
        :isChecked="showCompleteFlg"
        :labelText="'会計済'"
        v-model="showCompleteFlg"
      />
      <base-check-box
        class="checkbox"
        :isChecked="showUnpaidFlg"
        :labelText="'未会計'"
        v-model="showUnpaidFlg"
      />
      <div
        class="red-circled-number"
        v-if="unpaidNumber !== 0"
        @click="showAll('unpaid')"
        data-test="unpaid-number"
      >
        {{ unpaidNumber }}
      </div>
      <base-check-box
        class="checkbox"
        :isChecked="showRedoFlg"
        :labelText="'再会計'"
        v-model="showRedoFlg"
      />
      <div
        class="red-circled-number"
        v-if="redoNumber !== 0"
        @click="showAll('redo')"
        data-test="redo-number"
      >
        {{ redoNumber }}
      </div>
      <base-check-box
        class="checkbox"
        :isChecked="showIncompleteFlg"
        :labelText="'未収金'"
        v-model="showIncompleteFlg"
      />
      <div
        class="red-circled-number"
        v-if="incompleteNumber !== 0"
        @click="showAll('incomplete')"
        data-test="incomplete-number"
      >
        {{ incompleteNumber }}
      </div>
    </div>
    <div class="area search-order">
      <div class="order-result">
        検索結果：{{ filteredPayments.length }}件({{
          medicalPayments.length
        }}件中)
      </div>
      <div class="order-setting">
        <div class="label">並び替え：</div>
        <base-select-box
          class="select-box"
          :selectData="sortTypes"
          v-model="sortType"
        />
        <base-select-box
          class="select-box"
          :selectData="orderTypes"
          v-model="orderType"
        />
      </div>
    </div>
    <div class="area list">
      <payment-list-table
        :payments="filteredPayments"
        :patientPaymentsFlg="true"
        @go-to-show-payment-page="goToShowPaymentPage"
        @go-to-new-payment-page="goToNewPaymentPage"
      >
      </payment-list-table>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import PaymentListTable from '@/components/parts/organisms/PaymentListTable'
import SearchArea from '@/components/parts/molecules/SearchArea'
import { mapGetters } from 'vuex'
import { makePaymentDetail } from '@/utils/price_calculation'

export default {
  name: 'PatientPayments',

  components: {
    BaseCheckBox,
    BaseSelectBox,
    OwnerPatientDetail,
    PaymentListTable,
    SearchArea
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      showCompleteFlg: true,
      showIncompleteFlg: true,
      showRedoFlg: true,
      showUnpaidFlg: true,
      startDate: '',
      endDate: '',
      sortType: 0,
      sortTypes: [
        { id: 0, name: '診療日' },
        { id: 1, name: '会計日' }
      ],
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getMedicalPaymentsByPatientId: 'medicalPayments/getDataByPatientId',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId'
    }),
    medicalPayments() {
      return (
        this.getMedicalPaymentsByPatientId(this.patientId)?.filter(
          v =>
            v.middleCalculateFlg === 1 ||
            v.endHospitalizationFlg === 1 ||
            (v.endHospitalizationFlg === 0 &&
              v.startHospitalizationFlg === 0 &&
              v.inHospitalFlg === 0 &&
              v.middleCalculateFlg === 0)
        ) || []
      )
    },
    displayPayments() {
      return this.medicalPayments.map(medicalPayment => {
        const {
          type,
          unpaidAmount,
          totalCash,
          totalCard,
          totalChange,
          latestPayDateTime,
          medicalTreatmentDay
        } = makePaymentDetail(
          medicalPayment,
          this.getMedicalPaymentHistory,
          this.getMedicalRecordByOriginalId,
          this.getPaymentsByMedicalPaymentId
        )
        return {
          ...medicalPayment,
          type,
          unpaidAmount,
          red: unpaidAmount !== 0,
          cash: totalCash || 0,
          card: totalCard || 0,
          change: totalChange || 0,
          payDateTime: latestPayDateTime,
          date: medicalTreatmentDay
        }
      })
    },
    filteredPayments() {
      return this.displayPayments
        .filter(v => this.filterByDate(v) && this.filterByType(v))
        .sort((a, b) => this.sortByOrderType(a, b))
    },
    unpaidNumber() {
      const num = this.displayPayments.filter(v => v.type === '未会計').length
      return num < 100 ? num : '99+'
    },
    redoNumber() {
      const num = this.displayPayments.filter(v => v.type === '再会計').length
      return num < 100 ? num : '99+'
    },
    incompleteNumber() {
      const num = this.displayPayments.filter(v => v.type === '未収金').length
      return num < 100 ? num : '99+'
    }
  },

  methods: {
    filterByDate(payment) {
      return this.startDate !== '' && this.endDate !== ''
        ? this.startDate <= payment.date && payment.date <= this.endDate
        : this.startDate !== ''
        ? this.startDate <= payment.date
        : this.endDate !== ''
        ? payment.date <= this.endDate
        : true
    },
    filterByType(payment) {
      return (
        (this.showCompleteFlg && payment.type === '会計済') ||
        (this.showUnpaidFlg && payment.type === '未会計') ||
        (this.showRedoFlg && payment.type === '再会計') ||
        (this.showIncompleteFlg && payment.type === '未収金')
      )
    },
    sortByOrderType(a, b) {
      const date = this.sortType === 0 ? 'date' : 'payDateTime'
      if (this.orderType === 0) {
        return a[date] === b[date]
          ? b.originalId - a.originalId
          : b[date] < a[date]
          ? -1
          : 1
      } else {
        return a[date] === b[date]
          ? a.originalId - b.originalId
          : a[date] < b[date]
          ? -1
          : 1
      }
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    showAll(type) {
      const types = ['complete', 'unpaid', 'redo', 'incomplete']
      const flgName = type => {
        return 'show' + type.charAt(0).toUpperCase() + type.slice(1) + 'Flg'
      }
      types.forEach(v =>
        v === type ? (this[flgName(v)] = true) : (this[flgName(v)] = false)
      )
      this.$refs['search-area'].clearDate()
    },
    goToShowPaymentPage(displayPayment) {
      const medicalPaymentOriginalId = displayPayment.originalId //←medicalPaymentのoriginalIdの値
      this.$router.push({
        path: `payments/${medicalPaymentOriginalId}/show`,
        params: { medicalPaymentOriginalId: medicalPaymentOriginalId }
      })
    },
    goToNewPaymentPage(displayPayment) {
      const medicalPaymentOriginalId = displayPayment.originalId //←medicalPaymentのoriginalIdの値
      this.$router.push({
        path: `payments/${medicalPaymentOriginalId}/new`,
        params: { medicalPaymentOriginalId: medicalPaymentOriginalId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-payments {
  display: inline-block;
  min-width: 100%;
  > .owner-patient {
    margin-top: 40px;
  }
  > .search {
    margin-top: 40px;
  }
  > .search-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .select-box {
        margin-left: 10px;
      }
    }
  }
  > .checkboxes {
    margin-top: 40px;
    display: flex;
    align-items: center;
    > .checkbox ~ .checkbox {
      padding-left: 30px;
    }
    > .red-circled-number {
      margin-left: 5px;
      width: 23px;
      height: 23px;
      text-align: center;
      line-height: 23px;
      border-radius: 50%;
      color: #{$white};
      font-size: 13px;
      background-color: #{$tomato};
      cursor: pointer;
      @include hover();
    }
  }
  > .list {
    min-width: 1200px;
    margin-top: 15px;
    ::v-deep .table-body {
      max-height: 630px;
    }
    .buttons {
      width: 260px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: 20px;
    }
  }
}
</style>
