<template>
  <div class="owner-patient-list-item" ref="observerTarget">
    <div class="detail owner">
      <div class="item show-id">
        <div class="label">飼主ID</div>
        <div class="value show-id owner" data-test="owner">
          {{ patientGroup[0].ownerShowId }}
        </div>
      </div>
      <div
        class="item full-name"
        :title="
          `${patientGroup[0].ownerLastName} ${patientGroup[0].ownerFirstName}`
        "
      >
        <div class="label">氏 名</div>
        <div class="value emphasis" data-test="owner">
          {{ patientGroup[0].ownerLastName }}
          {{ patientGroup[0].ownerFirstName }}
        </div>
      </div>
      <div class="item tel">
        <div class="label">TEL</div>
        <div class="value" data-test="owner">{{ patientGroup[0].tel }}</div>
      </div>
      <div class="item full-address" :title="patientGroup[0].fullAddress">
        <div class="label"><img src="@/assets/images/home.png" /></div>
        <div class="value" data-test="owner">
          {{ patientGroup[0].fullAddress }}
        </div>
      </div>
    </div>
    <div
      class="detail patient"
      v-for="patient in patientGroup"
      :key="patient.id"
      @click="goToMedicalRecordNew(patient)"
    >
      <div class="item image">
        <img
          v-if="patientImagesByPatientId[patient.id]"
          :src="patientImagesByPatientId[patient.id]"
          data-test="image"
        />
        <img v-else src="@/assets/images/photo.png" data-test="image" />
      </div>
      <div class="item show-id" :title="patient.showId">
        <div class="label">患者ID</div>
        <div class="value show-id" data-test="show-id">
          {{ patient.showId }}
        </div>
      </div>
      <div class="item name" :title="patient.name">
        <div class="label">なまえ</div>
        <div class="value name">
          <span class="emphasis" data-test="name">{{ patient.name }}</span>
        </div>
      </div>
      <div class="item age-birthday" data-test="age-birthday">
        <div class="value age-birthday">
          <span
            v-if="patient.birthday !== '' && calcAge(patient) !== ''"
            class="emphasis"
            data-test="age"
            >{{ calcAge(patient) }}</span
          >
          {{
            patient.birthday ? '/ ' + makeBirthday(patient) : '生年月日未登録'
          }}
        </div>
      </div>
      <div
        class="item species"
        :title="`${makeSpeciesName(patient)}/${patient.breed}`"
      >
        <div class="label">種類</div>
        <div class="value species" data-test="species">
          <span class="emphasis">{{ makeSpeciesName(patient) }}</span>
          {{ patient.breed && ' / ' + patient.breed }}
        </div>
      </div>
      <div class="item sex-name">
        <div class="label">性別</div>
        <div class="value sex-name" data-test="sex-name">
          {{ makePatientSexName(patient) }}
        </div>
      </div>
      <div class="item last-treatment-date">
        <div class="label last-treatment-date">最終診療日</div>
        <div class="value last-treatment-date" data-test="last-treatment-date">
          {{ makeLastTreatmentDate(patient) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { makeBirthday, calcAge } from '@/utils/patient_info'

export default {
  name: 'OwnerPatientListItem',

  props: {
    patientGroup: { type: Array, required: true },
    patientImagesByPatientId: { type: Object }
  },

  data() {
    return {
      makeBirthday,
      calcAge,
      observer: null
    }
  },

  computed: {
    ...mapGetters({
      getMasterData: 'master/getData',
      getSpecies: 'species/getDataById'
    }),
    patientSexes() {
      return this.getMasterData('patientSexes')
    }
  },

  mounted() {
    const target = this.$refs.observerTarget
    this.observer = new IntersectionObserver(() => this.$emit('intersect'))
    this.observer.observe(target)
  },

  beforeDestroy() {
    this.observer = null
  },

  methods: {
    makeSpeciesName(patient) {
      return this.getSpecies(patient.speciesId)?.name || ''
    },
    makePatientSexName(patient) {
      const patientSex = this.patientSexes.find(v => v.id === patient.sex)
      return patientSex ? patientSex.name : ''
    },
    makeLastTreatmentDate(patient) {
      return patient.lastTreatmentDate
        ? moment(patient.lastTreatmentDate, 'YYYYMMDD').format(
            'YYYY年MM月DD日（dd）'
          )
        : '未診療'
    },
    goToMedicalRecordNew(patient) {
      this.$router.push({
        name: 'medical-record-new',
        params: { ownerId: patient.ownerId, patientId: patient.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
.owner-patient-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  border: solid 1px $border-color;
  background-color: #{$very-light-shade-brown};
  padding: 0 15px;
  > .detail {
    display: flex;
    min-height: 50px;
    &.owner {
      font-size: 16px;
      > .item {
        margin-right: 20px;
        &.show-id {
          max-width: 170px;
        }
        &.full-name {
          flex: 1;
          width: 0;
          max-width: fit-content;
        }
        &.tel {
          max-width: 130px;
        }
        &.full-address {
          flex: 1;
          width: 0;
          max-width: fit-content;
        }
      }
    }
    &.patient {
      font-size: 12px;
      cursor: pointer;
      > .item {
        margin-right: 15px;
      }
      &:hover {
        background-color: #{$very-light-shade-orange};
      }
    }
    > .item {
      display: flex;
      align-items: center;
      white-space: nowrap;
      &.image {
        margin-right: 10px;
      }
      > .label {
        margin-right: 10px;
        font-size: 11px;
        > img {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.last-treatment-date {
          max-width: 70px;
        }
      }
      > .value {
        text-overflow: ellipsis;
        overflow: hidden;
        &.show-id {
          font-weight: bold;
          font-size: 11px;
          width: 60px;
          &.owner {
            width: auto;
          }
        }
        &.name {
          width: 150px;
        }
        &.age-birthday {
          width: 150px;
        }
        &.species {
          width: 130px;
        }
        &.sex-name {
          width: 30px;
        }
        &.last-treatment-date {
          width: 130px;
        }
      }
      > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  > .detail ~ .detail {
    border-top: solid 1px $border-color;
  }
}
.emphasis {
  font-weight: bold;
  font-size: 16px;
}
</style>
