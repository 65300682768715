<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="insurance-popup" tabindex="-1" @keyup.esc.stop="closePopup">
      <validation-observer v-slot="{ invalid }">
        <popup
          :invalid="
            invalid || !isValidBirthday || isWaiting || lookOnlyFlg === 1
          "
          @click-close-mark="closePopup"
          @cancel="closePopup"
          @decision="register"
        >
          <template v-slot:content>
            <div class="content">
              <div class="title">保険設定</div>
              <div class="input-form">
                <div class="area patient">
                  <div class="area-head">
                    <div class="text">患者情報</div>
                    <div class="line"></div>
                  </div>
                  <div class="area-body">
                    <div class="row insurance">
                      <select-box-form
                        data-test="insurance"
                        :selectData="insuranceList"
                        :styles="{ width: '150px' }"
                        :value="insuranceToId(inputPatient.insurance)"
                        @input="inputInsurance"
                      >
                        保険
                      </select-box-form>
                    </div>
                    <div class="row birthday" data-test="row-birthday">
                      <birthday-input-form
                        data-test="birthday"
                        :birthday="inputPatient.birthday"
                        :requiredFlg="isBirthdayRequired"
                        :errorShowFlg="isBirthdayRequired"
                        v-model="inputPatient.birthday"
                      >
                        生年月日
                      </birthday-input-form>
                    </div>
                  </div>
                </div>
                <div
                  v-show="inputPatient.insurance"
                  class="area insurance"
                  data-test="area-insurance"
                >
                  <div class="area-head">
                    <div class="text">保険情報</div>
                    <div class="line"></div>
                  </div>
                  <div class="area-body">
                    <div
                      v-show="inputPatient.insurance === 'anicom'"
                      class="row anicom"
                      data-test="row-anicom"
                    >
                      <div class="label space"></div>
                      <validation-observer
                        :disabled="inputPatient.insurance !== 'anicom'"
                      >
                        <anicom-form
                          data-test="anicom"
                          :anicomPatient="inputAnicomPatient"
                          :popoverProps="{ placement: 'top' }"
                          @input="inputAnicom"
                          @renew-period="renewPeriod"
                        />
                      </validation-observer>
                    </div>
                    <div
                      v-show="inputPatient.insurance === 'ipet'"
                      class="row ipet"
                      data-test="row-ipet"
                    >
                      <div class="label space"></div>
                      <validation-observer
                        :disabled="inputPatient.insurance !== 'ipet'"
                      >
                        <ipet-form
                          data-test="ipet"
                          :insurance="inputPatient.insurance"
                          :ipetPatient="inputIpetPatientNative"
                          :popoverProps="{ placement: 'top' }"
                          @input="inputIpet"
                          @renew-period="renewPeriod"
                          @clear-date="clearDate"
                        />
                      </validation-observer>
                    </div>
                    <div
                      v-show="inputPatient.insurance === 'docomo'"
                      class="row docomo"
                      data-test="row-docomo"
                    >
                      <div class="label space"></div>
                      <validation-observer
                        :disabled="inputPatient.insurance !== 'docomo'"
                      >
                        <ipet-form
                          data-test="docomo"
                          :insurance="inputPatient.insurance"
                          :ipetPatient="inputIpetPatientDocomo"
                          :popoverProps="{ placement: 'top' }"
                          @input="inputDocomo"
                          @renew-period="renewPeriod"
                          @clear-date="clearDate"
                        />
                      </validation-observer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        data-test="alert-insurance-popup"
        v-if="alertInsurancePopup.isOpened"
        v-bind="alertInsurancePopup"
        @cancel="alertInsurancePopup.cancel"
        @decision="alertInsurancePopup.decision"
        >{{ alertInsurancePopup.message }}</announce-popup
      >
      <announce-popup
        data-test="success-popup"
        v-if="successPopup.isOpened"
        v-bind="successPopup"
        @close="successPopup.close"
        >{{ successPopup.message }}</announce-popup
      >
      <announce-popup
        data-test="failure-popup"
        v-if="failurePopup.isOpened"
        v-bind="failurePopup"
        @close="failurePopup.close"
        >{{ failurePopup.message }}</announce-popup
      >
      <announce-popup
        data-test="alert-close-popup"
        v-if="alertClosePopup.isOpened"
        v-bind="alertClosePopup"
        @cancel="alertClosePopup.cancel"
        @decision="alertClosePopup.decision"
        >{{ alertClosePopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import AnicomForm from '@/components/parts/organisms/AnicomForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BirthdayInputForm from '@/components/parts/molecules/BirthdayInputForm'
import IpetForm from '@/components/parts/organisms/IpetForm'
import Popup from '@/components/popups/Popup'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import moment from 'moment'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import { VALID_DATE_REGEX } from '@/utils/define'

const baseAnicomPatient = { cId: '', planName: '', startDate: '', endDate: '' }
const baseIpetPatient = {
  planNm: '',
  claimRate: 0,
  policyNo: '',
  startDate: '',
  endDate: '',
  firstContractDate: ''
}

export default {
  name: 'InsurancePopup',

  components: {
    AnicomForm,
    AnnouncePopup,
    BirthdayInputForm,
    FocusTrap,
    IpetForm,
    Popup,
    SelectBoxForm,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patient: { type: Object },
    anicomPatient: { type: Object },
    ipetPatient: { type: Object }
  },

  data() {
    return {
      initialData: {},
      inputData: {},
      inputPatient: {},
      inputAnicomPatient: { ...baseAnicomPatient },
      inputIpetPatientNative: { ...baseIpetPatient },
      inputIpetPatientDocomo: { ...baseIpetPatient },
      insuranceList: [
        { id: 0, name: '未設定', value: '' },
        { id: 1, name: 'アニコム', value: 'anicom' },
        { id: 2, name: 'アイペット', value: 'ipet' },
        { id: 3, name: 'ドコモ', value: 'docomo' }
      ],
      isWaiting: false,
      needGoOwnerPage: false,
      alertInsurancePopup: {
        isOpened: false,
        type: 'alert',
        title: '確認',
        message: '変更前の保険は利用できなくなりますがよろしいですか？',
        buttons: ['キャンセル', 'OK'],
        cancel: () => (this.alertInsurancePopup.isOpened = false),
        decision: () => {}
      },
      successPopup: {
        isOpened: false,
        type: 'success',
        title: '完了',
        message: '登録しました',
        buttons: ['閉じる'],
        close: () => this.$emit('updated')
      },
      failurePopup: {
        isOpened: false,
        type: 'failure',
        title: '失敗',
        message: '',
        buttons: ['閉じる'],
        close: () => {
          this.failurePopup.isOpened = false
          if (this.needGoOwnerPage) {
            this.$router.push({ path: `/main/owners/${this.patient.ownerId}` })
          }
        }
      },
      alertClosePopup: {
        isOpened: false,
        type: 'alert',
        title: '確認',
        message: '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
        buttons: ['キャンセル', '閉じる'],
        cancel: () => (this.alertClosePopup.isOpened = false),
        decision: () => this.$emit('close')
      }
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    isBirthdayRequired() {
      return this.inputPatient.insurance !== ''
    },
    isValidBirthday() {
      const { insurance, birthday } = this.inputPatient
      return insurance ? VALID_DATE_REGEX.test(birthday) : true
    },
    insuranceDetail() {
      const inputKey = this.getCurrentInputKey()
      return this[inputKey] ?? {}
    },
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    }
  },

  watch: {
    insuranceDetail(newVal) {
      this.inputData.insuranceDetail = newVal
    }
  },

  created() {
    // 患者 初期値設定
    this.inputPatient = { ...this.patient }

    // 保険 初期値設定
    const { insurance } = this.inputPatient
    if (insurance === 'anicom' && this.anicomPatient) {
      this.inputAnicomPatient = { ...this.anicomPatient }
    } else if (insurance === 'ipet' && this.ipetPatient) {
      this.inputIpetPatientNative = { ...this.ipetPatient }
    } else if (insurance === 'docomo' && this.ipetPatient) {
      this.inputIpetPatientDocomo = { ...this.ipetPatient }
    }

    this.inputData = {
      patient: this.inputPatient,
      insuranceDetail: this.insuranceDetail
    }
    this.initialData = _.cloneDeep(this.inputData)
  },

  methods: {
    insuranceToId(insurance) {
      const target = this.insuranceList.find(v => v.value === insurance)
      const id = target?.id ?? ''
      return id
    },
    inputInsurance(id) {
      const target = this.insuranceList.find(v => v.id === id)
      const newInsurance = target?.value ?? ''
      const { insurance: oldInsurance } = this.inputPatient
      if (oldInsurance && oldInsurance !== newInsurance) {
        this.inputPatient.insurance = newInsurance // 一度変更しないと元にもどせない
        this.inputPatient.insurance = oldInsurance // 選択前の値に戻す
        this.alertInsurancePopup.decision = () => {
          this.inputPatient.insurance = newInsurance
          this.alertInsurancePopup.isOpened = false
        }
        this.alertInsurancePopup.isOpened = true
      } else {
        this.inputPatient.insurance = newInsurance
      }
    },
    inputAnicom({ key, val }) {
      this.inputAnicomPatient[key] = val
    },
    inputIpet({ key, val }) {
      this.inputIpetPatientNative[key] = val
    },
    inputDocomo({ key, val }) {
      this.inputIpetPatientDocomo[key] = val
    },
    renewPeriod() {
      const inputKey = this.getCurrentInputKey()
      if (this[inputKey]) {
        const addYear = YYYYMMDD =>
          moment(YYYYMMDD, 'YYYYMMDD')
            .add(1, 'year')
            .format('YYYYMMDD')
        this[inputKey].startDate = addYear(this[inputKey].startDate)
        this[inputKey].endDate = addYear(this[inputKey].endDate)
      }
    },
    clearDate() {
      const inputKey = this.getCurrentInputKey()
      const dateKey = 'firstContractDate'
      if (this[inputKey]?.hasOwnProperty(dateKey)) {
        this[inputKey][dateKey] = ''
      }
    },
    getCurrentInputKey() {
      const { insurance } = this.inputPatient
      const inputKeys = {
        anicom: 'inputAnicomPatient',
        ipet: 'inputIpetPatientNative',
        docomo: 'inputIpetPatientDocomo'
      }
      const key = inputKeys[insurance] ?? ''
      return key
    },
    async register() {
      this.isWaiting = true
      const result = await this.$store.dispatch('patients/update', {
        patient: this.inputPatient,
        anicomPatient: this.getInputByCategory('anicom'),
        ipetPatient: this.getInputByCategory('ipet')
      })
      if (result === true) {
        this.initialData = _.cloneDeep(this.inputData)
        this.successPopup.isOpened = true
      } else {
        let message = '登録に失敗しました'
        if (result === 'no patient' || result === 'no data in clinic') {
          message = `登録に失敗しました。\n対象の患者は既に削除されています。`
          this.needGoOwnerPage = true
        }
        this.failurePopup.message = message
        this.failurePopup.isOpened = true
      }
      this.isWaiting = false
    },
    getInputByCategory(category) {
      const { insurance } = this.inputPatient
      const categories = { anicom: 'anicom', ipet: 'ipet', docomo: 'ipet' }
      const currentCategory = categories[insurance]
      const inputKey =
        category === currentCategory ? this.getCurrentInputKey() : ''
      const input = this[inputKey] ?? null
      return input
    },
    closePopup() {
      if (this.diffFlg) {
        this.alertClosePopup.isOpened = true
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.insurance-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    width: 760px;
    min-height: 420px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .input-form {
      margin-top: 30px;
      > .area {
        > .area-head {
          display: flex;
          align-items: center;
          gap: 10px;
          > .text {
            font-size: 15px;
            font-weight: bold;
          }
          > .line {
            flex: 1;
            height: 1px;
            background-color: #{$light-grey};
          }
        }
        > .area-body {
          padding: 20px 20px 0 20px;
          > .row {
            width: 720px;
            margin-bottom: 25px;
            ::v-deep .select-box-form,
            ::v-deep .birthday-input-form {
              > .heading {
                width: 150px;
                margin-right: 0px;
              }
            }
            ::v-deep .anicom-form .row {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
