<template>
  <div class="medical-contents-history-detail">
    <div
      v-if="medicalContent.delFlg"
      class="overlay"
      :class="historyStatus"
      data-test="overlay"
    ></div>
    <div class="rich-text-editor-show" ref="target">
      <div class="dates">
        <div class="date-author" data-test="date-author">
          <div class="item author">
            <div class="label">作成者</div>
            <div class="value" :title="authorNameTime">
              {{ authorNameTime }}
            </div>
          </div>
          <div class="item editor" v-if="showEditor">
            <div class="label">
              {{ historyStatus === 'delete' ? '削除者' : '編集者' }}
            </div>
            <div class="value" :title="editorNameTime">
              {{ editorNameTime }}
            </div>
          </div>
        </div>
        <div class="date-staff">
          <div class="item date">
            <div class="label">診療日</div>
            <div class="value">{{ medicalContent.formatDate }}</div>
          </div>
        </div>
      </div>
      <editor-content class="editor" :editor="editor" data-test="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import {
  configureStarterKit,
  FontSize,
  BackgroundColor,
  createVueRichTextEditorExaminationTable,
  createVueRichTextEditorImage,
  createVueRichTextEditorMedicalContentImage
} from '@/utils/rich_text_editor_custom_options'
import Image from '@tiptap/extension-image'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MedicalContentsHistoryDetail',

  components: {
    EditorContent
  },

  props: {
    medicalContent: {
      type: Object,
      default: function() {
        return {}
      }
    },
    searchRegExp: { type: [RegExp, null], default: null },
    trimSearchText: { type: String },
    displayCount: { type: Number }
  },

  data() {
    return {
      editor: null,
      firstTimeProcessFlg: true
    }
  },

  computed: {
    ...mapGetters({
      getStaff: 'staffs/getDataById'
    }),
    historyStatus() {
      return this.medicalContent.latest.id !== this.medicalContent.id
        ? 'edit'
        : this.medicalContent.latest.delFlg === 1
        ? 'delete'
        : ''
    },
    showEditor() {
      return (
        this.medicalContent.id !== this.medicalContent.original.id ||
        this.historyStatus === 'delete'
      )
    },
    authorNameTime() {
      return `${this.makeStaffName(
        this.medicalContent.original.inputStaffId
      )}（${this.formatDateTime(this.medicalContent.original.createdAt)}）`
    },
    editorNameTime() {
      return this.historyStatus === 'delete'
        ? `${this.makeStaffName(
            this.medicalContent.deleteStaffId
          )}（${this.formatDateTime(this.medicalContent.updatedAt)}）`
        : `${this.makeStaffName(
            this.medicalContent.inputStaffId
          )}（${this.formatDateTime(this.medicalContent.createdAt)}）`
    }
  },

  created() {
    this.setEditor()
  },

  mounted() {
    const target = this.$refs.target
    let observer = new IntersectionObserver(() => {
      this.emitScrollBottom()
    })
    observer.observe(target)
  },

  watch: {
    searchRegExp: function() {
      this.setEditor()
    }
  },

  methods: {
    setEditor() {
      if (this.editor) {
        this.editor.destroy()
        this.editor = null
      }
      const VueRichTextEditorExaminationTable = createVueRichTextEditorExaminationTable(
        {
          patientId: this.medicalContent.patientId,
          medicalContentId: this.medicalContent.id,
          richTextEditorShowFlg: true,
          searchRegExp: this.searchRegExp
        }
      )
      const VueRichTextEditorImage = createVueRichTextEditorImage({
        richTextEditorShowFlg: true,
        isMedicalContentsHistoryDetail: true
      })
      const VueRichTextEditorMedicalContentImage = createVueRichTextEditorMedicalContentImage(
        {
          richTextEditorShowFlg: true,
          searchRegExp: this.searchRegExp,
          isMedicalContentsHistoryDetail: true
        }
      )
      const editor = new Editor({
        content: this.medicalContent.content,
        extensions: [
          configureStarterKit(),
          TextStyle,
          Color,
          FontSize,
          BackgroundColor,
          Image,
          VueRichTextEditorExaminationTable,
          VueRichTextEditorImage,
          VueRichTextEditorMedicalContentImage,
          FontFamily,
          Underline
        ],
        //editable: falseの設定だとjestでエラーが発生します。
        //解決方法が見つからず、また上記の設定をしなくてもテストする内容は変わらないため、
        //テスト環境ではtrueにします
        editable: process.env.NODE_ENV === 'test' ? true : false
      })
      this.editor = editor
      if (this.searchRegExp) {
        //formContent→カルテ登録画面・診療内容フォームと同じ状態の文字列の値を作成
        //※this.editor.getText()でhtmlタグを除いたテキストだけの値を取得できますが、
        //  改行コード\nの数が、実際の画面の診療フォームと違っており、
        //  .setTextSelection処理で位置を指定する時にずれてしまうため使用していないです
        let formContent = ''
        this.editor.getJSON().content.forEach(v => {
          if (v.type.includes('VueRichTextEditor')) {
            formContent += '\n'
          } else {
            if (v.type === 'paragraph' && v.content)
              v.content.forEach(n => {
                if (n.type === 'text') {
                  formContent += n.text
                }
              })
            formContent += '\n\n'
          }
        })
        const searchTextIndexData = []
        let result
        while ((result = this.searchRegExp.exec(formContent))) {
          const previousValue =
            searchTextIndexData[searchTextIndexData.length - 1]
          if (previousValue && previousValue.indexTo === result.index) {
            previousValue.indexTo = result.index + this.trimSearchText.length
          } else {
            searchTextIndexData.push({
              indexFrom: result.index,
              indexTo: result.index + this.trimSearchText.length
            })
          }
        }
        searchTextIndexData.forEach(obj => {
          this.editor
            .chain()
            .setTextSelection({
              from: obj.indexFrom + 1,
              to: obj.indexTo + 1
            })
            .setBackgroundColor('#fce1cc')
            .toggleBold()
            .run()
        })
      }
    },
    emitScrollBottom() {
      if (
        !this.firstTimeProcessFlg &&
        this.medicalContent.number >= this.displayCount
      ) {
        this.$emit('scroll-bottom')
      }
      //このコンポーネントの要素が表示された時、1回emitScrollBottomの処理が走ります
      //その場合、全ての診療内容データを追加するまで連続して処理が走ってしまうため、1回目はemitしないようにしております
      this.firstTimeProcessFlg = false
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('YYYY年MM月DD日 HH:mm')
    },
    makeStaffName(staffId) {
      const staff = this.getStaff(staffId)
      const staffName = staff ? staff.lastName + ' ' + staff.firstName : ''
      return staffName
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-contents-history-detail {
  background-color: #{$white};
  position: relative;
  border: 1px solid #{$light-grey};
  box-sizing: border-box;
  margin-bottom: 10px;
  > .overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
    pointer-events: none;
    &.edit {
      background: repeating-linear-gradient(
        135deg,
        #{$brown-gray},
        #{$brown-gray} 10px,
        transparent 10px,
        transparent 20px
      );
      opacity: 0.3;
    }
    &.delete {
      background-color: #{$brown-gray};
      opacity: 0.3;
    }
  }
  .rich-text-editor-show {
    > .dates {
      font-size: 13px;
      margin: 0 10px 5px 10px;
      padding: 10px 5px 15px 5px;
      border-bottom: 1px solid #{$light-grey};
      > .date-author {
        display: flex;
        > .item {
          display: flex;
          align-items: center;
          flex: 1;
          width: 0;
          > .label {
            width: 45px;
            font-size: 11px;
          }
          > .value {
            flex: 1;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        > .editor {
          padding-left: 10px;
          font-weight: bold;
        }
      }
      > .date-staff {
        margin-top: 8px;
        display: flex;
        > .item {
          display: flex;
          align-items: center;
          flex: 1;
          > .label {
            width: 45px;
            font-size: 11px;
          }
          > .value {
            font-size: 13px;
          }
        }
      }
    }
    > .editor {
      ::v-deep .ProseMirror {
        overflow-x: hidden;
        padding: 5px;
        > p {
          margin: 0;
        }
      }
    }
  }
}
</style>
