var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('focus-trap',{attrs:{"escapeDeactivates":false,"clickDeactivates":false}},[_c('div',{staticClass:"no-apply-reason-popup",attrs:{"tabindex":"-1"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.checkDiff.apply(null, arguments)}}},[_c('validation-observer',{ref:"observer",staticClass:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('popup',{attrs:{"invalid":invalid},on:{"cancel":_vm.checkDiff,"click-close-mark":_vm.checkDiff,"decision":_vm.registerReason},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("保険対象外事由 登録")]),_c('div',{staticClass:"check-list"},[_c('div',{staticClass:"list 1"},_vm._l((_vm.checkList.filter(function (v) { return v.id <= 4; })),function(item){return _c('base-check-box',{key:item.id,attrs:{"isChecked":item.value,"labelText":item.text,"styles":{
                    width: '140px',
                    marginRight: item.id !== 4 ? '30px' : 0
                  },"numFlg":true,"data-test":("checkbox-" + (item.id))},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})}),1),_c('div',{staticClass:"list 2"},_vm._l((_vm.checkList.filter(
                    function (v) { return v.id >= 5 && v.id <= 7; }
                  )),function(item){return _c('base-check-box',{key:item.id,attrs:{"isChecked":item.value,"labelText":item.text,"styles":{ width: '140px', marginRight: '30px' },"numFlg":true,"data-test":("checkbox-" + (item.id))},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})}),1),_c('div',{staticClass:"list 3"},_vm._l((_vm.checkList.filter(function (v) { return v.id === 8; })),function(item){return _c('base-check-box',{key:item.id,attrs:{"isChecked":item.value,"labelText":item.text,"styles":{ width: '140px', marginRight: '0' },"numFlg":true,"data-test":("checkbox-" + (item.id))},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})}),1)]),_c('div',{staticClass:"text"},[_c('validation-provider',{staticClass:"validation-provider",attrs:{"rules":{ notSurrogatePair: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('base-multiple-lines-text-box',{attrs:{"maxlength":256,"placeholder":'256文字以内',"styles":{ width: '650px', height: '150px' }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(errors[0])?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])]},proxy:true}],null,true)})]}}])}),(_vm.popup.show)?_c('announce-popup',{attrs:{"type":_vm.popup.type,"title":_vm.popup.title,"buttons":_vm.popup.buttons,"layerNumber":2},on:{"cancel":_vm.closePopup,"decision":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.popup.message))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }